import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus,FaWallet } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import "../Wallet/Wallet.scss";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { MdOutlineCancel } from "react-icons/md";
import Input from "../../components/input/Input";
import UtilsData, { ValidationMessage } from "../../Utils/Utils";
import {
  addUpdateWalletService,
  getAllWalletService,
} from "./WalletService/WalletService";
import { format } from "date-fns";
import {
  clearWalletPageData,
  getWalletPageData,
} from "../../redux/Actions/WalletAction/WalletPageAction";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { clearWalletFilterData } from "../../redux/Actions/WalletAction/WalletFilterAction";
import {
  clearWalletData,
  getWalletData,
} from "../../redux/Actions/WalletAction/WalletAction";

const Wallet = () => {
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const walletData = useSelector((state) => state.wallet);
  const walletPage = useSelector((state) => state.walletPage?.items);
  const walletFilter = useSelector((state) => state.walletFilter);
  const [currentPage, setCurrentPage] = useState(1);
  const [walletActionData, setWalletActionData] = useState([]);
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [isToggled, setIsToggled] = useState(false);
  const [razorModal, setRazorModal] = useState(false);
  const userData = useSelector((state) => state.auth?.user);
  const walletTypeOptions = [
    { value: "0", label: "Credit" },
    { value: "1", label: "Debit" },
  ];

  const [initialValues, setInitialValues] = useState({
    mobile:
      userData?.data && userData.data?.phoneNumber
        ? userData.data.phoneNumber
        : "",
    amount: "5",
    type: "0",
  });
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .required(ValidationMessage.MOBILE_REQUIRED)
      .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
    amount: Yup.string().required("Amount is required"),
    type: Yup.string().required("Type is required"),
  });

  const showKey = {
    transactionID: true,
    name: true,
    transactionAmount: true,
  };
  const [columns, setColumns] = useState([
    {
      name: "Transaction Number",
      selector: (row) => row.transactionID,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Transaction Amount",
      selector: (row) => row.transactionAmount,
    },
  ]);
  const styleData = {
    quantity: { type: { textAlign: "end" } },
  };

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setWalletActionData(actionData);
    }
  }, [sideBarData]);

  /**
   * Fetch purchase requisition item data
   */
  const fetchWalletData = () => {
    const requestBody = {
      p_skip: walletPage > 1 ? (walletPage - 2) * 10 : (walletPage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (walletFilter.startDate !== "" && walletFilter.startDate !== null) {
      requestBody.p_created_date = walletFilter.startDate;
    }
    if (walletFilter.endDate !== "" && walletFilter.endDate !== null) {
      requestBody.p_end_date = walletFilter.endDate;
    }
    if (walletFilter.status !== "" && walletFilter.status !== null) {
      requestBody.p_status = walletFilter.status;
    }
    if (walletFilter.type !== "" && walletFilter.type !== null) {
      requestBody.p_type = walletFilter.type;
    }
    getAllWalletService(requestBody, tokenData, dispatch);
    if (walletPage > 1) {
      setCurrentPage(walletPage - 1);
      dispatch(getWalletPageData(walletPage - 1));
    }
  };

  useEffect(() => {
    if (
      (walletData && walletData.items && walletData.items.length === 0) ||
      !walletData
    ) {
      fetchWalletData();
    } else {
      if (
        walletFilter?.purchaseRequisitionNo !== "" ||
        (walletFilter?.startDate &&
          walletFilter?.startDate !== format(new Date(), "yyyy-MM-dd")) ||
        walletFilter?.endDate !== "" ||
        walletFilter?.status !== "" ||
        walletFilter?.type !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (walletData.items[0]?.data && walletData.items[0].data.length > 0) {
      const transformedWalletData = walletData.items[0].data.map((item) => {
        return {
          id: item.transactionId,
          name: item.first_name + " " + item.last_name,
          amount: item.amount,
          phNum: item.phone_number,
          prNum: item.pr_number,
          transactionType: item.transaction_type,
          orderStatus: item.status,
          paymentId: item.payment_id,
        };
      });
      setTableData(transformedWalletData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [walletData, walletFilter]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleRazorpayPayment = (amount, mobile, type) => {
    const options = {
      key: "rzp_test_4FB6hVm34EFrgp",
      amount: parseFloat(amount) * 100,
      currency: "INR",
      name: "Bespeakk",
      description: "Test Transaction",
      handler: async (response) => {
        toast.success("Payment successful!");

        // Call another API here after successful payment
        try {
          const requestBody = {
            p_amount: amount,
            p_transaction_type: type,
          };
          console.log("req ",requestBody);
          
          // return
          dispatch(
            addUpdateWalletService(requestBody, tokenData, "add", navigate)
          );
        } catch (error) {
          console.error("API call failed:", error);
          toast.error("Failed to update after payment.");
        }
      },
      modal: {
        ondismiss: () => {
          toast.warn("Payment was cancelled.");
        },
      },
      prefill: {
        name: "Enter Card Holder's Name",
        contact: "9352257062", // Use the mobile parameter here
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new window.Razorpay(options);

    razorpay.on("payment.failed", (response) => {
      toast.error("Payment failed! Please try again.");
    });

    razorpay.open();
  };

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (walletFilter.startDate !== "" && walletFilter.startDate !== null) {
        const date = new Date(walletFilter.startDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }
      if (walletFilter.endDate !== "" && walletFilter.endDate !== null) {
        const date = new Date(walletFilter.endDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const dateTimeFormat = `${year}-${month}-${day}`;
        requestBody.p_end_date = dateTimeFormat;
      }
      if (walletFilter.status !== "" && walletFilter.status !== null) {
        requestBody.p_status = walletFilter.status;
      }
      if (walletFilter.type !== "" && walletFilter.type !== null) {
        requestBody.p_type = walletFilter.type;
      }

      getAllWalletService(requestBody, tokenData, dispatch);
      dispatch(getWalletData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearWalletFilterData());
    dispatch(clearWalletPageData());
    dispatch(clearWalletData());
    setIsToggled(false);
  };

  const handleSubmit = (values) => {
    setRazorModal(!razorModal);
    handleRazorpayPayment(
      values.amount,
      userData.data.phoneNumber,
      values.type
    );
  };

  console.log("wallet ", walletData);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {walletActionData && walletActionData.length > 0
                      ? walletActionData[0].name
                      : "Wallet"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {walletActionData && walletActionData.length > 0
                      ? walletActionData[0].name
                      : "Wallet"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {(walletFilter?.startDate !== "" &&
                  walletFilter?.startDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                (walletFilter?.endDate !== "" &&
                  walletFilter?.endDate !== format(new Date(), "yyyy-MM-dd")) ||
                walletFilter.type !== "" ||
                walletFilter.status !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="wallet" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Balance In Wallet
                  "
                  onClick={() => setRazorModal(!razorModal)}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus razor-btn">
                      <GiWallet />
                    </div>
                  }
                ></Button>
              </div>
            </div>
            {/* {!isToggled && <Filter page="wallet"></Filter>} */}
            <div
              className="wallet-amt"
              data-bs-toggle="tooltip"
              title="Total Balance"
            >
              <p>Total Balance : ₹1000</p>
            </div>
            <CustomTable
              page="wallet"
              showToggle={false}
              checkBool={false}
              showKey={showKey}
              showActions={false}
              showTooltip={true}
              data={walletData}
              heading={columns}
              styleData={styleData}
              disabled={false}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
            {/* {walletData &&
              walletData.items[0]?.data &&
              walletData.items[0].data && (
                <>
                  <CustomTable
                    page="purchase-requisition"
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActions}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    styleData={styleData}
                    disabled={
                      walletActionData &&
                      walletActionData[0]?.edit &&
                      walletActionData[0].edit === "0"
                    }
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />

                  {walletData.items[0].total_records >
                    walletData.items[0].data.length &&
                    walletData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={
                          walletData.items[0].total_records
                        }
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )} */}
          </div>
          <Footer />
        </div>
        {razorModal && (
          <div className="modal-overlay">
            <div className="modal-content-order">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="add-roles-form row">
                      <div>
                        <div>
                          <h4>Enter Details</h4>
                          <button
                            data-bs-toggle="tooltip"
                            title="Close"
                            className="modal-close"
                            onClick={() => setRazorModal(!razorModal)}
                          >
                            <MdOutlineCancel />
                          </button>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Phone Number
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="mobile"
                                  // label="Mobile No"
                                  placeholder="Enter Mobile Number"
                                  className="add-roles"
                                  value={values.mobile}
                                  Regex={/[^0-9\s]/g}
                                  disabled={true}
                                  maxLength={10}
                                  hidelength={"true"}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Amount
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="amount"
                                  // label="Mobile No"
                                  placeholder="Enter Amount"
                                  className="add-roles"
                                  value={values.amount}
                                  Regex={/[^0-9\s]/g}
                                  // disabled={Data}
                                  maxLength={10}
                                  hidelength={"true"}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Amount
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="type"
                                  options={walletTypeOptions}
                                  component={CustomSelect}
                                  value={values.type}
                                  // onchangedata={(option) =>
                                  //   handleTypeChange(option)
                                  // }
                                  placeholder="Type..."
                                  isMulti={false}
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="paragraph-editor">
                                <div className="btn-submit-1 mt-3">
                                  <Button
                                    type="submit"
                                    data-bs-toggle="tooltip"
                                    title="Add Balance"
                                    name="Add Balance"
                                    className="btn razor-btn "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Wallet;
