/**
 * Setting units dropdown list data into reducer
 * @param {*} UnitsDropdown
 * @returns {*}
 */
export const getUnitsDropdownData = (units) => ({
  type: "GET_UNITS_DROPDOWN_DATA",
  payload: units,
});

/**
 * clearing units dropdown list data
 * @returns {*}
 */
export const clearUnitsDropdownData = () => ({
  type: "CLEAR_UNITS_DROPDOWN_DATA",
});

/**
 * Setting total units dropdown list data into reducer (No pagnation)
 * @param {*} UnitsDropdown
 * @returns {*}
 */
export const getTotalUnitsDropdownData = (units) => ({
  type: "GET_TOTAL_UNITS_DROPDOWN",
  payload: units,
});

/**
 * Clearing total units dropdown list data (No pagination)
 * @returns {*}
 */
export const clearTotalUnitsDropdownData = () => ({
  type: "CLEAR_TOTAL_UNITS_DROPDOWN",
});
