import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { getAllBranchService } from "./BranchService/BranchService";
import UtilsData from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import ExcelData from "../../services/Regular-services/ExcelExport";
import {
  clearBranchPageData,
  getBranchPageData,
} from "../../redux/Actions/BranchAction/BranchPageAction";
import Toggle from "../../components/Toggle/Toggle";
import { clearBranchFilterData } from "../../redux/Actions/BranchAction/BranchFilterReducer";
import { clearBranchData } from "../../redux/Actions/BranchAction/BranchAction";

const showToggle = {
  status: true,
  delete: false,
};

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  address: true,
  pincode: true,
};

export default function Branches() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();

  const styleData = {
    pincode: {type: {textAlign: 'center'}}
  }

  const [currentPage, setCurrentPage] = useState(1);

  const [isToggled, setIsToggled] = useState(false);

  const branchData = useSelector((state) => state.branch);
  const branchFilter = useSelector((state) => state.branchFilter);
  const branchPage = useSelector((state) => state.branchPage?.items);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [branchActionData, setbranchActionData] = useState([]);

  const [columns, setColumns] = useState([]);

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setbranchActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      branchActionData[0]?.view &&
      branchActionData[0].view === "0" &&
      branchActionData[0]?.edit &&
      branchActionData[0].edit === "0" &&
      branchActionData[0]?.add &&
      branchActionData[0].add === "0" &&
      branchActionData[0]?.delete &&
      branchActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name ",
          selector: (row) => row.Name,
        },
        {
          name: "Address",
          selector: (row) => row.Address,
        },
        {
          name: "Pincode",
          selector: (row) => row.Pincode,
        },
        {
          name: "Status",
          selector: (row) => row.active,
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name ",
          selector: (row) => row.Name,
        },
        {
          name: "Address",
          selector: (row) => row.Address,
        },
        {
          name: "Pincode",
          selector: (row) => row.Pincode,
        },
        {
          name: "Status",
          selector: (row) => row.active,
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [branchActionData]);

  /**
   * Show actions which are to be displayed in table
   */
  const showActions = {
    showView:
      (branchActionData[0]?.view && branchActionData[0].view === "1") ||
      !branchActionData
        ? true
        : false,
    showEdit:
      (branchActionData[0]?.edit && branchActionData[0].edit === "1") ||
      !branchActionData
        ? true
        : false,
    showDelete:
      (branchActionData[0]?.delete && branchActionData[0].delete === "1") ||
      !branchActionData
        ? true
        : false,
    showUndo:
      (branchActionData[0]?.edit && branchActionData[0].edit === "1") ||
      !branchActionData
        ? true
        : false,
    navigate:
      (branchActionData[0]?.edit && branchActionData[0].edit === "1") ||
      !branchActionData
        ? "/add-branch"
        : false,
  };

  useEffect(() => {
    setCurrentPage(branchPage);
  }, [branchPage]);

  /**
   * Fetch branch data
   */
  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (branchPage > 1) {
      requestBody.p_skip = (branchPage - 2) * 10;
      dispatch(getBranchPageData(branchPage - 1));
    } else {
      requestBody.p_skip = (branchPage - 1) * 10;
      dispatch(getBranchPageData(branchPage));
    }
    if (branchFilter.name !== "" && branchFilter.name !== null) {
      requestBody.p_name = branchFilter.name;
    }
    if (branchFilter.pincode !== "" && branchFilter.pincode !== null) {
      requestBody.p_pincode = branchFilter.pincode;
    }
    if (branchFilter.isActive !== "" && branchFilter.isActive !== null) {
      requestBody.p_is_active = branchFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (branchFilter.isDelete !== "" && branchFilter.isDelete !== null) {
      requestBody.p_IsDeleted = branchFilter.isDelete;
    }
    if (
      branchFilter.isDeliveryAvailable &&
      branchFilter.isDeliveryAvailable !== ""
    ) {
      requestBody.p_delivery_available = branchFilter.isDeliveryAvailable;
    }
    if (
      branchFilter.isPublicAvailable &&
      branchFilter.isPublicAvailable !== ""
    ) {
      requestBody.p_public_avilable = branchFilter.isPublicAvailable;
    }
    if (branchFilter.isWpSmsAllow && branchFilter.isWpSmsAllow !== "") {
      requestBody.p_wp_sms_allow = branchFilter.isWpSmsAllow;
    }
    if (branchFilter.taxType && branchFilter.taxType !== "") {
      requestBody.p_tax_type = branchFilter.taxType;
    }
    getAllBranchService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (branchData && branchData.items.length === 0) {
      fetchBranch();
    }
    if (
      branchFilter.name !== "" ||
      branchFilter.pincode !== "" ||
      branchFilter.isActive !== "" ||
      branchFilter.isDelete !== "" ||
      branchFilter.isDeliveryAvailable !== "" ||
      branchFilter.isPublicAvailable !== "" ||
      branchFilter.isWpSmsAllow !== "" ||
      branchFilter.taxType !== ""
    ) {
      setIsToggled(true);
    }
    // eslint-disable-next-line
  }, [branchData, branchFilter]);

  /**
   * Function for changing page
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (branchFilter.name !== "" && branchFilter.name !== null) {
        requestBody.p_name = branchFilter.name;
      }
      if (branchFilter.pincode !== "" && branchFilter.pincode !== null) {
        requestBody.p_pincode = branchFilter.pincode;
      }
      if (branchFilter.isActive !== "" && branchFilter.isActive !== null) {
        requestBody.p_is_active = branchFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (branchFilter.isDelete !== "" && branchFilter.isDelete !== null) {
        requestBody.p_IsDeleted = branchFilter.isDelete;
      }
      if (
        branchFilter.isDeliveryAvailable &&
        branchFilter.isDeliveryAvailable !== ""
      ) {
        requestBody.p_delivery_available = branchFilter.isDeliveryAvailable;
      }
      if (
        branchFilter.isPublicAvailable &&
        branchFilter.isPublicAvailable !== ""
      ) {
        requestBody.p_public_avilable = branchFilter.isPublicAvailable;
      }
      if (branchFilter.isWpSmsAllow && branchFilter.isWpSmsAllow !== "") {
        requestBody.p_wp_sms_allow = branchFilter.isWpSmsAllow;
      }
      if (branchFilter.taxType && branchFilter.taxType !== "") {
        requestBody.p_tax_type = branchFilter.taxType;
      }
      getAllBranchService(requestBody, tokenData, dispatch);
      setCurrentPage(pageNumber);
      dispatch(getBranchPageData(pageNumber));
    }
  };

  /**
   * Function for changing filters
   */
  const clearFilters = () => {
    dispatch(clearBranchFilterData());
    dispatch(clearBranchData());
    dispatch(clearBranchPageData());
    setIsToggled(false);
  };

  /**
   * Function for handling toggle (if tpggle = true, then false and vice versa)
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {branchActionData && branchActionData.length > 0
                      ? branchActionData[0].name
                      : "Branch"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {branchActionData && branchActionData.length > 0
                      ? branchActionData[0].name
                      : "Branch"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {branchFilter.name !== "" ||
                branchFilter.pincode !== "" ||
                branchFilter.isActive !== "" ||
                branchFilter.isDelete !== "" ||
                branchFilter.isDeliveryAvailable !== "" ||
                branchFilter.isPublicAvailable !== "" ||
                branchFilter.isWpSmsAllow !== "" ||
                branchFilter.taxType !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="branch"   handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>

                {branchData &&
                  branchData.items &&
                  branchData.items[0]?.data &&
                  branchData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={branchData.items[0].data}
                      fileName={"branch"}
                    ></ExcelData>
                  )}
                {((branchActionData[0]?.add &&
                  branchActionData[0].add === "1") ||
                  !branchActionData) && (
                  <Button
                    onClick={() => navigate("/add-branch")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {/* {isToggled && <Filter page="branch"></Filter>} */}
            {branchData &&
              branchData.items &&
              branchData.items[0]?.data &&
              branchData.items[0].data && (
                <>
                  <CustomTable
                    page="branch"
                    showToggle={showToggle}
                    showDescription={true}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    checkBool={false}
                    data={branchData.items[0].data}
                    heading={columns}
                    styleData={styleData}
                    disabled={
                      branchActionData &&
                      branchActionData[0]?.edit &&
                      branchActionData[0].edit === "0"
                    }
                  />

                  {branchData.items[0].total_records >
                    branchData.items[0].data.length &&
                    branchData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={branchData.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
