import { toast } from "react-toastify";
import { API_URL, toastClass, ValidationMessage } from "../../../Utils/Utils";

import { CalculateCurrentTime } from "../../../services/Regular-services/DateService";
import { postApiService } from "../../../services/API-services/PostService";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { Dispatch } from "redux";
import { LoginResponse } from "./LoginModalAPIResponse";
import { login, loginFail } from "../../../redux/Actions/authAction/AuthAction";
import { getSideBarByRoleId } from "../../../components/Sidebar/SidebarService/SidebarService";
import { getUnitsDropdownService } from "../../Units/UnitsService/UnitsService";

/**
 * Service for login API Integration
 * @param email
 * @param password
 * @param rememberMe
 * @param token
 * @param dispatch
 * @returns
 */
// export const loginAuth = (
//   email: string, password: string,
//   rememberMe: boolean, token: string,
//   dispatch: Dispatch): Promise<LoginResponse> => {
//   return new Promise<LoginResponse>(async (resolve, reject) => {
//     try {
//       dispatch(setLoader());
//       // Call your login API endpoint
//       const bodyData = {
//         email: email,
//         password: password
//       }
//       const response = await postApiService(API_URL.BASE_URL + API_URL.LOGIN, token, bodyData)
//       if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
//         const currentLoginTime = CalculateCurrentTime();
//         toast.success('login success!');
//         dispatch(login(response, rememberMe, currentLoginTime));
//         getSideBarByRoleId(response.data.rolePermissionsId, response.token, dispatch);
//         resolve(response)
//       }
//       else {
//         if (response === 'unauthenticated') {
//           toast.error('login failed, please try again!');
//         }
//         else {
//           toast.error(response?.message ? response.message : 'login failed, please try again!');
//         }
//         dispatch(loginFail());
//         // reject(response);
//       }
//     } catch (error) {
//       toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
//       dispatch(loginFail());
//     } finally {
//       dispatch(clearLoader());
//     }
//   });
// };

export const loginAuth = (
  phone: string,
  otp: string,
  token: string,
  dispatch: Dispatch
): Promise<LoginResponse> => {
  return new Promise<LoginResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      // Call your login API endpoint
      const response = await postApiService(
        API_URL.BASE_URL + API_URL.LOGIN + `?phone_number=${phone}&otp=${otp}`,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        const currentLoginTime = CalculateCurrentTime();
        toast.success("login success!");
        dispatch(login(response, currentLoginTime));
        getSideBarByRoleId(
          response.data.rolePermissionsId,
          response.token,
          dispatch
        );
        const unitRequestBody = {
          p_skip: 0,
          p_limit: 1000000,
        };
        getUnitsDropdownService(unitRequestBody, response.token, dispatch);
        resolve(response);
      } else {
        if (response === "unauthenticated") {
          toast.error("login failed, please try again!");
        } else {
          toast.error(
            response?.message
              ? response.message
              : "login failed, please try again!"
          );
        }
        dispatch(loginFail());
        // reject(response);
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      dispatch(loginFail());
    } finally {
      dispatch(clearLoader());
    }
  });
};

/**
 * Service for Request OTP
 * @param email
 * @param password
 * @param rememberMe
 * @param token
 * @param dispatch
 * @returns
 */
export const requestOTP = (
  phone: string,
  token: string,
  setIsOtpRequested: any,
  dispatch: Dispatch
): Promise<LoginResponse> => {
  return new Promise<LoginResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());

      const response = await postApiService(
        API_URL.BASE_URL + `auth/send-otp?phone_number=${phone}`,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success("OTP sent successfully!");
        setIsOtpRequested(true);
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        dispatch(loginFail());
        // reject(response);
      }
    } catch (error) {
      if (!document.querySelector(toastClass)) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      dispatch(loginFail());
    } finally {
      dispatch(clearLoader());
    }
  });
};
