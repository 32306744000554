import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Toggle from "../../components/Toggle/Toggle";
import Filter from "../../components/Filter/Filter";
import UtilsData from "../../Utils/Utils";
import { getAllPurchaseOrderService } from "./PurchaseOrderService/PurchaseOrderService";
import {
  clearPurchaseOrderPageData,
  getPurchaseOrderPageData,
} from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderPageAction";
import { format } from "date-fns";
import { clearPurchaseOrderFilterData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderFilterAction";
import { clearPurchaseOrderData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderAction";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import "../PurchaseOrder/PurchaseOrder.scss";

const PurchaseOrder = () => {
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [purchaseOrderActionData, setPurchaseOrderActionData] = useState([]);
  const purchaseOrderPage = useSelector(
    (state) => state.purchaseOrderPage?.items
  );
  const purchaseOrderFilter = useSelector((state) => state.purchaseOrderFilter);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setPurchaseOrderActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    prNum: true,
    poNum: true,
  };

  const showActions = {
    showView: true,
    showGRN: true,
    showWP: true,
    navigate: "/view-purchase-order",
    navigateGRN: "/add-goods-received-note",
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "PO number",
      selector: (row) => row.poNum,
    },
    {
      name: "PR number",
      selector: (row) => row.prNum,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ];

  const styleData = {
    quantity: { type: { textAlign: "end" } },
  };

  /**
   * Fetch purchase requisition item data
   */
  const fetchPurchaseOrder = () => {
    const requestBody = {
      p_skip:
        purchaseOrderPage > 1
          ? (purchaseOrderPage - 2) * 10
          : (purchaseOrderPage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (
      purchaseOrderFilter.purchaseOrderNo !== "" &&
      purchaseOrderFilter.purchaseOrderNo !== null
    ) {
      requestBody.p_purchase_order_no = purchaseOrderFilter.purchaseOrderNo;
    }
    if (
      purchaseOrderFilter.orderStartDate !== "" &&
      purchaseOrderFilter.orderStartDate !== null
    ) {
      requestBody.p_created_date = purchaseOrderFilter.orderStartDate;
    }
    if (
      purchaseOrderFilter.orderEndDate !== "" &&
      purchaseOrderFilter.orderEndDate !== null
    ) {
      requestBody.p_end_date = purchaseOrderFilter.orderEndDate;
    }

    getAllPurchaseOrderService(requestBody, tokenData, dispatch);
    if (purchaseOrderPage > 1) {
      setCurrentPage(purchaseOrderPage - 1);
      dispatch(getPurchaseOrderPageData(purchaseOrderPage - 1));
    }
  };

  useEffect(() => {
    if (
      (purchaseOrderData &&
        purchaseOrderData.items &&
        purchaseOrderData.items.length === 0) ||
      !purchaseOrderData
    ) {
      fetchPurchaseOrder();
    } else {
      if (
        purchaseOrderFilter?.purchaseOrderNo !== "" ||
        (purchaseOrderFilter?.orderStartDate &&
          purchaseOrderFilter?.orderStartDate !==
            format(new Date(), "yyyy-MM-dd")) ||
        purchaseOrderFilter?.orderEndDate !== "" ||
        (purchaseOrderFilter?.vendor && purchaseOrderFilter.vendor !== "") ||
        purchaseOrderFilter?.status !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (
      purchaseOrderData.items[0]?.data &&
      purchaseOrderData.items[0].data.length > 0
    ) {
      const transformedPurchaseRequisitionData =
        purchaseOrderData.items[0].data.map((item) => {
          return {
            id: item.id,
            name:
              item.vendor_user_first_name + " " + item.vendor_user_last_name,
            phNum: item.vendor_user_phone_number,
            poNum: item.po_number,
            prNum: item.pr_number,
            prDate: item.pr_date,
            orderStatus: item.status,
            instructions: item.instructions,
            ingredients: item.ingredients_data,
            timeZone: item.timeZone,
            vendorUserId: item.vendor_user_id,
            modifiedAt: item.modified_at,
            vendor_user_id: item.vendor_user_id,
            assign_user_id: item.assign_user_id,
            pr_id: item.pr_id,
          };
        });
      setTableData(transformedPurchaseRequisitionData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [purchaseOrderData, purchaseOrderFilter]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (
        purchaseOrderFilter.purchaseOrderNo !== "" &&
        purchaseOrderFilter.purchaseOrderNo !== null
      ) {
        requestBody.p_purchase_order_no = purchaseOrderFilter.purchaseOrderNo;
      }
      if (
        purchaseOrderFilter.orderStartDate !== "" &&
        purchaseOrderFilter.orderStartDate !== null
      ) {
        const date = new Date(purchaseOrderFilter.orderStartDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }
      if (
        purchaseOrderFilter.orderEndDate !== "" &&
        purchaseOrderFilter.orderEndDate !== null
      ) {
        const date = new Date(purchaseOrderFilter.orderEndDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const dateTimeFormat = `${year}-${month}-${day}`;
        requestBody.p_end_date = dateTimeFormat;
      }

      getAllPurchaseOrderService(requestBody, tokenData, dispatch);
      dispatch(getPurchaseOrderPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearPurchaseOrderFilterData());
    dispatch(clearPurchaseOrderPageData());
    dispatch(clearPurchaseOrderData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {purchaseOrderActionData &&
                    purchaseOrderActionData.length > 0
                      ? purchaseOrderActionData[0].name
                      : "Purchase Requisition"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {purchaseOrderActionData &&
                    purchaseOrderActionData.length > 0
                      ? purchaseOrderActionData[0].name
                      : "Purchase Order"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {purchaseOrderFilter.purchaseOrderNo !== "" ||
                (purchaseOrderFilter?.orderStartDate !== "" &&
                  purchaseOrderFilter?.orderStartDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                (purchaseOrderFilter?.orderEndDate !== "" &&
                  purchaseOrderFilter?.orderEndDate !==
                    format(new Date(), "yyyy-MM-dd")) ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-postion">
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="purchase-order" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
              </div>
            </div>
            {/* {!isToggled && <Filter page="purchase-order"></Filter>} */}
            {purchaseOrderData &&
              purchaseOrderData.items[0]?.data &&
              purchaseOrderData.items[0].data && (
                <>
                  <CustomTable
                    page="purchase-order"
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActions}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    styleData={styleData}
                    disabled={
                      purchaseOrderActionData &&
                      purchaseOrderActionData[0]?.edit &&
                      purchaseOrderActionData[0].edit === "0"
                    }
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />

                  {purchaseOrderData.items[0].total_records >
                    purchaseOrderData.items[0].data.length &&
                    purchaseOrderData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={purchaseOrderData.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PurchaseOrder;
