const productPricingPageInitialState = {
  items: 1,
};

/**
 * store product pricing page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const productPricingPageReducer = (
  state = productPricingPageInitialState,
  action
) => {
  switch (action.type) {
    case "GET_PRODUCT_PRICING_PAGE":
      return {
        ...state,
        items: action.payload,
      };
    case "CLEAR_PRODUCT_PRICING_PAGE":
      return productPricingPageInitialState;
    default:
      return state;
  }
};
