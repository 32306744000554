import React, { useEffect } from "react";
import "./Sidebar.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./Sidebarmenu";
import { useDispatch, useSelector } from "react-redux";
import { getIcon } from "../../services/Regular-services/IconService";
import Logo from "../../assets/images/Bespeakk-logo.png";
import { OpenSidebarAction } from "../../redux/Actions/OpenSideBarAction/OpenSideBarAction";
import { listPagePaths } from "../../Utils/Utils";

export default function Sidebar() {
  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(window.innerWidth <= 991 ? false : true);
  const order = useSelector((state) => state.order);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  useEffect(() => {
    dispatch(OpenSidebarAction(isOpen));
    // eslint-disable-next-line
  }, [isOpen]);

  const sideBarData = useSelector((state) => state.sideBar?.items);
  const [routeData, setRouteData] = useState([]);

  useEffect(() => {
    if (Array.isArray(sideBarData)) {
      const sortedSideBarData = [...sideBarData].sort(
        (a, b) => a.sequence - b.sequence
      );
      let newRouteData = [];
      const menuMap = {};

      for (const item of sortedSideBarData) {
        const routeItem = {
          ...item,
          path: item?.router_link ? "/" + item.router_link : null,
          name: item.name,
          icon: getIcon(item.icon),
          subRoutes: [],
        };

        // Check if item should show order count in name
        if (item.name === "Orders") {
          const itemCount =
            order?.dataCount && order.dataCount > 0 ? order.dataCount : 0;
          routeItem.name = (
            <span>
              {item.name}{" "}
              {itemCount > 0 && <span className="pending">({itemCount})</span>}
            </span>
          );
        }

        menuMap[item.menu_id] = routeItem;

        if (item.parent_id === 0) {
          newRouteData.push(routeItem);
        } else if (![41, 2, 3, 1, 14, 54].includes(item.parent_id)) {
          if (menuMap[item.parent_id]) {
            if (!routeItem?.path) {
              menuMap[item.parent_id].subRoutes.push(routeItem);
            } else if (routeItem.allow === "1") {
              menuMap[item.parent_id].subRoutes.push(routeItem);
            }
          }
        } else {
          if (item.allow === "1") {
            newRouteData.push({
              ...item,
              path: "/" + item.router_link,
              name: item.name,
              icon: getIcon(item.icon),
              allow: item.allow,
              subRoutes: [],
            });
          }
        }
      }

      // Recursive filtering function
      const filterRoutes = (routes) => {
        return routes
          .map((route) => {
            if (route.subRoutes && route.subRoutes.length > 0) {
              route.subRoutes = filterRoutes(route.subRoutes);
            }
            return route;
          })
          .filter(
            (route) =>
              !(
                (route.subRoutes.length === 0 && route.allow === "0") ||
                (route.parent_id === 0 && route.subRoutes.length === 0)
              )
          );
      };
      // Apply filtering
      const filteredRouteData = filterRoutes(newRouteData);
      setRouteData(filteredRouteData);
    }
    // eslint-disable-next-line
  }, [sideBarData, order]);

  const toggle = () => setIsOpen(!isOpen);
  const [activeUrl, setActiveUrl] = useState(null);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0,
      },
    },
  };

  useEffect(() => {
    const scrollToActive = () => {
      setTimeout(() => {
        const activeMenuItem = document.querySelector(".link.active");
        if (activeMenuItem) {
          activeMenuItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }, 300); // Delay to allow DOM updates
    };
    scrollToActive();
  }, [activeUrl, location]);

  useEffect(() => {
    routeData.some((route) => {
      const matchedPagePath = listPagePaths.find(
        (item) => item.pagePath === location.pathname
      );
      if (!route.subRoute) {
        if (
          route.path === location.pathname ||
          (matchedPagePath && matchedPagePath.listPath === route.path)
        ) {
          setActiveUrl(route.path);
          return true;
        }
      }
      setActiveUrl(null);
      setIsToggled(false);
      return false;
    });
    // eslint-disable-next-line
  }, [location.pathname, routeData]);
  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "280px" : "50px",
            transition: {
              duration: 0,
              damping: 10,
            },
          }}
          className="sidebar scrollbar-width-none"
        >
          <section className="top_section">
            {isOpen && (
              <h1 className="logo">
                <img src={Logo} alt="Logo" />
              </h1>
            )}
            <div className="bars" onClick={handleButtonClick}>
              <MdKeyboardArrowRight
                className={isToggled ? "main" : ""}
                onClick={toggle}
              />
            </div>
          </section>
          <section className="routes scrollbar-width-none">
            {routeData.map((route, index) => {
              if (
                route?.subRoutes &&
                Array.isArray(route?.subRoutes) &&
                route.subRoutes.length > 0
              ) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className={`link ${activeUrl === route.path ? "active" : ""}`}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
      </div>
    </>
  );
}
