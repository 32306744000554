import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import Toggle from "../../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateTagService } from "../TagService/TagService";
import { modifyString } from "../../../services/Regular-services/StringService";

//Validation schema
const validationSchema = Yup.object({
  tagTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
});

export default function AddTag() {
  //Setting initial values
  const [initialValues, setInitialValues] = useState({
    tagTitle: "",
    status: true,
  });

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  const tagPage = useSelector((state) => state.tagPage?.items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const token = UtilsData();

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [tagActionData, settagActionData] = useState([]);
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "tags"
      );
      settagActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        tagTitle: Data.data.title,
        status:
          Data.data.isActive === "0" ||
          Data.data.isActive === true ||
          Data.data.isActive === 1
            ? false
            : true,
      });
    }
  }, [Data]);

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    if (!Data) {
      const requestBody = {
        p_id: 0,
        p_title: values.tagTitle,
        p_isActive:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_IsDeleted: "0",
        p_createdBy: userData.data.userId,
      };
      dispatch(addUpdateTagService(requestBody, token, "add", navigate));
    } else {
      const requestBody = {
        p_id: Data.data.tagId,
        p_title: values.tagTitle,
        p_isActive:
          values.status === true || values.status === 1 || values.status === "1"
            ? "1"
            : "0",
        p_IsDeleted: Data.data.isDeleted,
        p_createdBy: Data.data.createdBy,
        p_modifiedBy: userData.data.userId,
      };
      if (Data?.length && tagPage > 1) {
        if (
          values.status === "0" ||
          values.status === 0 ||
          values.status === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateTagService(
                requestBody,
                token,
                "update",
                navigate,
                tagPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateTagService(
                requestBody,
                token,
                "update",
                navigate,
                tagPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateTagService(
              requestBody,
              token,
              "update",
              navigate,
              tagPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateTagService(requestBody, token, "update", navigate),
          "update"
        );
      }
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {tagActionData && tagActionData.length > 0
                      ? modifyString(tagActionData[0].name)
                      : "Tags"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/tags")}>
                    {tagActionData && tagActionData.length > 0
                      ? tagActionData[0].name
                      : "Tags"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {tagActionData && tagActionData.length > 0
                      ? modifyString(tagActionData[0].name)
                      : "Tags"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {tagActionData && tagActionData.length > 0
                  ? modifyString(tagActionData[0].name)
                  : "Tags"}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-12">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Tag title <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="tagTitle"
                          label="Title"
                          placeholder="Enter Tag title"
                          className="add-roles"
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field
                          name="status"
                          className="toggle"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/tags", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
