const productInventoryPageInitialState = { 
    items: 1
};

/**
 * Store product inventory page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const ProductInventoryPageReducer = (state = productInventoryPageInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_INVENTORY_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_PRODUCT_INVENTORY_PAGE':
            return productInventoryPageInitialState;
        default:
            return state;
      };
}