import React, { useEffect, useRef, useState } from "react";
import "./AddOrder.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../components/input/Input";
import UtilsData, { toastClass, ValidationMessage } from "../../../Utils/Utils";
import Button from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { getAllProductService } from "../../Product/ProductService/ProductService";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailService,
  placeOrderService,
} from "../OrderService/OrderService";
import OrderCart from "../../../components/OrderCart/OrderCart";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {
  getAllDiscountService,
  getDiscountById,
} from "../../Discount/DiscountService/DiscountService";
import { addUpdateOrderRequest } from "./AddUpdateOrderRequest/AddUpdateOrderRequest";
import { MdOutlineCancel } from "react-icons/md";
import { modifyString } from "../../../services/Regular-services/StringService";
import { getAllSpotService } from "../../Spot/SpotService/SpotService";
import { FaPlus } from "react-icons/fa6";
import { getAllCategoryService } from "../../Category/Category-service/CategoryService";
import { getAllSubCategoryService } from "../../SubCategory/SubCategoryService/SubCategoryService";
import { clearTotalSubCategoryData } from "../../../redux/Actions/subCategoryAction/SubCategoryAction";

export default function AddOrder() {
  /**
   * Getting data from brand table
   */
  const location = useLocation();
  const { Data } = location.state || {};
  const { tableNo } = location.state || {};
  const navigate = useNavigate();
  const totalProduct = useSelector((state) => state.totalProduct);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [addedProducts, setAddedProducts] = useState([]);
  const [totalAmountData, setTotalAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [appliedDiscountCode, setAppliedDiscountCode] = useState(null);
  const [updatedTotalAmt, setUpdatedTotalAmt] = useState(null);
  const [discountAmt, setDiscountAmt] = useState(null);
  const [discountID, setDiscountID] = useState(null);
  const [discountDetail, setDiscountDetail] = useState(null);
  const totalCategory = useSelector((state) => state.totalCategory);

  const orderCartRef = useRef();

  const openModal = (productData) => {
    if (orderCartRef.current) {
      orderCartRef.current.openModal(productData); // Pass product data
    }
  };

  const [message, setMessage] = useState("");
  const authuserData = useSelector((state) => state.auth?.user);
  // const totalBrand = useSelector((state) => state.totalBrand);
  // const [activeIndex, setActiveIndex] = useState(null);
  // const [brandData, setBrandData] = useState([]);
  const [discountOption, setDiscountOption] = useState([]);

  const [userDetailsModal, setUserDetailModal] = useState(false);

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [orderActionData, setOrderActionData] = useState([]);
  const spotData = useSelector((state) => state.spot);
  const [tableOptions, setTableOptions] = useState([]);
  // const [attributeModal, setAttributeModal] = useState(false);

  const [productCategory, setProductCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [subCategoryActiveIndex, setSubCategoryActiveIndex] = useState(null);
  const subCategoryData = useSelector((state) => state.totalSubCategory);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "orders"
      );
      setOrderActionData(actionData);
    }
  }, [sideBarData]);

  const totalDiscount = useSelector((state) => state.totalDiscount);
  const dispatch = useDispatch();
  const token = UtilsData();

  const fetchTotalDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 50000,
    };
    getAllDiscountService(requestBody, token, dispatch, true);
  };

  const fetchCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 500000,
      p_is_active: "1",
      p_type: "0",
    };
    getAllCategoryService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalCategory &&
      totalCategory.items &&
      totalCategory.items.length === 0
    ) {
      fetchCategory();
    } else {
      if (
        totalCategory.items[0]?.data &&
        totalCategory.items[0].data.length > 0
      ) {
        setProductCategory(totalCategory.items[0].data);
      } else {
        setProductCategory([]);
      }
    }
    // eslint-disable-next-line
  }, [totalCategory]);

  /**
   * Click on category filter
   * @param {*} category
   * @param {*} index
   * @returns
   */
  const clickCategory = (category, index) => {
    const categoryData = [category.categoryId];
    if (categoryId && categoryId.length > 0) {
      const duplicateCategory = categoryId.find(
        (item) => item === category.categoryId
      );
      if (duplicateCategory) {
        setActiveIndex(null);
        setSubCategoryActiveIndex(null);
        setCategoryId([]);
        setSubCategoryId([]);
        dispatch(clearTotalSubCategoryData());
        const productRequestBody = {
          p_skip: 0,
          p_take: 5000,
          p_is_active: "1",
        };
        getAllProductService(productRequestBody, token, dispatch, true, true);
        return;
      }
    }
    setActiveIndex(index);
    setSubCategoryActiveIndex(null);
    setCategoryId(categoryData);
    setSubCategoryId([]);
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_categoryIds: categoryData.join(","),
      p_is_active: "1",
    };
    getAllSubCategoryService(requestBody, token, dispatch, true, true);

    const productRequestBody = {
      p_skip: 0,
      p_take: 5000,
      p_category_id: categoryData.toString(),
      p_is_active: "1",
    };
    getAllProductService(productRequestBody, token, dispatch, true, true);
  };

  /**
   * Click on sub-category filter
   * @param {*} subCategory
   * @param {*} index
   * @returns
   */
  const clickSubCategory = (subCategory, index) => {
    const subCategoryData = [subCategory.id];
    if (subCategoryId && subCategoryId.length > 0) {
      const duplicateSubCategory = subCategoryId.find(
        (item) => item === subCategory.id
      );
      if (duplicateSubCategory) {
        setSubCategoryId([]);
        setSubCategoryActiveIndex(null);
        const productRequestBody = {
          p_skip: 0,
          p_take: 5000,
          p_category_id: categoryId.join(","),
          p_is_active: "1",
        };
        getAllProductService(productRequestBody, token, dispatch, true, true);
        return;
      }
    }
    setSubCategoryId(subCategoryData);
    setSubCategoryActiveIndex(index);
    const productRequestBody = {
      p_skip: 0,
      p_take: 5000,
      p_category_id: categoryId.join(","),
      p_sub_category_id: subCategoryData.join(","),
      p_is_active: "1",
    };
    getAllProductService(productRequestBody, token, dispatch, true, true);
  };

  useEffect(() => {
    if (
      totalDiscount &&
      totalDiscount.items &&
      totalDiscount.items.length === 0
    ) {
      fetchTotalDiscount();
    } else {
      if (
        totalDiscount.items &&
        totalDiscount.items[0] &&
        totalDiscount.items[0]?.data &&
        totalDiscount.items[0].data.length > 0
      ) {
        const currentDate = new Date();
        const validDiscounts = totalDiscount.items[0].data.filter((item) => {
          const endDate = new Date(item.end_date);
          return endDate > currentDate;
        });

        const optionData = validDiscounts.map((item) => ({
          value: item.discountId,
          label: item.discountCode,
        }));
        setDiscountOption(optionData);
      } else {
        setDiscountOption([]);
      }
    }
    // eslint-disable-next-line
  }, [totalDiscount, Data]);

  const [productOptions, setProductOptions] = useState([]);

  const quantityOptions = [];

  for (let i = 1; i <= 100; i++) {
    quantityOptions.push({ value: i.toString(), label: i.toString() });
  }

  const [dineOptions, setDineOptions] = useState([
    { value: "0", label: "Dine In" },
    { value: "1", label: "Take Away" },
    { value: "2", label: "Delivery" },
    { value: "3", label: "Zomato" },
    { value: "4", label: "Swiggy" },
  ]);

  const [inputData, setInputData] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);

  const [initialValues, setInitialValues] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    tableNo: "",
    product: "",
    size: "",
    price: "",
    quantity: "",
    dineIn: "0",
    status: "1",
    payment: "0",
    zomatoSwiggyPrice: null,
    discount: "",
    productCode: "",
  });

  const [disableName, setDisableName] = useState(true);

  const paymentOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "UPI" },
    { value: "2", label: "Card" },
    { value: "3", label: "Cash" },
    { value: "5", label: "Zomato" },
    { value: "6", label: "Swiggy" },
    { value: "4", label: "Other" },
  ];

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      // mobile: Yup.string()
      //   .required(ValidationMessage.MOBILE_REQUIRED)
      //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
      // firstName: Yup.string().required(ValidationMessage.FIRST_NAME_REQUIRED),
      // lastName: Yup.string().required(ValidationMessage.LAST_NAME_REQUIRED),
      dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
      payment: Yup.string().required("Payment is required!"),
    })
  );

  // changes by sakshi
  const handleSelectedAttributes = (data) => {
    // Function to check if data is grouped
    const isGrouped = (data) => {
      return (
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).length > 0
      );
    };

    // Group data if it's not already grouped
    const groupedAttributes = isGrouped(data)
      ? data
      : data.reduce((acc, attr) => {
          const { cat_name } = attr;

          if (!acc[cat_name]) {
            acc[cat_name] = [];
          }

          acc[cat_name].push(attr);
          return acc;
        }, {});

    return groupedAttributes;
  };

  useEffect(() => {
    if (Data && Data.data) {
      if (Data.data.type === "2" || Data.data.type === 2) {
        setDineOptions([
          { value: "0", label: "Dine In" },
          { value: "1", label: "Take Away" },
          { value: "2", label: "Delivery" },
          { value: "3", label: "Zomato" },
          { value: "4", label: "Swiggy" },
        ]);
      } else {
        setDineOptions([
          { value: "0", label: "Dine In" },
          { value: "1", label: "Take Away" },
          { value: "3", label: "Zomato" },
          { value: "4", label: "Swiggy" },
        ]);
      }
      fetchOrderDetail();
    } else {
      setDineOptions([
        { value: "0", label: "Dine In" },
        { value: "1", label: "Take Away" },
        { value: "3", label: "Zomato" },
        { value: "4", label: "Swiggy" },
      ]);
    }
    // eslint-disable-next-line
  }, [Data, totalProduct]);
  useEffect(() => {
    if (initialValues.dineIn === "0") {
      setValidationSchema(
        Yup.object({
          // mobile: Yup.string()
          //   .required(ValidationMessage.MOBILE_REQUIRED)
          //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
          // firstName: Yup.string().required(
          //   ValidationMessage.FIRST_NAME_REQUIRED
          // ),
          // lastName: Yup.string().required(
          //   ValidationMessage.LAST_NAME_REQUIRED
          // ),
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    } else if (initialValues.dineIn === "1" || initialValues.dineIn === "2" || initialValues.dineIn === "3" || initialValues.dineIn === "4" ) {
      setValidationSchema(
        Yup.object({
          // mobile: Yup.string()
          //   .required(ValidationMessage.MOBILE_REQUIRED)
          //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
          // firstName: Yup.string().required(
          //   ValidationMessage.FIRST_NAME_REQUIRED
          // ),
          // lastName: Yup.string().required(
          //   ValidationMessage.LAST_NAME_REQUIRED
          // ),
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          // tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    }
  }, [initialValues]);

  useEffect(() => {
    if (tableNo) {
      setInitialValues({
        mobile: "",
        firstName: "",
        lastName: "",
        tableNo: Number(tableNo.table_no),
        product: "",
        size: "",
        price: "",
        quantity: "",
        dineIn: "0",
        status: "1",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "",
      });
    }
  }, [tableNo]);

  const fetchOrderDetail = async () => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      let discountOnEdit = { status: "Fail" };
      if (
        Data.data?.discount_id &&
        Data.data?.discount_id !== "" &&
        Data.data?.discount_id !== "NULL" &&
        Data.data?.discount_id !== "Null" &&
        Data.data?.discount_id !== "null"
      ) {
        discountOnEdit = await getDiscountById(
          Data.data.discount_id,
          token,
          dispatch,
          true
        );

        if (
          discountOnEdit?.status &&
          (discountOnEdit.status === "success" ||
            discountOnEdit.status === "200" ||
            discountOnEdit.status === 200)
        ) {
          const currentDate = new Date();
          const endDate = new Date(discountOnEdit?.data?.end_date);
          if (endDate > currentDate) {
            setAppliedDiscountCode(Data.data.Discount_Code);
            setDiscountAmt(Data.data.total_discount_amount);

            setDiscountID(Data.data.discount_id);
            setDiscountDetail(discountOnEdit?.data || null);
          } else {
            if (!document.querySelector(toastClass)) {
              toast.warn(
                "Discount code (" + Data.data.Discount_Code + ") is expired!"
              );
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.warn(
              "Discount code (" +
                Data.data.Discount_Code +
                ") is no more applicable!"
            );
          }
        }
      }

      setDeliveryCharge(
        Data.data?.delivery_charge ? Number(Data.data.delivery_charge) : 0
      );

      const filteredProductData = Data?.data?.productData?.map(
        (addedProduct) => {
          const product = totalProduct.items[0].data.find(
            (product) => product.productId === addedProduct.productId
          );

          return {
            ...product,
            quantity: addedProduct.quantity,
            food_type_one:
              addedProduct?.food_type_1 &&
              addedProduct?.food_type_1 !== "" &&
              addedProduct?.food_type_1 !== "null" &&
              addedProduct?.food_type_1 !== "NULL"
                ? addedProduct.food_type_1
                : "0",
            food_type_jain_regular:
              addedProduct?.food_type_2 &&
              addedProduct.food_type_2 !== "" &&
              addedProduct.food_type_2 !== "null" &&
              addedProduct.food_type_2 !== "NULL"
                ? addedProduct.food_type_2
                : "2",
            product_instruction:
              addedProduct?.product_instruction &&
              addedProduct.product_instruction !== "" &&
              addedProduct.product_instruction !== "NULL" &&
              addedProduct.product_instruction !== "null"
                ? addedProduct.product_instruction
                : null,
            // changes by sakshi
            selectedAttributes: addedProduct?.product_attribute
              ? handleSelectedAttributes(addedProduct.product_attribute)
              : null,
            price: addedProduct.price,
            map_table_id: addedProduct?.map_table_id
              ? addedProduct.map_table_id
              : 0,
            status: addedProduct.productStatus,
          };
        }
      );
      const updatedAddedProduct = filteredProductData.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          // Flattening data dynamically
          const flattenedData = Object.values(item.selectedAttributes) // Get all values (arrays) of the object
            .flat() // Flatten the arrays into one single array
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value), // Convert 'value' to a number
            }));

          // Calculate the total value
          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });

      setAddedProducts(updatedAddedProduct);

      // setAddedProducts(filteredProductData);

      setMessage(Data.data.instructions);

      const currentDiscountDate = new Date();
      const endDiscountDate = new Date(discountOnEdit?.data?.end_date);

      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.firstName &&
          Data.data.firstName !== "" &&
          Data.data.firstName !== "N/A"
            ? Data.data.firstName
            : "",
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Number(Data.data.tableNo) : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: Data.data.payment,
        zomatoSwiggyPrice: Data.data.zomatoSwiggyPrice,
        discount:
          discountOnEdit?.status &&
          (discountOnEdit.status === "200" ||
            discountOnEdit.status === 200 ||
            discountOnEdit === "success") &&
          endDiscountDate > currentDiscountDate
            ? Data.data.discount_id
            : "",
      });
      // setTotalAmount(Data.data.total_amount_with_tax)
      // setUpdatedTotalAmt(Data.data.total_amount_with_tax)
    } else {
      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.firstName &&
          Data.data.firstName !== "" &&
          Data.data.firstName !== "N/A"
            ? Data.data.firstName
            : "",
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Data.data.table_no : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "0",
      });
      setUpdatedTotalAmt(0);
    }

    if (
      Data.data?.phoneNumber &&
      Data.data.phoneNumber !== "" &&
      Data.data.phoneNumber !== "N/A"
    ) {
      setDisableName(false);
    }
  };

  const changeDineInData = (value, setFieldValue) => {
    if (value === "0") {
      setValidationSchema(
        Yup.object({
          // mobile: Yup.string()
          //   .required(ValidationMessage.MOBILE_REQUIRED)
          //   .max(ValidationMessage.MOBILE_LENGTH)
          //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
          // firstName: Yup.string().required(
          //   ValidationMessage.FIRST_NAME_REQUIRED
          // ),
          // lastName: Yup.string().required(ValidationMessage.LAST_NAME_REQUIRED),
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    } else {
      setValidationSchema(
        Yup.object({
          // mobile: Yup.string()
          //   .required(ValidationMessage.MOBILE_REQUIRED)
          //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
          // firstName: Yup.string().required(
          //   ValidationMessage.FIRST_NAME_REQUIRED
          // ),
          // lastName: Yup.string().required(ValidationMessage.LAST_NAME_REQUIRED),
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
      setFieldValue("tableNo", "");
    }
    setFieldValue("dineIn", value);
    setInitialValues((prevState) => ({
      ...prevState,
      dineIn: value,
    }));
    if (addedProducts && addedProducts.length > 0) {
      if (inputData && inputData !== "") {
        const filteredInputProduct = totalProduct.items[0].data.filter(
          (product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const updatedData = filteredInputProduct.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      } else {
        const updatedData = productOptions.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });
        // Set the updated data
        setProductOptions(updatedData);
      }
      const updatedAddedProduct = addedProducts.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          // Flattening data dynamically
          const flattenedData = Object.values(item.selectedAttributes) // Get all values (arrays) of the object
            .flat() // Flatten the arrays into one single array
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value), // Convert 'value' to a number
            }));

          // Calculate the total value
          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });

      setAddedProducts(updatedAddedProduct);
      const totalAmount = updatedAddedProduct.reduce(
        (sum, product) =>
          sum + Number(product.newPrice) * Number(product.quantity),
        0
      );
      setTotalAmount(totalAmount);
      setUpdatedTotalAmt(totalAmount - discountAmt);
    } else {
      if (inputData && inputData !== "") {
        const filteredInputProduct = totalProduct.items[0].data.filter(
          (product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const updatedData = filteredInputProduct.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      } else {
        const updatedData = productOptions.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      }
      setUpdatedTotalAmt(0);
    }
  };

  useEffect(() => {
    if (
      (totalProduct && totalProduct.items && totalProduct.items.length <= 0) ||
      !totalProduct
    ) {
      const requestBody = {
        p_skip: 0,
        p_take: 5000,
        p_is_active: "1",
      };
      getAllProductService(requestBody, token, dispatch, true);
    }
    // eslint-disable-next-line
  }, [totalProduct]);

  useEffect(() => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      if (addedProducts && addedProducts.length > 0) {
        // const filteredProductData = totalProduct.items[0].data.filter(
        //   (product) => {
        //     const isNotInAddedProducts = !addedProducts.some(
        //       (addedProduct) =>
        //         addedProduct.productId === product.productId &&
        //         ((addedProduct?.status && addedProduct.status === "0") ||
        //           !addedProduct?.status)
        //     );
        //     const hasAttributeData =
        //       product.attribute_data !== null &&
        //       product.attribute_data !== undefined &&
        //       product.attribute_data !== "null" &&
        //       product.attribute_data !== "NULL";

        //     const jainRegular =
        //       (product?.jain_or_not === "1" || product?.jain_regular === "0") &&
        //       product?.veg_nonveg !== "1";

        //     return hasAttributeData || jainRegular || isNotInAddedProducts;
        //   }
        // );
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          const updatedData = filteredInputProduct.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });
          // Set the updated data
          setProductOptions(updatedData);
        } else {
          const data = totalProduct.items[0].data;
          const updatedData = data.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });
          // Set the updated data
          setProductOptions(updatedData);
          // setProductOptions(totalProduct.items[0].data);
        }

        const totalAmount = addedProducts.reduce(
          (sum, product) =>
            sum + Number(product.newPrice) * Number(product.quantity),
          0
        );
        setTotalAmount(totalAmount);
        setUpdatedTotalAmt(totalAmount - discountAmt);
      } else {
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          const updatedData = filteredInputProduct.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          // Set the updated data
          setProductOptions(updatedData);
        } else {
          const data = totalProduct.items[0].data;
          const updatedData = data.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          // Set the updated data
          setProductOptions(updatedData);
        }
        setUpdatedTotalAmt(0);
      }
    } else {
      setProductOptions([]);
    }

    // eslint-disable-next-line
  }, [totalProduct, addedProducts, inputData]);

  const onChangeTableNo = (value, setFieldValue, values) => {
    if (value === "") {
      if (initialValues.dineIn === "0") {
        setValidationSchema(
          Yup.object({
            // mobile: Yup.string()
            //   .required(ValidationMessage.MOBILE_REQUIRED)
            //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
            // firstName: Yup.string().required(
            //   ValidationMessage.FIRST_NAME_REQUIRED
            // ),
            // lastName: Yup.string().required(
            //   ValidationMessage.LAST_NAME_REQUIRED
            // ),
            dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            payment: Yup.string().required("Payment is required!"),
          })
        );
      } else {
        setValidationSchema(
          Yup.object({
            // mobile: Yup.string()
            //   .required(ValidationMessage.MOBILE_REQUIRED)
            //   .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
            // firstName: Yup.string().required(
            //   ValidationMessage.FIRST_NAME_REQUIRED
            // ),
            // lastName: Yup.string().required(
            //   ValidationMessage.LAST_NAME_REQUIRED
            // ),
            dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            payment: Yup.string().required("Payment is required!"),
          })
        );
      }
    }
  };
  const handlePaymentValidation = (option, setFieldValue, values) => {
    // if (option.value === "5" || option.value === "6") {
    //   setValidationSchema(
    //     Yup.object({
    //       dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    //       // tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    //       payment: Yup.string().required("Payment is required!"),
    //       zomatoSwiggyPrice: Yup.string().required(
    //         ValidationMessage.FIELD_REQUIRED
    //       ),
    //     })
    //   );
    // } else {
    //   setValidationSchema(
    //     Yup.object({
    //       dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    //       // tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    //       payment: Yup.string().required("Payment is required!"),
    //       // zomatoSwiggyPrice: Yup.string().required(
    //       //   ValidationMessage.FIELD_REQUIRED
    //       // ),
    //     })
    //   );
    // }
    setFieldValue("zomatoSwiggyPrice", "");
  };

  const handlePhoneChange = (event, setFieldValue) => {
    const newValue = event.target.value.replace(/[^0-9\s]/g, "");
    if (newValue) {
      const trimmedValue = newValue.slice(0, 10);
      if (trimmedValue.length === 10) {
        getUserDetailService(trimmedValue, dispatch).then((res) => {
          if (
            res &&
            (res.status === 200 ||
              res.status === "200" ||
              res.status === "success")
          ) {
            setDisableName(false);
            setFieldValue("firstName", res.data.firstName);
            setFieldValue("lastName", res.data.lastName);
          } else {
            setDisableName(false);
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
          }
        });
      } else {
        setDisableName(true);
        setFieldValue("firstName", "");
        setFieldValue("lastName", "");
      }
    } else {
      setDisableName(true);
      setFieldValue("firstName", "");
      setFieldValue("lastName", "");
    }
  };

  useEffect(() => {
    if (addedProducts && addedProducts.length > 0) {
      const total = addedProducts.reduce(
        (sum, product) => sum + product.newPrice * product.quantity,
        0
      );
      // const totalTax =
      //   authuserData?.data?.branchTaxType === "GST"
      //     ? addedProducts.reduce(
      //         (sum, product) =>
      //           sum +
      //           (Number(product.newPrice) *
      //             product.quantity *
      //             ((product.cgst ? Number(product.cgst) : 0) +
      //               (product.sgst ? Number(product.sgst) : 0) +
      //               (product.igst ? Number(product.igst) : 0))) /
      //             100,
      //         0
      //       )
      //     : authuserData?.data?.branchTaxType === "VAT"
      //     ? addedProducts.reduce(
      //         (sum, product) =>
      //           sum +
      //           (Number(product.newPrice) * product.quantity * Number(product.vat)) /
      //             100,
      //         0
      //       )
      //     : 0;
      const totalTax =
      authuserData?.data?.branchTaxType === "GST"
        ? addedProducts.reduce(
            (sum, product) =>
              sum +
              (Number(product.newPrice) *
                product.quantity *
                ((product.cgst ? Number(product.cgst) : 0) +
                  (product.sgst ? Number(product.sgst) : 0))) /
                100,
            0
          )
        : authuserData?.data?.branchTaxType === "VAT"
        ? addedProducts.reduce(
            (sum, product) =>
              sum +
              (Number(product.newPrice) * product.quantity * Number(product.vat)) /
                100,
            0
          )
        : 0;
      setTaxAmount(totalTax.toFixed(2));

      let discount = 0;

      if (discountID && discountID > 0 && discountDetail) {
        let eligibleTotal = 0;
        let totalCartAmount = 0;

        addedProducts.forEach((product) => {
          totalCartAmount += product.newPrice * product.quantity;

          if (isProductEligible(product, discountDetail)) {
            eligibleTotal += product.newPrice * product.quantity;
          }
        });

        if (discountDetail?.spacific_item === "0") {
          if (totalCartAmount >= discountDetail?.min_cart_value) {
            const discountAmount =
              (totalCartAmount * discountDetail.discount_amount) / 100;
            let newDiscount = Math.min(
              discountAmount,
              discountDetail.max_discount_ammount
            );
            discount = newDiscount;
            setDiscountAmt(newDiscount);
          } else {
            toast.warn(
              `Add more products to apply the discount. Minimum cart value: ${
                authuserData?.data?.symbol || "₹"
              }${discountDetail?.min_cart_value}!`
            );
            discount = 0;
            setAppliedDiscountCode(null);
            setDiscountAmt(0);
            setDiscountDetail(null);
            setDiscountID(null);
          }
        } else if (eligibleTotal < discountDetail.min_cart_value) {
          toast.warn(
            "Not enough eligible products in cart to apply the discount!"
          );
          discount = 0;
          setAppliedDiscountCode(null);
          setDiscountAmt(0);
          setDiscountDetail(null);
          setDiscountID(null);
        } else {
          const discountAmount =
            (eligibleTotal * discountDetail?.discount_amount) / 100;
          let newDiscount = Math.min(
            discountAmount,
            discountDetail.max_discount_ammount
          );
          discount = newDiscount;
          setDiscountAmt(newDiscount);
        }
      } else {
        discount = 0;
      }

      const deliveryData = deliveryCharge;
      const finalTotal = total + totalTax + deliveryData - discount;
      let roundedValue;
      if (finalTotal % 1 >= 0.3) {
        roundedValue = Math.ceil(finalTotal);
      } else {
        roundedValue = Math.floor(finalTotal);
      }
      // setTotalAmount(total + totalTax + deliveryData - discount);
      // setUpdatedTotalAmt(total + totalTax + deliveryData - discount);
      setTotalAmount(roundedValue);
      setUpdatedTotalAmt(roundedValue);
      setDeliveryCharge(deliveryCharge);
    } else {
      setTotalAmount(0);
      setUpdatedTotalAmt(0);
      setTaxAmount(0);
      setDeliveryCharge(deliveryCharge);
      setDiscountAmt(0);
    }
    // eslint-disable-next-line
  }, [addedProducts]);

  const isProductEligible = (product, discountDetails) => {
    const {
      product_ids,
      brand_ids,
      category_ids,
      sub_category_ids,
      leaf_level_category_ids,
    } = discountDetails;
    // Check if the product ID matches
    if (product_ids && product_ids.includes(product.productId.toString())) {
      return true;
    }

    // Check if the brand ID matches
    if (brand_ids && brand_ids.includes(product.brand_id.toString())) {
      return true;
    }

    // Check if the category ID matches
    if (category_ids && category_ids.includes(product.category_ids)) {
      return true;
    }

    // Check if the sub-category ID matches
    if (
      sub_category_ids &&
      sub_category_ids.includes(product.sub_category_ids)
    ) {
      return true;
    }

    // Check if the leaf level category ID matches
    if (
      leaf_level_category_ids &&
      leaf_level_category_ids.includes(product.leaf_level_category_ids)
    ) {
      return true;
    }
    return false;
  };

  /**
   * Set discount
   * @param {*} value
   * @param {*} setFieldValue
   * @returns
   */

  const onSelectDiscount = async (value, setFieldValue, boolValue) => {
    try {
      if (addedProducts.length > 0) {
        let newData = {};
        if (discountDetail && discountID && discountID === value) {
          newData = {
            status: "success",
            data: discountDetail,
          };
        }
        const discountData =
          discountDetail && discountID && discountID === value
            ? newData
            : await getDiscountById(value, token, dispatch);
        if (
          discountData?.status &&
          (discountData.status === 200 ||
            discountData.status === "200" ||
            discountData.status === "success" ||
            discountData.status === "Success")
        ) {
          let eligibleTotal = 0;
          let totalCartAmount = 0;
          // let eligibleLength = 0;

          addedProducts.forEach((product) => {
            totalCartAmount += product.price * product.quantity;

            if (isProductEligible(product, discountData.data)) {
              // eligibleLength += 1;
              eligibleTotal += product.price * product.quantity;
            }
          });

          if (discountData.data?.spacific_item === "0") {
            if (totalCartAmount >= discountData.data?.min_cart_value) {
              const discountAmount =
                (totalCartAmount * discountData.data.discount_amount) / 100;
              let newDiscount = Math.min(
                discountAmount,
                discountData.data.max_discount_ammount
              );

              setDiscountAmt(newDiscount);
              if (boolValue) {
                setTotalAmount(totalAmountData - newDiscount);
                setUpdatedTotalAmt(updatedTotalAmt - newDiscount);
              }
              setAppliedDiscountCode(discountData.data?.code);
              setDiscountDetail(discountData.data);
              setDiscountID(discountData.data?.id || null);
              setFieldValue("discount", discountData.data?.id || "");
            } else {
              if (boolValue) {
                toast.warn(
                  `Add more products to apply the discount. Minimum cart value: ${
                    authuserData?.data?.symbol || "₹"
                  }${discountData.data?.min_cart_value}`
                );
              }
              setFieldValue("discount", "");
            }
          } else if (eligibleTotal < discountData.data.min_cart_value) {
            // let cartProducts = addedProducts.map((product) => {
            //   return {
            //     ...product,
            //     discountedAmount: 0,
            //   };
            // });
            if (boolValue) {
              toast.warn(
                "Not enough eligible products in cart to apply the discount!"
              );
            }
            setFieldValue("discount", "");
          } else {
            // let cartProducts = addedProducts.map((product) => {
            //   let discountedAmount = 0;
            //   if (
            //     eligibleTotal >= discountData.data.min_cart_value ||
            //     totalCartAmount >= discountData.data.min_cart_value
            //   ) {
            //     if (isProductEligible(product, discountData.data)) {
            //       // Apply percentage discount
            //       if (discountData.data.discount_type === "1") {
            //         const discount =
            //           (eligibleTotal * discountData.data.discount_amount) /
            //           100 /
            //           (eligibleLength > 0
            //             ? eligibleLength
            //             : addedProducts.length);
            //         discountedAmount = Math.min(
            //           discount,
            //           discountData.data.max_discount_ammount
            //         );
            //       }
            //       // Apply fixed discount
            //       else if (discountData.data.discount_type === "0") {
            //         discountedAmount =
            //           discountData.data.discount_amount /
            //           (eligibleLength > 0
            //             ? eligibleLength
            //             : addedProducts.length);
            //       }
            //     }
            //   }
            //   return {
            //     ...product,
            //     discountedAmount: discountedAmount > 0 ? Math.round(discountedAmount) : 0,
            //   };
            // });
            // setAddedProducts(cartProducts);
            const discountAmount =
              (eligibleTotal * discountData.data?.discount_amount) / 100;
            let newDiscount = Math.min(
              discountAmount,
              discountData.data.max_discount_ammount
            );

            setDiscountAmt(newDiscount);
            if (boolValue) {
              setTotalAmount(totalAmountData - newDiscount);
              setUpdatedTotalAmt(updatedTotalAmt - newDiscount);
            }
            setAppliedDiscountCode(discountData.data?.code);
            setDiscountDetail(discountData.data);
            setDiscountID(discountData.data?.id || null);
            setFieldValue("discount", discountData.data?.id || "");
          }
        }
      } else {
        if (boolValue) {
          toast.warn("Cart is empty!");
        }
        setFieldValue("discount", "");
        setUpdatedTotalAmt(0);
        setTotalAmount(0);
        setDiscountDetail(null);
        setDiscountID(null);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleUserDetailModal = () => {
    setUserDetailModal(!userDetailsModal);
  };
  const fetchSpot = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
      is_active: "1",
    };
    getAllSpotService(requestBody, token, dispatch);
  };
  const order = useSelector((state) => state.order);
  useEffect(() => {
    if (spotData && spotData.items && spotData.items.length === 0) {
      fetchSpot();
    } else {
      if (spotData.items[0]?.data && spotData.items[0].data.length > 0) {
        const newSpotData = spotData.items[0].data
          .map((spot) => {
            const matchingOrder = order.items.filter((order) => {
              // Check if Data.data.table_no is present, if it is, exclude those orders
              const isOrderExcluded = Data?.data.table_no
                ? Number(order.table_no) === Number(Data.data.table_no)
                : false;

              // Return true only if the order should not be excluded
              return (
                Number(order.table_no) === Number(spot.id) && !isOrderExcluded
              );
            });

            const matchComplete =
              matchingOrder && matchingOrder.length > 0
                ? matchingOrder.filter(
                    (item) => item.status !== "3" && item.status !== "4"
                  )
                : [];

            if (matchComplete.length === 0) {
              return {
                value: spot.id,
                label: spot.name,
              };
            }
            return null;
          })
          .filter((spot) => spot !== null);
        setTableOptions(newSpotData);
      } else {
        setTableOptions([]);
      }
    }
    // eslint-disable-next-line
  }, [spotData]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const handleSubmit = (values) => {
    if (addedProducts.length < 1) {
      toast.error("Please add product!");
      return;
    }

    if (values.mobile && values.mobile !== "") {
      const isValidLength = values.mobile.length === 10;
      const startsWithValidDigit = /^[6789]/.test(values.mobile);
      if (!isValidLength || !startsWithValidDigit) {
        toast.error(
          "Please add a valid 10-digit mobile number starting with 6, 7, 8, or 9!"
        );
        return;
      }
    }

    const requestBody = addUpdateOrderRequest(
      addedProducts,
      values,
      Data,
      deliveryCharge,
      discountAmt,
      discountID,
      appliedDiscountCode,
      message,
      updatedTotalAmt,
      taxAmount
    );

    if (Data && Data.data) {
      handlesaveClick(
        dispatch,
        placeOrderService(requestBody, token, "update", navigate, orderPage),
        "update"
      );
    } else {
      dispatch(placeOrderService(requestBody, token, "add", navigate));
    }
  };
  const inputRef = useRef(null);

  const handleAddedProduct = (values, setFieldValue) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (values.productCode !== "") {
      const product = productOptions.find((item) => {
        const itemLastPart = item.skuCode.split("-").pop();
        return itemLastPart === values.productCode;
      });
      if (product) {
        const existingProduct = addedProducts.find(
          (item) => item.productId === product.productId
        );
        if (!product.attribute_data) {
          if (existingProduct) {
            const updatedProducts = addedProducts.map((item) =>
              item.productId === product.productId
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
            setAddedProducts(updatedProducts);
            toast.success("Product quantity updated!");
          } else {
            // If it doesn't exist, add it
            const newProduct = {
              ...product,
              quantity: 1,
              food_type_jain_regular: "2",
              product_instruction: "",
              price: product.price,
              map_table_id:
                addedProducts.length > 0 ? addedProducts.length - 1 : 0,
              status: "0",
            };
            setAddedProducts((prevProducts) => [...prevProducts, newProduct]);
            toast.success("Product added!");
          }
        } else {
          openModal(product);
        }
      } else {
        toast.error("No product found with the given code");
      }
      setFieldValue("productCode", "");
    } else {
      toast.error("Enter SKU Code");
    }
  };
  const handleKeyPress = (event, values, setFieldValue) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (values.productCode === "") {
        toast.error("Enter SKU Code");
      } else {
        const product = productOptions.find((item) => {
          const itemLastPart = item.skuCode.split("-").pop();
          return itemLastPart === values.productCode;
        });

        if (product) {
          if (product.attribute_data) {
            // Open modal if product has attributes
            openModal(product);
          } else {
            // Handle adding product directly
            handleAddedProduct(values, setFieldValue);
          }
        } else {
          toast.error("No product found with the given code");
        }

        // Clear the input field after processing
        setFieldValue("productCode", "");
      }
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {orderActionData && orderActionData.length > 0
                      ? modifyString(orderActionData[0].name)
                      : "Order"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/orders")}>
                    {orderActionData && orderActionData.length > 0
                      ? orderActionData[0].name
                      : "Orders"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {orderActionData && orderActionData.length > 0
                      ? modifyString(orderActionData[0].name)
                      : "Order"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    {userDetailsModal && (
                      <div className="modal-overlay">
                        <div className="modal-content-order">
                          <div>
                            <h4>Enter Customer Details</h4>
                            <button
                              className="modal-close"
                              onClick={() => handleUserDetailModal()}
                            >
                              <MdOutlineCancel />
                            </button>
                          </div>
                          <div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      Customer Mobile
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="mobile"
                                    label="Mobile Number"
                                    placeholder="Enter Mobile Number"
                                    className="add-roles"
                                    maxLength={10}
                                    Regex={/[^0-9\s]/g}
                                    value={values.mobile}
                                    hidelength="true"
                                    disabled={Data}
                                    onInput={(event) =>
                                      handlePhoneChange(event, setFieldValue)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      First Name
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="firstName"
                                    label="Name"
                                    placeholder="Enter First Name"
                                    className="add-roles"
                                    Regex={/[^a-zA-z\s]/g}
                                    value={values.firstName}
                                    hidelength="true"
                                    disabled={
                                      (Data && Data.isEdit === false) ||
                                      disableName
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      Last Name
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="lastName"
                                    label="Name"
                                    placeholder="Enter Last Name"
                                    className="add-roles"
                                    Regex={/[^a-zA-z\s]/g}
                                    value={values.lastName}
                                    hidelength="true"
                                    disabled={
                                      (Data && Data.isEdit === false) ||
                                      disableName
                                    }
                                  />
                                </div>
                              </div>

                              <div className={"col-md-4"}>
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      Dine In/Take Away
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Field
                                    className="select-menu mb-2"
                                    name="dineIn"
                                    options={dineOptions}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    changeData={(value) => {
                                      changeDineInData(value, setFieldValue);
                                    }}
                                    value={
                                      values.dineIn === "" ||
                                      values.dineIn === null
                                        ? false
                                        : values.dineIn
                                    }
                                    disabled={
                                      (Data && Data.isEdit === false) ||
                                      (Data?.data && Data.data?.type === "2")
                                    }
                                  />
                                </div>
                              </div>
                              {values.dineIn === "0" && (
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Table Number
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Field
                                      className="select-menu mb-2"
                                      name="tableNo"
                                      options={tableOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      value={values.tableNo}
                                      disabled={Data && Data.isEdit === false}
                                      styles={customStyles}
                                      onchangedata={(value) =>
                                        onChangeTableNo(
                                          value,
                                          setFieldValue,
                                          values
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div className={"col-md-4"}>
                                <div className="name-type">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Payment
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Field
                                    className="select-menu mb-2"
                                    name="payment"
                                    options={paymentOption}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    onchangedata={(value) =>
                                      handlePaymentValidation(
                                        value,
                                        setFieldValue,
                                        values
                                      )
                                    }
                                    value={
                                      values.payment === "" ||
                                      values.payment === null
                                        ? false
                                        : values.payment
                                    }
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>

                              {(values.payment === "5" ||
                                values.payment === "6") && (
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Zomato/Swiggy Price
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="zomatoSwiggyPrice"
                                      label="zomato/Swiggy Price"
                                      placeholder="Enter Price"
                                      isRequired={true}
                                      Regex={/[^0-9.\s]/g}
                                      // maxLength={7}
                                      hidelength={"true"}
                                      value={values.zomatoSwiggyPrice}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className={"col-md-4"}>
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      Discount
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Field
                                    className="select-menu mb-2"
                                    name="discount"
                                    options={discountOption}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    changeData={(value) =>
                                      onSelectDiscount(
                                        value,
                                        setFieldValue,
                                        true
                                      )
                                    }
                                    value={
                                      values.discount === "" ||
                                      values.discount === null
                                        ? false
                                        : values.discount
                                    }
                                    disabled={Data && Data.isEdit === false}
                                  />
                                  {discountID &&
                                  ((Data && Data.isEdit === true) || !Data) ? (
                                    <Button
                                      name="Remove discount"
                                      type="button"
                                      onClick={() => {
                                        setFieldValue("discount", "");
                                        setTotalAmount(
                                          totalAmountData + discountAmt
                                        );
                                        setUpdatedTotalAmt(
                                          updatedTotalAmt + discountAmt
                                        );
                                        setDiscountDetail(null);
                                        setDiscountAmt(0);
                                        setDiscountID(null);
                                      }}
                                    ></Button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            {((Data && Data.isEdit === true) || !Data) && (
                              <div className="col-lg-5 col-md-6">
                                <div className="paragraph-editor">
                                  <div className="btn-submit order-submit-btn">
                                    <Button
                                      type="submit"
                                      name="Place Order"
                                      className="btn login-btn  place-order-btn"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginBottom: "10px",
                      }}
                    >
                      {/* Search Bar Section */}
                      <div
                        className="col-lg-6 col-sm-12 col-12"
                        style={{
                          flex: "1",
                          minWidth: "250px",
                          marginBottom: "10px",
                        }}
                      >
                        <div className="name-type">
                          <div className="search-bar">
                            <SearchBar
                              inputData={inputData}
                              setInputData={setInputData}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Dine In/Take Away and Table Number Section */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          backgroundColor: "#f9f9f9",
                          flex: "1",
                          minWidth: "250px",
                          marginBottom: "10px",
                          marginLeft: 10,
                        }}
                      >
                        {/* Dine In/Take Away Field */}
                        <div
                          className="dropdown-addorder" // Your CSS class
                          style={{
                            flex: "1",
                            padding: "10px",
                            marginRight: 10,
                            minWidth: "50px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginBottom: "8px",
                            }}
                          >
                            Service Type
                          </h4>
                          <Field
                            name="dineIn"
                            options={dineOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            changeData={(value) =>
                              changeDineInData(value, setFieldValue)
                            }
                            value={
                              values.dineIn === "" || values.dineIn === null
                                ? false
                                : values.dineIn
                            }
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>

                        {/* Table Number Field (Only visible when dineIn === "0") */}
                        {values.dineIn === "0" && (
                          <div
                            className="dropdown-addorder" // Your CSS class
                            style={{
                              flex: "1", // Inline styles
                              padding: "10px",
                              minWidth: "50px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                marginBottom: "8px",
                              }}
                            >
                              Table
                            </h4>
                            <Field
                              name="tableNo"
                              options={tableOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              value={values.tableNo}
                              disabled={Data && Data.isEdit === false}
                              onchangedata={(value) =>
                                onChangeTableNo(value, setFieldValue, values)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 col-sm-12 col-lg-8">
                        <div className="category hide-scrollbar">
                          <ul className="category-list">
                            {productCategory &&
                              productCategory.length > 0 &&
                              productCategory.map((category, index) => (
                                <li
                                  key={index}
                                  onClick={() => clickCategory(category, index)}
                                  className={
                                    activeIndex === index ? "cat-active" : ""
                                  }
                                >
                                  <Link>{category.name}</Link>
                                </li>
                              ))}
                          </ul>
                        </div>
                        {subCategoryData &&
                        subCategoryData.items &&
                        subCategoryData.items.length > 0 &&
                        subCategoryData.items[0].data &&
                        subCategoryData.items[0].data.length > 0 ? (
                          // <div className="col-md-8 col-sm-12 col-lg-8">
                          <div className="category hide-scrollbar sub-category">
                            <ul className="sub-category-list">
                              {subCategoryData.items[0].data.map(
                                (subCategory, index) => (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      clickSubCategory(subCategory, index)
                                    }
                                    className={
                                      subCategoryActiveIndex === index
                                        ? "sub-active"
                                        : ""
                                    }
                                  >
                                    <Link>{subCategory.name}</Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        ) : (
                          // </div>
                          <></>
                        )}
                      </div>

                      <div className="col-md-4 input-product-code">
                        <div className="name-type product-Code">
                          <div style={{ width: "100%" }}>
                            <Input
                              inputRef={inputRef}
                              type="text"
                              name="productCode"
                              label="Product Code"
                              placeholder="Enter SKU Code"
                              className="add-roles"
                              maxLength={10}
                              Regex={/[^0-9\s]/g}
                              value={values.productCode}
                              hidelength="true"
                              disabled={Data}
                              onInput={(event) =>
                                setFieldValue("productCode", event.target.value)
                              }
                              onKeyPress={(e) =>
                                handleKeyPress(e, values, setFieldValue)
                              }
                            />
                          </div>

                          <div
                            className="pc-btn-new"
                            onClick={() =>
                              handleAddedProduct(values, setFieldValue)
                            }
                          >
                            <FaPlus
                              className={`add-new-btn ${
                                Data ? "disabled" : ""
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <br /> */}
                    <div className="row">
                      {productOptions && productOptions.length > 0 ? (
                        <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                          <div className="product-order">
                            <div className="row">
                              {productOptions.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-6 col-12"
                                  key={index}
                                >
                                  {/* OrderCart */}
                                  <OrderCart
                                    productData={item}
                                    setAddedProducts={setAddedProducts}
                                    showAddButton={true}
                                    addedProducts={addedProducts}
                                    productOptions={productOptions}
                                    setProductOptions={setProductOptions}
                                    disabled={Data && Data.isEdit === false}
                                    setFieldValue={setFieldValue}
                                    discountID={discountID}
                                    onSelectDiscount={onSelectDiscount}
                                    ref={orderCartRef}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={`col-lg-8 col-md-6 col-sm-12 col-12`}>
                          <div className="product-order">
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-12">
                                <b>No Product Found!</b>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className="col-lg-4 col-md-6 col-sm-12 col-12 position-relative"
                        style={{
                          backgroundColor: "#c2bfbf",
                          border: 1,
                          borderRadius: 10,
                        }}
                      >
                        {addedProducts && addedProducts.length > 0 ? (
                          <>
                            <div className="new-order-detail">
                              <h6>Order Details</h6>
                              {addedProducts.map((item, index) => (
                                <div key={index}>
                                  <OrderCart
                                    productData={item}
                                    showAddButton={false}
                                    showMessageInput={true}
                                    addedProducts={addedProducts}
                                    setAddedProducts={setAddedProducts}
                                    productOptions={productOptions}
                                    setProductOptions={setProductOptions}
                                    showRadio={true}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              ))}
                            </div>
                            {/* Message Input and Total Amount Section */}
                            <div className="pb-3">
                              <div className="message-input">
                                <textarea
                                  value={message}
                                  placeholder="Would you like to add generalized instruction to chef 🧐?"
                                  onChange={(event) =>
                                    setMessage(event.target.value)
                                  }
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                            </div>
                            {/* Total Amount and Buttons */}
                            <div className="row total-amount-btn">
                              <div className="col-lg-7 col-md-6">
                                <div className="name-type">
                                  <div className="paragraph-editor">
                                    <h5 className="paragraph-title">
                                      Total Amount :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {updatedTotalAmt !== 0
                                        ? updatedTotalAmt
                                        : totalAmountData}
                                    </h5>
                                    {taxAmount && taxAmount > 0 ? (
                                      <span style={{ fontSize: 12 }}>
                                        Tax :{" "}
                                        {authuserData?.data?.symbol
                                          ? authuserData.data.symbol
                                          : "₹"}
                                        {taxAmount}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {discountID ? (
                                      <span
                                        style={{
                                          fontSize: 12,
                                          display: "block",
                                        }}
                                      >
                                        Discount :{" "}
                                        {authuserData?.data?.symbol
                                          ? authuserData.data.symbol
                                          : "₹"}
                                        {discountAmt}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* Button Section */}
                              {(Data && Data.isEdit === true) || !Data ? (
                                <div className="col-lg-5 col-md-6">
                                  <div className="paragraph-editor">
                                    <div className="btn-submit">
                                      <Button
                                        type="button"
                                        name="Place Order"
                                        className="btn login-btn place-order-btn width-100"
                                        onClick={() => handleUserDetailModal()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-lg-5 col-md-6">
                                  <div className="paragraph-editor">
                                    <div className="btn-submit">
                                      <Button
                                        type="button"
                                        name="Details"
                                        className="btn login-btn place-order-btn"
                                        onClick={() => handleUserDetailModal()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="no-data-card">
                              <h6 className="no-data-title">
                                No Products Added
                              </h6>
                              <p className="no-data-description">
                                Your cart is currently empty. Please add some
                                products to proceed.
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
