import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Toggle from "../../../components/Toggle/Toggle";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddDiscountCatalogue.scss";
import ErrorComp from "../../../components/Error/ErrorComp";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import "../../../App.scss";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { DiscountCatalogueById, addUpdateDiscountCatalogueService } from "../DiscountCatalogueService/DiscountCatalogueService";
import { parseImage } from "../../../services/Regular-services/ImageService";
import { handleCancleClick, handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { toast } from "react-toastify";
import { modifyString } from "../../../services/Regular-services/StringService";

//Validation schema
const validationSchema = Yup.object({
  discountName: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  discountType: Yup.string().required(ValidationMessage.TYPE_SELECT),
  discountTypeOn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  discountImage: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE),
  discountValue: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  startDate: Yup.date().required(ValidationMessage.START_DATE_SELECT),
  endDate: Yup.date().required(ValidationMessage.END_DATE_SELECT),
  catalogueFor: Yup.string().required(ValidationMessage.FIELD_REQUIRED)
});


const discountTypeOptions = [
  { value: "0", label: "Fix" },
  { value: "1", label: "Upto" },
];

const DiscountAmountOptions = [
  { value: "0", label: "Amount" },
  { value: "1", label: "Percent" },
];

const CatalogueForOptions = [
  { value: "3", label: "Brand" },
  { value: "0", label: "Categories" },
  { value: "1", label: "Sub Categories" },
  { value: "2", label: "Leaf Level Categories" },
  { value: "4", label: "Product" },
];
export default function AddDiscountCatalogue() {
  const navigate = useNavigate();

  const [errorImages, setErrorImages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const user = useSelector(state => state.auth.user);
  const token = UtilsData();
  const discountCataloguePage = useSelector(state => state.discountCataloguePage?.items);
  const dispatch = useDispatch();

  //Getting data from disount-list and setting accordingly
  const location = useLocation();
  const { Data } = location.state || {};

  //Initial values
  const [initialValues, setInitialValues] = useState({
    discountName: '',
    discountType: '',
    discountTypeOn: '',
    discountImage: [],
    discountValue: '',
    startDate: '',
    endDate: '',
    catalogueFor: '',
    status: true
  });

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [discountActionData, setdiscountActionData] = useState([]);
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === 'discount-catalogue'
      );
      setdiscountActionData(actionData);
    }
  }, [sideBarData]);

  /**
   * Fetching category detail data
   */
  const fetchDiscountCatalogue = async (discountId) => {
    try {
      // Call the getRoleById function
      const discountCatalogueData = await DiscountCatalogueById(discountId, token, dispatch);

      const discountCatalogueImage = discountCatalogueData.data.image_data ? parseImage(JSON.stringify(discountCatalogueData.data.image_data)) : null;
      const discountCatalogueImageData = discountCatalogueImage ? discountCatalogueImage.map(item => ({
        imageId: parseInt(item.id),
        url: item.url,
        imageType: parseInt(item.type)
      })) : [];
      setShowImage(true);

      setInitialValues({
        discountName: discountCatalogueData.data.title,
        discountType: discountCatalogueData.data.discount_type,
        discountTypeOn: discountCatalogueData.data.discount_type_on,
        discountImage: discountCatalogueImageData,
        discountValue: discountCatalogueData.data.discount_amount,
        startDate: discountCatalogueData.data.start_date,
        endDate: discountCatalogueData.data.end_date,
        catalogueFor: discountCatalogueData.data.discount_catlogue_for,
        status: discountCatalogueData.data.is_active === '1' ? true : false
      })

      return discountCatalogueData;
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchDiscountCatalogue(Data.data.discountId);
    }
    // eslint-disable-next-line
  }, [Data])

  /**
   * Handling form submission
   * @param {*} values 
   */
  const handleSubmit = (values) => {
    const imageId = values.discountImage.map(item => ({
      id: item.imageId,
      type_id: item.imageType
    }))

    if (!Data) {
      const requestBody = {
        p_title: values.discountName,
        p_discount_type: values.discountType,
        p_max_discount_amount: values.discountValue,
        p_discount_type_on: values.discountTypeOn,
        p_discount_catlogue_for: values.catalogueFor,
        p_start_date: format(new Date(values.startDate), 'yyyy-MM-dd'),
        p_end_date: format(new Date(values.endDate), 'yyyy-MM-dd'),
        p_created_by: user.data.userId,
        p_is_active: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_imageIds: imageId
      }
      dispatch(addUpdateDiscountCatalogueService(requestBody, token, 'add', navigate))
    }
    else {
      const requestBody = {
        p_id: Data.data.discountId,
        p_title: values.discountName,
        p_discount_type: values.discountType,
        p_max_discount_amount: values.discountValue,
        p_discount_type_on: values.discountTypeOn,
        p_discount_catlogue_for: values.catalogueFor,
        p_start_date: format(new Date(values.startDate), 'yyyy-MM-dd'),
        p_end_date: format(new Date(values.endDate), 'yyyy-MM-dd'),
        p_created_by: user.data.userId,
        p_is_active: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_imageIds: imageId,
        p_modified_by: user.data.userId,
        p_IsDeleted: '0'
      }
      if (Data?.length && discountCataloguePage > 1) {
        if ((values.status === '0' || values.status === 0 || values.status === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateDiscountCatalogueService(requestBody, token, 'update', navigate, discountCataloguePage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateDiscountCatalogueService(requestBody, token, 'update', navigate, discountCataloguePage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateDiscountCatalogueService(requestBody, token, 'update', navigate, discountCataloguePage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateDiscountCatalogueService(requestBody, token, 'update', navigate), 'update');
      }
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} {discountActionData && discountActionData.length > 0
                      ? modifyString(discountActionData[0].name)
                      : "Discount Catalogue"}</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/discount-catalogue")}>
                  {discountActionData && discountActionData.length > 0
                      ? discountActionData[0].name
                      : "Discount Catalogue"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} {discountActionData && discountActionData.length > 0
                      ? modifyString(discountActionData[0].name)
                      : "Discount Catalogue"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} {discountActionData && discountActionData.length > 0
                      ? modifyString(discountActionData[0].name)
                      : "Discount Catalogue"}</h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Title<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="discountName"
                          label="Title"
                          placeholder="Enter Title"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          maxLength={20}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Discount Type
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name='discountType'
                          options={discountTypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.discountType}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Discount Type On
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="discountTypeOn"
                          options={DiscountAmountOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.discountTypeOn}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Maximum Discount Amount
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="discountValue"
                          label="value"
                          placeholder="Enter Discount Value"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Add Image <span className="required-label"></span> </h4>
                        </div>
                        <UploadImage
                          name="discountImage"
                          onChange={setFieldValue}
                          value={values.discountImage}
                          filterByType={11}
                          setErrorImages={setErrorImages}
                          setShowImage={setShowImage}
                          disabled={Data && Data.isEdit === false}
                        />
                        <ShowImage value={values.discountImage} errorImages={errorImages} showImage={showImage} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <div className="date-picker">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Date<span className="required-label"></span>
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-md-5 datepicker-width">
                              <DatePicker
                                selected={values.startDate}
                                onChange={(date) => {
                                  // Check if the selected start date is greater than the current end date
                                  if (date > values.endDate) {
                                    // If the start date is greater than the end date, set the end date to null
                                    setFieldValue("endDate", null);
                                  }
                                  // Update the start date value
                                  setFieldValue("startDate", date);
                                }}
                                selectsStart
                                startDate={values.startDate}
                                endDate={values.endDate}
                                placeholderText="Start Date"
                                showIcon
                                minDate={new Date()}
                                disabled={Data && Data.isEdit === false}
                              />
                              <ErrorMessage
                                name="startDate"
                                component={ErrorComp}
                              ></ErrorMessage>
                            </div>
                            <div className="col-md-2">
                              <label className="text-center w-100">To</label>
                            </div>
                            <div className="col-md-5 datepicker-width">
                              <DatePicker
                                selected={values.endDate}
                                onChange={(date) =>
                                  setFieldValue("endDate", date)
                                }
                                selectsEnd
                                startDate={values.startDate}
                                endDate={values.endDate}
                                placeholderText="End Date"
                                showIcon
                                minDate={values.startDate}
                                disabled={Data && Data.isEdit === false}
                              />
                              <ErrorMessage
                                name="endDate"
                                component={ErrorComp}
                              ></ErrorMessage>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Catalogue for
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="catalogueFor"
                          options={CatalogueForOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.catalogueFor}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                      </div>
                      <Field
                        name="status"
                        setFieldValue={setFieldValue}
                        component={Toggle}
                        disabled={Data && Data.isEdit === false}
                      ></Field>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/discount-catalogue', navigate)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}