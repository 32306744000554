import "./Button.scss";
import React from "react";
import { SiRazorpay } from "react-icons/si";

export default function Button(props) {
  const { type, name, page, ...rest } = props;
  return (
    <>
      <button className="btn" type={props.type} {...rest}>
        {props.name}
      </button>
    </>
  );
}
