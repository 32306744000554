import React from 'react';
import QRCode from 'react-qr-code';
import LOGO from "../../assets/images/Bespeakk-logo.png";
import "../QRCode/QRCode.scss";
import { API_URL } from '../../Utils/Utils';

export default function QRCodeComponent({ companyCode, companyLogo,companyPincode, companyName,tableNo }) {
  const url = tableNo
    ? `${API_URL.QRCode_URL}/menu/${companyCode}/${companyPincode}/${tableNo}`
    : `${API_URL.QRCode_URL}/menu/${companyCode}/${companyPincode}`;
    

  // Assuming brandLogo is a relative path, prepend the base URL
  // const brandLogoUrl = `http://api.ecomnode.topnotchsolutions.in/${brandLogo}`;

  // const [imageLoaded, setImageLoaded] = useState(false);

  // const handleImageLoad = () => setImageLoaded(true);

  // useEffect(() => {
  //   setImageLoaded(false); // Reset image loaded state on props change
  // }, [brandLogo]);

  return (
    <>
      <table className='qr-code' style={{ margin: "0 auto" }}>
        <tbody>
          <tr>
            <td style={{ textAlign: "left" }}>
              {/* company logo */}
              {/* <img
                style={{ width: 60, height: 60, objectFit: "cover", borderRadius: "50%" }}
                src={brandLogoUrl}
                alt="Company Logo"
                onLoad={handleImageLoad}
              /> */}
               <img src={companyLogo} alt="Company Logo" style={{ width: 60, height: 60, objectFit: "cover", borderRadius: "50%" }} />
            </td>
            <td style={{ textAlign: "right" }}>
              <img style={{ width: 150 }} src={LOGO} alt="Logo" />
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{ background: "#1D3B42" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td
                      style={{ textAlign: "center", fontSize: "30px", color: "#FFF", lineHeight: "25px", paddingBottom: 15 }}
                    >
                      {companyName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ textAlign: "center", background: "#FFF", padding: 20, margin: "0 30px" }}
                    >
                      <div
                        style={{ backgroundColor: "#FFFFFF", padding: "10px", display: "inline-block" }}
                      >
                        <QRCode
                          value={url}
                          size={250}
                          style={{ color: "#FF0000" }}
                        />
                      </div>
                    </td>
                  </tr>
                  {tableNo && (
                    <tr>
                      <td style={{ textAlign: "center", fontSize: "30px", color: "#FFF", borderRight:"0px" }}>
                        Table No. {tableNo}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      style={{ textAlign: "center", fontSize: "18px", color: "#FFF", padding: "0" }}
                    >
                      Powered By Topnotch IT Consultant
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}