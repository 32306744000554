const BranchFilterInitialState = {
    name: '', 
    pincode: '',
    isActive: '',
    isDelete: '',
    isPublicAvailable:'',
    isWpSmsAllow:'',
    isDeliveryAvailable:'',
    taxType:''
};

/**
 * Store branch filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const BranchFilterReducer = (state = BranchFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_BRANCH_FILTER':
            return {
                ...state,
                name: action.payload.name,
                pincode: action.payload.pincode, 
                isActive: action.payload.isActive,
                isDelete: action.payload.isDelete,
                isPublicAvailable:action.payload.isPublicAvailable,
                isWpSmsAllow:action.payload.isWpSmsAllow,
                isDeliveryAvailable:action.payload.isDeliveryAvailable,
                taxType:action.payload.taxType
            }
        case 'CLEAR_BRANCH_FILTER':
            return BranchFilterInitialState;
        default:
            return state;
      };
}