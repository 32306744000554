// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-amount {
  background-color: #000000 !important;
  text-align: center !important;
  color: #FFF !important;
  font-size: 18px;
  width: 270px !important;
  font-weight: 500;
  height: 46px;
  margin: 0 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Vendors/Vendors.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,6BAAA;EACA,sBAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".purchase-amount{\n    background-color: #000000 !important;\n    text-align: center !important;\n    color: #FFF !important;\n    font-size: 18px;\n    width: 270px !important;\n    font-weight: 500;\n    height: 46px;\n    margin: 0 5px;\n    border-radius: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
