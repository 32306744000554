import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import UtilsData from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineAnalytics } from "react-icons/md";
import { uploadOrderPDFService } from "../Order/OrderService/OrderService";
import { format } from "date-fns";
import { getAllSpotService } from "../Spot/SpotService/SpotService";
import "../Dashboard/Dashboard.scss";
import { MdEdit } from "react-icons/md";
import { FaEye, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { IoMdPrint } from "react-icons/io";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
import CustomTable from "../../components/Table/Table";

export default function Dashboard() {
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  // const sideBarData = useSelector((state) => state.sideBar.items);
  // const [dashboardActionData, setDashboardActionData] = useState([]);
  const DashboardOrders = useSelector((state) => state.dashboardOrder);
  const spotData = useSelector((state) => state.spot);
  const [spotsOrdersData, setSpotsOrdersData] = useState([]);
  const authuserData = useSelector((state) => state.auth?.user);
  const [orderSummary, setOrderSummary] = useState({
    totalAmt: 0,
    totalOrder: 0,
  });
  const navigate = useNavigate();
  const [earningsModal, setEarningsModal] = useState(false);

  const fetchSpot = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
      is_active: "1",
    };
    getAllSpotService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (spotData && spotData.items && spotData.items.length === 0) {
      fetchSpot();
    } else {
      if (spotData.items[0]?.data && spotData.items[0].data.length > 0) {
        const newSpotData = spotData.items[0].data.map((spot) => {
          const matchingOrder = DashboardOrders.items.filter(
            (order) => Number(order.table_no) === Number(spot.id)
          );
          const matchComplete =
            matchingOrder && matchingOrder.length > 0
              ? matchingOrder.filter(
                  (item) => item.status !== "3" && item.status !== "4"
                )
              : [];
          return {
            id: spot.id,
            name: spot.name,
            order:
              matchComplete && matchComplete.length > 0
                ? matchComplete[0]
                : null,
          };
        });
        setSpotsOrdersData(newSpotData);
      } else {
        setSpotsOrdersData([]);
      }
    }
    // eslint-disable-next-line
  }, [spotData, DashboardOrders]);

  // useEffect(() => {
  //   if (sideBarData) {
  //     const actionData = sideBarData.filter(
  //       (item) => item.router_link === window.location.pathname.slice(1)
  //     );
  //     // setDashboardActionData(actionData);
  //   }
  // }, [sideBarData]);

  useEffect(() => {
    const { totalAmount, count } = DashboardOrders.items.reduce(
      (acc, current) => {
        if (current.status === "3") {
          acc.totalAmount += parseFloat(current.order_amount);
          acc.count += 1;
        }
        return acc;
      },
      { totalAmount: 0, count: 0 }
    );

    const totalAmountWithRupeeSymbol = `₹${totalAmount.toFixed(2)}`;
    setOrderSummary({
      totalAmt: totalAmountWithRupeeSymbol,
      totalOrder: count,
    });
    if (DashboardOrders) {
      calculateTotals();
    }
  }, [DashboardOrders]);

  const getFormattedSize = (data) => {
    // Group attributes by cat_name
    const groupedAttributes = data.reduce((acc, attr) => {
      const { cat_name, name } = attr;

      if (!acc[cat_name]) {
        acc[cat_name] = [];
      }

      acc[cat_name].push(name);
      return acc;
    }, {});

    // Format the output string
    const formattedSize = Object.entries(groupedAttributes)
      .map(([cat_name, names]) => `${cat_name}: ${names.join(", ")}`)
      .join(", ");

    return formattedSize;
    // }

    // // Return the original size if attribute_data is not valid
    // return product.size;
  };

  const handleNavigate = (route, item, isEdit) => {
    if (item.order) {
      const transformedData = {
        id: item.order.orderId,
        customerName:
          item.order.first_name !== "Bespeakk" &&
          item.order.first_name !== "bespeakk"
            ? item.order.first_name + " " + item.order.last_name
            : "N/A",
        phoneNumber:
          item.order.phone_number &&
          item.order.phone_number !== "" &&
          Number(item.order.phone_number) !== 8155006366
            ? item.order.phone_number
            : "N/A",
        createdAt: item.order.createdAt
          ? format(item.order.createdAt, "dd-MM-yyyy")
          : "",
        orderCreatedAt: item.order.createdAt,
        orderModifiedAt: item.order.modifiedAt,
        modifiedAt: item.order.modifiedAt
          ? format(item.order.modifiedAt, "dd-MM-yyyy")
          : "",
        tableNo:
          item.order.table_no !== "" && item.order.table_no !== null
            ? item.order.table_no
            : "—",
        type: item.order.type,
        tableType:
          item.order.type === "2"
            ? "Delivery"
            : item.order.type === "1"
            ? "Take away"
            : "Dine in",
        totalItem: item.order?.productData
          ? JSON.parse(item.order.productData).reduce(
              (sum, product) => sum + Number(product.quantity),
              0
            )
          : 0,
        orderAmount: item.order.productData
          ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            JSON.parse(item.order.productData).reduce(
              (sum, product) =>
                sum + Number(product.price) * Number(product.quantity),
              0
            )
          : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") + "0",
        orderSubTotal: item.order.productData
          ? JSON.parse(item.order.productData).reduce(
              (sum, product) =>
                sum + Number(product.price) * Number(product.quantity),
              0
            )
          : 0,
        deliveryChange:
          (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          (item.order?.delivery_charge ? item.order.delivery_charge : "0"),
        total_delivery_charge: item.order?.delivery_charge
          ? Number(item.order.delivery_charge)
          : 0,
        taxAmount:
          (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          (item.order?.total_tax_amount ? item.order.total_tax_amount : "0"),
        discountAmount:
          (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          (item.order.total_discount_amount
            ? item.order.total_discount_amount
            : "0"),
        total_tax_amount: item.order?.total_tax_amount
          ? Number(item.order.total_tax_amount)
          : 0,
        totalOrderWithTax:
          (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          (item.order?.order_amount ? item.order.order_amount : "0"),
        total_amount_with_tax: item.order?.order_amount
          ? Number(item.order.order_amount)
          : 0,
        orderNumber: item.order.order_number,
        productData: item.order.productData
          ? JSON.parse(item.order.productData).map((product) => ({
              productId: product?.productId ? Number(product.productId) : 0,
              productName: product?.productName
                ? product?.productName.replace(/^'(.*)'$/, "$1")
                : "",
              size:
                product?.attribute_data &&
                product.attribute_data !== "" &&
                product.attribute_data !== "null" &&
                product?.attribute_data !== "NULL"
                  ? getFormattedSize(JSON.parse(product.attribute_data))
                  : product.size,
              product_size: product.size,
              quantity: product.quantity,
              product_attribute: product?.attribute_data
                ? JSON.parse(product.attribute_data)
                : null,
              tablePrice: product.price
                ? (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") + Number(product.price)
                : (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") + 0,
              price: product.price ? Number(product.price) : 0,
              totalTablePrice:
                product.quantity && product.price
                  ? (authuserData?.data?.symbol
                      ? authuserData.data.symbol
                      : "₹") +
                    Number(product.quantity) * Number(product.price)
                  : (authuserData?.data?.symbol
                      ? authuserData.data.symbol
                      : "₹") + 0,
              totalPrice:
                product.quantity && product.price
                  ? Number(product.quantity) * Number(product.price)
                  : 0,
              food_type_two:
                product?.food_type_2 &&
                product.food_type_2 !== "" &&
                product.food_type_2 !== "null" &&
                product.food_type_2 !== "NULL"
                  ? product.food_type_2 === "0"
                    ? "No Onion/Garlic"
                    : product.food_type_2 === "1"
                    ? "Jain"
                    : "Regular"
                  : "Regular",
              product_instruction:
                product?.product_instruction &&
                product.product_instruction !== "" &&
                product.product_instruction !== "null" &&
                product.product_instruction !== "NULL"
                  ? product.product_instruction
                  : null,
              table_product_instruction:
                product?.product_instruction &&
                product.product_instruction !== "" &&
                product.product_instruction !== "null" &&
                product.product_instruction !== "NULL"
                  ? product.product_instruction
                  : "—",
              food_type_2:
                product?.food_type_2 &&
                product.food_type_2 !== "" &&
                product.food_type_2 !== "null"
                  ? product.food_type_2
                  : null,
              food_type_1:
                product?.food_type_1 &&
                product.food_type_1 !== "" &&
                product.food_type_1 !== "null"
                  ? product.food_type_1
                  : null,
              attribute_data:
                product?.attribute_data &&
                product.attribute_data !== "" &&
                product.attribute_data !== "null" &&
                product?.attribute_data !== "NULL"
                  ? product.attribute_data
                  : null,
              map_table_id: product?.map_table_id ? product.map_table_id : 0,
              orderId: item.order.orderId,
              productStatus: product?.status ? product.status : "0",
              cgst: product?.cgst,
              sgst: product.sgst,
              igst: product.igst,
              vat: product.vat,
            }))
          : [],
        payment: item.order.payment,
        orderStatus: item.order.status,
        user_id: item.order.user_id,
        firstName:
          item.order.first_name !== "Bespeakk" &&
          item.order.first_name !== "bespeakk"
            ? item.order.first_name
            : "",
        lastName: item.order.last_name,
        instructions: item.order.instructions,
        brand_id: item.order.brand_id,
        brand_name: item.order.brand_name,
        brand_gst_number: item.order.brand_gst_number,
        address_id: item.order?.address_id,
        address_line1: item.order.address_line1,
        address_line2: item.order.address_line2,
        address_city: item.order.address_city,
        address_postal_code: item.order.address_postal_code,
        address_title: item.order.address_title,
        total_discount_amount: item.order.total_discount_amount,
        discount_id: item.order.discount_id,
        Discount_Code: item.order.Discount_Code,
        delivery_charge: item.order.delivery_charge,
        companyName: item.order.companyName,
        companyAddress: item.order.companyAddress,
        table_no: item.order.table_no,
        zomatoSwiggyPrice: item.order.zomato_swiggy_other_amount,
      };
      const Data = {
        data: transformedData,
        isEdit: isEdit,
      };
      navigate(route, { state: { Data } });
    } else {
      const tableNo = {
        table_no: item.id,
      };
      navigate(route, { state: { tableNo } });
    }
  };

  const calculateTotals = (order) => {
    if (!order || !order.productData) return { totalItems: 0, totalServed: 0 };
    const products = JSON.parse(order.productData); // Parse the JSON string
    let totalItems = 0;
    let totalServed = 0;
    let totalOrderAmt = order.order_amount;

    const orderCreationTime = moment(order.createdAt); // Use moment to parse the date
    const now = moment(); // Current time using moment

    // Add 10 seconds to the order creation time
    const updatedTime = orderCreationTime.add(10, "seconds");

    // Calculate the total time difference
    const totalTimeInSeconds = now.diff(updatedTime, "seconds");

    // Convert total time to hours, minutes, and seconds
    const totalTimeInHours = Math.floor(totalTimeInSeconds / 3600);
    const remainingMinutes = Math.floor((totalTimeInSeconds % 3600) / 60);
    const remainingSeconds = totalTimeInSeconds % 60;

    // Format total time as a string in the desired format
    const totalTime = `${totalTimeInHours}h ${remainingMinutes}m ${remainingSeconds}s`;

    products.forEach((product) => {
      totalItems += product.quantity; // Sum up all quantities
      if (product.status === "1") {
        totalServed += product.quantity; // Count only served items
      }
    });

    return { totalItems, totalServed, totalOrderAmt, totalTime };
  };

  const printKOT = (item) => {
    const orderData = {
      id: item.order.orderId,
      customerName:
        item.order.first_name !== "Bespeakk" &&
        item.order.first_name !== "bespeakk"
          ? item.order.first_name + " " + item.order.last_name
          : "N/A",
      phoneNumber:
        item.order.phone_number &&
        item.order.phone_number !== "" &&
        Number(item.order.phone_number) !== 8155006366
          ? item.order.phone_number
          : "N/A",
      createdAt: item.order.createdAt
        ? format(item.order.createdAt, "dd-MM-yyyy")
        : "",
      orderCreatedAt: item.order.createdAt,
      orderModifiedAt: item.order.modifiedAt,
      modifiedAt: item.order.modifiedAt
        ? format(item.order.modifiedAt, "dd-MM-yyyy")
        : "",
      tableNo:
        item.order.table_no !== "" && item.order.table_no !== null
          ? item.order.table_no
          : "—",
      type: item.order.type,
      tableType:
        item.order.type === "2"
          ? "Delivery"
          : item.order.type === "1"
          ? "Take away"
          : "Dine in",
      totalItem: item.order?.productData
        ? JSON.parse(item.order.productData).reduce(
            (sum, product) => sum + Number(product.quantity),
            0
          )
        : 0,
      orderAmount: item.order.productData
        ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          JSON.parse(item.order.productData).reduce(
            (sum, product) =>
              sum + Number(product.price) * Number(product.quantity),
            0
          )
        : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") + "0",
      orderSubTotal: item.order.productData
        ? JSON.parse(item.order.productData).reduce(
            (sum, product) =>
              sum + Number(product.price) * Number(product.quantity),
            0
          )
        : 0,
      deliveryChange:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.delivery_charge ? item.order.delivery_charge : "0"),
      total_delivery_charge: item.order?.delivery_charge
        ? Number(item.order.delivery_charge)
        : 0,
      taxAmount:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.total_tax_amount ? item.order.total_tax_amount : "0"),
      discountAmount:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order.total_discount_amount
          ? item.order.total_discount_amount
          : "0"),
      total_tax_amount: item.order?.total_tax_amount
        ? Number(item.order.total_tax_amount)
        : 0,
      totalOrderWithTax:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.order_amount ? item.order.order_amount : "0"),
      total_amount_with_tax: item.order?.order_amount
        ? Number(item.order.order_amount)
        : 0,
      orderNumber: item.order.order_number,
      productData: item.order.productData
        ? JSON.parse(item.order.productData).map((product) => ({
            productId: product?.productId ? Number(product.productId) : 0,
            productName: product?.productName
              ? product?.productName.replace(/^'(.*)'$/, "$1")
              : "",
            size:
              product?.attribute_data &&
              product.attribute_data !== "" &&
              product.attribute_data !== "null" &&
              product?.attribute_data !== "NULL"
                ? getFormattedSize(JSON.parse(product.attribute_data))
                : product.size,
            product_size: product.size,
            quantity: product.quantity,
            product_attribute: product?.attribute_data
              ? JSON.parse(product.attribute_data)
              : null,
            tablePrice: product.price
              ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                Number(product.price)
              : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                0,
            price: product.price ? Number(product.price) : 0,
            totalTablePrice:
              product.quantity && product.price
                ? (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") +
                  Number(product.quantity) * Number(product.price)
                : (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") + 0,
            totalPrice:
              product.quantity && product.price
                ? Number(product.quantity) * Number(product.price)
                : 0,
            food_type_two:
              product?.food_type_2 &&
              product.food_type_2 !== "" &&
              product.food_type_2 !== "null" &&
              product.food_type_2 !== "NULL"
                ? product.food_type_2 === "0"
                  ? "No Onion/Garlic"
                  : product.food_type_2 === "1"
                  ? "Jain"
                  : "Regular"
                : "Regular",
            product_instruction:
              product?.product_instruction &&
              product.product_instruction !== "" &&
              product.product_instruction !== "null" &&
              product.product_instruction !== "NULL"
                ? product.product_instruction
                : null,
            table_product_instruction:
              product?.product_instruction &&
              product.product_instruction !== "" &&
              product.product_instruction !== "null" &&
              product.product_instruction !== "NULL"
                ? product.product_instruction
                : "—",
            food_type_2:
              product?.food_type_2 &&
              product.food_type_2 !== "" &&
              product.food_type_2 !== "null"
                ? product.food_type_2
                : null,
            food_type_1:
              product?.food_type_1 &&
              product.food_type_1 !== "" &&
              product.food_type_1 !== "null"
                ? product.food_type_1
                : null,
            attribute_data:
              product?.attribute_data &&
              product.attribute_data !== "" &&
              product.attribute_data !== "null" &&
              product?.attribute_data !== "NULL"
                ? product.attribute_data
                : null,
            map_table_id: product?.map_table_id ? product.map_table_id : 0,
            orderId: item.order.orderId,
            productStatus: product?.status ? product.status : "0",
            cgst: product?.cgst,
            sgst: product.sgst,
            igst: product.igst,
            vat: product.vat,
          }))
        : [],
      payment: item.order.payment,
      orderStatus: item.order.status,
      user_id: item.order.user_id,
      firstName:
        item.order.first_name !== "Bespeakk" &&
        item.order.first_name !== "bespeakk"
          ? item.order.first_name
          : "",
      lastName: item.order.last_name,
      instructions: item.order.instructions,
      brand_id: item.order.brand_id,
      brand_name: item.order.brand_name,
      brand_gst_number: item.order.brand_gst_number,
      address_id: item.order?.address_id,
      address_line1: item.order.address_line1,
      address_line2: item.order.address_line2,
      address_city: item.order.address_city,
      address_postal_code: item.order.address_postal_code,
      address_title: item.order.address_title,
      total_discount_amount: item.order.total_discount_amount,
      discount_id: item.order.discount_id,
      Discount_Code: item.order.Discount_Code,
      delivery_charge: item.order.delivery_charge,
      companyName: item.order.companyName,
      companyAddress: item.order.companyAddress,
      table_no: item.order.table_no,
    };
    const printWindow = window.open("", "", "height=600,width=800");
    // Define the HTML structure for the table with inline CSS
    const htmlContent = `
        <html>
        <head>
          <title>Order Details</title>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0 auto; padding: 0; width: 80mm; text-align: center;">
          <h1 style="text-align: center; font-size: 16px; margin-top: 10px;">Order Details</h1>
          <div style="margin: 10px; text-align: left;">
            <p style="margin: 5px 0; font-size: 12px;">Order No. - <strong>${
              orderData?.orderNumber
            }</strong></p>
            <p style="margin: 5px 0; font-size: 12px; border-top: 1px solid black;">
            <br>
              Dine In: ${orderData?.type === "0" ? "Yes" : "No"}<br>
              Table Number: ${
                orderData?.type === "0" ? orderData.tableNo : "Parcel"
              }<br>
              Date: ${orderData?.createdAt ? orderData.createdAt : ""}<br>

            </p>
            ${
              orderData?.instructions
                ? `<p style="font-size: 12px; margin-bottom:5px">General Instruction</p>
                    <p style="font-size: 10px;">${orderData.instructions}</p> `
                : ""
            }

               <tbody>
            ${
              orderData?.productData && orderData.productData.length > 0
                ? orderData.productData
                    .map(
                      (product) => `
                <tr>
                 <td style=" font-size: 12px;">
    ${product.quantity} x ${product.productName} - ${product.food_type_two}<br>
    ${
      product?.product_instruction
        ? `<span class="instructions" style="font-style: italic; font-size: 12px;">Instruction: ${product.product_instruction}</span>`
        : ""
    }
    ${
      product?.attribute_data &&
      product.attribute_data !== "" &&
      product.attribute_data !== "null" &&
      product.attribute_data !== "NULL"
        ? `<div class="attribute-data" style="font-style: italic; font-size: 12px; color: gray;">${JSON.parse(
            product.attribute_data
          )
            .map(
              (attr) => `
          ${attr.cat_name}: ${attr.name}
        `
            )
            .join("<br>")}</div>`
        : ""
    }
    </td>

                </tr>
              `
                    )
                    .join("")
                : "<tr><td>No products available.</td></tr>"
            }
          </tbody>
          </div>
          <div style="margin-top: 10px; text-align: center; font-size: 12px;">
            <p>Thanks for visiting!</p>
          </div>
          <div style="display: flex; padding: 0 10px; justify-content: flex-end;">
            <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin-left: auto;">
              <!-- Your icon or content here -->
            </div>
          </div>
        </body>
        </html>
      `;
    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();

    // Trigger the print dialog
    printWindow.print();
  };

  const printInvoice = (item) => {
    const orderData = {
      id: item.order.orderId,
      customerName:
        item.order.first_name !== "Bespeakk" &&
        item.order.first_name !== "bespeakk"
          ? item.order.first_name + " " + item.order.last_name
          : "N/A",
      phoneNumber:
        item.order.phone_number &&
        item.order.phone_number !== "" &&
        Number(item.order.phone_number) !== 8155006366
          ? item.order.phone_number
          : "N/A",
      createdAt: item.order.createdAt
        ? format(item.order.createdAt, "dd-MM-yyyy")
        : "",
      orderCreatedAt: item.order.createdAt,
      orderModifiedAt: item.order.modifiedAt,
      modifiedAt: item.order.modifiedAt
        ? format(item.order.modifiedAt, "dd-MM-yyyy")
        : "",
      tableNo:
        item.order.table_no !== "" && item.order.table_no !== null
          ? item.order.table_no
          : "—",
      type: item.order.type,
      tableType:
        item.order.type === "2"
          ? "Delivery"
          : item.order.type === "1"
          ? "Take away"
          : "Dine in",
      totalItem: item.order?.productData
        ? JSON.parse(item.order.productData).reduce(
            (sum, product) => sum + Number(product.quantity),
            0
          )
        : 0,
      orderAmount: item.order.productData
        ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          JSON.parse(item.order.productData).reduce(
            (sum, product) =>
              sum + Number(product.price) * Number(product.quantity),
            0
          )
        : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") + "0",
      orderSubTotal: item.order.productData
        ? JSON.parse(item.order.productData).reduce(
            (sum, product) =>
              sum + Number(product.price) * Number(product.quantity),
            0
          )
        : 0,
      deliveryChange:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.delivery_charge ? item.order.delivery_charge : "0"),
      total_delivery_charge: item.order?.delivery_charge
        ? Number(item.order.delivery_charge)
        : 0,
      taxAmount:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.total_tax_amount ? item.order.total_tax_amount : "0"),
      discountAmount:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order.total_discount_amount
          ? item.order.total_discount_amount
          : "0"),
      total_tax_amount: item.order?.total_tax_amount
        ? Number(item.order.total_tax_amount)
        : 0,
      totalOrderWithTax:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.order_amount ? item.order.order_amount : "0"),
      total_amount_with_tax: item.order?.order_amount
        ? Number(item.order.order_amount)
        : 0,
      orderNumber: item.order.order_number,
      productData: item.order.productData
        ? JSON.parse(item.order.productData).map((product) => ({
            productId: product?.productId ? Number(product.productId) : 0,
            productName: product?.productName
              ? product?.productName.replace(/^'(.*)'$/, "$1")
              : "",
            size:
              product?.attribute_data &&
              product.attribute_data !== "" &&
              product.attribute_data !== "null" &&
              product?.attribute_data !== "NULL"
                ? getFormattedSize(JSON.parse(product.attribute_data))
                : product.size,
            product_size: product.size,
            quantity: product.quantity,
            product_attribute: product?.attribute_data
              ? JSON.parse(product.attribute_data)
              : null,
            tablePrice: product.price
              ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                Number(product.price)
              : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                0,
            price: product.price ? Number(product.price) : 0,
            totalTablePrice:
              product.quantity && product.price
                ? (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") +
                  Number(product.quantity) * Number(product.price)
                : (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") + 0,
            totalPrice:
              product.quantity && product.price
                ? Number(product.quantity) * Number(product.price)
                : 0,
            food_type_two:
              product?.food_type_2 &&
              product.food_type_2 !== "" &&
              product.food_type_2 !== "null" &&
              product.food_type_2 !== "NULL"
                ? product.food_type_2 === "0"
                  ? "No Onion/Garlic"
                  : product.food_type_2 === "1"
                  ? "Jain"
                  : "Regular"
                : "Regular",
            product_instruction:
              product?.product_instruction &&
              product.product_instruction !== "" &&
              product.product_instruction !== "null" &&
              product.product_instruction !== "NULL"
                ? product.product_instruction
                : null,
            table_product_instruction:
              product?.product_instruction &&
              product.product_instruction !== "" &&
              product.product_instruction !== "null" &&
              product.product_instruction !== "NULL"
                ? product.product_instruction
                : "—",
            food_type_2:
              product?.food_type_2 &&
              product.food_type_2 !== "" &&
              product.food_type_2 !== "null"
                ? product.food_type_2
                : null,
            food_type_1:
              product?.food_type_1 &&
              product.food_type_1 !== "" &&
              product.food_type_1 !== "null"
                ? product.food_type_1
                : null,
            attribute_data:
              product?.attribute_data &&
              product.attribute_data !== "" &&
              product.attribute_data !== "null" &&
              product?.attribute_data !== "NULL"
                ? product.attribute_data
                : null,
            map_table_id: product?.map_table_id ? product.map_table_id : 0,
            orderId: item.order.orderId,
            productStatus: product?.status ? product.status : "0",
            cgst: product?.cgst,
            sgst: product.sgst,
            igst: product.igst,
            vat: product.vat,
          }))
        : [],
      payment: item.order.payment,
      orderStatus: item.order.status,
      user_id: item.order.user_id,
      firstName:
        item.order.first_name !== "Bespeakk" &&
        item.order.first_name !== "bespeakk"
          ? item.order.first_name
          : "",
      lastName: item.order.last_name,
      instructions: item.order.instructions,
      brand_id: item.order.brand_id,
      brand_name: item.order.brand_name,
      brand_gst_number: item.order.brand_gst_number,
      address_id: item.order?.address_id,
      address_line1: item.order.address_line1,
      address_line2: item.order.address_line2,
      address_city: item.order.address_city,
      address_postal_code: item.order.address_postal_code,
      address_title: item.order.address_title,
      total_discount_amount: item.order.total_discount_amount,
      discount_id: item.order.discount_id,
      Discount_Code: item.order.Discount_Code,
      delivery_charge: item.order.delivery_charge,
      companyName: item.order.companyName,
      companyAddress: item.order.companyAddress,
      table_no: item.order.table_no,
    };
    const printWindow = window.open("", "", "height=600,width=800");
    const cleanProductData = orderData.productData;
    const isSingleProduct = cleanProductData.length === 1;
    const totalWithoutTax =
      Number(orderData.total_amount_with_tax) -
      Number(orderData.total_tax_amount) +
      Number(orderData.total_discount_amount) -
      Number(orderData?.delivery_charge);

    const generateTaxInfo = (item) => {
      let taxInfo = "";
      const price = Number(item.price) || 0;

      const quantity = item.quantity || 1;
      const cgst = Number(item.cgst) || 0;
      const sgst = Number(item.sgst) || 0;
      const igst = Number(item.igst) || 0;
      const vat = Number(item.vat) || 0;

      if (cgst > 0)
        taxInfo += `<div><span class="short-font">CGST:</span> <span class="short-font">${
          (quantity * price * cgst) / 100
        }</span></div>`;
      if (sgst > 0)
        taxInfo += `<div><span class="short-font">SGST:</span> <span class="short-font">${
          (quantity * price * sgst) / 100
        }</span></div>`;
      if (igst > 0)
        taxInfo += `<div><span class="short-font">IGST:</span> <span class="short-font">${
          (quantity * price * igst) / 100
        }</span></div>`;
      if (vat > 0)
        taxInfo += `<div><span class="short-font">VAT:</span> <span class="short-font">${
          (quantity * price * vat) / 100
        }</span></div>`;

      return taxInfo;
    };

    const invoiceHtml = `
  <html>
    <head>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 5px;
          text-align: left;
          font-size: 12px;
        }
        .header {
          text-align: center;
          margin-bottom: 10px;
        }
        .company-info, .order-info {
          margin: 5px;
          text-align: center;
        }
        .invoice-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 5px;
          font-size: 10px;
        }
        .invoice-table th, .invoice-table td {
          padding: 5px;
          border: 1px solid #ddd;
          text-align: left;
          vertical-align: top;
        }
        .total {
          font-weight: bold;
        }
        .thank-you-message {
          text-align: center;
          margin-top: 10px;
        }
        ${
          isSingleProduct
            ? `
        .invoice-table {
          margin-top: 0;
        }
        .company-info, .order-info {
          margin-bottom: 0;
        }
        .thank-you-message {
          margin-top: 5px;
        }
        `
            : ""
        }
        @media print {
          .page-break {
            page-break-before: always;
          }
          .invoice-table {
            page-break-inside: auto;
          }
          .invoice-table tr {
            page-break-inside: avoid;
            page-break-after: auto;
          }
        }
        .short-font {
        font-size: 8px
       }
      </style>
    </head>
    <body>
      <div class="header">
        <p class="company-info">
          <strong>${orderData?.companyName}</strong><br />
          ${
            orderData?.branch_tax_type === "GST"
              ? "GSTIN - " + orderData?.branch_gst_number
              : ""
          }<br />
          ${orderData?.companyAddress}
        </p>
        <p class="order-info">
          Date: ${
            orderData?.createdAt ? orderData?.createdAt : format(new Date())
          }<br />
          Dine In: ${orderData.type === "0" ? "Yes" : "No"}<br />
          Table Number: ${
            orderData.type === "0" ? orderData.table_no : "—"
          }<br />
          Cashier: ${orderData?.companyName}
        </p>
      </div>
      <div class="invoice-content">
        <table class="invoice-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Tax</th>
              <th></th>
              <th>Qty</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            ${cleanProductData
              .map(
                (item) => `
                <tr>
                  <td>${item.productName} - ${
                  item.food_type_2 === "2"
                    ? "Regular"
                    : item.food_type_2 === "1"
                    ? "Jain"
                    : item.food_type_2 === "0"
                    ? "No onion/garlic"
                    : ""
                }</td>
                  <td>${generateTaxInfo(item)}</td>
                  <td>
                    ${
                      item.attribute_data &&
                      item.attribute_data !== "" &&
                      item.attribute_data !== "null" &&
                      item.attribute_data !== "NULL"
                        ? `<div class="attribute-data">
                          ${JSON.parse(item.attribute_data)
                            .map(
                              (attr, attrIndex) => `
                              <div key="${attrIndex}" class="attr-div">
                                ${attr.cat_name}: ${attr.name}
                              </div>`
                            )
                            .join("")}
                        </div>`
                        : ""
                    }
                  </td>
                  <td>${item.quantity}</td>    
                  <td>${item.price}</td>
                  <td>${Number(item.quantity) * parseFloat(item.price)}</td>
                </tr>`
              )
              .join("")}
            <tr>
              <td class="total">Sub Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="total">${
                orderData?.country_symbol || "₹"
              }${totalWithoutTax}</td>
            </tr>
            <tr>
              <td>TotalTax</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>${Number(orderData?.total_tax_amount)}</td>
            </tr>
            ${
              orderData?.discount_id
                ? `
              <tr>
                <td>Discount Amount</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>${Number(orderData?.total_discount_amount).toFixed(2)}</td>
              </tr>`
                : ""
            }
            ${
              orderData.type === "2"
                ? `
              <tr>
                <td>Delivery charge</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>${orderData?.delivery_charge || 0}</td>
              </tr>`
                : ""
            }
            <tr>
              <td class="total">Grand Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="total">${orderData?.country_symbol || "₹"}${Number(
      orderData?.total_amount_with_tax
    )}</td>
            </tr>
          </tbody>
        </table>
        <p class="thank-you-message">Thanks for visiting!</p>
      </div>
    </body>
  </html>`;
    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();

    // Trigger the print dialog
    printWindow.print();
  };

  const generatePDFAndUpload = async (item) => {
    const { companyLogo, companyName, companyOwnerName, companyPhneNumber } =
      authuserData.data;
    const orderData = {
      id: item.order.orderId,
      customerName:
        item.order.first_name !== "Bespeakk" &&
        item.order.first_name !== "bespeakk"
          ? item.order.first_name + " " + item.order.last_name
          : "N/A",
      phoneNumber:
        item.order.phone_number &&
        item.order.phone_number !== "" &&
        Number(item.order.phone_number) !== 8155006366
          ? item.order.phone_number
          : "N/A",
      createdAt: item.order.createdAt
        ? format(item.order.createdAt, "dd-MM-yyyy")
        : "",
      orderCreatedAt: item.order.createdAt,
      orderModifiedAt: item.order.modifiedAt,
      modifiedAt: item.order.modifiedAt
        ? format(item.order.modifiedAt, "dd-MM-yyyy")
        : "",
      tableNo:
        item.order.table_no !== "" && item.order.table_no !== null
          ? item.order.table_no
          : "—",
      type: item.order.type,
      tableType:
        item.order.type === "2"
          ? "Delivery"
          : item.order.type === "1"
          ? "Take away"
          : "Dine in",
      totalItem: item.order?.productData
        ? JSON.parse(item.order.productData).reduce(
            (sum, product) => sum + Number(product.quantity),
            0
          )
        : 0,
      orderAmount: item.order.productData
        ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
          JSON.parse(item.order.productData).reduce(
            (sum, product) =>
              sum + Number(product.price) * Number(product.quantity),
            0
          )
        : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") + "0",
      orderSubTotal: item.order.productData
        ? JSON.parse(item.order.productData).reduce(
            (sum, product) =>
              sum + Number(product.price) * Number(product.quantity),
            0
          )
        : 0,
      deliveryChange:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.delivery_charge ? item.order.delivery_charge : "0"),
      total_delivery_charge: item.order?.delivery_charge
        ? Number(item.order.delivery_charge)
        : 0,
      taxAmount:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.total_tax_amount ? item.order.total_tax_amount : "0"),
      discountAmount:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order.total_discount_amount
          ? item.order.total_discount_amount
          : "0"),
      total_tax_amount: item.order?.total_tax_amount
        ? Number(item.order.total_tax_amount)
        : 0,
      totalOrderWithTax:
        (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
        (item.order?.order_amount ? item.order.order_amount : "0"),
      total_amount_with_tax: item.order?.order_amount
        ? Number(item.order.order_amount)
        : 0,
      orderNumber: item.order.order_number,
      productData: item.order.productData
        ? JSON.parse(item.order.productData).map((product) => ({
            productId: product?.productId ? Number(product.productId) : 0,
            productName: product?.productName
              ? product?.productName.replace(/^'(.*)'$/, "$1")
              : "",
            size:
              product?.attribute_data &&
              product.attribute_data !== "" &&
              product.attribute_data !== "null" &&
              product?.attribute_data !== "NULL"
                ? getFormattedSize(JSON.parse(product.attribute_data))
                : product.size,
            product_size: product.size,
            quantity: product.quantity,
            product_attribute: product?.attribute_data
              ? JSON.parse(product.attribute_data)
              : null,
            tablePrice: product.price
              ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                Number(product.price)
              : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
                0,
            price: product.price ? Number(product.price) : 0,
            totalTablePrice:
              product.quantity && product.price
                ? (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") +
                  Number(product.quantity) * Number(product.price)
                : (authuserData?.data?.symbol
                    ? authuserData.data.symbol
                    : "₹") + 0,
            totalPrice:
              product.quantity && product.price
                ? Number(product.quantity) * Number(product.price)
                : 0,
            food_type_two:
              product?.food_type_2 &&
              product.food_type_2 !== "" &&
              product.food_type_2 !== "null" &&
              product.food_type_2 !== "NULL"
                ? product.food_type_2 === "0"
                  ? "No Onion/Garlic"
                  : product.food_type_2 === "1"
                  ? "Jain"
                  : "Regular"
                : "Regular",
            product_instruction:
              product?.product_instruction &&
              product.product_instruction !== "" &&
              product.product_instruction !== "null" &&
              product.product_instruction !== "NULL"
                ? product.product_instruction
                : null,
            table_product_instruction:
              product?.product_instruction &&
              product.product_instruction !== "" &&
              product.product_instruction !== "null" &&
              product.product_instruction !== "NULL"
                ? product.product_instruction
                : "—",
            food_type_2:
              product?.food_type_2 &&
              product.food_type_2 !== "" &&
              product.food_type_2 !== "null"
                ? product.food_type_2
                : null,
            food_type_1:
              product?.food_type_1 &&
              product.food_type_1 !== "" &&
              product.food_type_1 !== "null"
                ? product.food_type_1
                : null,
            attribute_data:
              product?.attribute_data &&
              product.attribute_data !== "" &&
              product.attribute_data !== "null" &&
              product?.attribute_data !== "NULL"
                ? product.attribute_data
                : null,
            map_table_id: product?.map_table_id ? product.map_table_id : 0,
            orderId: item.order.orderId,
            productStatus: product?.status ? product.status : "0",
            cgst: product?.cgst,
            sgst: product.sgst,
            igst: product.igst,
            vat: product.vat,
          }))
        : [],
      payment: item.order.payment,
      orderStatus: item.order.status,
      user_id: item.order.user_id,
      firstName:
        item.order.first_name !== "Bespeakk" &&
        item.order.first_name !== "bespeakk"
          ? item.order.first_name
          : "",
      lastName: item.order.last_name,
      instructions: item.order.instructions,
      brand_id: item.order.brand_id,
      brand_name: item.order.brand_name,
      brand_gst_number: item.order.brand_gst_number,
      address_id: item.order?.address_id,
      address_line1: item.order.address_line1,
      address_line2: item.order.address_line2,
      address_city: item.order.address_city,
      address_postal_code: item.order.address_postal_code,
      address_title: item.order.address_title,
      total_discount_amount: item.order.total_discount_amount,
      discount_id: item.order.discount_id,
      Discount_Code: item.order.Discount_Code,
      delivery_charge: item.order.delivery_charge,
      companyName: item.order.companyName,
      companyAddress: item.order.companyAddress,
      table_no: item.order.table_no,
    };
    const cleanProductData = orderData.productData;
    const isSingleProduct = cleanProductData.length === 1;
    const totalWithoutTax =
      Number(orderData.total_amount_with_tax) -
      Number(orderData.total_tax_amount) +
      Number(orderData.total_discount_amount) -
      Number(orderData?.delivery_charge);

    const generateTaxInfo = (item) => {
      let taxInfo = "";
      const price = Number(item.price) || 0;

      const quantity = item.quantity || 1;
      const cgst = Number(item.cgst) || 0;
      const sgst = Number(item.sgst) || 0;
      const igst = Number(item.igst) || 0;
      const vat = Number(item.vat) || 0;

      if (cgst > 0)
        taxInfo += `<div><span class="short-font">CGST:</span> <span class="short-font">${
          (quantity * price * cgst) / 100
        }</span></div>`;
      if (sgst > 0)
        taxInfo += `<div><span class="short-font">SGST:</span> <span class="short-font">${
          (quantity * price * sgst) / 100
        }</span></div>`;
      if (igst > 0)
        taxInfo += `<div><span class="short-font">IGST:</span> <span class="short-font">${
          (quantity * price * igst) / 100
        }</span></div>`;
      if (vat > 0)
        taxInfo += `<div><span class="short-font">VAT:</span> <span class="short-font">${
          (quantity * price * vat) / 100
        }</span></div>`;

      return taxInfo;
    };

    const invoiceHtml = `
  <html>
    <head>
      <style>
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 5px;
          text-align: left;
          font-size: 12px;
        }
        .header {
          text-align: center;
          margin-bottom: 10px;
        }
        .company-info, .order-info {
          margin: 5px;
          text-align: center;
        }
        .invoice-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 5px;
          font-size: 10px;
        }
        .invoice-table th, .invoice-table td {
          padding: 5px;
          border: 1px solid #ddd;
          text-align: left;
          vertical-align: top;
        }
        .total {
          font-weight: bold;
        }
        .thank-you-message {
          text-align: center;
          margin-top: 10px;
        }
        ${
          isSingleProduct
            ? `
        .invoice-table {
          margin-top: 0;
        }
        .company-info, .order-info {
          margin-bottom: 0;
        }
        .thank-you-message {
          margin-top: 5px;
        }
        `
            : ""
        }
        @media print {
          .page-break {
            page-break-before: always;
          }
          .invoice-table {
            page-break-inside: auto;
          }
          .invoice-table tr {
            page-break-inside: avoid;
            page-break-after: auto;
          }
        }
        .short-font {
        font-size: 8px
       }
      </style>
    </head>
    <body>
      <div class="header">
        <p class="company-info">
          <strong>${orderData?.companyName}</strong><br />
          ${
            orderData?.branch_tax_type === "GST"
              ? "GSTIN - " + orderData?.branch_gst_number
              : ""
          }<br />
          ${orderData?.companyAddress}
        </p>
        <p class="order-info">
          Date: ${
            orderData?.createdAt ? orderData?.createdAt : format(new Date())
          }<br />
          Dine In: ${orderData.type === "0" ? "Yes" : "No"}<br />
          Table Number: ${
            orderData.type === "0" ? orderData.table_no : "—"
          }<br />
          Cashier: ${orderData?.companyName}
        </p>
      </div>
      <div class="invoice-content">
        <table class="invoice-table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Tax</th>
              <th></th>
              <th>Qty</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            ${cleanProductData
              .map(
                (item) => `
                <tr>
                  <td>${item.productName} - ${
                  item.food_type_2 === "2"
                    ? "Regular"
                    : item.food_type_2 === "1"
                    ? "Jain"
                    : item.food_type_2 === "0"
                    ? "No onion/garlic"
                    : ""
                }</td>
                  <td>${generateTaxInfo(item)}</td>
                  <td>
                    ${
                      item.attribute_data &&
                      item.attribute_data !== "" &&
                      item.attribute_data !== "null" &&
                      item.attribute_data !== "NULL"
                        ? `<div class="attribute-data">
                          ${JSON.parse(item.attribute_data)
                            .map(
                              (attr, attrIndex) => `
                              <div key="${attrIndex}" class="attr-div">
                                ${attr.cat_name}: ${attr.name}
                              </div>`
                            )
                            .join("")}
                        </div>`
                        : ""
                    }
                  </td>
                  <td>${item.quantity}</td>    
                  <td>${item.price}</td>
                  <td>${Number(item.quantity) * parseFloat(item.price)}</td>
                </tr>`
              )
              .join("")}
            <tr>
              <td class="total">Sub Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="total">${
                orderData?.country_symbol || "₹"
              }${totalWithoutTax}</td>
            </tr>
            <tr>
              <td>TotalTax</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>${Number(orderData?.total_tax_amount)}</td>
            </tr>
            ${
              orderData?.discount_id
                ? `
              <tr>
                <td>Discount Amount</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>${Number(orderData?.total_discount_amount).toFixed(2)}</td>
              </tr>`
                : ""
            }
            ${
              orderData.type === "2"
                ? `
              <tr>
                <td>Delivery charge</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>${orderData?.delivery_charge || 0}</td>
              </tr>`
                : ""
            }
            <tr>
              <td class="total">Grand Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="total">${orderData?.country_symbol || "₹"}${Number(
      orderData?.total_amount_with_tax
    )}</td>
            </tr>
          </tbody>
        </table>
        <p class="thank-you-message">Thanks for visiting!</p>
      </div>
    </body>
  </html>`;
    // Create a temporary div to render the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.top = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.height = "297mm";
    tempDiv.style.overflow = "hidden";
    tempDiv.innerHTML = invoiceHtml;
    document.body.appendChild(tempDiv);

    try {
      // Convert HTML to Canvas
      const canvas = await html2canvas(tempDiv, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight); // Use JPEG

      // Convert PDF to Blob
      const pdfBlob = pdf.output("blob");
      const file = new File(
        [pdfBlob],
        `invoice-${orderData?.orderNumber}.pdf`,
        {
          type: "application/pdf",
        }
      );

      // Prepare upload data
      const uploadData = {
        pdf: file,
        p_order_user: orderData.firstName + " " + orderData.lastName,
        p_order_phone_number: orderData.phoneNumber,
        p_total_amt: orderData.totalOrderWithTax,
        p_company_name: companyName,
        // p_branch_user_phone_number: companyPhneNumber,
        // p_branch_user_name: companyOwnerName,
        // p_branch_Address: user.data.branchAddress,
      };
      // return
      dispatch(uploadOrderPDFService(uploadData, tokenData));
    } catch (error) {
      console.error("Error generating PDF or uploading:", error);
    } finally {
      // Clean up by removing the temporary div
      document.body.removeChild(tempDiv);
    }
  };

  const showKey = {
    itemName: true,
    totalQtySold: true,
    totalAmountEarned: true,
  };
  const styleData = {
    totalQtySold: { type: { textAlign: "end" } },
    totalAmountEarned: { type: { textAlign: "end" } },
  };

  const columns = [
    {
      name: "Product Name",
      selector: (row) => row.itemName,
    },
    {
      name: "Total Quanity",
      selector: (row) => row.totalQtySold,
    },
    {
      name: "Total Earnings",
      selector: (row) => row.totalAmountEarned,
    },
  ];
  const [viewSalesData, setViewSalesData] = useState([]);

  const handleViewEarnings = () => {
    if (!earningsModal) {
      const productData = DashboardOrders.items
        .filter((order) => order.status === "3" || order.status === 3)
        .map((order) => JSON.parse(order.productData));

      if (productData.length > 0) {
        const summary = {};

        productData.forEach((group) => {
          group.forEach((item) => {
            const { productName, quantity, price } = item;
            if (!summary[productName]) {
              summary[productName] = { totalQty: 0, totalAmount: 0 };
            }

            summary[productName].totalQty += quantity;
            summary[productName].totalAmount += quantity * price;
          });
        });

        const result = Object.entries(summary).map(
          ([productName, { totalQty, totalAmount }]) => ({
            itemName: productName,
            totalQtySold: totalQty,
            totalAmountEarned: totalAmount,
          })
        );
        setViewSalesData(result);
      }
    }
    setEarningsModal(!earningsModal);
  };

  return (
    <>
      <div className="main-section order-new">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content sticky-container">
            <div className="row sticky-header">
              <div className="col-lg-4">
                <div className="card total-earning-card">
                  <h5>Today's total Earnings: {orderSummary.totalAmt}</h5>

                  <MdOutlineAnalytics
                    data-bs-toggle="tooltip"
                    title="Analytics"
                    className="icon eys-icon"
                    onClick={() => handleViewEarnings(!earningsModal)}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card total-earning-card">
                  <h5>Total Completed Orders: {orderSummary.totalOrder}</h5>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="row"> */}
              {spotsOrdersData.length > 0 ? (
                spotsOrdersData.map((item, index) => {
                  const totals = item.order && calculateTotals(item.order);
                  return (
                    <div key={index} className="col-lg-4 col-md-6 col-12 mb-3">
                      {item.order ? (
                        <div className={`tile`}>
                          <h5
                            className={` ${
                              item.order.status === "0"
                                ? "pendingSpot"
                                : item.order.status === "1"
                                ? "confirmSpot"
                                : item.order.status === "2"
                                ? "in-progress"
                                : "occupiedSpot"
                            }`}
                          >
                            Order Status :{" "}
                            {item.order.status === "0"
                              ? "Pending"
                              : item.order.status === "1"
                              ? "Confirmed"
                              : item.order.status === "2"
                              ? "In-progress"
                              : "occupiedSpot"}
                          </h5>
                          <div className="tile-content">
                            <div className="tile-content-head">
                              <h3>Table Number : {item.name}</h3>
                              <div className="icon-container">
                                <FaEye
                                  className="icon"
                                  data-bs-toggle="tooltip"
                                  title="View"
                                  onClick={() =>
                                    handleNavigate("/edit-order", item, false)
                                  }
                                />
                                <MdEdit
                                  className="icon"
                                  data-bs-toggle="tooltip"
                                  title="Edit"
                                  onClick={() =>
                                    handleNavigate("/edit-order", item, true)
                                  }
                                />
                                {(
                                  item.order.phone_number !== "8155006366" &&
                                  item.order.phone_number !== 8155006366 &&
                                  item.order.phone_number !== "N/A") && (
                                  <IoLogoWhatsapp
                                    className="icon wp-icon"
                                    data-bs-toggle="tooltip"
                                    title="Send Invoice"
                                    onClick={() => {
                                      generatePDFAndUpload(item);
                                    }}
                                  />
                                )}

                                <IoMdPrint
                                  className="icon"
                                  onClick={() => printInvoice(item)}
                                  data-bs-toggle="tooltip"
                                  title="Invoice"
                                />
                                <Button
                                  data-bs-toggle="tooltip"
                                  title="KOT"
                                  onClick={() => printKOT(item)}
                                  className="KOT-btn-dashboard"
                                  name={<p className="kot-icon">KOT</p>}
                                ></Button>
                              </div>
                            </div>
                            <div>
                              <p>Order ID: {item.order?.order_number}</p>
                              <p>Total Items: {totals.totalItems}</p>
                              <p>Total Items Served: {totals.totalServed}</p>
                              <p>Order Amount: {"₹" + totals.totalOrderAmt}</p>
                              <p>Time from Order placed: {totals.totalTime}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="tile unoccupied"
                          onClick={() =>
                            handleNavigate("/add-order", item, true)
                          }
                        >
                          <h5 className="unoccupied-title">Unoccupied</h5>
                          <div className="tile-content-head unoccupied-head">
                            <h3>Table Number : {item.name}</h3>
                            <div className="icon-container">
                              <FaPlus
                                data-bs-toggle="tooltip"
                                title="Add"
                                className="icon"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <h3>No data available</h3>
              )}
              {/* </div> */}
            </div>
          </div>

          {earningsModal && (
            <div className="modal-overlay">
              <div className="modal-content-order-1 modal-bg">
                <div className="modal-header">
                  <h4>Sales Details</h4>
                  <button
                    className="modal-close"
                    onClick={() => handleViewEarnings(!earningsModal)}
                  >
                    <MdOutlineCancel />
                  </button>
                </div>
                <div className="modal-body">
                  {viewSalesData.length > 0 ? (
                    <CustomTable
                      page="dashboard"
                      showToggle={false}
                      checkBool={false}
                      showKey={showKey}
                      showActions={false}
                      showTooltip={false}
                      data={viewSalesData}
                      heading={columns}
                      disabled={true}
                      currentPage={0}
                      styleData={styleData}
                    />
                  ) : (
                    <p>No sales data available.</p>
                  )}
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
