/**
 * Set order list data into reducer
 * @param {*} dashboardOrder
 * @returns {*}
 */
export const getDashboardOrderData = ({
  order,
  time,
  totalRecords,
  api,
  dataCount,
}) => ({
  type: "GET_DASHBOARD_ORDER_DATA",
  payload: { order, time, totalRecords, api, dataCount },
});

export const addDashboardOrderData = ({
  order,
  time,
  totalRecords,
  api,
  dataCount,
}) => ({
  type: "ADD_DASHBOARD_ORDER",
  payload: { order, time, totalRecords, api, dataCount },
});

export const updateDashboardOrderStatus = ({ orderId, status }) => ({
  type: "UPDATE_DASHBOARD_ORDER_STATUS",
  payload: { orderId, status },
});

/**
 * clear order list data
 * @returns {*}
 */
export const clearDashboardOrderData = () => ({
  type: "CLEAR_DASHBOARD_ORDER_DATA",
});

/**
 * set total order list data into reducer (No pagination)
 * @param {*} dashboardOrderData
 * @returns {*}
 */
export const getTotalDashboardOrderData = (orderData) => ({
  type: "GET_TOTAL_DASHBOARD_ORDER_DATA",
  payload: orderData,
});

/**
 * clear total order list data (No pagination)
 * @returns {*}
 */
export const clearTotalDashboardOrderData = () => ({
  type: "CLEAR_TOTAL_DASHBOARD_ORDER_DATA",
});
