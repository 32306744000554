const stackHolderDetailInitialState = {
  items: [],
};

/**
 * Store StackHolder Detail list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const StackHolderDetailReducer = (
  state = stackHolderDetailInitialState,
  action
) => {
  switch (action.type) {
    case "GET_STAKEHOLDER_DETAIL_DATA":
      return {
        ...state,
        items: [action.payload],
      };
    case "CLEAR_STAKEHOLDER_DETAIL_DATA":
      return stackHolderDetailInitialState;
    default:
      return state;
  }
};
