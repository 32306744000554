import React from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import * as Yup from 'yup';
import { ValidationMessage } from '../../../Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import Toggle from '../../../components/Toggle/Toggle';
import { handleCancleClick } from '../../../components/Alertmessage/Alertmessage';
import Button from '../../../components/Button/Button';

//Setting initial values
const initialValues = {
  tagTitle: ''
}

//Validation schema
const validationSchema = Yup.object({
  tagTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED)
})

//Handling form submission
const handleSubmit = (values) => {
}

export default function ViewCart() {

  const location = useLocation();
  const { Data } = location.state || {};

  const navigate = useNavigate();

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div> */}
        {/* <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Cart Detail</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/carts")}>Cart</Breadcrumb.Item>
                  <Breadcrumb.Item active>Cart Detail</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>Cart Detail</h4>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Cart ID <span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="Cart ID"
                        label="Cart ID"
                        placeholder="Enter Cart ID"
                        className="add-roles"
                        maxLength={50}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Full Name <span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="Full Name"
                        label="Full Name"
                        placeholder="Enter Full Name"
                        className="add-roles"
                        maxLength={50}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Contact Number <span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="Contact Number"
                        label="Contact Number"
                        placeholder="Enter Contact Number"
                        className="add-roles"
                        maxLength={50}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Email<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="email"
                        name="Email"
                        placeholder="Enter Email"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Product Name<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="Product Name"
                        name="Product Name"
                        placeholder="Enter Product Name"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Quantities<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="Quantities"
                        name="Quantities"
                        placeholder="Enter Quantities"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Prices<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="Prices"
                        name="Prices"
                        placeholder="Enter Prices"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Subtotal <span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="Subtotal"
                        label="Subtotal"
                        placeholder="Enter Subtotal"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Discounts <span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="Discounts"
                        label="Discounts"
                        placeholder="Enter Discounts"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Total Amount <span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="Total Amount"
                        label="Total Amount"
                        placeholder="Enter Total Amount"
                        className="add-roles"
                        maxLength={70}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Status</h4>
                      <Field name='active'
                        className='toggle' component={Toggle}
                        disabled={Data && Data.isEdit === false} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name="Save"
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name="Cancel"
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick("/carts", navigate)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          {/* <Footer /> */}
        {/* </div> */}
      </div>
    </>
  )
}
