/**
 * set stock history filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getStockHistoryFilterData = ({
    ingredientId,
    orderDate,
    // status,
    // payment,
  }) => ({
    type: "GET_STOCK_HISTORY_FILTER",
    payload: { ingredientId, orderDate },
  });
  
  /**
   * clear stock history filtered data
   * @returns {*}
   */
  export const clearStockHistoryFilterData = () => ({
    type: "CLEAR_STOCK_HISTORY_FILTER",
  });
  