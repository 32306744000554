import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { applyMiddleware } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import rootReducer from "./CombinedReducers";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "auth",
    "sideBar",
    "paymentDetail",
    "stakeHolderDetail",
    "accountProductConfig",
    "verifyAccount"
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
