/**
 * Setting inventory item list data into reducer
 * @param {*} inventoryItem 
 * @returns {*}
 */
export const getInventoryItemData = (inventoryItem) => ({
    type: 'GET_INVENTORY_ITEM_DATA',
    payload: inventoryItem
})

/**
 * clearing inventory item list data
 * @returns {*}
 */
export const clearInventoryItemData = () => ({
    type: 'CLEAR_INVENTORY_ITEM_DATA'
})

/**
 * Setting total inventory item list data into reducer (No pagnation)
 * @param {*} inventoryItem 
 * @returns {*}
 */
export const getTotalInventoryItemData = (inventoryItem) => ({
    type: 'GET_TOTAL_INVENTORY_ITEM',
    payload: inventoryItem
})

/**
 * Clearing total inventory item llist data (No pagination)
 * @returns {*}
 */
export const clearTotalInventoryItemData = () => ({
    type: 'CLEAR_TOTAL_INVENTORY_ITEM'
})