import { Dispatch } from "redux";
import {
  addUpdatePurchaseRequisitionRequest,
  getAllPurchaseRequisitionRequest,
  getAllPurchaseRequisitionResponse,
} from "./PurchaseRequisitionModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  clearPurchaseRequisitionData,
  clearTotalPurchaseRequisitionData,
  getPurchaseRequisitionData,
  getTotalPurchaseRequisitionData,
} from "../../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearPurchaseRequisitionPageData,
  getPurchaseRequisitionPageData,
} from "../../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionPageAction";
import Swal from "sweetalert2";

let errorShow = false;

/*
 * Service to get PR list
 */
export const getAllPurchaseRequisitionService = (
  purchaseRequisitionListRequest: getAllPurchaseRequisitionRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllPurchaseRequisitionResponse> => {
  // Update return type here
  return new Promise<getAllPurchaseRequisitionResponse>(
    async (resolve, reject) => {
      // Update type parameter here
      try {
        dispatch(setLoader());
        let queryParams = "";
        // Adding query parameters if they are provided
        if (
          purchaseRequisitionListRequest.p_skip !== undefined &&
          purchaseRequisitionListRequest.p_skip !== null
        ) {
          queryParams += `p_skip=${purchaseRequisitionListRequest.p_skip}&`;
        }
        if (
          purchaseRequisitionListRequest.p_take !== undefined &&
          purchaseRequisitionListRequest.p_take !== null
        ) {
          queryParams += `p_take=${purchaseRequisitionListRequest.p_take}&`;
        }
        if (
          purchaseRequisitionListRequest.p_purchase_requisition_no !==
            undefined &&
          purchaseRequisitionListRequest.p_purchase_requisition_no !== null
        ) {
          queryParams += `p_purchase_requisition_no=${purchaseRequisitionListRequest.p_purchase_requisition_no}&`;
        }
        if (
          purchaseRequisitionListRequest.p_created_date !== undefined &&
          purchaseRequisitionListRequest.p_created_date !== null
        ) {
          queryParams += `p_created_date=${purchaseRequisitionListRequest.p_created_date}&`;
        }
        if (
          purchaseRequisitionListRequest.p_end_date !== undefined &&
          purchaseRequisitionListRequest.p_end_date !== null
        ) {
          queryParams += `p_end_date=${purchaseRequisitionListRequest.p_end_date}&`;
        }
        if (
          purchaseRequisitionListRequest.p_vendor_id !== undefined &&
          purchaseRequisitionListRequest.p_vendor_id !== null
        ) {
          queryParams += `p_vendor_id=${purchaseRequisitionListRequest.p_vendor_id}&`;
        }

        if (
          purchaseRequisitionListRequest.p_status !== undefined &&
          purchaseRequisitionListRequest.p_status !== null
        ) {
          queryParams += `p_status=${purchaseRequisitionListRequest.p_status}&`;
        }

        if (
          purchaseRequisitionListRequest.p_time_zone !== undefined &&
          purchaseRequisitionListRequest.p_time_zone !== null
        ) {
          queryParams += `p_time_zone=${purchaseRequisitionListRequest.p_time_zone}&`;
        }
        const response = await getApiService(
          API_URL.BASE_URL +
            API_URL.PURCHASEREQUISITION +
            (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
          token
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          if (total && total === true) {
            dispatch(getTotalPurchaseRequisitionData(response));
          } else if (total && total === "filter") {
            toast.success("Filter success!");
            dispatch(getPurchaseRequisitionData(response));
          } else {
            dispatch(getPurchaseRequisitionData(response));
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.error(
              response?.message
                ? response.message === "Permission does not exists."
                  ? "Purchase Requisition permission does not exists."
                  : response.message
                : ValidationMessage.SOMETHING_WENT_WRONG
            );
          }
          errorShow = true;
          if (
            response?.message &&
            response.message === "Unauthorized access!"
          ) {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
        }
        resolve(response);
      } catch (error) {
        if (errorShow === false) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    }
  );
};

/*
 * Service for adding and updating Vendor
 */
export const addUpdatePurchaseRequisitionService = (
  addUpdatePurchaseRequisitionRequest: addUpdatePurchaseRequisitionRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.PURCHASEREQUISITION,
          token,
          addUpdatePurchaseRequisitionRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.PURCHASEREQUISITION,
          token,
          addUpdatePurchaseRequisitionRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearPurchaseRequisitionData());
        dispatch(clearTotalPurchaseRequisitionData());
        //   dispatch(clearStockData());
        if (type === "add") {
          dispatch(clearPurchaseRequisitionPageData());
          toast.success("Purchase Requisition added successfully!");
          navigate("/purchase-requisition");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Purchase Requisition undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getPurchaseRequisitionPageData(pageNumber + 1));
              } else {
                dispatch(clearPurchaseRequisitionPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Purchase Requisition updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/purchase-requisition");
              });
            } else {
              toast.success(
                "Purchase Requisition status updated successfully!"
              );
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getPurchaseRequisitionPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};
