/**
 * Set Vendor page data
 * @param {*} page 
 * @returns {*}
 */
export const getVendorPageData = (page) => ({
    type: 'GET_VENDOR_PAGE',
    payload: page
})

/**
 * Clear Vendor page data
 * @returns {*}
 */
export const clearVendorPageData = () => ({
    type: 'CLEAR_VENDOR_PAGE'
})