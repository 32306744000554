import { Dispatch } from "redux";
import {
  addUpdateVendorRequest,
  getAllVendorRequest,
  getAllVendorResponse,
  placeIngrediantRequest,
  UploadPDFRequest,
} from "./VendorModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearTotalVendorData,
  clearVendorData,
  getTotalVendorData,
  getVendorData,
} from "../../../redux/Actions/VendorAction/VendorAction";
import {
  clearVendorPageData,
  getVendorPageData,
} from "../../../redux/Actions/VendorAction/VendorPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { postApiServiceWp } from "../../../services/API-services/PostServiceWp";
import { clearStockData } from "../../../redux/Actions/StocksAction/StockAction";

let errorShow = false;

/*
 * Service to get vendor list
 */
export const getAllVendorService = (
  vendorListRequest: getAllVendorRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllVendorResponse> => {
  // Update return type here
  return new Promise<getAllVendorResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        vendorListRequest.p_skip !== undefined &&
        vendorListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${vendorListRequest.p_skip}&`;
      }
      if (
        vendorListRequest.p_take !== undefined &&
        vendorListRequest.p_take !== null
      ) {
        queryParams += `p_take=${vendorListRequest.p_take}&`;
      }
      if (
        vendorListRequest.p_order_no !== undefined &&
        vendorListRequest.p_order_no !== null
      ) {
        queryParams += `p_order_no=${vendorListRequest.p_order_no}&`;
      }
      if (
        vendorListRequest.p_created_date !== undefined &&
        vendorListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${vendorListRequest.p_created_date}&`;
      }
      if (
        vendorListRequest.p_end_date !== undefined &&
        vendorListRequest.p_end_date !== null
      ) {
        queryParams += `p_end_date=${vendorListRequest.p_end_date}&`;
      }
      if (
        vendorListRequest.p_vendor_id !== undefined &&
        vendorListRequest.p_vendor_id !== null
      ) {
        queryParams += `p_vendor_id=${vendorListRequest.p_vendor_id}&`;
      }
      if (
        vendorListRequest.p_created_time !== undefined &&
        vendorListRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${vendorListRequest.p_created_time}&`;
      }
      if (
        vendorListRequest.p_status !== undefined &&
        vendorListRequest.p_status !== null
      ) {
        queryParams += `p_status=${vendorListRequest.p_status}&`;
      }
      if (
        vendorListRequest.p_payment !== undefined &&
        vendorListRequest.p_payment !== null
      ) {
        queryParams += `p_payment=${vendorListRequest.p_payment}&`;
      }
      if (
        vendorListRequest.p_time_zone !== undefined &&
        vendorListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${vendorListRequest.p_time_zone}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.VENDOR +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalVendorData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getVendorData(response));
        } else {
          dispatch(getVendorData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Vendor permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating Vendor
 */
export const addUpdateVendorService = (
  addUpdateVendorRequest: addUpdateVendorRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.VENDOR,
          token,
          addUpdateVendorRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.VENDOR,
          token,
          addUpdateVendorRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearVendorData());
        dispatch(clearTotalVendorData());
        dispatch(clearStockData());
        if (type === "add") {
          dispatch(clearVendorPageData());
          toast.success("Vendor added successfully!");
          navigate("/vendors");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Vendor undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getVendorPageData(pageNumber + 1));
              } else {
                dispatch(clearVendorPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Vendor updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/vendors");
              });
            } else {
              toast.success("Vendor status updated successfully!");
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getVendorPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for updateing Line level ingrediant of  Vendor
 */
export const LineLevelIngredientService = (
  placeProductRequest: placeIngrediantRequest,
  token: string,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await postApiService(
        API_URL.BASE_URL + API_URL.INGREDIENT_STATUS,
        token,
        placeProductRequest
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success("Ingrediant delivered successfully!");

        if (pageNumber) {
          dispatch(getVendorPageData(pageNumber + 1));
        } else {
          dispatch(clearVendorPageData());
        }
        dispatch(clearVendorData());
        dispatch(clearTotalVendorData());
        dispatch(clearStockData());
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Vendor permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
    } catch (error) {
      if (errorShow === false) {
        if (!document.querySelector(toastClass)) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting vendor
 */
export const deleteVendorService = (
  deleteId: number,
  token: string,
  pageNumber: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(
        API_URL.BASE_URL + API_URL.VENDOR + "/" + deleteId,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearVendorData());
        dispatch(clearTotalVendorData());
        dispatch(clearStockData());
        toast.success("Vendor deleted successfully!");
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
        dispatch(getVendorPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for file upload for whatsapp
 */
/*
 * Service for file upload for whatsapp
 */
export const uploadPDFService = (
  uploadPDFRequest: UploadPDFRequest,
  token: string,
  navigate?: any
) => {
  // console.log("uploadPDFRequest: ", uploadPDFRequest);

  return async (dispatch: Dispatch) => {
    // console.log("uploadPDFRequest return: ", uploadPDFRequest);
    let errorShow = false;

    try {
      dispatch(setLoader());

      // Prepare FormData
      const formData = new FormData();
      formData.append("pdf", uploadPDFRequest.pdf);
      formData.append(
        "p_vendor_phone_number",
        uploadPDFRequest.p_vendor_phone_number
      );
      formData.append(
        "p_branch_user_phone_number",
        uploadPDFRequest.p_branch_user_phone_number
      );
      formData.append(
        "p_branch_user_name",
        uploadPDFRequest.p_branch_user_name
      );
      formData.append("p_company_name", uploadPDFRequest.p_company_name);
      formData.append("p_branch_Address", uploadPDFRequest.p_branch_Address);
    
      // Make API call
      const response = await postApiServiceWp(
        API_URL.BASE_URL + API_URL.VENDOR + API_URL.WHATSAPP,
        token,
        formData
      );

      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success("Message sent to vendor successfully!");

        if (navigate) {
          Swal.fire({
            title: "Success!",
            text: "Message sent to vendor successfully!",
            icon: "success",
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            navigate("/vendors");
          });
        }
      } else {
        toast.error(
          response?.message ? response.message : "Something went wrong"
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          // Handle unauthorized access, e.g., logout or remove persist storage
        }
      }
    } catch (error) {
      if (!errorShow) {
        toast.error("Something went wrong");
      }
      console.error("Error in uploadPDFService:", error);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};
