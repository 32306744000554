import React, { useEffect, useRef, useState } from "react";
import "./SubCategory.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import CustomTable from "../../components/Table/Table";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Toggle from "../../components/Toggle/Toggle";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import {
  getAllSubCategoryService,
  uploadSubCategoryExcelService,
} from "./SubCategoryService/SubCategoryService.tsx";
import UtilsData, { ValidationMessage } from "../../Utils/Utils";
import { formatUTCDate } from "../../services/Regular-services/DateService";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearSubCategoryPageData,
  getSubCategoryPageData,
} from "../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { clearSubCategoryFilterData } from "../../redux/Actions/subCategoryAction/SubCategoryFilterAction";
import { clearSubCategoryData } from "../../redux/Actions/subCategoryAction/SubCategoryAction";
import sampleSubCategoryExcelFile from "../../assets/files/Sub-Category-Import.xlsx";
import DownloadExcel from "../../services/Regular-services/DownloadExcelService";
import { TfiImport } from "react-icons/tfi";
import FileUploadModal from "../../components/Import-component/ImportComponent";
import { toast } from "react-toastify";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  description: true,
  category: true,
  code: true,
  category_name: true,
  // sequence: true,
};

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

export default function SubCategory() {
  //Getting data from reducer
  const subCategoryData = useSelector((state) => state.subcategory);

  const SubCategoryFilter = useSelector((state) => state.SubCategoryFilter);

  const SubCategoryPage = useSelector((state) => state.SubCategoryPage?.items);

  const [currentPageNumber, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(SubCategoryPage);
  }, [SubCategoryPage]);

  const [subCategoryList, setSubCategoryList] = useState([]);

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [subCategoryActionData, setsubCategoryActionData] = useState([]);

  useEffect(() => {
    if (Array.isArray(sideBarData)) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "sub-category"
      );
      setsubCategoryActionData(actionData);
    } else {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  }, [sideBarData]);

  const [showActionBool, setShowActionBool] = useState(false);

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (
      subCategoryActionData[0]?.view &&
      subCategoryActionData[0].view === "0" &&
      subCategoryActionData[0]?.edit &&
      subCategoryActionData[0].edit === "0" &&
      subCategoryActionData[0]?.add &&
      subCategoryActionData[0].add === "0" &&
      subCategoryActionData[0]?.delete &&
      subCategoryActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Name,
          sortable: true,
        },
        {
          name: "Description",
          selector: (row) => row.Description,
          sortable: true,
        },
        {
          name: "Code",
          selector: (row) => row.Code,
          sortable: true,
        },
        {
          name: "Category",
          selector: (row) => row.category,
          sortable: true,
        },
        // {
        //   name: "Sequence",
        //   selector: (row) => row.Sequence,
        //   sortable: true,
        // },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Name,
          sortable: true,
        },
        {
          name: "Description",
          selector: (row) => row.Description,
          sortable: true,
        },
        {
          name: "Code",
          selector: (row) => row.Code,
          sortable: true,
        },
        {
          name: "Category",
          selector: (row) => row.category,
          sortable: true,
        },
        // {
        //   name: "Sequence",
        //   selector: (row) => row.Sequence,
        //   sortable: true,
        // },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [subCategoryActionData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (subCategoryActionData[0]?.view &&
        subCategoryActionData[0].view === "1") ||
      !subCategoryActionData
        ? true
        : false,
    showEdit:
      (subCategoryActionData[0]?.edit &&
        subCategoryActionData[0].edit === "1") ||
      !subCategoryActionData
        ? true
        : false,
    showDelete:
      (subCategoryActionData[0]?.delete &&
        subCategoryActionData[0].delete === "1") ||
      !subCategoryActionData
        ? true
        : false,
    showUndo:
      (subCategoryActionData[0]?.edit &&
        subCategoryActionData[0].edit === "1") ||
      !subCategoryActionData
        ? true
        : false,
    navigate:
      (subCategoryActionData[0]?.edit &&
        subCategoryActionData[0].edit === "1") ||
      !subCategoryActionData
        ? "/edit-sub-category"
        : false,
  };

  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Fetching sub-category list
   */
  const fetchSubCategory = () => {
    if (SubCategoryPage > 1) {
      const requestBody = {
        p_skip: SubCategoryPage ? (SubCategoryPage - 2) * 10 : 0,
        p_take: 10,
      };
      if (SubCategoryFilter.name !== "" && SubCategoryFilter.name !== null) {
        requestBody.p_name = SubCategoryFilter.name;
      }
      if (
        SubCategoryFilter.category !== "" &&
        SubCategoryFilter.category !== null
      ) {
        requestBody.p_categoryIds = SubCategoryFilter.category;
      }
      if (SubCategoryFilter.code !== "" && SubCategoryFilter.code !== null) {
        requestBody.p_category_code = SubCategoryFilter.code;
      }
      if (SubCategoryFilter.type !== "" && SubCategoryFilter.type !== null) {
        requestBody.p_type = SubCategoryFilter.type;
      }
      if (
        SubCategoryFilter.isActive !== "" &&
        SubCategoryFilter.isActive !== null
      ) {
        requestBody.p_is_active = SubCategoryFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (
        SubCategoryFilter.isDelete !== "" &&
        SubCategoryFilter.isDelete !== null
      ) {
        requestBody.p_isDeleted = SubCategoryFilter.isDelete;
      }
      getAllSubCategoryService(requestBody, tokenData, dispatch);
      setCurrentPage(SubCategoryPage - 1); // Update the current page state
      dispatch(getSubCategoryPageData(SubCategoryPage - 1));
    } else {
      const requestBody = {
        p_skip: SubCategoryPage ? (SubCategoryPage - 1) * 10 : 0,
        p_take: 10,
      };
      if (SubCategoryFilter.name !== "" && SubCategoryFilter.name !== null) {
        requestBody.p_name = SubCategoryFilter.name;
      }
      if (
        SubCategoryFilter.category !== "" &&
        SubCategoryFilter.category !== null
      ) {
        requestBody.p_categoryIds = SubCategoryFilter.category;
      }
      if (SubCategoryFilter.code !== "" && SubCategoryFilter.code !== null) {
        requestBody.p_category_code = SubCategoryFilter.code;
      }
      if (SubCategoryFilter.type !== "" && SubCategoryFilter.type !== null) {
        requestBody.p_type = SubCategoryFilter.type;
      }
      if (
        SubCategoryFilter.isActive !== "" &&
        SubCategoryFilter.isActive !== null
      ) {
        requestBody.p_is_active = SubCategoryFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (
        SubCategoryFilter.isDelete !== "" &&
        SubCategoryFilter.isDelete !== null
      ) {
        requestBody.p_isDeleted = SubCategoryFilter.isDelete;
      }
      getAllSubCategoryService(requestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    if (
      subCategoryData &&
      subCategoryData.items &&
      subCategoryData.items.length === 0
    ) {
      fetchSubCategory();
    } else {
      if (
        SubCategoryFilter.name !== "" ||
        SubCategoryFilter.code !== "" ||
        SubCategoryFilter.category !== "" ||
        SubCategoryFilter.type !== "" ||
        SubCategoryFilter.isActive !== "" ||
        SubCategoryFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }

    if (
      subCategoryData.items[0]?.data &&
      subCategoryData.items[0].data.length > 0
    ) {
      const transformedAdminData = subCategoryData.items[0].data.map(
        (item) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          code: item.sub_category_code,
          category_name: item.category_name,
          createdAt: formatUTCDate(item.created_at),
          sequence: item.Sequence,
          isActive: item.is_active,
          isDeleted: item.IsDeleted,
          typeId: item.type,
          createdBy: item.created_by,
          categoryId: item.category_id,
        })
      );
      setSubCategoryList(transformedAdminData);
    } else {
      setSubCategoryList([]);
    }
    // eslint-disable-next-line
  }, [subCategoryData]);

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getSubCategoryPageData(pageNumber));
    if (pageNumber !== currentPageNumber) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (SubCategoryFilter.name !== "" && SubCategoryFilter.name !== null) {
        requestBody.p_name = SubCategoryFilter.name;
      }
      if (
        SubCategoryFilter.category !== "" &&
        SubCategoryFilter.category !== null
      ) {
        requestBody.p_categoryIds = SubCategoryFilter.category;
      }
      if (SubCategoryFilter.code !== "" && SubCategoryFilter.code !== null) {
        requestBody.p_category_code = SubCategoryFilter.code;
      }
      if (SubCategoryFilter.type !== "" && SubCategoryFilter.type !== null) {
        requestBody.p_type = SubCategoryFilter.type;
      }
      if (
        SubCategoryFilter.isActive !== "" &&
        SubCategoryFilter.isActive !== null
      ) {
        requestBody.p_is_active = SubCategoryFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (
        SubCategoryFilter.isDelete !== "" &&
        SubCategoryFilter.isDelete !== null
      ) {
        requestBody.p_isDeleted = SubCategoryFilter.isDelete;
      }
      getAllSubCategoryService(requestBody, tokenData, dispatch);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Import modal open
   */
  const handleImportClick = () => {
    // Open the modal
    setIsModalOpen(true);
  };

  /**
   * Import modal close
   */
  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  /**
   * Handle file upload
   * @param {*} file
   */
  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(uploadSubCategoryExcelService(file, tokenData, setIsModalOpen));
  };

  /**
   * Clear filtered data
   */
  const clearcategoryFilters = () => {
    dispatch(clearSubCategoryPageData());
    dispatch(clearSubCategoryFilterData());
    dispatch(clearSubCategoryData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Sub Categories</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Sub Categories</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {(SubCategoryFilter.name !== "" ||
                  SubCategoryFilter.category !== "" ||
                  SubCategoryFilter.code !== "" ||
                  SubCategoryFilter.type !== "" ||
                  SubCategoryFilter.isActive !== "" ||
                  SubCategoryFilter.isDelete !== "") && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearcategoryFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                {/* {((subCategoryActionData[0]?.add &&
                  subCategoryActionData[0].add === "1") ||
                  !subCategoryActionData) && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <TfiImport />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleImportClick}>
                        Import
                      </Dropdown.Item>
                      <DownloadExcel
                        excelFile={sampleSubCategoryExcelFile}
                        fileName="Sub-Category-Import.xlsx"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}

                <FileUploadModal
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onFileUpload={handleFileUpload}
                  isClose={isModalOpen}
                />
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter
                page="subCategory" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>

                {subCategoryData &&
                  subCategoryData.items &&
                  subCategoryData.items[0]?.data &&
                  subCategoryData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={subCategoryData.items[0].data}
                      fileName={"subcategory"}
                    ></ExcelData>
                  )}

                {((subCategoryActionData[0]?.add &&
                  subCategoryActionData[0].add === "1") ||
                  !subCategoryActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add sub-category"
                    onClick={() => navigate("/add-sub-category")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {/* {isToggled && (
              <Filter
                page="subCategory"
                handleButtonClick={handleButtonClick}
              ></Filter>
            )} */}
            {subCategoryData &&
              subCategoryData.items &&
              subCategoryData.items[0]?.data &&
              subCategoryData.items[0].data && (
                <>
                  <CustomTable
                    totalRecords={
                      subCategoryData.items[0].maxSequence
                        ? subCategoryData.items[0].maxSequence
                        : subCategoryData.items[0].total_records
                        ? subCategoryData.items[0].total_records
                        : 0
                    }
                    showDescription={true}
                    page="subCategory"
                    showToggle={showToggle}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    data={subCategoryList}
                    heading={columns}
                    checkBool={false}
                    disabled={
                      subCategoryActionData[0]?.edit &&
                      subCategoryActionData[0].edit === "0"
                    }
                  />
                  {subCategoryData.items[0].total_records >
                    subCategoryData.items[0].data.length &&
                    subCategoryData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={subCategoryData.items[0].total_records}
                        currentPage={currentPageNumber}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}