import { Dispatch } from "redux";
import { addUpdateRemainigStockRequest } from "./AddRemainingStockModal";
import { clearLoader, setLoader } from "../../../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../../../services/API-services/PostService";
import { API_URL, removePersistStorage, ValidationMessage } from "../../../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearStockData, clearTotalStockData } from "../../../../../redux/Actions/StocksAction/StockAction";
import { clearStockPageData, getStockPageData } from "../../../../../redux/Actions/StocksAction/StockPageAction";
import { clearStockHistoryData, clearTotalStockHistoryData } from "../../../../../redux/Actions/StockHistoryAction/StockHistoryAction";


let errorShow = false;

/*
 * Service for adding and updating Vendor
 */
export const addUpdateRemainingStockService = (
    addUpdateRemainigStockRequest: addUpdateRemainigStockRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.STOCKS,
            token,
            addUpdateRemainigStockRequest
          );
        }
        // if (type === "update") {
        //   response = await postApiService(
        //     API_URL.BASE_URL + API_URL.VENDOR,
        //     token,
        //     addUpdateRemainigStockRequest
        //   );
        // }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearStockData());
          dispatch(clearTotalStockData());
          dispatch(clearStockHistoryData());
          dispatch(clearTotalStockHistoryData());
          // dispatch()
          if (type === "add") {
            dispatch(clearStockPageData());
            toast.success("Stock added successfully!");
            navigate("/stock");
          }
        //   if (type === "update") {
        //     if (navigate && navigate === "undo") {
        //       toast.success("Vendor undeleted successfully!");
        //     } else {
        //       if (navigate) {
        //         if (pageNumber) {
        //           dispatch(getStockPageData(pageNumber + 1));
        //         } else {
        //           dispatch(clearStockPageData());
        //         }
        //         Swal.fire({
        //           title: "Saved!",
        //           text: "Stock updated successfully!",
        //           icon: "success",
        //           timer: 2000,
        //           showClass: {
        //             popup: "animate__animated animate__fadeInDown",
        //           },
        //           hideClass: {
        //             popup: "animate__animated animate__fadeOutUp",
        //           },
        //         }).then(() => {
        //           navigate("/stocks");
        //         });
        //       } else {
        //         toast.success("Vendor status updated successfully!");
        //       }
        //     }
        //   }
        } else {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
          errorShow = true;
          if (response?.message && response.message === "Unauthorized access!") {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          if (pageNumber) {
            dispatch(getStockPageData(pageNumber));
          }
        }
      } catch (error) {
        if (errorShow === false) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };
  