import React from 'react'
import '../Footer/Footer.scss'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <div className='new-footer'>
    <div className="footer-copyright text-center py-2">© 2024 Copyright : 
          <Link to="/"> Topnotch IT Consultant - {process.env.REACT_APP_CONSTANT_VERSION}</Link>
      </div>
    </div>

    </>
  )
}