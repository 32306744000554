import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import { Breadcrumb } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import Footer from "../../../components/Footer/Footer";
import { modifyString } from "../../../services/Regular-services/StringService";
import Input from "../../../components/input/Input";
import Toggle from "../../../components/Toggle/Toggle";
import Button from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import { getUnitsDropdownData } from "../../../redux/Actions/UnitsAction/UnitsDropdownAction";
import {
  addUpdateUnitsService,
  getAllUnitsService,
  getUnitsDropdownService,
} from "../UnitsService/UnitsService";
import { clearUnitsPageData } from "../../../redux/Actions/UnitsAction/UnitsPageAction";

const AddUnits = () => {
  const validationSchema = Yup.object({
    unit: Yup.string().required(ValidationMessage.UNIT_REQUIRED),
  });

  const [initialValues, setInitialValues] = useState({
    unit: "",
    status: 1,
  });
  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const [fieldArrays, setFieldArrays] = useState([]);
  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);
  const [unitsOptions, setUnitsOptions] = useState([]);
  const totalUnits = useSelector((state) => state.totalUnits);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [unitsActionData, setUnitsActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "units"
      );
      setUnitsActionData(actionData);
    }
  }, [sideBarData]);

    useEffect(() => {
      if (Data && Data.data) {
        setInitialValues({
          unit: Data.data.unit_id,
          id:Data.data.id,
          status: Data.data.status,
          is_deleted: Data.data.is_delete,
        });
      }
    }, [Data]);

    
  /**
   * Fetching product data from API
   */
  // const fetchUnitsDropdown = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_limit: 1000000,
  //   };
  //   getUnitsDropdownService(requestBody, token, dispatch, true);
  // };

  // useEffect(() => {
  //   if (
  //     totalUnitsDropdown &&
  //     totalUnitsDropdown.items &&
  //     totalUnitsDropdown.items.length === 0
  //   ) {
  //     fetchUnitsDropdown();
  //   } else {
  //     if (
  //       totalUnitsDropdown.items[0]?.data &&
  //       totalUnitsDropdown.items[0].data.length > 0
  //     ) {  
  //       const newUnit = totalUnitsDropdown.items[0].data.map((item) => ({
  //         label: item?.unit,
  //         value: item?.id,
  //         unit_value: item?.unit_value,
  //       }));   
  //       if (totalUnits?.items?.length === 0) {
  //         fetchUnits();
  //       } else if (totalUnits?.items[0]?.data?.length > 0) {
  //         const filteredUnitsOptions = newUnit.filter(
  //           (newItem) =>
  //             !totalUnits.items[0].data.some(
  //               (unit) => unit.unit_id === newItem.value
  //             )
  //         );
  //         setUnitsOptions(filteredUnitsOptions);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [totalUnitsDropdown, totalUnits]);

  const fetchUnits = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      // p_is_active: "1",
    };
    getAllUnitsService(requestBody, token, dispatch, true);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };
  const handleSubmit = (values) => {
    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_unit_id: values.unit,
      p_isActive:
        values.status === true || values.status === 1 || values.status === "1"
          ? "1"
          : "0",
      ...(Data && Data.data && { p_IsDeleted: values.is_deleted }),
    };
    console.log("req ",requestBody);
    
    // return
    dispatch(clearUnitsPageData());
    if (!Data) {
      dispatch(addUpdateUnitsService(requestBody, token, "add", navigate));
    } else {
      handlesaveClick(
        dispatch,
        addUpdateUnitsService(requestBody, token, "update", navigate),
        "update"
      );
    }
  };
  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {unitsActionData && unitsActionData.length > 0
                      ? modifyString(unitsActionData[0].name)
                      : "Units"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/units")}>
                    {unitsActionData && unitsActionData.length > 0
                      ? unitsActionData[0].name
                      : "Units"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {unitsActionData && unitsActionData.length > 0
                      ? modifyString(unitsActionData[0].name)
                      : "Units"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {unitsActionData && unitsActionData.length > 0
                  ? modifyString(unitsActionData[0].name)
                  : "Units"}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">
                            Select Unit
                            <span className="required-label"></span>
                          </h4>
                          <Field
                            className="select-menu mb-2"
                            name="unit"
                            options={unitsOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            disabled={Data && Data.isEdit=== false}
                            value={values.unit}
                            Regex={/[^a-zA-Z\s]/g}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Status</h4>
                          <Field
                            name="status"
                            className="toggle"
                            setFieldValue={setFieldValue}
                            component={Toggle}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <div className="btn-submit mt-3">
                            {((Data && Data.isEdit === true) || !Data) && (
                              <>
                                <Button
                                  type="submit"
                                  name="Save"
                                  className="btn login-btn"
                                />
                                <Button
                                  type="button"
                                  name="Cancel"
                                  className="btn clear-btn login-btn"
                                  onClick={() =>
                                    handleCancleClick("/units", navigate)
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AddUnits;
