import React, { useEffect, useRef, useState } from "react";
import "./InventoryItems.scss";
import CustomTable from "../../components/Table/Table";
import Footer from "../../components/Footer/Footer";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { FaFilter, FaPlus } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import UtilsData from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryItemService } from "./InventoryItemService/InventoryItemService";
import {
  clearInventoryItemPageData,
  getInventoryItemPageData,
} from "../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import { clearInventoryItemData } from "../../redux/Actions/InventoryItemAction/InventoryItemAction";
import CustomPagination from "../../components/Pagination/Pagination";
import Toggle from "../../components/Toggle/Toggle";
import { clearInventoryItemFilterData } from "../../redux/Actions/InventoryItemAction/InventoryItemFilterAction";
import {
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../services/Regular-services/ConvertUnitService";

export default function InventoryItems() {
  const inventoryItemData = useSelector((state) => state.inventoryItem);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const inventoryItemPage = useSelector(
    (state) => state.inventoryItemPage?.items
  );
  const inventoryItemFilter = useSelector((state) => state.inventoryItemFilter);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [inventoryItemActionData, setInventoryItemActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setInventoryItemActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    quantity: true,
    unit: true,
    category_name:true
  };
  const showToggle = {
    status: true,
  };

  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Threshold Quantity",
      selector: (row) => row.quantity,
    },
    {
      name: "Threshold Unit",
      selector: (row) => row.unit,
    },
    {
      name: "Category Name",
      selector: (row) => row.category_name,
    },
    {
      name: "Status",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ]);

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      inventoryItemActionData[0]?.view &&
      inventoryItemActionData[0].view === "0" &&
      inventoryItemActionData[0]?.edit &&
      inventoryItemActionData[0].edit === "0" &&
      inventoryItemActionData[0]?.add &&
      inventoryItemActionData[0].add === "0" &&
      inventoryItemActionData[0]?.delete &&
      inventoryItemActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.name,
        },
        {
          name: "Threshold Quantity",
          selector: (row) => row.quantity,
        },
        {
          name: "Threshold Unit",
          selector: (row) => row.unit,
        },
        {
          name: "Category Name",
          selector: (row) => row.category_name,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.name,
        },
        {
          name: "Threshold Quantity",
          selector: (row) => row.quantity,
        },
        {
          name: "Threshold Unit",
          selector: (row) => row.unit,
        },
        {
          name: "Category Name",
          selector: (row) => row.category_name,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
    }
  }, [inventoryItemActionData]);

  const showActions = {
    showView:
      (inventoryItemActionData[0]?.view &&
        inventoryItemActionData[0].view === "1") ||
      !inventoryItemActionData
        ? true
        : false,
    showEdit:
      (inventoryItemActionData[0]?.edit &&
        inventoryItemActionData[0].edit === "1") ||
      !inventoryItemActionData
        ? true
        : false,
    showDelete:
      (inventoryItemActionData[0]?.delete &&
        inventoryItemActionData[0].delete === "1") ||
      !inventoryItemActionData
        ? true
        : false,
    showUndo:
      (inventoryItemActionData[0]?.edit &&
        inventoryItemActionData[0].edit === "1") ||
      !inventoryItemActionData
        ? true
        : false,
    navigate:
      (inventoryItemActionData[0]?.edit &&
        inventoryItemActionData[0].edit === "1") ||
      !inventoryItemActionData
        ? "/edit-inventory-item"
        : false,
  };

  useEffect(() => {
    setCurrentPage(inventoryItemPage);
  }, [inventoryItemPage]);

  /**
   * Fetch inventory item data
   */
  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip:
        inventoryItemPage > 1
          ? (inventoryItemPage - 2) * 10
          : (inventoryItemPage - 1) * 10,
      p_limit: 10,
    };
    if (
      inventoryItemFilter.title !== "" &&
      inventoryItemFilter.title !== null
    ) {
      requestBody.p_title = inventoryItemFilter.title;
    }
    if (
      inventoryItemFilter.isActive !== "" &&
      inventoryItemFilter.isActive !== null
    ) {
      requestBody.p_isActive = inventoryItemFilter.isActive;
    } else {
      requestBody.p_isActive = "1";
    }
    if (
      inventoryItemFilter.isDelete !== "" &&
      inventoryItemFilter.isDelete !== null
    ) {
      requestBody.p_IsDeleted = inventoryItemFilter.isDelete;
    }

    getAllInventoryItemService(requestBody, tokenData, dispatch);
    if (inventoryItemPage > 1) {
      setCurrentPage(inventoryItemPage - 1); // Update the current page state
      dispatch(getInventoryItemPageData(inventoryItemPage - 1));
    }
  };

  useEffect(() => {
    if (
      inventoryItemData &&
      inventoryItemData.items &&
      inventoryItemData.items.length === 0
    ) {
      fetchInventoryItem();
    } else {
      if (
        inventoryItemFilter?.title !== "" ||
        inventoryItemFilter?.isActive !== "" ||
        inventoryItemFilter?.isDelete !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (
      inventoryItemData.items[0]?.data &&
      inventoryItemData.items[0].data.length > 0
    ) {
      const transformedInventoryItemData = inventoryItemData.items[0].data.map(
        (item) => {
          let quantityInOriginalUnit = parseFloat(item.thresoldValue);
          let unit = item.thresoldUnit;
          if (
            unit === "gm" ||
            unit === "ml" ||
            unit === "kg" ||
            unit === "l" ||
            unit === "mg"
          ) {
            if (unit === "gm" && quantityInOriginalUnit > 999) {
              quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
              unit = "kg";
            } else if (unit === "gm" && quantityInOriginalUnit < 1) {
              quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
              unit = "mg";
            } else if (unit === "ml" && quantityInOriginalUnit > 999) {
              quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
              unit = "l";
            }
          }

          return {
            id: item.IngredientId,
            name: item.title,
            quantity: quantityInOriginalUnit,
            unit: unit,
            category_name:item.category_name ? item.category_name : "N/A",
            status: item.isActive,
            is_delete: item.isDeleted,
            isActive:item.isActive,
            category_id:item.category_id,
            instruction:item.instruction
          };
        }
      );

      setTableData(transformedInventoryItemData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [inventoryItemData, inventoryItemFilter]);

  const styleData = {
    quantity: { type: { textAlign: "end" } },
  };

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (
        inventoryItemFilter.title !== "" &&
        inventoryItemFilter.title !== null
      ) {
        requestBody.p_title = inventoryItemFilter.title;
      }
      if (
        inventoryItemFilter.isActive !== "" &&
        inventoryItemFilter.isActive !== null
      ) {
        requestBody.p_isActive = inventoryItemFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (
        inventoryItemFilter.isDelete !== "" &&
        inventoryItemFilter.isDelete !== null
      ) {
        requestBody.p_IsDeleted = inventoryItemFilter.isDelete;
      }

      getAllInventoryItemService(requestBody, tokenData, dispatch);
      dispatch(getInventoryItemPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearInventoryItemFilterData());
    dispatch(clearInventoryItemPageData());
    dispatch(clearInventoryItemData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
      <>
        <div className="main-section">
          <div className="sidebar-content">
            <Sidebar />
          </div>
          <div className="body-content">
            <Header />
            <div className="table-body-content">
              <div className="roles">
                <div>
                  <div className="title">
                    <h4>
                      {inventoryItemActionData &&
                      inventoryItemActionData.length > 0
                        ? inventoryItemActionData[0].name
                        : "Inventory Items"}
                    </h4>
                  </div>
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate("/home")}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {inventoryItemActionData &&
                      inventoryItemActionData.length > 0
                        ? inventoryItemActionData[0].name
                        : "Inventory Items"}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="btn-groups">
                  {inventoryItemFilter.title !== "" ||
                  inventoryItemFilter.isActive !== "" ||
                  inventoryItemFilter.isDelete !== "" ? (
                    <Dropdown className="header-dropdown action-btn">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        data-bs-toggle="tooltip"
                        title="Action"
                      >
                        <GrMoreVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={clearFilters}>
                          Clear Filter
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                   <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="inventory-item"  handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>

                  {inventoryItemData &&
                    inventoryItemData.items[0]?.data &&
                    inventoryItemData.items[0].data.length > 0 && (
                      <ExcelData
                        excelData={inventoryItemData.items[0].data}
                        fileName={"inventory-item"}
                      ></ExcelData>
                    )}
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Inventory Item"
                    onClick={() => navigate("/add-inventory-item")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                </div>
              </div>
              {/* {isToggled && <Filter page="inventory-item"></Filter>} */}

              {inventoryItemData &&
                inventoryItemData.items[0]?.data &&
                inventoryItemData.items[0].data && (
                  <>
                    <CustomTable
                      page="inventory-item"
                      showToggle={showToggle}
                      checkBool={false}
                      showKey={showKey}
                      showActions={showActionBool ? showActions : false}
                      showTooltip={true}
                      data={tableData}
                      heading={columns}
                      styleData={styleData}
                      disabled={
                        inventoryItemActionData &&
                        inventoryItemActionData[0]?.edit &&
                        inventoryItemActionData[0].edit === "0"
                      }
                      currentPage={currentPageNumber}
                      onPageChange={onPageChange}
                    />

                    {/* pagination */}
                    {inventoryItemData.items[0].total_records >
                      inventoryItemData.items[0].data.length &&
                      inventoryItemData.items[0].total_records > 10 && (
                        <CustomPagination
                          totalRecords={
                            inventoryItemData.items[0].total_records
                          }
                          currentPage={currentPageNumber}
                          onPageChange={onPageChange}
                        />
                      )}
                  </>
                )}
            </div>
            <Footer />
          </div>
        </div>
      </>
  );
}
