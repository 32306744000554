import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import CustomTable from "../../components/Table/Table";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackagerService } from "./PackagerService/PackagerService";
import UtilsData from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearPackagerPageData,
  getPackagerPageData,
} from "../../redux/Actions/PackagerAction/PackagerPageAction";
import { clearPackagerData } from "../../redux/Actions/PackagerAction/PackagerAction";
import { clearPackagerFilterData } from "../../redux/Actions/PackagerAction/PackagerFilterAction";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  address: true,
  typeName: true,
  pincode: true,
};

const showToggle = {
  status: true,
  delete: false,
};

export default function PackagerAndManufacturer() {
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const packager = useSelector((state) => state.packager);
  const packagerPage = useSelector((state) => state.packagerPage?.items);
  const packagerFilter = useSelector((state) => state.packagerFilter);

  const dispatch = useDispatch();
  const tokenData = UtilsData();

  const [packagerListData, setPackagerListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [packagerActionData, setpackagerActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setpackagerActionData(actionData);
    }
  }, [sideBarData]);
  // packager-and-manufacturer

  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      packagerActionData[0]?.view &&
      packagerActionData[0].view === "0" &&
      packagerActionData[0]?.edit &&
      packagerActionData[0].edit === "0" &&
      packagerActionData[0]?.add &&
      packagerActionData[0].add === "0" &&
      packagerActionData[0]?.delete &&
      packagerActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Nam,
        },
        {
          name: "Type",
          selector: (row) => row.type,
        },
        {
          name: "Address",
          selector: (row) => row.address,
        },
        {
          name: "Pincode",
          selector: (row) => row.pincode,
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Nam,
        },
        {
          name: "Type",
          selector: (row) => row.type,
        },
        {
          name: "Address",
          selector: (row) => row.address,
        },
        {
          name: "Pincode",
          selector: (row) => row.pincode,
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [packagerActionData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (packagerActionData[0]?.view && packagerActionData[0].view === "1") ||
      !packagerActionData
        ? true
        : false,
    showEdit:
      (packagerActionData[0]?.edit && packagerActionData[0].edit === "1") ||
      !packagerActionData
        ? true
        : false,
    showDelete:
      (packagerActionData[0]?.delete && packagerActionData[0].delete === "1") ||
      !packagerActionData
        ? true
        : false,
    showUndo:
      (packagerActionData[0]?.edit && packagerActionData[0].edit === "1") ||
      !packagerActionData
        ? true
        : false,
    navigate:
      (packagerActionData[0]?.edit && packagerActionData[0].edit === "1") ||
      !packagerActionData
        ? "/add-packager"
        : false,
  };

  useEffect(() => {
    setCurrentPage(packagerPage);
  }, [packagerPage]);

  /**
   * Fetch packager data
   */
  const fetchPackager = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10,
    };
    if (packagerPage > 1) {
      requestBody.p_skip = (packagerPage - 2) * 10;
      dispatch(getPackagerPageData(packagerPage - 1));
    } else {
      requestBody.p_skip = (packagerPage - 1) * 10;
      dispatch(getPackagerPageData(packagerPage));
    }

    if (packagerFilter.name !== "" && packagerFilter.name !== null) {
      requestBody.p_name = packagerFilter.name;
    }
    if (packagerFilter.type !== "" && packagerFilter.type !== null) {
      requestBody.p_type = packagerFilter.type;
    }

    if (packagerFilter.isActive !== "" && packagerFilter.isActive !== null) {
      requestBody.p_is_active = packagerFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }

    if (packagerFilter.isDelete !== "" && packagerFilter.isDelete) {
      requestBody.p_IsDeleted = packagerFilter.isDelete;
    }
    getAllPackagerService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (packager && packager.items.length === 0) {
      fetchPackager();
    } else {
      if (packager.items[0]?.data && packager.items[0].data.length > 0) {
        const transformedData = packager.items[0].data.map((item) => ({
          productPackagerId: item.productPackagerId,
          name: item.name,
          typeName:
            item.type === "0"
              ? "Manufacturer"
              : item.type === "1"
              ? "Packager"
              : item.type === "2"
              ? "importer"
              : "",
          type: item.type,
          address: item.address,
          pincode: item.pincode,
          is_active: item.is_active,
          created_by: item.created_by,
          modified_by: item.modified_by,
          created_at: item.created_at,
          modified_at: item.modified_at,
          IsDeleted: item.IsDeleted,
        }));
        setPackagerListData(transformedData);
      } else {
        setPackagerListData([]);
      }

      if (
        packagerFilter.name !== "" ||
        packagerFilter.type !== "" ||
        packagerFilter.isActive !== "" ||
        packagerFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [packager]);

  const styleData = {
    pincode: {type: {textAlign: 'center'}}
  }

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * On ppage change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (packagerFilter.name !== "" && packagerFilter.name !== null) {
        requestBody.p_name = packagerFilter.name;
      }
      if (packagerFilter.type !== "" && packagerFilter.type !== null) {
        requestBody.p_type = packagerFilter.type;
      }

      if (packagerFilter.isActive !== "" && packagerFilter.isActive !== null) {
        requestBody.p_is_active = packagerFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (packagerFilter.isDelete !== "" && packagerFilter.isDelete) {
        requestBody.p_IsDeleted = packagerFilter.isDelete;
      }
      getAllPackagerService(requestBody, tokenData, dispatch);
      setCurrentPage(pageNumber);
      dispatch(getPackagerPageData(pageNumber));
    }
  };

  /**
   * clear filtered data
   */
  const clearFilters = () => {
    setIsToggled(false);
    dispatch(clearPackagerFilterData());
    dispatch(clearPackagerPageData());
    dispatch(clearPackagerData());
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {packagerActionData && packagerActionData.length > 0
                      ? packagerActionData[0].name
                      : "Packager and Manufacturer"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {packagerActionData && packagerActionData.length > 0
                      ? packagerActionData[0].name
                      : "Packager and Manufacturer"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {packagerFilter.name !== "" ||
                packagerFilter.type !== "" ||
                packagerFilter.isActive !== "" ||
                packagerFilter.isDelete !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {packager &&
                  packager.items &&
                  packager.items[0]?.data &&
                  packager.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={packager.items[0].data}
                      fileName={"packager-and-manufacturer"}
                    ></ExcelData>
                  )}
                {((packagerActionData && packagerActionData[0]?.add === "1") ||
                  !packagerActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Discount"
                    onClick={() => navigate("/add-packager")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {isToggled && <Filter page="packager"></Filter>}
            {packager &&
              packager.items &&
              packager.items[0]?.data &&
              packager.items[0].data && (
                <>
                  <CustomTable
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    data={packagerListData}
                    heading={columns}
                    checkBool={false}
                    page="packager"
                    showToggle={showToggle}
                    styleData={styleData}
                    disabled={
                      packagerActionData && packagerActionData[0]?.edit === "0"
                    }
                  />

                  {packager.items[0].total_records >
                    packager.items[0].data.length &&
                    packager.items[0].total_records > 10 && (
                      <div className="custom-pagination">
                        <CustomPagination
                          totalRecords={packager.items[0].total_records}
                          currentPage={currentPage}
                          onPageChange={onPageChange}
                        />
                      </div>
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
