import { parseImage } from "../../../services/Regular-services/ImageService";

export const ProductToggleRequestBody = ({
  productDetail,
  isActive,
  isDelete,
  userId,
  toggleData,
}) => {
  const tagData = productDetail.data.tag_ids
    ? productDetail.data.tag_ids.split(",").map(Number)
    : [];
  const tagId = tagData.map((item) => ({
    id: item,
  }));

  const productImage = productDetail.data.image_data
    ? parseImage(JSON.stringify(productDetail.data.image_data))
    : null;
  const productImageData = productImage
    ? productImage.map((item) => ({
        id: parseInt(item.id),
        type_id: parseInt(item.type),
      }))
    : [];

  const productCategory = productDetail.data.category_ids
    ? productDetail.data.category_ids.split(",").map(Number)
    : [];
  const productCategoryData = productCategory.map((item) => ({
    id: item,
  }));

  const productSubCategory = productDetail.data.sub_category_ids
    ? productDetail.data.sub_category_ids.split(",").map(Number)
    : [];
  const productSubCategoryData = productSubCategory.map((item) => ({
    id: item,
  }));

  const productLeaf = productDetail.data.leaf_level_category_ids
    ? productDetail.data.leaf_level_category_ids.split(",").map(Number)
    : [];
  const productLeafData = productLeaf.map((item) => ({
    id: item,
  }));

  const attributeData = productDetail.data.attribute_data
    ? parseImage(productDetail.data.attribute_data)
    : [];

  const attributeImageData = productDetail.data.attribute_image_data
    ? parseImage(JSON.stringify(productDetail.data.attribute_image_data))
    : [];

  const attributeImageMap = new Map(
    attributeImageData.map((item) => [item.id, item])
  );
  const mergedAttributeData = attributeData.map((data) => ({
    id: data.id,
    value: data.value,
    imageId: parseInt(attributeImageMap.get(data.attribute_image).id) || null,
    type_id: parseInt(attributeImageMap.get(data.attribute_image).type) || null,
    category_id: parseInt(data.category_id),
  }));
  const requestBody = {
    id: toggleData.productId,
    warehouse_id: productDetail.data.warehouse_id,
    brand_id: productDetail.data.brand_id,
    manufacturer_id: productDetail?.data?.manufacturer_id
      ? productDetail.data.manufacturer_id
      : "",
    packer_id: productDetail?.data?.packer_id
      ? productDetail.data.packer_id
      : "",
    importer_id: productDetail?.data?.importer_id
      ? productDetail.data.importer_id
      : "",
    name: productDetail.data.name,
    description: productDetail.data.description,
    additional_description: productDetail.data.additional_description,
    product_code: productDetail.data.product_code,
    hsn: productDetail.data.hsn,
    price: productDetail.data.price,
    p_purchase_price: productDetail.data.purchase_price
      ? productDetail.data.purchase_price
      : 0,
    unit: productDetail.data.unit,
    sku: productDetail.data.sku,
    size: productDetail.data.size,
    color_code: productDetail.data.color_code,
    country_of_origin: productDetail.data.country_of_origin,
    color_name: productDetail.data.color_name,
    Tags: tagId,
    SKID: productDetail.data.SKID,
    EAN_CODES: productDetail.data.EAN_CODES,
    imageIds: productImageData,
    category_ids: productCategoryData,
    sub_category_ids: productSubCategoryData,
    leaf_level_ids: productLeafData,
    attributeData: mergedAttributeData,
    Unit_Fill_Pack_Size: productDetail.data.Unit_Fill_Pack_Size,
    Unit_Pack_Size: productDetail.data.Unit_Pack_Size,
    Length_of_outer_package: productDetail.data.Length_of_outer_package,
    Breadth_of_outer_package: productDetail.data.Breadth_of_outer_package,
    Height_of_outer_package: productDetail.data.Height_of_outer_package,
    Weight_including_packaging: productDetail.data.Weight_including_packaging,
    //   created_by: produc
    modified_by: userId,
    is_active: isActive
      ? toggleData.isActive === "1"
        ? "0"
        : "1"
      : productDetail.data.is_active,
    IsDeleted: isDelete
      ? toggleData.isDeleted === "1"
        ? "0"
        : "1"
      : productDetail.data.IsDeleted,
    p_veg_nonveg: toggleData.veg_nonveg,
    p_jain_regular: toggleData.jain_regular,
    p_exclude_filter: toggleData.filter,
    p_is_vegan: toggleData.vegan_or_not,
    p_is_jain: toggleData.jain_or_not,
    p_sgst:
      productDetail.data?.sgst && productDetail.data.sgst !== ""
        ? productDetail.data.sgst
        : null,
    p_cgst:
      productDetail.data?.cgst && productDetail.data.cgst !== ""
        ? productDetail.data.cgst
        : null,
    p_igst:
      productDetail.data?.igst && productDetail.data.igst !== ""
        ? productDetail.data.igst
        : null,
    p_vat:
      productDetail.data?.vat && productDetail.data.vat !== ""
        ? productDetail.data.vat
        : null,
  };
  return requestBody;
};