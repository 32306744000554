import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from "react-bootstrap";
import "../Industry/Industry.scss";
import Toggle from "../../components/Toggle/Toggle";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";

//Show keys which is to be displayed in table
const showKey = {
    name: true,
    price: true,
    size: true,
    // branch_id:true
};

const showToggle = {
    status: true,
};

const Industry = () => {
    const industryData = useSelector((state) => state.industry);
    const tokenData = UtilsData();
    const dispatch = useDispatch();
    const [currentPageNumber, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const industryPage = useSelector((state) => state.industryPage?.items);
    const industryFilter = useSelector((state) => state.industryFilter);

    const sideBarData = useSelector((state) => state.sideBar.items);
    const [industryActionData, setIndustryActionData] = useState([]);
    const user = useSelector((state) => state.auth?.user);
    const navigate = useNavigate();
    const [isToggled, setIsToggled] = useState(false);
    const showActions = {
        showView: true,
        showEdit: true,
        showDelete: true,
        showUndo: true,
        showPrint: true,
        navigate: "/addindustry",
    };
    //Headings

    const columns = [
        {
            name: "Name",
            selector: (row) => row.p_name,
        },
        {
            name: "Price",
            selector: (row) => row.p_price,
        },
        {
            name: "Size",
            selector: (row) => row.p_size,
        },
        // {
        //     name: 'Branch Id',
        //     selector: row => row.branch_id
        // },
        {
            name: "Status",
            cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
            name: "Actions",
            cell: (row) => <Toggle checked={row.toggle} />,
        },

        // {
        //     name: 'Available',
        //     cell: (row) => <Toggle checked={row.toggle} />
        // }
    ];

    useEffect(() => {
        if (sideBarData) {
            const actionData = sideBarData.filter(
                (item) => item.router_link === "attributes"
            );
            setIndustryActionData(actionData);
        }
    }, [sideBarData]);

    useEffect(() => {
        setCurrentPage(industryPage);
    }, [industryPage]);

    const tooltipRef = useRef(null);

    useEffect(() => {
        if (tooltipRef.current) {
            const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
            return () => {
                tooltip.dispose();
            };
        }
    }, []);

    /**
     * Handle filter toggle
     */
    const handleButtonClick = () => {
        setIsToggled((prevIsToggled) => !prevIsToggled);
    };

    /**
     * Integrate listing API on page change
     */
    // const onPageChange = (pageNumber) => {
    //     if (pageNumber !== currentPageNumber) {
    //         setCurrentPage(pageNumber);
    //         const requestBody = {
    //             p_skip: (pageNumber - 1) * 10,
    //             p_take: 10,
    //         };
    //         if (spotFilter.name !== "" && spotFilter.name !== null) {
    //             requestBody.name = spotFilter.name;
    //         }
    //         if (spotFilter.is_active !== "" && spotFilter.is_active !== null) {
    //             requestBody.is_active = spotFilter.is_active;
    //         } else {
    //             requestBody.is_active = "1";
    //         }
    //         if (spotFilter.is_deleted !== "" && spotFilter.is_deleted !== null) {
    //             requestBody.is_deleted = spotFilter.is_deleted;
    //         }
    //         // if (spotFilter.is_available !== '' && spotFilter.is_available !== null) {
    //         //     requestBody.is_available = spotFilter.is_available;
    //         // }
    //         // return
    //         getAllSpotService(requestBody, tokenData, dispatch);
    //         dispatch(getSpotPageData(pageNumber));
    //     }
    // };

    /**
     * Clear filterrd data
     */
    // const clearFilters = () => {
    //     dispatch(clearSpotFilterData());
    //     dispatch(clearSpotPageData());
    //     dispatch(clearSpotData());
    //     setIsToggled(false);
    // };


    return (
        <>
            <div className="main-section">
                {/* <div className="sidebar-content">
                    <Sidebar />
                </div>
                <div className="body-content">
                    <Header />
                    <div className="table-body-content">
                        <div className="roles">
                            <div>
                                <div className="title">
                                    <h4>Industries</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate("/home")}>
                                        Home
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>Industry</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-groups">
                                <Dropdown className="header-dropdown action-btn">
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        data-bs-toggle="tooltip"
                                        title="Action"
                                    >
                                        <GrMoreVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item>Delete</Dropdown.Item>
                                        <Dropdown.Item>Active</Dropdown.Item>
                                        <Dropdown.Item>Inactive</Dropdown.Item>
                                        <Dropdown.Item>Undo</Dropdown.Item>
                                        {(spotFilter?.name !== "" ||
                                            spotFilter?.is_active !== "" ||
                                            spotFilter?.is_deleted !== "") && (
                                                <Dropdown.Item onClick={clearFilters}>
                                                    Clear Filter
                                                </Dropdown.Item>
                                            )}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Button
                                    data-bs-toggle="tooltip"
                                    title="Filter"
                                    onClick={handleButtonClick}
                                    className="btn btn-fillter-main"
                                    name={
                                        <div className="filter">
                                            <FaFilter className="filter-icon" />
                                        </div>
                                    }
                                ></Button>
                                {industryData &&
                                    industryData.items[0]?.data &&
                                    industryData.items[0].data.length > 0 && (
                                        <ExcelData
                                            excelData={industryData.items[0].data}
                                            fileName={"image"}
                                        ></ExcelData>
                                    )}
                                {((industryActionData &&
                                    industryActionData[0]?.add &&
                                    industryActionData[0].add === "1") ||
                                    !industryActionData) && (
                                        <Button
                                            data-bs-toggle="tooltip"
                                            title="Add Spot"
                                            onClick={() => navigate("/addindustry")}
                                            className="btn btn-fillter-main"
                                            name={
                                                <div className="Plus">
                                                    <FaPlus />
                                                </div>
                                            }
                                        ></Button>
                                    )}
                            </div>
                        </div>
                        {isToggled && <Filter page="spots"></Filter>}
                        {industryData && industryData.items[0]?.data && industryData.items[0].data && (
                            <>
                                <CustomTable
                                    page="industry"
                                    showToggle={showToggle}
                                    checkBool={false}
                                    showKey={showKey}
                                    showActions={showActions}
                                    showTooltip={true}
                                    data={tableData}
                                    heading={columns}
                                    disabled={
                                        spotActionData &&
                                        spotActionData[0]?.edit &&
                                        spotActionData[0].edit === "0"
                                    }
                                    currentPage={currentPageNumber}
                                    onPageChange={onPageChange}
                                    getBrand={getBrand}
                                />
                                {industryData.items[0].total_records >
                                    industryData.items[0].data.length &&
                                    industryData.items[0].total_records > 10 && (
                                        <CustomPagination
                                            totalRecords={industryData.items[0].total_records}
                                            currentPage={currentPageNumber}
                                            onPageChange={onPageChange}
                                        />
                                    )}
                            </>
                        )}
                    </div>
                    <Footer />
                </div> */}
            </div>
        </>
    )
}

export default Industry