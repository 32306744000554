/**
 * Set spot filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */

export const getSpotFilterData = ({
  name,
  p_is_active,
  p_is_deleted,
  p_is_available,
}) => ({
  type: "GET_SPOT_FILTER",
  payload: { name, p_is_active, p_is_deleted, p_is_available },
});

/**
 * Clear spot filtered data
 * @returns {*}
 */
export const clearSpotFilterData = () => ({
  type: "CLEAR_SPOT_FILTER",
});