export const addUpdateOrderRequest = (
  addedProducts,
  values,
  Data,
  deliveryCharge,
  discountAmt,
  discountID,
  appliedDiscountCode,
  message,
  updatedTotalAmt,
  taxAmount
) => {
  const requestProductData = addedProducts.map((item) => {
    const attributeData = item.selectedAttributes
      ? JSON.stringify(
          Object.keys(item.selectedAttributes).flatMap((cat_name) =>
            item.selectedAttributes[cat_name].map((attr) => ({
              id: attr.id,
              name: attr.name,
              value: attr.value,
              cat_id: attr.cat_id,
              cat_selection_type: attr.cat_selection_type,
              cat_name: cat_name,
            }))
          )
        )
      : null;

    return {
      map_table_id:
        Data && Data?.data && item.map_table_id > addedProducts.length
          ? item.map_table_id
          : 0,
      product_id: Number(item.productId),
      product_size: item.size,
      quantity: Number(item.quantity),
      product_price: Number(item.newPrice),
      purchase_price: item?.purchase_price ? item.purchase_price : 0,
      product_instruction:
        item.product_instruction && item.product_instruction.trim() !== ""
          ? item.product_instruction
          : null,
      food_type_one:
        item.food_type_one &&
        item.food_type_one.trim() !== "" &&
        item.food_type_one.toUpperCase() !== "NULL"
          ? item.food_type_one
          : "0",
      food_type_two:
        item.food_type_jain_regular &&
        item.food_type_jain_regular.trim() !== "" &&
        item.food_type_jain_regular.toUpperCase() !== "NULL"
          ? item.food_type_jain_regular
          : null,
      attribute_data: attributeData,
      status: item?.status ? item.status : "0",
    };
  });
  const requestBody = {
    ...(Data && Data.data && { p_id: Data.data.id }),
    p_mobile_no:
      values.mobile && values.mobile !== "" && Number(values.mobile) !== 8155006366
        ? Number(values.mobile)
        : 8155006366,
    p_first_name:
      values.mobile && values.mobile !== "" && Number(values.mobile) !== 8155006366
        ? values.firstName && values.firstName !== ""
          ? values.firstName
          : "Bespeakk"
        : "Bespeakk",
    p_last_name:
      values.mobile && values.mobile !== "" && Number(values.mobile) !== 8155006366
        ? values.firstName && values.firstName !== ""
          ? values.lastName
          : ""
        : "",
    p_table_no:
      values.tableNo !== "" && values.tableNo !== null
        ? Number(values.tableNo)
        : "",
    p_instruction: message ? message : "",
    p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    p_address_id:
      Data && Data?.data && Data.data?.address_id ? Data.data.address_id : null,
    p_delivery_charges: deliveryCharge,
    p_discount_id: discountID ? discountID.toString() : 0,
    p_discount_code:
      appliedDiscountCode && appliedDiscountCode !== ""
        ? appliedDiscountCode
        : "",
    p_discount_amount: discountAmt,
    p_total_amount: updatedTotalAmt,
    p_total_tax_amount: taxAmount,
    p_product_data: requestProductData,
    p_status:
      values.status === 1 || values.status === "1" || values.status === true
        ? "1"
        : "0",
    p_type: values.dineIn,
    p_payment: values.payment,
    p_zomato_swiggy_other_amount:values.zomatoSwiggyPrice && values.zomatoSwiggyPrice !== '' ?values.zomatoSwiggyPrice:null
  };
  return requestBody;
};
