// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  color: #f1f1f1;
  background-color: #1D3B42;
  width: 100%;
  padding: 7px 15px 10px;
  border-radius: 5px;
  border: unset;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 420px) {
  .btn {
    font-size: 14px;
  }
}
.btn:hover {
  color: #FFF;
  background-color: #FF4F2B;
  transition: 0.5s ease-in-out;
}
.btn:focus {
  box-shadow: unset;
}
.btn:active, .btn:focus-visible {
  color: #FFF !important;
  background-color: #1D3B42 !important;
  border-color: unset !important;
}

.clear-btn {
  color: #000;
}
.clear-btn:hover {
  color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACI,cCCM;EDAN,yBCJQ;EDKR,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAEI;EAbJ;IAcQ,eAAA;EACN;AACF;AAAI;EACI,WCjBK;EDkBL,yBCnBO;EDoBP,4BAAA;AAER;AAAI;EACI,iBAAA;AAER;AAAI;EACI,sBAAA;EACA,oCAAA;EACA,8BAAA;AAER;;AACA;EACI,WC9BS;ADgCb;AADI;EACI,WCjCK;ADoCb","sourcesContent":["@import '../../variables';\n\n.btn {\n    color: $offWhite;\n    background-color: $main-color;\n    width: 100%;\n    padding: 7px 15px 10px;\n    border-radius: 5px;\n    border: unset;\n    text-align: center;\n    cursor: pointer;\n    font-size: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    @media(max-width:420px){\n        font-size: 14px;\n    }\n    &:hover {\n        color: $white-color;\n        background-color: $primary-color;\n        transition: 0.5s ease-in-out;\n    }\n    &:focus{\n        box-shadow: unset;\n    }\n    &:active , &:focus-visible{\n        color: $white-color !important;\n        background-color: $main-color !important;\n        border-color:unset !important ;\n    }\n}\n.clear-btn{\n    color: $black-color;\n    &:hover{\n        color: $white-color;\n    }\n}\n","$main-color:#1D3B42;\n$primary-color:#FF4F2B;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$successColor:#28a745;\n$dangerColor:#dc3545;\n$border-color:#DEE2E6;\n$red-color: #ff0000;\n$star-rating: #e1c100;\n$disabled-bg:#e9ecef;\n$gray:#E6E6E6;\n$lightLIGHTGRAY:#aaa;\n$orange-color:#008dff;\n$completed:#22bb33;\n$Confirmed:#fcaa11;\n$In-progress:#5bc0de;\n$Pending:#deb887;\n$Cancelled:#bb2124;\n$bespeakk-color:#e25233;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
