import React, { useState } from 'react'
import Button from '../../components/Button/Button';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import Toggle from '../../components/Toggle/Toggle';
import { useSelector } from 'react-redux';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { GrMoreVertical } from 'react-icons/gr';
import { Dropdown } from 'react-bootstrap';
import Filter from '../../components/Filter/Filter';

const columns = [
    {
        name: 'Policy ID',
        selector: row => row.id,
    },
    {
        name: 'Policy Name',
        selector: row => row.name,
    }
    ,
    {
        name: 'Policy Type',
        selector: row => row.type,
    },
    {
        name: 'Creation Date',
        selector: row => row.date,
    }
    ,
    {
        name: 'Status',
        selector: row => row.status,
        cell: (row) => <Toggle checked={row.toggle} onChange={() => handleToggleChange(row.id)} />,
    }
    ,
    {
        name: 'Actions',
        selector: row => row.actions,
    }
];

//Show actions which is to be displayed in table
const showActions = {
    showView: true,
    showEdit: true,
    showDelete: true,
    navigate: '/edit-policy'
}

//Show keys which is to be displayed in table
const showKey = {
    name: true,
    date: true,
    type: true
}

//Show toggle which is to be displayed in table
const showToggle = {
    status: true,
    delete: false
}

//Handle Toggle change
const handleToggleChange = (id) => {
};
export default function Policies() {
    const navigate = useNavigate();

    //Get data from reducer
    const policyData = useSelector((state) => state.policy);
    const [isToggled, setIsToggled] = useState(false);

    const handleButtonClick = () => {
        setIsToggled((prevIsToggled) => !prevIsToggled);
    };
    return (
        <>
            <div className='main-section'>
                <div className='sidebar-content'>
                    <Sidebar />
                </div>
                <div className='body-content'>
                    <Header />
                    <div className='table-body-content'>
                        <div className='roles'>
                            <div>
                                <div className='title'>
                                    <h4>Policies List</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Policies List</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className='btn-groups'>
                                <Dropdown className='header-dropdown action-btn' >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                                        <GrMoreVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >Delete</Dropdown.Item>
                                        <Dropdown.Item >Active</Dropdown.Item>
                                        <Dropdown.Item >Inactive</Dropdown.Item>
                                        <Dropdown.Item >Undo</Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button data-bs-toggle='tooltip' title='Filter' onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                                    <FaFilter className="filter-icon" />
                                </div>}></Button>

                                <Button data-bs-toggle='tooltip' title='Add Policy' onClick={() => navigate('/add-policy')} className="btn btn-fillter-main" name={<div className="Plus">
                                    <FaPlus />
                                </div>}></Button>
                            </div>
                        </div>
                        {isToggled &&
                            <Filter page='policy'></Filter>}
                        <CustomTable showToggle={showToggle} showKey={showKey} checkBool={false} page='Policies' showActions={showActions} showTooltip={true} showId={true} data={policyData} heading={columns} />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
