import { FloatingLabel, Form } from "react-bootstrap";
import "./Input.scss";
import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage } from "formik";
import ErrorComp from "../Error/ErrorComp";
import { MdOutlineCancel } from "react-icons/md";

export default function FileInput(props) {
  //Selected image
  const [selectedImage, setSelectedImages] = useState([]);
  // const [imageChange, setImageChange] = useState(true);

  const [hideInput, setHideInput] = useState(false);

  let {
    field,
    form,
    label,
    placeholder,
    id,
    isRequired,
    maxCount,
    fileValue,
    ...rest
  } = props;

  /**
   * Handle image selection
   * @param {*} event
   * @param {*} field
   * @param {*} form
   * @param {*} drop
   * @returns {*}
   */
  const handleFileChange = (event, field, form, drop) => {
    let files;
    if (drop && drop === true) {
      files = Array.from(event);
    } else {
      files = Array.from(event.target.files);
    }
    if (maxCount && maxCount > 1) {
      if (files.length + selectedImage.length > maxCount) {
        alert(`You can select up to ${maxCount} images.`);
        return;
      }
      setSelectedImages([...selectedImage, ...files]);
      form.setFieldValue(field.name, [...selectedImage, ...files]);
    } else {
      setSelectedImages(files);
      form.setFieldValue(field.name, files);
    }
  };

  /**
   * Handling drag and drop
   * @param {*} e
   * @param {*} field
   * @param {*} form
   */
  const handleDrop = (e, field, form) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files, field, form, true);
  };

  /**
   * Prevent default
   * @param {*} e
   */
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * Delete image function
   * @param {*} image
   */
  const onDeleteImage = (image) => {
    const updatedSelectedImages = selectedImage.filter(
      (selectedImg) => selectedImg !== image
    );
    if (selectedImage.length > 1) {
      setSelectedImages(updatedSelectedImages);
      form.setFieldValue(field.name, updatedSelectedImages);
    } else {
      setSelectedImages([]);
      form.setFieldValue(field.name, []);
    }
  };

  const fileInputRef = useRef(null);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  /**
   * Making input dissapear if selected image count is equal to max count
   */
  useEffect(() => {
    // Sync selectedImage with fileValue
    if (fileValue && fileValue.length > 0 && !selectedImage.length) {
      setSelectedImages(fileValue);
    }

    // Clear selectedImage if fileValue is cleared
    if (fileValue && fileValue.length === 0 && selectedImage.length > 0) {
      setSelectedImages([]);
      resetFileInput(); // Reset file input
    }

    // Hide input if maxCount is reached
    if (maxCount && maxCount > 1) {
      setHideInput(selectedImage.length >= maxCount);
    } else {
      setHideInput(false);
    }
  }, [fileValue, selectedImage, maxCount]);

  return (
    <>
      <FloatingLabel
        label={
          <span className={isRequired ? "required-label" : ""}>{label}</span>
        }
        className="mb-2 show-and-hide-btn"
      >
        <div className="input-container">
          {!hideInput && (
            <>
              <Form.Control
                ref={fileInputRef} // Add the ref
                type="file"
                placeholder={placeholder}
                {...rest}
                onChange={(e) => handleFileChange(e, field, form)}
                onDrop={(e) => handleDrop(e, field, form)}
                onDragOver={(e) => handleDragOver(e)}
              />
              {selectedImage.length <= 0 && (
                <span className="drag-n-drop-text">
                  Drop files here, or select files
                </span>
              )}
            </>
          )}
          {maxCount > 1 && selectedImage.length > 0 && (
            <span className="file-input-text">
              {selectedImage && selectedImage.length > 1 ? (
                <>{selectedImage.length} images selected</>
              ) : (
                <>{selectedImage.length} image selected </>
              )}
            </span>
          )}
          <div className="d-flex">
            {maxCount > 1 &&
              selectedImage &&
              selectedImage.map((image, index) => (
                <div className="image-main" key={index}>
                  <div className="all-image-perview">
                    <img
                      key={index}
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index}`}
                      className="image-preview"
                    />
                    <MdOutlineCancel
                      className="cancel-btn-icon"
                      onClick={() => onDeleteImage(image)}
                    />
                  </div>
                </div>
              ))}
          </div>
          {(maxCount === undefined || maxCount === null || maxCount <= 1) &&
            selectedImage &&
            selectedImage.length > 0 && (
              <>
                <div className="image-main">
                  <div className="all-image-perview">
                    <img
                      src={URL.createObjectURL(selectedImage[0])}
                      alt="Preview"
                      className="image-preview"
                    />
                    <MdOutlineCancel
                      className="cancel-btn-icon"
                      onClick={() => onDeleteImage(selectedImage)}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      </FloatingLabel>
      <ErrorMessage name={field.name} component={ErrorComp}></ErrorMessage>
    </>
  );
}
