/**
 * Setting Wallet list data into reducer
 * @param {*} wallet
 * @returns {*}
 */
export const getTotalWalletAmt = (wallet) => ({
    type: "GET_TOTAL_WALLET_AMT",
    payload: wallet,
  });
  
  /**
   * clearing Wallet list data
   * @returns {*}
   */
  export const clearTotalWalletAmt = () => ({
    type: "CLEAR_TOTAL_WALLET_DATA",
  });
  

  