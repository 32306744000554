/**
 * Setting Vendor list data into reducer
 * @param {*} vendor 
 * @returns {*}
 */
export const getVendorData = (vendor) => ({
    type: 'GET_VENDOR_DATA',
    payload: vendor
})

/**
 * clearing Vendor list data
 * @returns {*}
 */
export const clearVendorData = () => ({
    type: 'CLEAR_VENDOR_DATA'
})

/**
 * Setting total Vendor list data into reducer (No pagnation)
 * @param {*} vendor 
 * @returns {*}
 */
export const getTotalVendorData = (vendor) => ({
    type: 'GET_TOTAL_VENDOR',
    payload: vendor
})

/**
 * Clearing total Vendor list data (No pagination)
 * @returns {*}
 */
export const clearTotalVendorData = () => ({
    type: 'CLEAR_TOTAL_VENDOR'
})