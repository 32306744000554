/**
 * Setting units list data into reducer
 * @param {*} Units 
 * @returns {*}
 */
export const getUnitsData = (units) => ({
    type: 'GET_UNITS_DATA',
    payload: units
})

/**
 * clearing units list data
 * @returns {*}
 */
export const clearUnitsData = () => ({
    type: 'CLEAR_UNITS_DATA'
})

/**
 * Setting total units list data into reducer (No pagnation)
 * @param {*} units 
 * @returns {*}
 */
export const getTotalUnitsData = (units) => ({
    type: 'GET_TOTAL_UNITS',
    payload: units
})

/**
 * Clearing total units list data (No pagination)
 * @returns {*}
 */
export const clearTotalUnitsData = () => ({
    type: 'CLEAR_TOTAL_UNITS'
})