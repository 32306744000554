import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown, Offcanvas } from "react-bootstrap";
import "../Vendors/Vendors.scss";
import Toggle from "../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import { getAllVendorService } from "./VendorsService.js/VendorService";
import {
  clearVendorPageData,
  getVendorPageData,
} from "../../redux/Actions/VendorAction/VendorPageAction";
import { clearVendorFilterData } from "../../redux/Actions/VendorAction/VendorFilterAction";
import { clearVendorData } from "../../redux/Actions/VendorAction/VendorAction";
import { format } from "date-fns";
import "../Vendors/Vendors.scss";

const Vendors = () => {
  const vendorData = useSelector((state) => state.vendor);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const vendorPage = useSelector((state) => state.vendorPage?.items);
  const vendorFilter = useSelector((state) => state.vendorFilter);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [vendorActionData, setVendorActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setVendorActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    phNum: true,
    orderNum: true,
    totalAmt: true,
    payment: true,
    orderStatus: true,
    // quantity: true,
    // unit: true,
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Phone number",
      selector: (row) => row.phNum,
    },
    {
      name: "Order number",
      selector: (row) => row.orderNum,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmt,
    },
    {
      name: "Payment",
      selector: (row) => row.payment,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ];
  const showActions = {
    showEdit: true,
    // showDelete: true,
    showView: true,
    showWp: true,
    showPrint: true,
    // showUndo: true,
    navigate: "/addvendors",
  };

  useEffect(() => {
    setCurrentPage(vendorPage);
  }, [vendorPage]);

  /**
   * Fetch inventory item data
   */
  const fetchVendor = () => {
    const requestBody = {
      p_skip: vendorPage > 1 ? (vendorPage - 2) * 10 : (vendorPage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (vendorFilter.orderNo !== "" && vendorFilter.orderNo !== null) {
      requestBody.p_order_no = vendorFilter.orderNo;
    }
    if (
      vendorFilter.orderStartDate !== "" &&
      vendorFilter.orderStartDate !== null
    ) {
      requestBody.p_created_date = vendorFilter.orderStartDate;
    }
    if (
      vendorFilter.orderEndDate !== "" &&
      vendorFilter.orderEndDate !== null
    ) {
      requestBody.p_end_date = vendorFilter.orderEndDate;
    }
    if (vendorFilter.vendor !== "" && vendorFilter.vendor !== null) {
      requestBody.p_vendor_id = vendorFilter.vendor;
    }
    if (vendorFilter.status !== "" && vendorFilter.status !== null) {
      requestBody.p_status = vendorFilter.status;
    }
    if (vendorFilter.payment !== "" && vendorFilter.payment !== null) {
      requestBody.p_payment = vendorFilter.payment;
    }
    // return;
    getAllVendorService(requestBody, tokenData, dispatch);
    if (vendorPage > 1) {
      setCurrentPage(vendorPage - 1); // Update the current page state
      dispatch(getVendorPageData(vendorPage - 1));
    }
  };

  useEffect(() => {
    if (
      (vendorData && vendorData.items && vendorData.items.length === 0) ||
      !vendorData
    ) {
      fetchVendor();
    } else {
      if (
        vendorFilter?.orderNo !== "" ||
        (vendorFilter?.orderStartDate &&
          vendorFilter?.orderStartDate !== format(new Date(), "yyyy-MM-dd")) ||
        vendorFilter?.orderEndDate !== "" ||
        (vendorFilter?.vendor && vendorFilter.vendor !== "") ||
        vendorFilter?.status !== "" ||
        vendorFilter?.payment !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (vendorData.items[0]?.data && vendorData.items[0].data.length > 0) {
      const transformedVendorData = vendorData.items[0].data.map((item) => {
        return {
          id: item.id,
          name: item.first_name + " " + item.last_name,
          phNum: item.phone_number,
          orderNum: item.order_number,
          totalAmt: "₹" + String(item.total_amount),
          payment: item.payment,
          orderDate: item.order_date,
          orderStatus: item.order_status,
          instructions: item.instructions,
          ingredients: item.ingredients,
          timeZone: item.timeZone,
          vendorUserId: item.vendor_user_id,
          modifiedAt: item.modified_at,
          vendor_user_id: item.vendor_user_id,
        };
      });
      setTableData(transformedVendorData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [vendorData, vendorFilter]);

  const styleData = {
    phNum: { type: { textAlign: "end" } },
    orderNum:{type: {minWidth:"240px"}},
    "Order number":{type: {minWidth:"240px"}}
  };

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);
  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (vendorFilter.orderNo !== "" && vendorFilter.orderNo !== null) {
        requestBody.p_order_no = vendorFilter.orderNo;
      }
      if (
        vendorFilter.orderStartDate !== "" &&
        vendorFilter.orderStartDate !== null
      ) {
        const date = new Date(vendorFilter.orderStartDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }
      if (
        vendorFilter.orderEndDate !== "" &&
        vendorFilter.orderEndDate !== null
      ) {
        const date = new Date(vendorFilter.orderEndDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const dateTimeFormat = `${year}-${month}-${day}`;
        requestBody.p_end_date = dateTimeFormat;
      }
      if (vendorFilter.vendor !== "" && vendorFilter.vendor !== null) {
        requestBody.p_vendor_id = vendorFilter.vendor;
      }
      if (vendorFilter.status !== "" && vendorFilter.status !== null) {
        requestBody.p_status = vendorFilter.status;
      }
      if (vendorFilter.payment !== "" && vendorFilter.payment !== null) {
        requestBody.p_payment = vendorFilter.payment;
      }
      //   return;
      getAllVendorService(requestBody, tokenData, dispatch);
      dispatch(getVendorPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearVendorFilterData());
    dispatch(clearVendorPageData());
    dispatch(clearVendorData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="order-new">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div> */}
        {/* <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {vendorActionData && vendorActionData.length > 0
                      ? vendorActionData[0].name
                      : "Vendor Orders"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {vendorActionData && vendorActionData.length > 0
                      ? vendorActionData[0].name
                      : "Vendor Orders"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {vendorFilter?.orderNo !== "" ||
                (vendorFilter?.orderStartDate !== "" &&
                  vendorFilter?.orderStartDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                (vendorFilter?.orderEndDate !== "" &&
                  vendorFilter?.orderEndDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                vendorFilter?.vendor !== "" ||
                vendorFilter?.status !== "" ||
                vendorFilter?.payment !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}

<Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="vendors" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                {((vendorActionData &&
                  vendorActionData[0]?.add &&
                  vendorActionData[0].add === "1") ||
                  !vendorActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Vendor"
                    onClick={() => navigate("/addvendors")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {/* {isToggled && <Filter page="vendors"></Filter>} */}
            {vendorData &&
              vendorData.items[0]?.data &&
              vendorData.items[0].data && (
                <>
                  <div className="purchase-amount">
                    Total Purchase Amount : ₹{vendorData.items[0]?.total_purchase_amount ? vendorData.items[0]?.total_purchase_amount : 0}
                  </div>
                  <CustomTable
                    page="vendors"
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActions}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    disabled={
                      vendorActionData &&
                      vendorActionData[0]?.edit &&
                      vendorActionData[0].edit === "0"
                    }
                    currentPage={currentPageNumber}
                    styleData={styleData}
                    onPageChange={onPageChange}
                  />

                  {vendorData.items[0].total_records >
                    vendorData.items[0].data.length &&
                    vendorData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={vendorData.items[0].total_records}
                        currentPage={currentPageNumber}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
};

export default Vendors;
