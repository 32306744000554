/**
 * Set cltegory list data into reducer
 * @param {*} CUSTOMERORDER
 * @returns {*}
 */
export const getCustomerOrderData = (CUSTOMERORDER) => ({
  type: "GET_CUSTOMER_ORDERS_DATA",
  payload: CUSTOMERORDER,
});

/**
 * Clear CUSTOMER ORDER list data
 * @returns {*}
 */
export const clearCustomerOrderData = () => ({
  type: "CLEAR_CUSTOMER_ORDERS_DATA",
});

/**
 * Set total CUSTOMER ORDER list data into reducer (No pagination)
 * @param {*} CUSTOMERORDER
 * @returns {*}
 */
export const getTotalCustomerOrderData = (CUSTOMERORDER) => ({
  type: "GET_TOTAL_CUSTOMER_ORDERS",
  payload: CUSTOMERORDER,
});

/**
 * Clear total CUSTOMER ORDER list data (No pagination)
 * @returns {*}
 */
export const clearTotalCustomerOrder = () => ({
  type: "CLEAR_TOTAL_CUSTOMER_ORDERS",
});
