import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServiceProviderService,
  uploadServiceProviderExcelService,
} from "./ServiceProviderService/ServiceProviderService";
import UtilsData from "../../Utils/Utils";
import ExcelData from "../../services/Regular-services/ExcelExport";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearServiceProviderPageData,
  getServiceProviderPageData,
} from "../../redux/Actions/ServiceProviderAction/ServiceProviderPageAction";
import { clearServiceProviderFilterData } from "../../redux/Actions/ServiceProviderAction/ServiceProviderFilterAction";
import {
  clearServiceProviderData,
  clearTotalServiceProviderData,
} from "../../redux/Actions/ServiceProviderAction/ServiceProviderAction";
import { TfiImport } from "react-icons/tfi";
import DownloadExcel from "../../services/Regular-services/DownloadExcelService";
import FileUploadModal from "../../components/Import-component/ImportComponent";
import sampleServiceProviderExcelFile from "../../assets/files/Sub-Category-Import.xlsx";

const showToggle = {
  status: true,
};

/**
 * Show keys which are to be displayed in table
 */
const showKey = {
  name: true,
};
export default function ServiceProvider() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const ServiceProviderPage = useSelector(
    (state) => state.ServiceProviderPage?.items
  );
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [serviceProviderActionData, setserviceProviderActionData] = useState(
    []
  );
  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "service-provider"
      );
      setserviceProviderActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      serviceProviderActionData[0]?.view &&
      serviceProviderActionData[0].view === "0" &&
      serviceProviderActionData[0]?.edit &&
      serviceProviderActionData[0].edit === "0" &&
      serviceProviderActionData[0]?.add &&
      serviceProviderActionData[0].add === "0" &&
      serviceProviderActionData[0]?.delete &&
      serviceProviderActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name ",
          selector: (row) => row.Name,
        },
        {
          name: "Status ",
          selector: (row) => row.status,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name ",
          selector: (row) => row.Name,
        },
        {
          name: "Status ",
          selector: (row) => row.status,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [serviceProviderActionData]);

  /**
   * Show actions which are to be displayed in table
   */
  const showActions = {
    showView:
      (serviceProviderActionData[0]?.view &&
        serviceProviderActionData[0].view === "1") ||
      !serviceProviderActionData
        ? true
        : false,
    showEdit:
      (serviceProviderActionData[0]?.edit &&
        serviceProviderActionData[0].edit === "1") ||
      !serviceProviderActionData
        ? true
        : false,
    showDelete:
      (serviceProviderActionData[0]?.delete &&
        serviceProviderActionData[0].delete === "1") ||
      !serviceProviderActionData
        ? true
        : false,
    showUndo:
      (serviceProviderActionData[0]?.edit &&
        serviceProviderActionData[0].edit === "1") ||
      !serviceProviderActionData
        ? true
        : false,
    navigate:
      (serviceProviderActionData[0]?.edit &&
        serviceProviderActionData[0].edit === "1") ||
      !serviceProviderActionData
        ? "/add-service-provider"
        : false,
  };

  useEffect(() => {
    if (ServiceProviderPage) {
      setCurrentPage(ServiceProviderPage);
    }
  }, [ServiceProviderPage]);

  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const serviceProvider = useSelector((state) => state.serviceProvider);
  const ServiceProviderFilter = useSelector(
    (state) => state.ServiceProviderFilter
  );

  /**
   * Fetch service provider list
   */
  const fetchServiceProvider = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (ServiceProviderPage > 1) {
      requestBody.p_skip = (ServiceProviderPage - 2) * 10;
      dispatch(getServiceProviderPageData(ServiceProviderPage - 1));
    } else {
      requestBody.p_skip = (ServiceProviderPage - 1) * 10;
      dispatch(getServiceProviderPageData(ServiceProviderPage));
    }
    if (
      ServiceProviderFilter.name !== "" &&
      ServiceProviderFilter.name !== null
    ) {
      requestBody.p_name = ServiceProviderFilter.name;
    }
    if (
      ServiceProviderFilter.isActive !== "" &&
      serviceProvider.isActive !== null
    ) {
      requestBody.p_is_active = ServiceProviderFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (
      ServiceProviderFilter.isDelete !== "" &&
      ServiceProviderFilter.isDelete !== null
    ) {
      requestBody.p_IsDeleted = ServiceProviderFilter.isDelete;
    }
    getAllServiceProviderService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (
      serviceProvider &&
      serviceProvider.items &&
      serviceProvider.items.length === 0
    ) {
      fetchServiceProvider();
    } else {
      if (
        ServiceProviderFilter.name !== "" ||
        ServiceProviderFilter.isActive !== "" ||
        ServiceProviderFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [serviceProvider, ServiceProviderFilter]);

  /**
   * Functions for changing page
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (
        ServiceProviderFilter.name !== "" &&
        ServiceProviderFilter.name !== null
      ) {
        requestBody.p_name = ServiceProviderFilter.name;
      }
      if (
        ServiceProviderFilter.isActive !== "" &&
        serviceProvider.isActive !== null
      ) {
        requestBody.p_is_active = ServiceProviderFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (
        ServiceProviderFilter.isDelete !== "" &&
        ServiceProviderFilter.isDelete !== null
      ) {
        requestBody.p_IsDeleted = ServiceProviderFilter.isDelete;
      }
      getAllServiceProviderService(requestBody, tokenData, dispatch);
      dispatch(getServiceProviderPageData(pageNumber));
      setCurrentPage(pageNumber);
    }
  };

  const [isToggled, setIsToggled] = useState(false);

  /**
   * Handling Toggle => Changing from true to false and vice versa
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImportClick = () => {
    // Open the modal
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(
      uploadServiceProviderExcelService(file, tokenData, setIsModalOpen)
    );
  };

  /**
   * Function for clearing filters
   */
  const clearFilters = () => {
    dispatch(clearServiceProviderFilterData());
    dispatch(clearServiceProviderData());
    dispatch(clearServiceProviderPageData());
    dispatch(clearTotalServiceProviderData());
    setIsToggled(false);
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Service Provider</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Service Provider</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                <Dropdown className="header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>Delete</Dropdown.Item>
                    <Dropdown.Item>Active</Dropdown.Item>
                    <Dropdown.Item>Inactive</Dropdown.Item>
                    <Dropdown.Item>Undo</Dropdown.Item>
                    {(ServiceProviderFilter.name !== "" ||
                      ServiceProviderFilter.isActive !== "" ||
                      ServiceProviderFilter.isDelete !== "") && (
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filters
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                {/* {((serviceProviderActionData[0]?.add && serviceProviderActionData[0].add === '1') || (!serviceProviderActionData)) && (
                                    <Dropdown className='header-dropdown action-btn' >
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                                            <TfiImport />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleImportClick}>Import</Dropdown.Item>
                                            <DownloadExcel excelFile={sampleServiceProviderExcelFile} fileName="Service-Provider-Import.xlsx" />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )} */}

                <FileUploadModal
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onFileUpload={handleFileUpload}
                  isClose={isModalOpen}
                />

                <Button
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>

                {serviceProvider &&
                  serviceProvider.items &&
                  serviceProvider.items[0]?.data &&
                  serviceProvider.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={serviceProvider.items[0].data}
                      fileName={"service_provider"}
                    ></ExcelData>
                  )}
                {((serviceProviderActionData[0]?.add &&
                  serviceProviderActionData[0].add === "1") ||
                  !serviceProviderActionData) && (
                  <Button
                    onClick={() => navigate("/add-service-provider")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {isToggled && <Filter page="serviceProvider"></Filter>}
            {serviceProvider &&
              serviceProvider.items &&
              serviceProvider.items[0]?.data &&
              serviceProvider.items[0].data && (
                <>
                  <CustomTable
                    page="serviceProvider"
                    showToggle={showToggle}
                    showDescription={true}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    checkBool={false}
                    data={serviceProvider.items[0].data}
                    heading={columns}
                    disabled={
                      serviceProviderActionData &&
                      serviceProviderActionData[0]?.edit &&
                      serviceProviderActionData[0].edit === "0"
                    }
                  />
                  {serviceProvider.items[0].total_records >
                    serviceProvider.items[0].data.length &&
                    serviceProvider.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={serviceProvider.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}