import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button/Button';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import { FaFilter } from 'react-icons/fa';
import Filter from '../../components/Filter/Filter';
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from 'react-bootstrap';
import ExcelData from '../../services/Regular-services/ExcelExport';

const columns = [
    {
        name: 'Cart ID',
        selector: row => row.id,
      },
    {
      name: 'Customer Name',
      selector: row => row.Name,
    },
    {
        name: 'Customer Email',
        selector: row => row.email,
    },
    {
        name: 'Number of Items',
        selector: row => row.number,
    },
    {
        name: 'Subtotal',
        selector: row => row.total,
    },
    {
        name: 'Total Amount',
        selector: row => row.amount,
    },
    {
        name: 'Last Updated',
        selector: row => row.updated,
    },
    {
        name: 'Conversion Status',
        selector: row => row.Status,
    },
    {
      name: 'Action',
      selector: row => row.action,
    }
  ];
  
  const data = [
    {
      id: 1,
      name: 'New string',
      email: 'demo@gmail.com',
      number: '777 400 5564',
      total: '₹450',
      amount: '₹450',
      updated:  'Pending',  
      Status:'1',
    },
    {
        id: 2,
        name: 'New string',
        email: 'demo@gmail.com',
        number: '777 400 5564',
        total: '₹450',
        amount: '₹450',
        updated:  'Pending',  
        Status:'1',
      },
      {
        id: 3,
        name: 'New string',
        email: 'demo@gmail.com',
        number: '777 400 5564',
        total: '₹450',
        amount: '₹450',
        updated:  'Pending',  
        Status:'1',
      },
    
  
  ]
  
  //Show actions which is to be displayed in table
  const showActions = {
    showView: true,
    showEdit: false,
    showDelete: true,
    navigate: '/cart-detail'
  }
  
  //Show keys which is to be displayed in table
  const showKey = {
    id: true,
    name: true,
    email: true,
    number: true,
    total: true,
    amount:true,
    updated:true,
    Status:true,
  }
export default function Carts() {
    const tooltipRef = useRef(null);
    useEffect(() => {
      if (tooltipRef.current) {
        const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
        return () => {
          tooltip.dispose();
        };
      }
    }, []);
  
    const navigate = useNavigate();
    const [isToggled, setIsToggled] = useState(false);
  
    const handleButtonClick = () => {
      setIsToggled((prevIsToggled) => !prevIsToggled);
    };
  return (
    <>
        <div className='main-section'>
        <div className='sidebar-content'>
          <Sidebar />
        </div>
        <div className='body-content'>
          <Header />
          <div className='table-body-content'>
            <div className='roles'>
              <div>
                <div className='title'>
                  <h4>Carts</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Cart</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='btn-groups'>

                <Dropdown className='header-dropdown action-btn' >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >Delete</Dropdown.Item>
                    <Dropdown.Item >Active</Dropdown.Item>
                    <Dropdown.Item >Inactive</Dropdown.Item>
                    <Dropdown.Item >Undo</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>

                <Button data-bs-toggle='tooltip' title='Filter' onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                  <FaFilter className="filter-icon" />
                </div>}></Button>
                {data && data.length > 0 && (
                  <ExcelData excelData={data} fileName={'image'}></ExcelData>
                )}
              </div>
            </div>
            {isToggled &&
              <Filter page='tags'></Filter>}
            <CustomTable showId={true} checkBool={false} showKey={showKey} showActions={showActions} showTooltip={true} data={data} heading={columns} />
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
