import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "../Filter/Filter.scss";
import Input from "../input/Input";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomSelect from "../CustomSelect/CustomSelect";
import ErrorComp from "../Error/ErrorComp";
import { useDispatch, useSelector } from "react-redux";
import UtilsData, { imageTypeOptions } from "../../Utils/Utils";
import { getRolesService } from "../../pages/Roles/Role-Service/roleService.tsx";
import { clearRolePageData } from "../../redux/Actions/rolesAction/RolesPageAction.js";
import { clearAdminPageData } from "../../redux/Actions/adminAction/AdminPageAction.js";
import { getUserListService } from "../../pages/AdminUsers/User-service/UserService.tsx";
import { getRoleFilterData } from "../../redux/Actions/rolesAction/RolesFilterAction";
import { getAdminFilterData } from "../../redux/Actions/adminAction/AdminFilterAction";
import { getCategoryFilterData } from "../../redux/Actions/categoryAction/CategoryFilterAction";
import { getAllCategoryService } from "../../pages/Category/Category-service/CategoryService.tsx";
import { getSubCategoryFilterData } from "../../redux/Actions/subCategoryAction/SubCategoryFilterAction";
import { getAllSubCategoryService } from "../../pages/SubCategory/SubCategoryService/SubCategoryService.tsx";
import { getAllLeafLevelCategoryService } from "../../pages/LeafLevelCategory/LeafLevelService/LeafLevelService";
import { getLeafFilterData } from "../../redux/Actions/leafLevelCategoryAction/LeafFilterAction";
import { clearCategoryPageData } from "../../redux/Actions/categoryAction/CategoryPageAction";
import { clearSubCategoryPageData } from "../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { clearLeafPageData } from "../../redux/Actions/leafLevelCategoryAction/LeafPageAction";
import { getAllTagsService } from "../../pages/Tag/TagService/TagService";
import { getTagFilterData } from "../../redux/Actions/tagAction/TagFilterAction";
import { clearTagPageData } from "../../redux/Actions/tagAction/TagPageAction";
import { clearImagePageData } from "../../redux/Actions/imageGalleryAction/ImageGalleryPageAction";
import { getAllImageService } from "../../pages/Imagemanager/ImageService/ImageService";
import { getImageFilterData } from "../../redux/Actions/imageGalleryAction/ImageFilterAction";
import { getBrandFilterData } from "../../redux/Actions/brandAction/BrandFilterAction";
import { getAllBrandService } from "../../pages/BrandList/BrandService/BrandService";
import { clearBrandPageData } from "../../redux/Actions/brandAction/BrandPageAction";
import { getAllProductService } from "../../pages/Product/ProductService/ProductService";
import { getProductFilterData } from "../../redux/Actions/productAction/ProductFilterAction";
import { getAllAttributeService } from "../../pages/Attributes/AttributeService/AttributeService";
import { getAttributeFilterData } from "../../redux/Actions/AttributeAction/AttributeFilterAction";
import { clearAttributePageData } from "../../redux/Actions/AttributeAction/AttributePageAction";
import { getWarehouseFilterData } from "../../redux/Actions/WarehouseAction/WarehouseFilterAction";
import { getAllWarehouseService } from "../../pages/Warehouse/WarehouseService/WarehouseService";
import { clearWarehousePageData } from "../../redux/Actions/WarehouseAction/WarehousePageAction";
import { toast } from "react-toastify";
import { clearPackagerPageData } from "../../redux/Actions/PackagerAction/PackagerPageAction";
import { getPackagerFilterData } from "../../redux/Actions/PackagerAction/PackagerFilterAction";
import { getAllPackagerService } from "../../pages/Packager/PackagerService/PackagerService";
import { format } from "date-fns";
import { getAllDiscountService } from "../../pages/Discount/DiscountService/DiscountService";
import { getDiscountFilterData } from "../../redux/Actions/DiscountAction/DiscountFilterAction";
import { clearDiscountPageData } from "../../redux/Actions/DiscountAction/DiscountPageAction";
import { clearProductPageData } from "../../redux/Actions/productAction/ProductPageAction";
import { getAllDiscountCatalogueService } from "../../pages/DiscountCatalogue/DiscountCatalogueService/DiscountCatalogueService";
import { clearDiscountCataloguePageData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCataloguePageAction";
import { getDiscountCatalogueFilterData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCatalogueFilterAction";
import { clearInventoryPageData } from "../../redux/Actions/InventoryAction/InventoryPageAction";
import { getInventoryFilterData } from "../../redux/Actions/InventoryAction/InventoryFilterAction";
import { getAllInventoryService } from "../../pages/Inventory/InventoryService/InventoryService";
import { clearBranchPageData } from "../../redux/Actions/BranchAction/BranchPageAction";
import { getBranchFilterData } from "../../redux/Actions/BranchAction/BranchFilterReducer";
import { getAllBranchService } from "../../pages/Branches/BranchService/BranchService";
import { getAllServiceProviderService } from "../../pages/ServiceProvider/ServiceProviderService/ServiceProviderService";
import { getServiceProviderFilterData } from "../../redux/Actions/ServiceProviderAction/ServiceProviderFilterAction";
import { clearServiceProviderPageData } from "../../redux/Actions/ServiceProviderAction/ServiceProviderPageAction";
import { clearServicePageData } from "../../redux/Actions/ServiceAction/ServicePageAction";
import { getAllService } from "../../pages/Service/ServiceService/ServiceService";
import { getServiceFilterData } from "../../redux/Actions/ServiceAction/ServiceFilterAction";
import { getOrderFilterData } from "../../redux/Actions/orderAction/OrderFilterAction";
import { clearOrderPageData } from "../../redux/Actions/orderAction/OrderPAgeAction";
import { getAllOrderService } from "../../pages/Order/OrderService/OrderService";
import { clearCustomerPageData } from "../../redux/Actions/CustomerAction/CustomerPageAction";
import { getCustomerFilterData } from "../../redux/Actions/CustomerAction/CustomerFilterAction";
import { getCustomerListService } from "../../pages/Customer/CustomerService/CustomerService";
import { getCompanyService } from "../../pages/Company/Company-service/companyService";
import { clearCompanyData } from "../../redux/Actions/companyAction/CompanyAction";
import { getCompanyFilterData } from "../../redux/Actions/companyAction/CompanyFilterAction";
import { clearCompanyPageData } from "../../redux/Actions/companyAction/CompanyPageAction";
import { clearSpotPageData } from "../../redux/Actions/spotAction/SpotPageAction";
import { getSpotFilterData } from "../../redux/Actions/spotAction/SpotFilterAction";
import { getAllSpotService } from "../../pages/Spot/SpotService/SpotService";
import { getAllInventoryItemService } from "../../pages/InventoryItems/InventoryItemService/InventoryItemService";
import { getInventoryItemFilterData } from "../../redux/Actions/InventoryItemAction/InventoryItemFilterAction";
import { clearInventoryItemPageData } from "../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import { clearProductInventoryPageData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { getProductInventoryFilterData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryFilterAction";
import { getAllProductInventoryService } from "../../pages/ProductInventory/ProductInventoryService/ProductInventoryService";
import { clearVendorPageData } from "../../redux/Actions/VendorAction/VendorPageAction";
import { getVendorFilterData } from "../../redux/Actions/VendorAction/VendorFilterAction";
import { getAllVendorService } from "../../pages/Vendors/VendorsService.js/VendorService";
import { clearStockPageData } from "../../redux/Actions/StocksAction/StockPageAction";
import { getStockFilterData } from "../../redux/Actions/StocksAction/StockFilterAction";
import { getAllStocksService } from "../../pages/Stocks/StocksService/StocksService";
import { clearStockHistoryPageData } from "../../redux/Actions/StockHistoryAction/StockHistoryPageAction";
import { getStockHistoryFilterData } from "../../redux/Actions/StockHistoryAction/StockHistoryFilterAction";
import { getAllStockHistoryService } from "../../pages/Stocks/StockHistoryPage.js/StockHistoryServices/StockHistoryService";
import { clearNewVendorPageData } from "../../redux/Actions/NewVendorAction/NewVendorPageAction";
import { getNewVendorFilterData } from "../../redux/Actions/NewVendorAction/NewVendorFilterAction";
import { getAllNewVendorPersonService } from "../../pages/NewVendorPerson/NewVendorPersonService/NewVendorPersonService";
import { clearUnitsPageData } from "../../redux/Actions/UnitsAction/UnitsPageAction";
import { getUnitsFilterData } from "../../redux/Actions/UnitsAction/UnitsFilterAction";
import { clearPurchaseRequisitionPageData } from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionPageAction";
import { getPurchaseRequisitionFilterData } from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionFilterAction";
import { getAllPurchaseRequisitionService } from "../../pages/PurchaseRequisition/PurchaseRequisitionService/PurchaseRequisitionService";
import { clearPurchaseOrderPageData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderPageAction";
import { getAllPurchaseOrderService } from "../../pages/PurchaseOrder/PurchaseOrderService/PurchaseOrderService";
import { getPurchaseOrderFilterData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderFilterAction";
import { clearGRNPageData } from "../../redux/Actions/GoodReceiveNoteAction/GRNPageAction";
import { getGRNFilterData } from "../../redux/Actions/GoodReceiveNoteAction/GRNFilterAction";
import { getAllGoodReceiveNoteService } from "../../pages/GoodsReceiveNote/GoodsReceiveNoteResponse/GoodsReceiveNoteService";
import { clearWalletPageData } from "../../redux/Actions/WalletAction/WalletPageAction";
import { getAllWalletService } from "../../pages/Wallet/WalletService/WalletService";
import { getWalletFilterData } from "../../redux/Actions/WalletAction/WalletFilterAction";
import { clearProductPricingPageData } from "../../redux/Actions/ProductPricingAction/ProductPricingPageAction";
import { getProductPricingFilterData } from "../../redux/Actions/ProductPricingAction/ProductPricingFilterAction";

export default function Filter(props) {
  const userData = useSelector((state) => state.auth?.user);
  // const { handleClose } = props;

  const categoryOptions = [
    { value: "Product", label: "Product" },
    { value: "Beauty", label: "Beauty" },
  ];

  const packagerTypeOptions = [
    { value: "0", label: "Manufacturer" },
    { value: "1", label: "Packager" },
    { value: "2", label: "Importer" },
  ];

  // const discountTypeOptions = [
  //   { value: '0', label: '₹' },
  //   { value: '1', label: '%' }
  // ]
  const discountTypeOptions = [
    { value: "0", label: userData?.data?.symbol ? userData.data.symbol : "₹" },
    { value: "1", label: "%" },
  ];

  const activeDeleteOption = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  const taxTypeOption = [
    {
      value: "no tax",
      label: "No tax",
    },
    {
      value: "GST",
      label: "GST",
    },
    {
      value: "VAT",
      label: "VAT",
    },
  ];
  const paymentTypeOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "UPI" },
    { value: "2", label: "Card" },
    { value: "3", label: "Cash" },
    { value: "4", label: "Other" },
  ];

  const [initialValues, setInitialValues] = useState({});

  const roleFilter = useSelector((state) => state.roleFilter);
  const adminFilter = useSelector((state) => state.adminFilter);
  const categoryFilter = useSelector((state) => state.categoryFilter);
  const SubCategoryFilter = useSelector((state) => state.SubCategoryFilter);
  const leafFilter = useSelector((state) => state.leafFilter);
  const tagFilter = useSelector((state) => state.tagFilter);
  const spotFilter = useSelector((state) => state.spotFilter);
  const imageFilter = useSelector((state) => state.imageFilter);
  const brandFilter = useSelector((state) => state.brandFilter);
  const productFilter = useSelector((state) => state.productFilter);
  const attributeFilter = useSelector((state) => state.attributeFilter);
  const warehouseFilter = useSelector((state) => state.warehouseFilter);
  const packagerFilter = useSelector((state) => state.packagerFilter);
  const discountFilter = useSelector((state) => state.discountFilter);
  const inventoryItemFilter = useSelector((state) => state.inventoryItemFilter);
  const discountCatalogueFilter = useSelector(
    (state) => state.discountCatalogueFilter
  );
  const inventoryFilter = useSelector((state) => state.inventoryFilter);
  const productInventoryFilter = useSelector(
    (state) => state.productInventoryFilter
  );
  const vendorFilter = useSelector((state) => state.vendorFilter);
  const purchaseRequisitionFilter = useSelector(
    (state) => state.purchaseRequisitionFilter
  );
  const purchaseOrderFilter = useSelector((state) => state.purchaseOrderFilter);
  const walletFilter = useSelector((state) => state.walletFilter);
  const GRNFilter = useSelector((state) => state.GRNFilter);
  const stockHistoryFilter = useSelector((state) => state.stockHistoryFilter);
  const stockFilter = useSelector((state) => state.stockFilter);
  const newVendorFilter = useSelector((state) => state.newVendorFilter);
  const unitsFilter = useSelector((state) => state.unitsFilter);
  const branchFilter = useSelector((state) => state.branchFilter);
  const ServiceProviderFilter = useSelector(
    (state) => state.ServiceProviderFilter
  );
  const serviceFilter = useSelector((state) => state.serviceFilter);
  const orderFilter = useSelector((state) => state.OrderFilter);
  const customerFilter = useSelector((state) => state.customerFilter);

  const companyFilter = useSelector((state) => state.companyFilter);

  const dispatch = useDispatch();
  const token = UtilsData();

  /**
   * Getting category data
   */
  const categoryListData = useSelector((state) => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [categoryNewOptionsData, setCategoryNewOptionsData] = useState([]);

  /**
   * Fetching category data
   */
  const fetchCategory = () => {
    const roleRequestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllCategoryService(roleRequestBody, token, dispatch, true);
  };

  const subCategoryListData = useSelector((state) => state.totalSubCategory);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  /**
   * Fetting sub category data
   */
  const fetchSubCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllSubCategoryService(requestBody, token, dispatch, true);
  };

  const totalLeaf = useSelector((state) => state.totalLeaf);
  const [leafCategoryListOptions, setLeafCategoryListOptions] = useState([]);

  /**
   * Fetting leaf level category data
   */
  const fetchLeaf = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 100000000,
    };
    getAllLeafLevelCategoryService(requestBody, token, dispatch, true);
  };

  const totalTag = useSelector((state) => state.totalTag);
  const [tagListOptions, setTagListOptions] = useState([]);

  /**
   * Fetting tags data
   */
  const fetchTag = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllTagsService(requestBody, token, dispatch, true);
  };

  const totalVendorPerson = useSelector((state) => state.totalNewVendor);
  const [vendorPersonListOptions, setVendorPersonListOptions] = useState([]);
  const fetchVendorPerson = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllNewVendorPersonService(requestBody, token, dispatch, true);
  };

  const typeOptions = [
    { value: "0", label: "Product" },
    { value: "1", label: "Blog" },
    { value: "2", label: "Service" },
  ];

  const orderTypeOptions = [
    { value: "0", label: "Dine In" },
    { value: "1", label: "Take Out" },
    { value: "2", label: "Delivery" },
  ];

  const orderStatusOptions = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Confirmed" },
    { value: "2", label: "In Progress" },
    { value: "3", label: "Completed" },
    { value: "4", label: "Cancelled" },
  ];

  const vendorOrderStatusOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Completed" },
    { value: "2", label: "Cancelled" },
  ];

  const purchaseRequisitionStatusOption = [
    {
      value: "0",
      label: "Pending",
    },
    {
      value: "1",
      label: "Approved",
    },
    {
      value: "2",
      label: "Partially Approved",
    },
    {
      value: "3",
      label: "Rejected",
    },
    {
      value: "4",
      label: "PO Generated",
    },
  ];

  const totalBrand = useSelector((state) => state.totalBrand);
  const [brandListOptions, setBrandListOptions] = useState([]);

  /**
   * Fetting brand data
   */
  const fetchBrand = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllBrandService(requestBody, token, dispatch, true);
  };

  const totalWarehouse = useSelector((state) => state.totalWarehouse);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  /**
   * Fetting warehouse data
   */
  const fetchWarehouse = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 100000000,
    };
    getAllWarehouseService(requestBody, token, dispatch, true);
  };

  const totalBranch = useSelector((state) => state.totalBranch);
  const [branchOptions, setBranchOptions] = useState([]);

  /**
   * Fetting branch data
   */
  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000,
    };
    getAllBranchService(requestBody, token, dispatch, true);
  };

  const totalProduct = useSelector((state) => state.totalProduct);
  const [productOptions, setProductOptions] = useState([]);

  /**
   * Fetching product data from API
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: "1",
    };
    getAllProductService(requestBody, token, dispatch, true);
  };

  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const [inventoryItemOptions, setInventoryItemOptions] = useState([]);
  /**
   * Fetching product data from API
   */
  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  //Reinitializing initial values
  useEffect(() => {
    // Customer page
    if (props.page === "customer") {
      setInitialValues({
        phone: customerFilter.phone,
      });
    }

    // Brand Page
    if (props.page === "brand") {
      setInitialValues({
        name: brandFilter.name,
        category: brandFilter.category,
        subCategory: brandFilter.subCategory,
        leafCategory: brandFilter.leafCategory,
        tags: brandFilter.tags,
        status: brandFilter.isActive,
        is_delete: brandFilter.isDelete,
        type: branchFilter.type,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
          type: item.type,
        }));
        setCategoryListOptions(categoryOptions);
      }

      if (subCategoryListData?.items?.length === 0) {
        fetchSubCategory();
      }

      if (totalLeaf?.items?.length === 0) {
        fetchLeaf();
      }
    }

    // Category page
    if (props.page === "category") {
      setInitialValues({
        name: categoryFilter.name,
        code: categoryFilter.code,
        status: categoryFilter.isActive,
        is_delete: categoryFilter.isDelete,
      });
    }

    // Sub Category Page
    if (props.page === "subCategory") {
      setInitialValues({
        name: SubCategoryFilter.name,
        code: SubCategoryFilter.code,
        category: SubCategoryFilter.category,
        type: SubCategoryFilter.type,
        status: SubCategoryFilter.isActive,
        is_delete: SubCategoryFilter.isDelete,
      });

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
          type: item.type,
        }));
        setCategoryListOptions(categoryOptions);
      }
    }

    // Product Page
    if (props.page === "product") {
      setInitialValues({
        name: productFilter.name,
        category: productFilter.category,
        subCategory: productFilter.subCategory,
        leafCategory: productFilter.leafCategory,
        tags: productFilter.tags,
        sku: productFilter.sku,
        brand: productFilter.brand,
        status: productFilter.isActive,
        is_delete: productFilter.isDelete,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
        }));
        setCategoryListOptions(categoryOptions);
      }

      if (subCategoryListData?.items?.length === 0) {
        fetchSubCategory();
      } else if (subCategoryListData?.items[0]?.data?.length > 0) {
        const subCategoryOptions = subCategoryListData.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSubCategoryListOptions(subCategoryOptions);
      }

      if (totalLeaf?.items?.length === 0) {
        fetchLeaf();
      } else if (totalLeaf?.items[0]?.data?.length > 0) {
        const leafCategoryOptions = totalLeaf.items[0].data.map((item) => ({
          value: item.id,
          label: item.Title,
        }));
        setLeafCategoryListOptions(leafCategoryOptions);
      }

      if (totalBrand?.items?.length === 0) {
        fetchBrand();
      } else if (totalBrand?.items[0]?.data?.length > 0) {
        const brandOptions = totalBrand.items[0].data.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandListOptions(brandOptions);
      }
    }

    // Product Pricing Page
    if (props.page === "product-pricing") {
      setInitialValues({
        name: productFilter.name,
        sku: productFilter.sku,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
        }));
        setCategoryListOptions(categoryOptions);
      }

      if (subCategoryListData?.items?.length === 0) {
        fetchSubCategory();
      } else if (subCategoryListData?.items[0]?.data?.length > 0) {
        const subCategoryOptions = subCategoryListData.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSubCategoryListOptions(subCategoryOptions);
      }

      if (totalLeaf?.items?.length === 0) {
        fetchLeaf();
      } else if (totalLeaf?.items[0]?.data?.length > 0) {
        const leafCategoryOptions = totalLeaf.items[0].data.map((item) => ({
          value: item.id,
          label: item.Title,
        }));
        setLeafCategoryListOptions(leafCategoryOptions);
      }

      if (totalBrand?.items?.length === 0) {
        fetchBrand();
      } else if (totalBrand?.items[0]?.data?.length > 0) {
        const brandOptions = totalBrand.items[0].data.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandListOptions(brandOptions);
      }
    }

    // Image Gallery Page
    if (props.page === "imageManager") {
      setInitialValues({
        type: imageFilter.type,
        is_delete: imageFilter.isDelete,
      });
    }

    // Leaf level category page
    if (props.page === "leafCategory") {
      setInitialValues({
        name: leafFilter.name,
        subCategory: leafFilter.subCategory,
        status: leafFilter.isActive,
        is_delete: leafFilter.isDelete,
      });

      if (subCategoryListData?.items?.length === 0) {
        fetchSubCategory();
      } else if (subCategoryListData?.items[0]?.data?.length > 0) {
        const subCategoryOptions = subCategoryListData.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSubCategoryListOptions(subCategoryOptions);
      }
    }

    // Tags Page
    if (props.page === "tags") {
      setInitialValues({
        name: tagFilter?.title,
        status: tagFilter?.isActive,
        is_delete: tagFilter?.isDelete,
      });
    }

    // Spots Page
    if (props.page === "spots") {
      setInitialValues({
        name: spotFilter?.p_name,
        is_available: spotFilter?.p_is_available,
        is_active: spotFilter?.p_is_active,
        is_deleted: spotFilter?.p_is_deleted,
      });
    }

    // Inventory Item Page
    if (props.page === "inventory-item") {
      setInitialValues({
        name: inventoryItemFilter?.title,
        is_available: inventoryItemFilter?.isActive,
        is_active: inventoryItemFilter?.isDelete,
      });
    }

    // Product Inventory Item Page
    if (props.page === "product-inventory") {
      setInitialValues({
        productId: productInventoryFilter?.productId,
      });

      if (totalProduct?.items?.length === 0) {
        fetchProduct();
      } else if (totalProduct?.items[0]?.data?.length > 0) {
        const productOptions = totalProduct.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.productId,
        }));
        setProductOptions(productOptions);
      }
    }

    // Stocks Page
    if (props.page === "stocks" || props.page === "stock") {
      setInitialValues({
        ingredientId: stockFilter?.ingredientId,
      });

      if (totalInventoryItem?.items?.length === 0) {
        fetchInventoryItem();
      } else if (totalInventoryItem?.items[0]?.data?.length > 0) {
        const totalInventoryOptions = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
          })
        );
        setInventoryItemOptions(totalInventoryOptions);
      }
    }
    // Vendor Page
    if (props.page === "vendors") {
      setInitialValues({
        orderNo: vendorFilter?.orderNo,
        orderStartDate: vendorFilter.orderStartDate,
        orderEndDate: vendorFilter.orderEndDate,
        status: vendorFilter.status,
        payment: vendorFilter.orderType,
        vendor: vendorFilter.vendor,
      });
      if (
        (totalVendorPerson?.items && totalVendorPerson?.items.length === 0) ||
        !totalVendorPerson
      ) {
        fetchVendorPerson();
      } else if (totalVendorPerson?.items[0]?.data?.length > 0) {
        const totalVendorPersonOptions = totalVendorPerson.items[0].data.map(
          (item) => ({
            label: item?.first_name + " " + item?.last_name,
            value: item?.id,
          })
        );
        setVendorPersonListOptions(totalVendorPersonOptions);
      }
    }
    if (props.page === "purchase-requisition") {
      setInitialValues({
        purchaseRequisitionNo: purchaseRequisitionFilter?.purchaseRequisitionNo,
        orderStartDate: purchaseRequisitionFilter.orderStartDate,
        orderEndDate: purchaseRequisitionFilter.orderEndDate,
        status: purchaseRequisitionFilter.status,
        vendor: purchaseRequisitionFilter.vendor,
      });
      if (
        (totalVendorPerson?.items && totalVendorPerson?.items.length === 0) ||
        !totalVendorPerson
      ) {
        fetchVendorPerson();
      } else if (totalVendorPerson?.items[0]?.data?.length > 0) {
        const totalVendorPersonOptions = totalVendorPerson.items[0].data.map(
          (item) => ({
            label: item?.first_name + " " + item?.last_name,
            value: item?.id,
          })
        );
        setVendorPersonListOptions(totalVendorPersonOptions);
      }
    }
    if (props.page === "purchase-order") {
      setInitialValues({
        purchaseOrderNo: purchaseOrderFilter?.purchaseOrderNo,
        orderStartDate: purchaseOrderFilter.orderStartDate,
        orderEndDate: purchaseOrderFilter.orderEndDate,
      });
    }

    if (props.page === "grn") {
      setInitialValues({
        GRNNo: GRNFilter?.GRNNo,
        orderStartDate: GRNFilter.orderStartDate,
        orderEndDate: GRNFilter.orderEndDate,
      });
    }
    if (props.page === "wallet") {
      setInitialValues({
        startDate: walletFilter?.startDate,
        endDate: walletFilter.endDate,
        // status: walletFilter.status,
        // type: walletFilter.type,
      });
    }
    if (props.page === "new-vendor-person") {
      setInitialValues({
        firstName: newVendorFilter?.firstName,
        lastName: newVendorFilter.lastName,
        phNum: newVendorFilter.phNum,
        isActive: newVendorFilter.isActive,
        isDelete: newVendorFilter.isDelete,
      });
    }

    if (props.page === "units") {
      setInitialValues({
        unit: unitsFilter.unitId,
        isActive: unitsFilter.isActive,
        isDelete: unitsFilter.isDelete,
      });
    }

    // Stock History page
    if (props.page === "StockHistory") {
      setInitialValues({
        ingredientId: stockHistoryFilter?.ingredientId,
        orderDate: stockHistoryFilter.orderDate,
      });

      if (totalInventoryItem?.items?.length === 0) {
        fetchInventoryItem();
      } else if (totalInventoryItem?.items[0]?.data?.length > 0) {
        const totalInventoryOptions = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
          })
        );
        setInventoryItemOptions(totalInventoryOptions);
      }
    }

    // Attributes Page
    if (props.page === "attributes") {
      setInitialValues({
        name: attributeFilter.name,
        status: attributeFilter.isActive,
        is_delete: attributeFilter.isDelete,
      });
    }

    // Warehouse Page
    if (props.page === "warehouse") {
      setInitialValues({
        name: warehouseFilter.name,
        status: warehouseFilter.isActive,
        is_delete: warehouseFilter.isDelete,
      });
    }

    // Admin User Page
    if (props.page === "users") {
      setInitialValues({
        phone: adminFilter.phone,
        status: adminFilter.isActive,
        is_delete: adminFilter.isDelete,
      });
    }

    // Policy Page
    if (props.page === "policy") {
      setInitialValues({
        name: "",
        type: "",
        status: "",
        is_delete: "",
      });
    }

    // Order Page
    if (props.page === "order") {
      setInitialValues({
        orderDate: orderFilter.orderDate,
        status: orderFilter.status,
        type: orderFilter.orderType,
      });
    }

    // Role Page
    if (props.page === "roles") {
      setInitialValues({
        name: roleFilter.name,
        status: roleFilter.isActive,
        is_delete: roleFilter.isDelete,
      });
    }

    // Company Page
    if (props.page === "company") {
      setInitialValues({
        pincode: companyFilter.p_pincode,
        company_address: companyFilter.p_address,
        company_name: companyFilter.p_company_name,
      });
    }

    // Packager Page
    if (props.page === "packager") {
      setInitialValues({
        name: packagerFilter.name,
        type: packagerFilter.type,
        status: packagerFilter.isActive,
        is_delete: packagerFilter.isDelete,
      });
    }

    // Discount Page
    if (props.page === "discount") {
      setInitialValues({
        name: discountFilter.name,
        type: discountFilter.type,
        start_date: discountFilter.start_date,
        end_date: discountFilter.end_date,
        code: discountFilter.code,
        status: discountFilter.isActive,
        is_delete: discountFilter.isDelete,
      });
    }

    // Discount Catalogue Page
    if (props.page === "discountCatalogue") {
      setInitialValues({
        name: discountCatalogueFilter.name,
        start_date: discountCatalogueFilter.start_date,
        end_date: discountCatalogueFilter.end_date,
        status: discountCatalogueFilter.isActive,
        is_delete: discountCatalogueFilter.isDelete,
      });
    }

    // Inventory Page
    if (props.page === "inventory") {
      setInitialValues({
        code: inventoryFilter.productCode,
        name: inventoryFilter.name,
        sku: inventoryFilter.sku,
        warehouse: inventoryFilter.warehouse,
        status: inventoryFilter.isActive,
        is_delete: inventoryFilter.isDelete,
      });

      if (totalWarehouse?.items?.length === 0) {
        fetchWarehouse();
      } else if (totalWarehouse?.items[0]?.data?.length > 0) {
        const warehouse = totalWarehouse.items[0].data.map((item) => ({
          value: item.warehouseId,
          label: item.name,
          is_active: item.is_active,
        }));
        setWarehouseOptions(warehouse);
      }
    }

    // Branch Page
    if (props.page === "branch") {
      setInitialValues({
        name: branchFilter.name,
        pincode: branchFilter.pincode,
        status: branchFilter.isActive,
        is_delete: branchFilter.isDelete,
        is_public_avilable: branchFilter.isPublicAvailable,
        is_wp_sms_allow: branchFilter.isWpSmsAllow,
        is_delivery_available: branchFilter.isDeliveryAvailable,
        tax_type: branchFilter.taxType,
      });
    }

    // Service Provider Page
    if (props.page === "serviceProvider") {
      setInitialValues({
        name: ServiceProviderFilter.name,
        status: ServiceProviderFilter.isActive,
        is_delete: ServiceProviderFilter.isDelete,
      });
    }

    // Service Page
    if (props.page === "service") {
      setInitialValues({
        name: serviceFilter.name,
        tags:
          serviceFilter.tag === ""
            ? []
            : serviceFilter.tag.split(",").map(Number),
        brand: serviceFilter.brand,
        branch:
          serviceFilter.branch === ""
            ? []
            : serviceFilter.branchs.split(",").map(Number),
        category: serviceFilter.category,
        subcategory:
          serviceFilter.subCategory === ""
            ? []
            : serviceFilter.subCategory.split(",").map(Number),
        status: serviceFilter.isActive,
        is_delete: serviceFilter.isDelete,
      });

      if (totalTag?.items?.length === 0) {
        fetchTag();
      } else if (totalTag?.items[0]?.data?.length > 0) {
        const tagOptions = totalTag.items[0].data.map((item) => ({
          value: item.tagId,
          label: item.title,
        }));
        setTagListOptions(tagOptions);
      }

      if (categoryListData?.items?.length === 0) {
        fetchCategory();
      } else if (categoryListData?.items[0]?.data?.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map((item) => ({
          value: item.categoryId,
          label: item.name,
        }));
        setCategoryListOptions(categoryOptions);
      }

      if (subCategoryListData?.items?.length === 0) {
        fetchSubCategory();
      } else if (subCategoryListData?.items[0]?.data?.length > 0) {
        const subCategoryOptions = subCategoryListData.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSubCategoryListOptions(subCategoryOptions);
      }

      if (totalBrand?.items?.length === 0) {
        fetchBrand();
      } else if (totalBrand?.items[0]?.data?.length > 0) {
        const brandOptions = totalBrand.items[0].data.map((item) => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBrandListOptions(brandOptions);
      }

      if (totalBranch?.items?.length === 0) {
        fetchBranch();
      } else if (totalBranch?.items[0]?.data?.length > 0) {
        const branchOptions = totalBranch.items[0].data.map((item) => ({
          value: item.branchId,
          label: item.name,
          is_active: item.isActive,
        }));
        setBranchOptions(branchOptions);
      }
    }

    // eslint-disable-next-line
  }, [
    props.page,
    totalTag,
    subCategoryListData,
    totalLeaf,
    categoryListData,
    totalBrand,
    totalBranch,
    totalWarehouse,
    productInventoryFilter,
    stockFilter,
    vendorFilter,
    attributeFilter,
    warehouseFilter,
    adminFilter,
    orderFilter,
    roleFilter,
    companyFilter,
    packagerFilter,
    discountFilter,
    discountCatalogueFilter,
    inventoryFilter,
    ServiceProviderFilter,
    serviceFilter,
    totalInventoryItem,
    totalVendorPerson,
  ]);

  /**
   * Handle submit for filter
   */
  const handleSubmit = (values) => {
    //Role page
    if (props.page === "roles") {
      dispatch(clearRolePageData());
      const roleRequestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        roleRequestBody.p_search_title = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        roleRequestBody.p_is_active = values.status;
      } else {
        roleRequestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        roleRequestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getRoleFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getRolesService(roleRequestBody, token, dispatch, "filter");
        dispatch(clearRolePageData());
      }
    }
    // Company page
    if (props.page === "company") {
      dispatch(clearCompanyPageData());
      const companyRequestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.pincode !== "" && values.pincode !== null) {
        companyRequestBody.p_pincode = values.pincode;
      }
      if (values.company_address !== "" && values.company_address !== null) {
        companyRequestBody.p_address = values.company_address;
      }
      if (values.company_name !== "" && values.company_name !== null) {
        companyRequestBody.p_company_name = values.company_name;
      }

      // else {
      //   companyRequestBody.p_is_active = '1';
      // }
      if (values.is_delete !== "" && values.is_delete !== null) {
        companyRequestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.pincode === "" &&
        values.company_address === "" &&
        values.company_name === ""
      ) {
        toast.error("Please add filters!");
      } else {
        // dispatch(getCompanyFilterData({ country_name: values.country_name, isActive: values.status, isDelete: values.is_delete }));
        dispatch(
          getCompanyFilterData({
            p_company_name: values.company_name,
            p_pincode: values.pincode,
            p_address: values.company_address,
          })
        );
        getCompanyService(companyRequestBody, token, dispatch, "filter");
        dispatch(clearCompanyData());
      }
    }

    //Admin user page
    else if (props.page === "users") {
      dispatch(clearAdminPageData());
      const requestData = {
        skip: 0,
        take: 10,
      };
      if (values.phone !== "" && values.phone !== null) {
        requestData.search_phone_number = values.phone;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.search_is_active = values.status;
      } else {
        requestData.search_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_IsDeleted = values.is_delete;
      }
      if (
        values.phone === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getAdminFilterData({
            phone: values.phone,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getUserListService(requestData, token, dispatch, "filter");
        dispatch(clearAdminPageData());
      }
    }
    //Customer list page
    else if (props.page === "customer") {
      dispatch(clearCustomerPageData());
      const requestData = {
        skip: 0,
        take: 10,
      };
      if (values.phone !== "" && values.phone !== null) {
        requestData.search_phone_number = values.phone;
      }
      if (values.phone === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(getCustomerFilterData({ phone: values.phone }));
        getCustomerListService(requestData, token, dispatch, "filter");
        dispatch(clearCustomerPageData());
      }
    }
    //category page
    else if (props.page === "category") {
      const requestData = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestData.p_name = values.name;
      }
      if (values.code !== "" && values.code !== null) {
        requestData.p_category_code = values.code;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.p_is_active = values.status;
      } else {
        requestData.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.code === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getCategoryFilterData({
            name: values.name,
            code: values.code,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllCategoryService(requestData, token, dispatch, "filter");
        dispatch(clearCategoryPageData());
      }
    }
    //sub category page
    else if (props.page === "subCategory") {
      const requestData = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestData.p_name = values.name;
      }
      if (values.category !== "" && values.category !== null) {
        requestData.p_categoryIds = values.category;
      }
      if (values.code !== "" && values.code !== null) {
        requestData.p_category_code = values.code;
      }
      if (values.type !== "" && values.type !== null) {
        requestData.p_type = values.type;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.p_is_active = values.status;
      } else {
        requestData.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.category === "" &&
        values.code === "" &&
        values.type === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getSubCategoryFilterData({
            name: values.name,
            category: values.category,
            code: values.code,
            type: values.type,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllSubCategoryService(requestData, token, dispatch, "filter");
        dispatch(clearSubCategoryPageData());
      }
    }
    //leaf level category page
    else if (props.page === "leafCategory") {
      const requestData = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestData.p_name = values.name;
      }
      if (values.subCategory !== "" && values.subCategory !== null) {
        requestData.p_sub_categoryIds = values.subCategory;
      }
      if (values.status !== "" && values.status !== null) {
        requestData.p_is_active = values.status;
      } else {
        requestData.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestData.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.subCategory === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(
          getLeafFilterData({
            name: values.name,
            subCategory: values.subCategory,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllLeafLevelCategoryService(requestData, token, dispatch, "filter");
        dispatch(clearLeafPageData());
      }
    }
    //tags page
    else if (props.page === "tags" || props.page === "tag") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_isActive = values.status;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearTagPageData());
        dispatch(
          getTagFilterData({
            title: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllTagsService(requestBody, token, dispatch, "filter");
      }
    }

    //spots page
    else if (props.page === "spots" || props.page === "spot") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.is_active !== "" && values.is_active !== null) {
        requestBody.p_is_active = values.is_active;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_deleted !== "" && values.is_deleted !== null) {
        requestBody.p_is_deleted = values.is_deleted;
      }
      if (values.is_available !== "" && values.is_available !== null) {
        requestBody.p_is_available = values.is_available;
      }

      // Check if all fields are empty strings
      if (
        values.name === "" &&
        values.is_available === "" &&
        values.is_deleted === "" &&
        values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearSpotPageData());

        dispatch(
          getSpotFilterData({
            name: values.name,
            p_is_active: values.is_active,
            p_is_deleted: values.is_deleted,
            p_is_available: values.is_available,
          })
        );

        return getAllSpotService(requestBody, token, dispatch, "filter");
      }
    }

    // inventory item page
    else if (
      props.page === "inventory-items" ||
      props.page === "inventory-item"
    ) {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.is_active !== "" && values.is_active !== null) {
        requestBody.p_isActive = values.is_active;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.is_deleted !== "" && values.is_deleted !== null) {
        requestBody.p_IsDeleted = values.is_deleted;
      }

      // Check if all fields are empty strings
      if (
        values.name === "" &&
        values.is_deleted === "" &&
        values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearInventoryItemPageData());

        dispatch(
          getInventoryItemFilterData({
            title: values.name,
            isActive: values.is_active,
            isDelete: values.is_deleted,
          })
        );

        return getAllInventoryItemService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    } else if (props.page === "product-inventory") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.productId !== "" && values.productId !== null) {
        requestBody.p_product_id = values.productId;
      }
      // if (values.is_active !== "" && values.is_active !== null) {
      //   requestBody.p_isActive = values.is_active;
      // } else {
      //   requestBody.p_isActive = "1";
      // }
      // if (values.is_deleted !== "" && values.is_deleted !== null) {
      //   requestBody.p_IsDeleted = values.is_deleted;
      // }

      // Check if all fields are empty strings
      if (
        values.productId === ""
        // values.productId === "" &&
        // values.is_deleted === "" &&
        // values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearProductInventoryPageData());

        dispatch(
          getProductInventoryFilterData({
            productId: values.productId,
            // isActive: values.is_active,
            // isDelete: values.is_deleted,
          })
        );

        return getAllProductInventoryService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    } else if (props.page === "stocks") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.ingredientId !== "" && values.ingredientId !== null) {
        requestBody.p_ingredient_id = values.ingredientId;
      }
      // if (values.is_active !== "" && values.is_active !== null) {
      //   requestBody.p_isActive = values.is_active;
      // } else {
      //   requestBody.p_isActive = "1";
      // }
      // if (values.is_deleted !== "" && values.is_deleted !== null) {
      //   requestBody.p_IsDeleted = values.is_deleted;
      // }

      // Check if all fields are empty strings
      if (
        values.ingredientId === ""
        // values.productId === "" &&
        // values.is_deleted === "" &&
        // values.is_active === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearStockPageData());

        dispatch(
          getStockFilterData({
            ingredientId: values.ingredientId,
            // isActive: values.is_active,
            // isDelete: values.is_deleted,
          })
        );

        return getAllStocksService(requestBody, token, dispatch, "filter");
      }
    }

    // vendor order page
    else if (props.page === "vendors" || props.page === "vendor") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.orderNo !== "" && values.orderNo !== null) {
        requestBody.p_order_no = values.orderNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      if (values.vendor !== "" && values.vendor !== null) {
        requestBody.p_vendor_id = values.vendor;
      }

      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }

      if (values.payment !== "" && values.payment !== null) {
        requestBody.p_payment = values.payment;
      }

      // Check if all fields are empty strings
      if (
        values.orderNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === "" &&
        (values.vendor === "" || !values.vendor) &&
        values.status === "" &&
        values.payment === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Order End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearVendorPageData());

          dispatch(
            getVendorFilterData({
              orderNo: values.orderNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
              vendor: values.vendor,
              status: values.status,
              payment: values.payment,
            })
          );

          return getAllVendorService(requestBody, token, dispatch, "filter");
        }
      } else {
        dispatch(clearVendorPageData());

        dispatch(
          getVendorFilterData({
            orderNo: values.orderNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
            vendor: values.vendor,
            status: values.status,
            payment: values.payment,
          })
        );

        return getAllVendorService(requestBody, token, dispatch, "filter");
      }
    }

    // purchase requisition page
    else if (props.page === "purchase-requisition") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (
        values.purchaseRequisitionNo !== "" &&
        values.purchaseRequisitionNo !== null
      ) {
        requestBody.p_purchase_requisition_no = values.purchaseRequisitionNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      if (values.vendor !== "" && values.vendor !== null) {
        requestBody.p_vendor_id = values.vendor;
      }

      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }

      // Check if all fields are empty strings
      if (
        values.purchaseRequisitionNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === "" &&
        (values.vendor === "" || !values.vendor) &&
        values.status === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Purchase Requisition End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearPurchaseRequisitionPageData());

          dispatch(
            getPurchaseRequisitionFilterData({
              purchaseRequisitionNo: values.purchaseRequisitionNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
              vendor: values.vendor,
              status: values.status,
            })
          );

          return getAllPurchaseRequisitionService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearPurchaseRequisitionPageData());

        dispatch(
          getPurchaseRequisitionFilterData({
            purchaseRequisitionNo: values.purchaseRequisitionNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
            vendor: values.vendor,
            status: values.status,
          })
        );

        return getAllPurchaseRequisitionService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // purchase order page
    else if (props.page === "purchase-order") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.purchaseOrderNo !== "" && values.purchaseOrderNo !== null) {
        requestBody.p_purchase_order_no = values.purchaseOrderNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      // Check if all fields are empty strings
      if (
        values.purchaseOrderNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Purchase Order End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearPurchaseOrderPageData());

          dispatch(
            getPurchaseOrderFilterData({
              purchaseOrderNo: values.purchaseOrderNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
            })
          );

          return getAllPurchaseOrderService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearPurchaseOrderPageData());

        dispatch(
          getPurchaseOrderFilterData({
            purchaseOrderNo: values.purchaseOrderNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
          })
        );

        return getAllPurchaseOrderService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // GRN page
    else if (props.page === "grn") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.GRNNo !== "" && values.GRNNo !== null) {
        requestBody.p_grn_no = values.GRNNo;
      }

      if (values.orderStartDate !== "" && values.orderStartDate !== null) {
        const startDate = new Date(values.orderStartDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.orderEndDate !== "" && values.orderEndDate !== null) {
        const endDate = new Date(values.orderEndDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }

      // Check if all fields are empty strings
      if (
        values.GRNNo === "" &&
        values.orderStartDate === "" &&
        values.orderEndDate === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.orderStartDate && values.orderEndDate) {
        // if (values.orderEndDate) {
        let startDate = new Date(values.orderStartDate);
        let endDate = new Date(values.orderEndDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Purchase Requisition End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearGRNPageData());

          dispatch(
            getGRNFilterData({
              GRNNo: values.GRNNo,
              orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
              orderEndDate:
                values.orderEndDate && values.orderEndDate !== ""
                  ? format(values.orderEndDate, "yyyy-MM-dd")
                  : "",
            })
          );

          return getAllGoodReceiveNoteService(
            requestBody,
            token,
            dispatch,
            "filter"
          );
        }
      } else {
        dispatch(clearGRNPageData());

        dispatch(
          getGRNFilterData({
            GRNNo: values.GRNNo,
            orderStartDate: format(values.orderStartDate, "yyyy-MM-dd"),
            orderEndDate:
              values.orderEndDate && values.orderEndDate !== ""
                ? format(values.orderEndDate, "yyyy-MM-dd")
                : "",
          })
        );

        return getAllGoodReceiveNoteService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // wallet page
    else if (props.page === "wallet") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.startDate !== "" && values.startDate !== null) {
        const startDate = new Date(values.startDate);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const day = String(startDate.getDate()).padStart(2, "0");
        requestBody.p_created_date = `${year}-${month}-${day}`;
      }

      if (values.endDate !== "" && values.endDate !== null) {
        const endDate = new Date(values.endDate);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, "0");
        const day = String(endDate.getDate()).padStart(2, "0");
        requestBody.p_end_date = `${year}-${month}-${day}`;
      }
      // if (values.status !== "" && values.status !== null) {
      //   requestBody.p_status = values.status;
      // }
      // if (values.type !== "" && values.type !== null) {
      //   requestBody.p_type = values.type;
      // }
      // Check if all fields are empty strings
      if (
        // values.status === "" &&
        values.startDate === "" &&
        values.endDate === ""
        // values.type === ""
      ) {
        toast.error("Please add filter!");
      } else if (values.startDate && values.endDate) {
        let startDate = new Date(values.startDate);
        let endDate = new Date(values.endDate);
        startDate = startDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        endDate = endDate.toLocaleDateString({
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        if (endDate < startDate) {
          toast.error(
            "Transaction End Date cannot be earlier than Order Start Date!"
          );
        } else {
          dispatch(clearWalletPageData());

          dispatch(
            getWalletFilterData({
              startDate: format(values.startDate, "yyyy-MM-dd"),
              endDate:
                values.endDate && values.endDate !== ""
                  ? format(values.endDate, "yyyy-MM-dd")
                  : "",
              // status: values.status,
              // type: values.type,
            })
          );
          return getAllWalletService(requestBody, token, dispatch, "filter");
        }
      } else {
        dispatch(clearWalletPageData());

        dispatch(
          getWalletFilterData({
            startDate: format(values.startDate, "yyyy-MM-dd"),
            endDate:
              values.endDate && values.endDate !== ""
                ? format(values.endDate, "yyyy-MM-dd")
                : "",
            // status: values.status,
            // type: values.type,
          })
        );

        return getAllWalletService(requestBody, token, dispatch, "filter");
      }
    }

    // vendor page
    else if (props.page === "new-vendor-person") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.firstName !== "" && values.firstName !== null) {
        requestBody.p_first_name = values.firstName;
      }
      if (values.isActive !== "" && values.isActive !== null) {
        requestBody.p_isActive = values.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.isDelete !== "" && values.isDelete !== null) {
        requestBody.p_IsDeleted = values.isDelete;
      }
      if (values.lastName !== "" && values.lastName !== null) {
        requestBody.p_last_name = values.lastName;
      }
      if (values.phNum !== "" && values.phNum !== null) {
        requestBody.p_phone_number = values.phNum;
      }

      // Check if all fields are empty strings
      if (
        values.firstName === "" &&
        values.isActive === "" &&
        values.isDelete === "" &&
        values.lastName === "" &&
        values.phNum === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearNewVendorPageData());

        dispatch(
          getNewVendorFilterData({
            firstName: values.firstName,
            lastName: values.lastName,
            phNum: values.phNum,
            isActive: values.isActive,
            isDelete: values.isDelete,
          })
        );

        return getAllNewVendorPersonService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // units
    else if (props.page === "units") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.unit !== "" && values.unit !== null) {
        requestBody.p_unit_id = values.unit;
      }
      if (values.isActive !== "" && values.isActive !== null) {
        requestBody.p_isActive = values.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.isDelete !== "" && values.isDelete !== null) {
        requestBody.p_IsDeleted = values.isDelete;
      }

      // Check if all fields are empty strings
      if (
        values.unit === "" &&
        values.isActive === "" &&
        values.isDelete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearUnitsPageData());

        dispatch(
          getUnitsFilterData({
            unitId: values.unit,
            isActive: values.isActive,
            isDelete: values.isDelete,
          })
        );

        return getAllNewVendorPersonService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }

    // stock history page
    else if (props.page === "StockHistory") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (values.ingredientId !== "" && values.ingredientId !== null) {
        requestBody.p_ingredient_id = values.ingredientId;
      }
      if (values.orderDate !== "" && values.orderDate !== null) {
        const date = new Date(values.orderDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
        // requestBody.p_created_date = values.orderDate;
      }

      // Check if all fields are empty strings
      if (values.ingredientId === "" && values.orderDate === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(clearStockHistoryPageData());

        dispatch(
          getStockHistoryFilterData({
            ingredientId: values.ingredientId,
            orderDate: values.orderDate,
          })
        );

        return getAllStockHistoryService(
          requestBody,
          token,
          dispatch,
          "filter"
        );
      }
    }
    //Image manager page
    else if (props.page === "imageManager") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };

      if (values.type !== "" && values.type !== null) {
        requestBody.p_type_id = values.type;
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (values.type === "" && values.is_delete === "") {
        toast.error("Please add filter!");
      } else {
        dispatch(clearImagePageData());
        dispatch(
          getImageFilterData({ type: values.type, isDelete: values.is_delete })
        );
        getAllImageService(requestBody, token, dispatch, "filter");
      }
    }
    //Brand page
    else if (props.page === "brand" || props.page === "Brand") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.category !== "" && values.category !== null) {
        requestBody.p_category_id = values.category;
      }
      if (values.subCategory !== "" && values.subCategory !== null) {
        requestBody.p_sub_category_id = values.subCategory;
      }
      if (values.leafCategory !== "" && values.leafCategory !== null) {
        requestBody.p_leaf_level_category_id = values.leafCategory;
      }
      if (
        values.tags !== "" &&
        values.tags !== null &&
        values.tags.length > 0
      ) {
        requestBody.p_tag_id = values.tags.join(",");
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }
      if (
        values.name === "" &&
        values.category === "" &&
        values.subCategory === "" &&
        values.leafCategory === "" &&
        (values.tags === "" ||
          values.tags === null ||
          values.tags.length === 0) &&
        values.status === "" &&
        values.is_delete === "" &&
        values.type === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearBrandPageData());
        getAllBrandService(requestBody, token, dispatch, "filter");
        dispatch(
          getBrandFilterData({
            name: values.name,
            category: values.category,
            subCategory: values.subCategory,
            leafCategory: values.leafCategory,
            tags: values.tags,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
      }
    }
    //Product page
    else if (props.page === "product") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.brand !== "" && values.brand !== null) {
        requestBody.p_brand_id = values.brand;
      }
      if (
        values.category !== "" &&
        values.category !== null &&
        values.category.length > 0
      ) {
        requestBody.p_category_id = values.category;
      }
      if (
        values.subCategory !== "" &&
        values.subCategory !== null &&
        values.subCategory.length > 0
      ) {
        requestBody.p_sub_category_id = values.subCategory;
      }
      if (
        values.leafCategory !== "" &&
        values.leafCategory !== null &&
        values.leafCategory > 0
      ) {
        requestBody.p_leaf_level_category_id = values.leafCategory;
      }
      if (
        values.tags !== "" &&
        values.tags !== null &&
        values.tags.length > 0
      ) {
        requestBody.p_tag_id = values.tags.join(",");
      }
      if (values.sku !== "" && values.sku !== null) {
        requestBody.p_sku_code = values.sku;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.brand === "" &&
        (values.category === "" ||
          values.category === null ||
          values.category.length === 0) &&
        (values.subCategory === "" ||
          values.subCategory === null ||
          values.subCategory.length === 0) &&
        (values.leafCategory === "" ||
          values.leafCategory === null ||
          values.leafCategory.length === 0) &&
        (values.tags === "" ||
          values.tags === null ||
          values.tags.length === 0) &&
        values.sku === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        // dispatch(clearProductData());
        dispatch(clearProductPageData());
        getAllProductService(requestBody, token, dispatch, "filter");
        dispatch(
          getProductFilterData({
            name: values.name,
            category: values.category,
            subCategory: values.subCategory,
            leafCategory: values.leafCategory,
            tags: values.tags,
            sku: values.sku,
            brand: values.brand,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
      }
    }
    //Product page
    else if (props.page === "product-pricing") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.sku !== "" && values.sku !== null) {
        requestBody.p_sku_code = values.sku;
      }
      if (values.name === "" && values.sku === "") {
        toast.error("Please add filter!");
      } else {
        // dispatch(clearProductData());
        dispatch(clearProductPricingPageData());
        getAllProductService(requestBody, token, dispatch, "filter");
        dispatch(
          getProductPricingFilterData({
            name: values.name,
            sku: values.sku,
          })
        );
      }
    }
    //Attribute page
    else if (props.page === "attributes") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_isActive = values.status;
      } else {
        requestBody.p_isActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearAttributePageData());
        dispatch(
          getAttributeFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllAttributeService(requestBody, token, dispatch, "filter");
      }
    }
    //Warehouse page
    else if (props.page === "warehouse") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearWarehousePageData());
        dispatch(
          getWarehouseFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllWarehouseService(requestBody, token, dispatch, "filter");
      }
    }
    //packager and manufacturer page
    else if (props.page === "packager") {
      const requestBody = {
        p_skip: 0,
        p_take: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_type = values.type;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.type === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearPackagerPageData());
        dispatch(
          getPackagerFilterData({
            name: values.name,
            type: values.type,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllPackagerService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "discount") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.type !== "" && values.type !== null) {
        requestBody.p_discount_type = values.type;
      }
      if (values.start_date !== "" && values.start_date !== null) {
        requestBody.p_start_date = format(
          new Date(values.start_date),
          "yyyy-MM-dd"
        );
      }
      if (values.end_date !== "" && values.end_date !== null) {
        requestBody.p_end_date = format(
          new Date(values.end_date),
          "yyyy-MM-dd"
        );
      }
      if (values.code !== "" && values.code !== null) {
        requestBody.p_discount_code = values.code;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_IsActive = values.status;
      } else {
        requestBody.p_IsActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.type === "" &&
        values.start_date === "" &&
        values.end_date === "" &&
        values.code === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        getAllDiscountService(requestBody, token, dispatch, "filter");
        dispatch(
          getDiscountFilterData({
            name: values.name,
            type: values.type,
            start_date: values.start_date,
            end_date: values.end_date,
            code: values.code,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        dispatch(clearDiscountPageData());
      }
    } else if (props.page === "discountCatalogue") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_title = values.name;
      }
      if (values.start_date !== "" && values.start_date !== null) {
        requestBody.p_start_date = format(
          new Date(values.start_date),
          "yyyy-MM-dd"
        );
      }
      if (values.end_date !== "" && values.end_date !== null) {
        requestBody.p_end_date = format(
          new Date(values.end_date),
          "yyyy-MM-dd"
        );
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_IsActive = values.status;
      } else {
        requestBody.p_IsActive = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.start_date === "" &&
        values.end_date === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearDiscountCataloguePageData());
        dispatch(
          getDiscountCatalogueFilterData({
            name: values.name,
            start_date: values.start_date,
            end_date: values.end_date,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllDiscountCatalogueService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "inventory") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.code !== "" && values.code !== null) {
        requestBody.p_productCode = values.code;
      }
      if (values.sku !== "" && values.sku !== null) {
        requestBody.p_sku_code = values.sku;
      }
      if (values.warehouse !== "" && values.warehouse !== null) {
        requestBody.p_warehouse_id = values.warehouse;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      }
      // else {
      //   requestBody.p_is_active = '1';
      // }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_isDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.code === "" &&
        values.sku === "" &&
        values.warehouse === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearInventoryPageData());
        dispatch(
          getInventoryFilterData({
            productCode: values.code,
            sku: values.sku,
            warehouse: values.warehouse,
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        getAllInventoryService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "branch") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.pincode !== "" && values.pincode !== null) {
        requestBody.p_pincode = values.pincode;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.is_public_avilable !== "" &&
        values.is_public_avilable !== null
      ) {
        requestBody.p_public_avilable = values.is_public_avilable;
      }
      if (values.is_wp_sms_allow !== "" && values.is_wp_sms_allow !== null) {
        requestBody.p_wp_sms_allow = values.is_wp_sms_allow;
      }
      if (
        values.is_delivery_available !== "" &&
        values.is_delivery_available !== null
      ) {
        requestBody.p_delivery_available = values.is_delivery_available;
      }
      if (values.tax_type !== "" && values.tax_type !== null) {
        requestBody.p_tax_type = values.tax_type;
      }
      if (
        values.name === "" &&
        values.pincode === "" &&
        values.status === "" &&
        values.is_delete === "" &&
        values.is_public_avilable === "" &&
        values.is_wp_sms_allow === "" &&
        values.is_delivery_available === "" &&
        values.tax_type === ""
      ) {
        toast.error("Please add filter!");
      } else {
        dispatch(clearBranchPageData());
        dispatch(
          getBranchFilterData({
            name: values.name,
            pincode: values.pincode,
            isActive: values.status,
            isDelete: values.is_delete,
            isDeliveryAvailable: values.is_delivery_available,
            isPublicAvailable: values.is_public_avilable,
            isWpSmsAllow: values.is_wp_sms_allow,
            taxType: values.tax_type,
          })
        );
        getAllBranchService(requestBody, token, dispatch, "filter");
      }
    } else if (props.page === "serviceProvider") {
      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (
        values.name === "" &&
        values.status === "" &&
        values.is_delete === ""
      ) {
        toast.error("Please add filter!");
      } else {
        getAllServiceProviderService(requestBody, token, dispatch, "filter");
        dispatch(
          getServiceProviderFilterData({
            name: values.name,
            isActive: values.status,
            isDelete: values.is_delete,
          })
        );
        dispatch(clearServiceProviderPageData());
      }
    } else if (props.page === "service") {
      if (
        values.category === "" &&
        (values.subCategory === "" || values.subCategory.length === 0) &&
        (values.tags === "" || values.tags.length === 0) &&
        (values.branch === "" || values.branch.length === 0) &&
        values.name === "" &&
        values.status === "" &&
        values.is_delete === "" &&
        values.brand === ""
      ) {
        toast.error("Please add filter!");
        return;
      }

      const requestBody = {
        p_skip: 0,
        p_limit: 10,
      };
      if (values.category !== "" && values.category !== null) {
        requestBody.p_category_id = values.category
          ? parseInt(values.category)
          : "";
      }
      if (
        values.subCategory !== "" &&
        values.subCategory !== null &&
        values.subCategory.length > 0
      ) {
        requestBody.p_sub_category_id =
          values.subCategory.length > 0 ? values.subCategory.join(",") : "";
      }
      if (
        values.tags !== "" &&
        values.tags !== null &&
        values.tags.length > 0
      ) {
        requestBody.p_tag_id =
          values.tags.length > 0 ? values.tags.join(",") : "";
      }
      if (
        values.branch !== "" &&
        values.branch !== null &&
        values.branch.length > 0
      ) {
        requestBody.p_branch_id =
          values.branch.length > 0 ? values.branch.join(",") : "";
      }
      if (values.name !== "" && values.name !== null) {
        requestBody.p_name = values.name;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_is_active = values.status;
      } else {
        requestBody.p_is_active = "1";
      }
      if (values.is_delete !== "" && values.is_delete !== null) {
        requestBody.p_IsDeleted = values.is_delete;
      }
      if (values.brand !== "" && values.brand !== null) {
        requestBody.p_brand_id = values.brand ? parseInt(values.brand) : "";
      }
      dispatch(
        getServiceFilterData({
          name: values.name,
          category: values.category ? parseInt(values.category) : "",
          subCategory:
            values.subCategory.length > 0 ? values.subCategory.join(",") : "",
          tag: values.tags.length > 0 ? values.tags.join(",") : "",
          branch: values.branch.length > 0 ? values.branch.join(",") : "",
          brand: values.brand ? parseInt(values.brand) : "",
          isActive: values.status,
          isDelete: values.is_delete,
        })
      );

      dispatch(clearServicePageData());
      getAllService(requestBody, token, dispatch, "filter");
    } else if (props.page === "order") {
      if (
        (values.orderDate === "" || values.orderDate === null) &&
        (values.status === "" || values.status === null) &&
        (values.orderType === "" || values.orderType === null)
      ) {
        toast.error("Please add filter!");
        return;
      }

      const requestBody = {
        p_skip: 0,
        p_take: 1000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (values.orderDate !== "" && values.orderDate !== null) {
        const date = new Date(values.orderDate);

        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }
      if (values.status !== "" && values.status !== null) {
        requestBody.p_status = values.status;
      }
      if (values.orderType !== "" && values.orderType !== null) {
        requestBody.p_type = values.orderType;
      }

      const date = new Date(values.orderDate);

      // Formatting the date and time parts
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");

      // Combining them into the desired format
      const dateTimeFormat =
        values.orderDate !== "" && values.orderDate !== null
          ? `${year}-${month}-${day}`
          : "";

      dispatch(
        getOrderFilterData({
          orderDate: dateTimeFormat,
          status: values.status,
          orderType: values.orderType,
        })
      );
      dispatch(clearOrderPageData());
      getAllOrderService(requestBody, token, dispatch, "filter");
    } else {
    }
  };

  const handleTypeChange = (option) => {
    if (option && categoryListOptions && categoryListOptions.length > 0) {
      const categoryOptions = categoryListOptions.filter(
        (item) => item.type === option.value
      );
      setCategoryNewOptionsData(categoryOptions);
    }
  };

  /**
   * Setting sub-category and leaf level category data on category option select
   * @param {*} option
   * @param {*} data
   * @param {*} setFieldValue
   */
  const getFilterOptionData = (option, data, setFieldValue) => {
    if (data === "category") {
      if (
        subCategoryListData.items &&
        subCategoryListData.items[0]?.data &&
        subCategoryListData.items[0].data.length > 0
      ) {
        const filteredArr2 = subCategoryListData.items[0].data.filter(
          (item) => item.category_id === option.value
        );
        const subCategoryOptionData = filteredArr2.map((item) => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active,
        }));
        setSubCategoryListOptions(subCategoryOptionData);
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue("brandSubCategory", []);
          setLeafCategoryListOptions([]);
        }
        setFieldValue("brandLeafCategory", []);
      }
    } else if (data === "subCategory") {
      if (totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0) {
        const filterLeafData = totalLeaf.items[0].data.filter(
          (item) => item.SubcategoryId === option.value
        );
        const leafOptions = filterLeafData.map((item) => ({
          value: item.id,
          label: item.Title,
          is_active: item.is_active,
        }));
        setLeafCategoryListOptions(leafOptions);
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue("brandLeafCategory", []);
        }
      }
    }
  };

  return (
    <>
      <div className="">
        <div className="table-filter">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div>
                  {props.page === "roles" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Designation Name"
                            className="add-roles"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* changes by sakshi */}
                  {props.page === "company" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="company_name"
                            placeholder="Company Name"
                            className="add-roles"
                            value={values.company_name}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="pincode"
                            placeholder="Pincode"
                            className="add-roles"
                            value={values.pincode}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="company_address"
                            placeholder="Company Address"
                            className="add-roles"
                            value={values.company_address}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "brand" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Department Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={typeOptions}
                            component={CustomSelect}
                            value={values.type}
                            onchangedata={(option) => handleTypeChange(option)}
                            placeholder="Type..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryNewOptionsData}
                            component={CustomSelect}
                            value={values.category}
                            onchangedata={(option) =>
                              getFilterOptionData(
                                option,
                                "category",
                                setFieldValue
                              )
                            }
                            placeholder="category..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            value={values.subCategory}
                            onchangedata={(option) =>
                              getFilterOptionData(
                                option,
                                "subCategory",
                                setFieldValue
                              )
                            }
                            placeholder="sub category..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="leafCategory"
                            options={leafCategoryListOptions}
                            component={CustomSelect}
                            value={values.leafCategory}
                            placeholder="leaf level category..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tags"
                            options={tagListOptions}
                            component={CustomSelect}
                            value={values.tags}
                            placeholder="tags..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "customer" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="phone"
                            placeholder="Filter by mobile"
                            className="add-roles"
                            Regex={/[^0-9\s]/g}
                            maxLength={10}
                            hidelength={"true"}
                            value={values.phone}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "category" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Category name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder="Category code"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "branch" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Branch name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="pincode"
                            placeholder="Pincode"
                            className="add-roles"
                            Regex={/[^0-9]/g}
                            maxLength={6}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_public_avilable"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_public_avilable}
                            placeholder="Is Public available..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_wp_sms_allow"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_wp_sms_allow}
                            placeholder="Is WP SMS available..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delivery_available"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delivery_available}
                            placeholder="Is delivery available..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tax_type"
                            options={taxTypeOption}
                            component={CustomSelect}
                            value={values.tax_type}
                            placeholder="Tax type"
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "subCategory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder="Code"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={typeOptions}
                            component={CustomSelect}
                            value={values.type}
                            onchangedata={(option) => handleTypeChange(option)}
                            placeholder="Type..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryNewOptionsData}
                            component={CustomSelect}
                            value={values.category}
                            placeholder="Category..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "product" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Product name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryListOptions}
                            component={CustomSelect}
                            value={values.category}
                            placeholder="Category..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            value={values.subCategory}
                            placeholder="Sub category..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="leafCategory"
                            options={leafCategoryListOptions}
                            component={CustomSelect}
                            value={values.leafCategory}
                            placeholder="Leaf level category..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tags"
                            options={tagListOptions}
                            component={CustomSelect}
                            value={values.tags}
                            placeholder="Tags..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="sku"
                            placeholder="SKU"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="brand"
                            options={brandListOptions}
                            component={CustomSelect}
                            value={values.brand}
                            placeholder="Brand..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "product-pricing" && (
                    <>
                      <div className="col-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Product name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="sku"
                            placeholder="SKU"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "imageManager" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={imageTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder="Image type..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "order" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderDate}
                                  onChange={(date) => {
                                    setFieldValue("orderDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={orderStatusOptions}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Filter By Status..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="orderType"
                            options={orderTypeOptions}
                            component={CustomSelect}
                            value={values.orderType}
                            placeholder="Filter By Order Type..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "leafCategory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            value={values.subCategory}
                            placeholder="Filter sub category..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {(props.page === "tags" ||
                    props.page === "attributes" ||
                    props.page === "warehouse" ||
                    props.page === "serviceProvider") && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "users" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="phone"
                            placeholder="Mobile number"
                            className="add-roles"
                            maxLength={10}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "policy" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Policy name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={categoryOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder="Policy type..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "packager" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Filter by name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={packagerTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder="Filter by type..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "discount" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Discount name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={discountTypeOptions}
                            component={CustomSelect}
                            value={values.type}
                            placeholder="Discount type..."
                            isMulti={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.start_date}
                                  onChange={(date) => {
                                    // Check if the selected start date is greater than the current end date
                                    if (date > values.end_date) {
                                      // If the start date is greater than the end date, set the end date to null
                                      setFieldValue("end_date", null);
                                    }
                                    // Update the start date value
                                    setFieldValue("start_date", date);
                                  }}
                                  selectsStart
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText="Start Date"
                                  showIcon
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                              <div className="col-12">
                                <label className="text-center w-100">To</label>
                              </div>
                              <div className="col-12 mb-3 datepicker-width">
                                <DatePicker
                                  selected={values.end_date}
                                  onChange={(date) =>
                                    setFieldValue("end_date", date)
                                  }
                                  selectsEnd
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText="End Date"
                                  showIcon
                                  minDate={values.start_date}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder="Discount code"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "discountCatalogue" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Discount name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-5 datepicker-width">
                                <DatePicker
                                  selected={values.start_date}
                                  onChange={(date) => {
                                    // Check if the selected start date is greater than the current end date
                                    if (date > values.end_date) {
                                      // If the start date is greater than the end date, set the end date to null
                                      setFieldValue("end_date", null);
                                    }
                                    // Update the start date value
                                    setFieldValue("start_date", date);
                                  }}
                                  selectsStart
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText="Start Date"
                                  showIcon
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                              <div className="col-2">
                                <label className="text-center w-100">To</label>
                              </div>
                              <div className="col-5 datepicker-width">
                                <DatePicker
                                  selected={values.end_date}
                                  onChange={(date) =>
                                    setFieldValue("end_date", date)
                                  }
                                  selectsEnd
                                  startDate={values.start_date}
                                  endDate={values.end_date}
                                  placeholderText="End Date"
                                  showIcon
                                  minDate={values.start_date}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "inventory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="code"
                            placeholder="Product code"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Product Name"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="sku"
                            placeholder="SKU Code"
                            className="add-roles"
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="warehouse"
                            options={warehouseOptions}
                            component={CustomSelect}
                            placeholder="Filter warehouse..."
                            isMulti={false}
                            value={values.warehouse}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "service" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="tags"
                            options={tagListOptions}
                            component={CustomSelect}
                            placeholder="Filter tags..."
                            isMulti={true}
                            value={values.tags}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="brand"
                            options={brandListOptions}
                            component={CustomSelect}
                            placeholder="Filter brand..."
                            isMulti={false}
                            value={values.brand}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="branch"
                            options={branchOptions}
                            component={CustomSelect}
                            placeholder="Filter branch..."
                            isMulti={true}
                            value={values.branch}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="category"
                            options={categoryListOptions}
                            component={CustomSelect}
                            placeholder="Filter category..."
                            isMulti={false}
                            value={values.category}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="subCategory"
                            options={subCategoryListOptions}
                            component={CustomSelect}
                            placeholder="Filter sub category..."
                            isMulti={true}
                            value={values.subCategory}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_delete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_delete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "spots" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            // maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_available"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_available}
                            placeholder="Is Available..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "inventory-item" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="add-roles"
                            Regex={/[^a-zA-Z\s]/g}
                            // maxLength={70}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {props.page === "product-inventory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="productId"
                            options={productOptions}
                            component={CustomSelect}
                            value={values.productId}
                            placeholder="Product..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                    </>
                  )}
                  {(props.page === "stocks" || props.page === "stock") && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="ingredientId"
                            options={inventoryItemOptions}
                            component={CustomSelect}
                            value={values.ingredientId}
                            placeholder="Ingredient..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_active"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_active}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="is_deleted"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.is_deleted}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                    </>
                  )}
                  {props.page === "vendors" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText="Filter By Order End Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="vendor"
                            options={vendorPersonListOptions}
                            component={CustomSelect}
                            value={values.vendor}
                            placeholder="Select Vendor..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={vendorOrderStatusOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Filter By Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="payment"
                            options={paymentTypeOption}
                            component={CustomSelect}
                            value={values.payment}
                            placeholder="Filter By Payment..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="orderNo"
                            placeholder="Order No"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.orderNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "purchase-requisition" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText="Filter By Order End Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="vendor"
                            options={vendorPersonListOptions}
                            component={CustomSelect}
                            value={values.vendor}
                            placeholder="Select Vendor..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="status"
                            options={purchaseRequisitionStatusOption}
                            component={CustomSelect}
                            value={values.status}
                            placeholder="Filter By Status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="purchaseRequisitionNo"
                            placeholder="Purchase Requisition No"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.purchaseRequisitionNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "wallet" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.startDate}
                                  onChange={(date) => {
                                    setFieldValue("startDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="startDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.endDate}
                                  onChange={(date) => {
                                    setFieldValue("endDate", date);
                                  }}
                                  placeholderText="Filter By Order End Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="endDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="type"
                            options={walletOption}
                            component={CustomSelect}
                            value={values.type}
                            placeholder="Filter By Status..."
                            isMulti={false}
                          />
                        </div>
                      </div> */}
                    </>
                  )}

                  {props.page === "grn" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText="Filter By Order End Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="GRNNo"
                            placeholder="Goods Receive Note No"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.GRNNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "purchase-order" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderStartDate}
                                  onChange={(date) => {
                                    setFieldValue("orderStartDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderStartDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderEndDate}
                                  onChange={(date) => {
                                    setFieldValue("orderEndDate", date);
                                  }}
                                  placeholderText="Filter By Order End Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderEndDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="purchaseOrderNo"
                            placeholder="Purchase Order No"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.purchaseOrderNo}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "new-vendor-person" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.firstName}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            // maxLength={70}
                            value={values.lastName}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="text"
                            name="phNum"
                            placeholder="Mobile No"
                            className="add-roles"
                            value={values.phNum}
                            Regex={/[^0-9\s]/g}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="categoryType"
                            options={categoryListOptions}
                            component={CustomSelect}
                            value={values.category}
                            placeholder="Category..."
                            isMulti={true}
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isActive"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isActive}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isDelete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isDelete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "units" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Input
                            type="unit"
                            name="firstName"
                            placeholder="First Name"
                            className="add-roles"
                            // Regex={/[^a-zA-Z\s]/g}
                            maxLength={70}
                            value={values.unit}
                            hidelength={"true"}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isActive"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isActive}
                            placeholder="status..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="isDelete"
                            options={activeDeleteOption}
                            component={CustomSelect}
                            value={values.isDelete}
                            placeholder="Is Delete..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page === "StockHistory" && (
                    <>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <div className="date-picker">
                            <div className="row">
                              <div className="col-12 datepicker-width">
                                <DatePicker
                                  selected={values.orderDate}
                                  onChange={(date) => {
                                    setFieldValue("orderDate", date);
                                  }}
                                  placeholderText="Filter By Date"
                                  showIcon
                                  maxDate={new Date()}
                                />
                                <ErrorMessage
                                  name="orderDate"
                                  component={ErrorComp}
                                ></ErrorMessage>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="paragraph-editor">
                          <Field
                            className="select-menu mb-2"
                            name="ingredientId"
                            options={inventoryItemOptions}
                            component={CustomSelect}
                            value={values.ingredientId}
                            placeholder="Ingredient..."
                            isMulti={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {props.page !== "customer" &&
                    props.page !== "brand" &&
                    props.page !== "category" &&
                    props.page !== "subCategory" &&
                    props.page !== "product" &&
                    props.page !== "imageManager" &&
                    props.page !== "leafCategory" &&
                    props.page !== "tags" &&
                    props.page !== "users" &&
                    props.page !== "policy" &&
                    props.page !== "roles" &&
                    props.page !== "attributes" &&
                    props.page !== "warehouse" &&
                    props.page !== "packager" &&
                    props.page !== "discount" &&
                    props.page !== "discountCatalogue" &&
                    props.page !== "inventory" &&
                    props.page !== "branch" &&
                    props.page !== "serviceProvider" &&
                    props.page !== "service" &&
                    props.page !== "order" &&
                    props.page !== "customer" &&
                    props.page !== "company" &&
                    props.page !== "spots" &&
                    props.page !== "inventory-item" &&
                    props.page !== "product-inventory" &&
                    props.page !== "vendors" &&
                    props.page !== "stocks" &&
                    props.page !== "StockHistory" &&
                    props.page !== "new-vendor-person" &&
                    props.page !== "units" &&
                    props.page !== "purchase-requisition" &&
                    props.page !== "purchase-order" &&
                    props.page !== "grn" &&
                    props.page !== "wallet" &&
                    props.page !== "product-pricing" && (
                      <>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="brandCategory"
                              options={categoryOptions}
                              placeholder="Name"
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="Status"
                              options={categoryOptions}
                              placeholder="Email"
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="brandCategory"
                              options={categoryOptions}
                              placeholder="Code"
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="paragraph-editor">
                            <Select
                              name="brandCategory"
                              options={categoryOptions}
                              placeholder="Brand Name"
                              isMulti={false}
                            ></Select>
                          </div>
                        </div>
                      </>
                    )}
                  <div className="col-12 mt-3">
                    <div className="btn-submit" 
                    // onClick={handleClose}
                    >
                      <Button
                        type="submit"
                        name="Search"
                        className="btn login-btn"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}