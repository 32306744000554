import { toast } from "react-toastify";
import {
  API_URL,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { getApiService } from "../../../services/API-services/GetService";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import {
  getAdminUserListRequest,
  getAdminUserResponse,
} from "../../AdminUsers/User-service/UserModalResponse";
import { Dispatch } from "redux";
import {
  getCustomerData,
  getTotalCustomerData,
} from "../../../redux/Actions/CustomerAction/CustomerAction";
import { getCustomerOrdersRequest } from "./CustomerServiceModal";
import {
  clearCustomerOrderData,
  getCustomerOrderData,
  getTotalCustomerOrderData,
} from "../../../redux/Actions/CustomerAction/CustomerOrderAction";
import { formatDateToUTC } from "../../../services/Regular-services/DateService";

let errorShow = false;

/**
 * Get customer list service
 * @param customerListRequest
 * @param token
 * @param dispatch
 * @param total
 * @returns
 */
export const getCustomerListService = (
  customerListRequest: getAdminUserListRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
) => {
  return new Promise<getAdminUserResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        customerListRequest.skip !== undefined &&
        customerListRequest.skip !== null
      ) {
        queryParams += `skip=${customerListRequest.skip}&`;
      }
      if (
        customerListRequest.take !== undefined &&
        customerListRequest.take !== null
      ) {
        queryParams += `take=${customerListRequest.take}&`;
      }
      if (
        customerListRequest.searchUsername !== undefined &&
        customerListRequest.searchUsername !== null
      ) {
        queryParams += `searchUsername=${customerListRequest.searchUsername}&`;
      }
      if (
        customerListRequest.search !== undefined &&
        customerListRequest.search !== null
      ) {
        queryParams += `search=${customerListRequest.search}&`;
      }
      if (
        customerListRequest.search_username !== undefined &&
        customerListRequest.search_username !== null
      ) {
        queryParams += `search_username=${customerListRequest.search_username}&`;
      }
      if (
        customerListRequest.search_phone_number !== undefined &&
        customerListRequest.search_phone_number !== null
      ) {
        queryParams += `search_phone_number=${customerListRequest.search_phone_number}&`;
      }
      if (
        customerListRequest.search_first_name !== undefined &&
        customerListRequest.search_first_name !== null
      ) {
        queryParams += `search_first_name=${customerListRequest.search_first_name}&`;
      }
      if (
        customerListRequest.search_last_name !== undefined &&
        customerListRequest.search_last_name !== null
      ) {
        queryParams += `search_last_name=${customerListRequest.search_last_name}&`;
      }
      if (
        customerListRequest.search_email !== undefined &&
        customerListRequest.search_email !== null
      ) {
        queryParams += `search_email=${customerListRequest.search_email}&`;
      }
      if (
        customerListRequest.search_age !== undefined &&
        customerListRequest.search_age !== null
      ) {
        queryParams += `search_age=${customerListRequest.search_age}&`;
      }
      if (
        customerListRequest.search_is_active !== undefined &&
        customerListRequest.search_is_active !== null
      ) {
        queryParams += `search_is_active=${customerListRequest.search_is_active}&`;
      }
      if (
        customerListRequest.p_IsDeleted !== undefined &&
        customerListRequest.p_IsDeleted !== null
      ) {
        queryParams += `p_IsDeleted=${customerListRequest.p_IsDeleted}&`;
      }
      if (
        customerListRequest.search_dob !== undefined &&
        customerListRequest.search_dob !== null
      ) {
        queryParams += `search_dob=${customerListRequest.search_dob}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.CUSTOMER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalCustomerData(response));
        }
        if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getCustomerData(response));
        } else {
          dispatch(getCustomerData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Customer permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service to get Packager and Manufacturer list
 */
export const getCustomersOrderService = (
  customerOrdersRequest: getCustomerOrdersRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        customerOrdersRequest.p_skip !== undefined &&
        customerOrdersRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${customerOrdersRequest.p_skip}&`;
      }
      if (
        customerOrdersRequest.p_take !== undefined &&
        customerOrdersRequest.p_take !== null
      ) {
        queryParams += `p_take=${customerOrdersRequest.p_take}&`;
      }
      if (
        customerOrdersRequest.p_order_no !== undefined &&
        customerOrdersRequest.p_order_no !== null
      ) {
        queryParams += `p_order_no=${customerOrdersRequest.p_order_no}&`;
      }
      if (
        customerOrdersRequest.p_created_date !== undefined &&
        customerOrdersRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${customerOrdersRequest.p_created_date}&`;
      }
      if (
        customerOrdersRequest.p_created_time !== undefined &&
        customerOrdersRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${customerOrdersRequest.p_created_time}&`;
      }
      if (
        customerOrdersRequest.p_status !== undefined &&
        customerOrdersRequest.p_status !== null
      ) {
        queryParams += `p_status=${customerOrdersRequest.p_status}&`;
      }
      if (
        customerOrdersRequest.p_type !== undefined &&
        customerOrdersRequest.p_type !== null
      ) {
        queryParams += `p_type=${customerOrdersRequest.p_type}&`;
      }
      if (
        customerOrdersRequest.p_time_zone !== undefined &&
        customerOrdersRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${customerOrdersRequest.p_time_zone}&`;
      }
      if (
        customerOrdersRequest.p_user_id !== undefined &&
        customerOrdersRequest.p_user_id !== null
      ) {
        queryParams += `p_user_id=${customerOrdersRequest.p_user_id}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.ORDER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      // dispatch(clearCustomerOrderData())

      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalCustomerOrderData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(
            getCustomerOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: null,
            })
          );
        } else {
          if (response.data && response.data.length > 0) {
            const pendingCount = response.data.filter(
              (item: any) => item.status === "0"
            ).length;
            dispatch(
              getCustomerOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: pendingCount,
              })
            );
          } else {
            dispatch(
              getCustomerOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: 0,
              })
            );
          }
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};
