const purchaseRequisitionPageInitialState = {
  items: 1,
};

/**
 * Store PURCHASE REQUISITION page data
 * @param {number} state
 * @param {*} action
 * @returns {number}
 */
export const PurchaseRequisitionPageReducer = (
  state = purchaseRequisitionPageInitialState,
  action
) => {
  switch (action.type) {
    case "GET_PURCHASE_REQUISITION_PAGE":
      return {
        ...state,
        items: action.payload,
      };
    case "CLEAR_PURCHASE_REQUISITION_PAGE":
      return purchaseRequisitionPageInitialState;
    default:
      return state;
  }
};
