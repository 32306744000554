import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Button from "../../components/Button/Button";
import CustomTable from "../../components/Table/Table";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import "../BrandList/BrandList.scss";
import Toggle from "../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/Filter/Filter";
import "../../components/Filter/Filter.scss";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import {
  getAllBrandService,
  uploadBrandExcelService,
} from "./BrandService/BrandService";
import UtilsData from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearBrandPageData,
  getBrandPageData,
} from "../../redux/Actions/brandAction/BrandPageAction";
import { clearBrandData } from "../../redux/Actions/brandAction/BrandActions";
import { clearBrandFilterData } from "../../redux/Actions/brandAction/BrandFilterAction";
import FileUploadModal from "../../components/Import-component/ImportComponent";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  description: true,
};

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

export default function BrandList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const brandPage = useSelector((state) => state.brandPage?.items);
  const brandFilter = useSelector((state) => state.brandFilter);
  const tokenData = UtilsData();
  const [currentPage, setCurrentPage] = useState(1);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [brandActionData, setbrandActionData] = useState([]);

  const [columns, setColumns] = useState([]);

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setbrandActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      brandActionData[0]?.view &&
      brandActionData[0].view === "0" &&
      brandActionData[0]?.edit &&
      brandActionData[0].edit === "0" &&
      brandActionData[0]?.add &&
      brandActionData[0].add === "0" &&
      brandActionData[0]?.delete &&
      brandActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Department name",
          selector: (row) => row.brandname,
        },
        {
          name: "Description ",
          selector: (row) => row.description,
        },
        {
          name: "Status",
          selector: (row) => row.active,
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Department name",
          selector: (row) => row.brandname,
        },
        {
          name: "Description ",
          selector: (row) => row.description,
        },
        {
          name: "Status",
          selector: (row) => row.active,
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          selector: (row) => row.edit,
        },
      ]);
    }
  }, [brandActionData]);

  /**
   * Show actions which are to be displayed in table
   */
  const showActions = {
    showView:
      (brandActionData[0]?.view && brandActionData[0].view === "1") ||
      !brandActionData
        ? true
        : false,
    showEdit:
      (brandActionData[0]?.edit && brandActionData[0].edit === "1") ||
      !brandActionData
        ? true
        : false,
    showDelete:
      (brandActionData[0]?.delete && brandActionData[0].delete === "1") ||
      !brandActionData
        ? true
        : false,
    showUndo:
      (brandActionData[0]?.edit && brandActionData[0].edit === "1") ||
      !brandActionData
        ? true
        : false,
    navigate:
      (brandActionData[0]?.edit && brandActionData[0].edit === "1") ||
      !brandActionData
        ? "/edit-brand"
        : false,
  };

  useEffect(() => {
    setCurrentPage(brandPage);
  }, [brandPage]);

  /**
   * Get data from reducer
   */
  const brandData = useSelector((state) => state.brand);

  const [isToggled, setIsToggled] = useState(false);

  /**
   * Fetching brand data from API
   */
  const fetchBrand = () => {
    if (brandPage > 1) {
      const requestBody = {
        p_skip: (brandPage - 2) * 10,
        p_limit: 10,
      };
      if (brandFilter.name !== "" && brandFilter.name !== null) {
        requestBody.p_name = brandFilter.name;
      }
      if (brandFilter.category !== "" && brandFilter.category !== null) {
        requestBody.p_category_id = brandFilter.category;
      }
      if (brandFilter.subCategory !== "" && brandFilter.subCategory !== null) {
        requestBody.p_sub_category_id = brandFilter.subCategory;
      }
      if (
        brandFilter.leafCategory !== "" &&
        brandFilter.leafCategory !== null
      ) {
        requestBody.p_leaf_level_category_id = brandFilter.leafCategory;
      }
      if (brandFilter.tags && brandFilter.tags.length > 0) {
        requestBody.p_tag_id = brandFilter.tags.join(",");
      }
      if (brandFilter.type !== "" && brandFilter.type !== null) {
        requestBody.p_type = brandFilter.type;
      }
      if (brandFilter.isActive !== "" && brandFilter.isActive !== null) {
        requestBody.p_is_active = brandFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (brandFilter.isDelete !== "" && brandFilter.isDelete !== null) {
        requestBody.p_IsDeleted = brandFilter.isDelete;
      }
      getAllBrandService(requestBody, tokenData, dispatch);
      dispatch(getBrandPageData(brandPage - 1));
    } else {
      const requestBody = {
        p_skip: (brandPage - 1) * 10,
        p_limit: 10,
      };
      if (brandFilter.name !== "" && brandFilter.name !== null) {
        requestBody.p_name = brandFilter.name;
      }
      if (brandFilter.category !== "" && brandFilter.category !== null) {
        requestBody.p_category_id = brandFilter.category;
      }
      if (brandFilter.subCategory !== "" && brandFilter.subCategory !== null) {
        requestBody.p_sub_category_id = brandFilter.subCategory;
      }
      if (
        brandFilter.leafCategory !== "" &&
        brandFilter.leafCategory !== null
      ) {
        requestBody.p_leaf_level_category_id = brandFilter.leafCategory;
      }
      if (brandFilter.tags && brandFilter.tags.length > 0) {
        requestBody.p_tag_id = brandFilter.tags.join(",");
      }

      if (brandFilter.isActive !== "" && brandFilter.isActive !== null) {
        requestBody.p_is_active = brandFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (brandFilter.isDelete !== "" && brandFilter.isDelete !== null) {
        requestBody.p_IsDeleted = brandFilter.isDelete;
      }
      getAllBrandService(requestBody, tokenData, dispatch);
      dispatch(getBrandPageData(brandPage));
    }
  };

  useEffect(() => {
    if (brandData && brandData.items.length === 0) {
      fetchBrand();
    } else {
      if (
        brandFilter.name !== "" ||
        brandFilter.category !== "" ||
        brandFilter.subCategory !== "" ||
        brandFilter.leafCategory !== "" ||
        (brandFilter.tags && brandFilter.tags.length > 0) ||
        brandFilter.isActive !== "" ||
        brandFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [brandData, brandFilter]);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Function for changing page
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (brandFilter.name !== "" && brandFilter.name !== null) {
        requestBody.p_name = brandFilter.name;
      }
      if (brandFilter.category !== "" && brandFilter.category !== null) {
        requestBody.p_category_id = brandFilter.category;
      }
      if (brandFilter.subCategory !== "" && brandFilter.subCategory !== null) {
        requestBody.p_sub_category_id = brandFilter.subCategory;
      }
      if (
        brandFilter.leafCategory !== "" &&
        brandFilter.leafCategory !== null
      ) {
        requestBody.p_leaf_level_category_id = brandFilter.leafCategory;
      }
      if (brandFilter.tags && brandFilter.tags.length > 0) {
        requestBody.p_tag_id = brandFilter.tags.join(",");
      }

      if (brandFilter.isActive !== "" && brandFilter.isActive !== null) {
        requestBody.p_is_active = brandFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (brandFilter.isDelete !== "" && brandFilter.isDelete !== null) {
        requestBody.p_IsDeleted = brandFilter.isDelete;
      }
      getAllBrandService(requestBody, tokenData, dispatch);
      setCurrentPage(pageNumber);
      dispatch(getBrandPageData(pageNumber));
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Import modal open
   */
  // const handleImportClick = () => {
  //   // Open the modal
  //   setIsModalOpen(true);
  // };

  /**
   * Import modal close
   */
  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  /**
   * Handle file upload
   * @param {*} file
   */
  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(uploadBrandExcelService(file, tokenData, setIsModalOpen));
  };

  /**
   * Function for clearing filters
   */
  const clearFilters = () => {
    dispatch(clearBrandData());
    dispatch(clearBrandFilterData());
    dispatch(clearBrandPageData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      {/* <div className="sidebar-content">
        <Sidebar />
      </div> */}
      {/* <div className="body-content">
        <Header /> */}
      <div className="table-body-content">
        <div className="roles">
          <div>
            <div className="title">
              <h4>
                {brandActionData && brandActionData.length > 0
                  ? brandActionData[0].name
                  : "Brand"}
              </h4>
            </div>
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate("/home")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {brandActionData && brandActionData.length > 0
                  ? brandActionData[0].name
                  : "Brand"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="btn-groups">
            {brandFilter.name !== "" ||
            brandFilter.category !== "" ||
            brandFilter.subCategory !== "" ||
            brandFilter.leafCategory !== "" ||
            (brandFilter.tags && brandFilter.tags.length > 0) ||
            brandFilter.isActive !== "" ||
            brandFilter.isDelete !== "" ? (
              <Dropdown className="header-dropdown action-btn">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  data-bs-toggle="tooltip"
                  title="Action"
                >
                  <GrMoreVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {(brandFilter.name !== "" ||
                    brandFilter.category !== "" ||
                    brandFilter.subCategory !== "" ||
                    brandFilter.leafCategory !== "" ||
                    (brandFilter.tags && brandFilter.tags.length > 0) ||
                    brandFilter.isActive !== "" ||
                    brandFilter.isDelete !== "") && (
                    <Dropdown.Item onClick={clearFilters}>
                      Clear Filter
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <></>
            )}

            {/* {((brandActionData[0]?.add && brandActionData[0].add === '1') || (!brandActionData)) && (
                <Dropdown className='header-dropdown action-btn' >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                  <Dropdown className='header-dropdown action-btn' >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                    <TfiImport />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleImportClick}>Import</Dropdown.Item>
                    <DownloadExcel excelFile={sampleBrandExcelFile} fileName="Brand-Import.xlsx" />
                  </Dropdown.Menu>
                </Dropdown>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleImportClick}>Import</Dropdown.Item>
                    <DownloadExcel excelFile={sampleBrandExcelFile} fileName="Brand-Import.xlsx" />
                  </Dropdown.Menu>
                </Dropdown>
              )} */}

            <FileUploadModal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              onFileUpload={handleFileUpload}
              isClose={isModalOpen}
            />

            <Button
              onClick={handleShow}
              data-bs-toggle="tooltip"
              title="Filter"
              // onClick={handleButtonClick}
              className="btn btn-fillter-main"
              name={
                <div className="filter">
                  <FaFilter className="filter-icon" />
                </div>
              }
            ></Button>

            <Offcanvas show={show} onHide={handleClose} placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filter Options</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Filter page="brand" handleClose={handleClose}></Filter>
              </Offcanvas.Body>
            </Offcanvas>
            {brandData &&
              brandData.items &&
              brandData.items[0]?.data &&
              brandData.items[0].data.length > 0 && (
                <ExcelData
                  excelData={brandData.items[0].data}
                  fileName={"brand"}
                />
              )}
            {((brandActionData[0]?.add && brandActionData[0].add === "1") ||
              !brandActionData) && (
              <Button
                onClick={() => navigate("/add-brand")}
                className="btn btn-fillter-main"
                name={
                  <div className="Plus">
                    <FaPlus />
                  </div>
                }
              ></Button>
            )}
          </div>
        </div>
        {/* {isToggled && <Filter page="brand"></Filter>} */}
        {brandData &&
          brandData.items &&
          brandData.items[0]?.data &&
          brandData.items[0].data && (
            <>
              <CustomTable
                page="brand"
                showDescription={true}
                showToggle={showToggle}
                showKey={showKey}
                showActions={showActionBool ? showActions : false}
                showTooltip={true}
                checkBool={false}
                data={brandData.items[0].data}
                heading={columns}
                disabled={
                  brandActionData &&
                  brandActionData[0]?.edit &&
                  brandActionData[0].edit === "0"
                }
              />

              {brandData.items[0].total_records >
                brandData.items[0].data.length &&
                brandData.items[0].total_records > 10 && (
                  <CustomPagination
                    totalRecords={brandData.items[0].total_records}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
            </>
          )}
      </div>
      {/* <Footer /> */}
    </div>
    // </div>
  );
}
