/**
 * Setting account Product Config list data into reducer
 * @param {*} accountProductConfig
 * @returns {*}
 */
export const accountProductConfigData = (accountProductConfig) => ({
  type: "GET_ACC_PRODUCT_CONFIG_DATA",
  payload: accountProductConfig,
});

/**
 * clearing account Product Config list data
 * @returns {*}
 */
export const clearAccountProductConfigData = () => ({
  type: "CLEAR_ACC_PRODUCT_CONFIG_DATA",
});
