import { Dispatch } from "redux";
import { getAllStockRequest, getAllStockResponse } from "./StocksModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import {
  getStockData,
  getTotalStockData,
} from "../../../redux/Actions/StocksAction/StockAction";
import { toast } from "react-toastify";

let errorShow = false;

/*
 * Service to get stock
 */
export const getAllStocksService = (
  StockRequest: getAllStockRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllStockResponse> => {
  return new Promise<getAllStockResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (StockRequest.p_skip !== undefined && StockRequest.p_skip !== null) {
        queryParams += `p_skip=${StockRequest.p_skip}&`;
      }
      if (StockRequest.p_take !== undefined && StockRequest.p_take !== null) {
        queryParams += `p_take=${StockRequest.p_take}&`;
      }
      if (
        StockRequest.p_ingredient_id !== undefined &&
        StockRequest.p_ingredient_id !== null
      ) {
        queryParams += `p_ingredient_id=${StockRequest.p_ingredient_id}&`;
      }

      // if (brandListRequest.p_is_active !== undefined && brandListRequest.p_is_active !== null) {
      //   queryParams += `p_is_active=${brandListRequest.p_is_active}&`
      // }
      // if (brandListRequest.p_IsDeleted !== undefined && brandListRequest.p_IsDeleted !== null) {
      //   queryParams += `p_IsDeleted=${brandListRequest.p_IsDeleted}&`
      // }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.STOCKS +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalStockData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getStockData(response));
        } else {
          dispatch(getStockData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Stock permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};
