const spotInitialState = {
    items: []
};

/**
 * Store spot list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */

export const spotReducer = (state = spotInitialState, action) => {
    switch (action.type) {
        case 'GET_SPOT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_SPOT_DATA':
            return spotInitialState;
        default:
            return state;
    };
}

const totalSpotInitialState = {
    items: []
}

/**
 * Store total spot list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalSpotReducer = (state = totalSpotInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_SPOT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_SPOT_DATA':
            return totalSpotInitialState;
        default:
            return state;
    };
}