import React from "react";
import "../WalletWarning/WalletWarning.scss";

const WalletWarning = ({ props }) => {
  return (
    <div className="warning">
      <span>
        Your wallet balance is low. Please recharge to avoid WhatsApp messaging
        service interruption.
      </span>
    </div>
  );
};

export default WalletWarning;
