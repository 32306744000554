const vendorPageInitialState = {
    items: 1
}

/**
 * Store vendor page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const VendorPageReducer = (state = vendorPageInitialState, action) => {
    switch (action.type) {
        case 'GET_VENDOR_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_VENDOR_PAGE':
            return vendorPageInitialState;
        default:
            return state;
      };
}