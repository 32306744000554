import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import {
  clearServicePageData,
  getServicePageData,
} from "../../redux/Actions/ServiceAction/ServicePageAction";
import UtilsData from "../../Utils/Utils";
import {
  getAllService,
  uploadServiceExcelService,
} from "./ServiceService/ServiceService";
import CustomPagination from "../../components/Pagination/Pagination";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { clearServiceFilterData } from "../../redux/Actions/ServiceAction/ServiceFilterAction";
import {
  clearServiceData,
  clearTotalServiceData,
} from "../../redux/Actions/ServiceAction/ServiceAction";
import FileUploadModal from "../../components/Import-component/ImportComponent";

const showToggle = {
  status: true,
  delete: false,
};

/**
 * Show keys which are to be displayed in table
 */
const showKey = {
  name: true,
  brandName: true,
  description: true,
};

export default function Service() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();

  const service = useSelector((state) => state.service);
  const serviceFilter = useSelector((state) => state.serviceFilter);
  const servicePage = useSelector((state) => state.servicePage?.items);

  const [currentPage, setCurrentPage] = useState(1);
  const [serviceData, setServiceData] = useState([]);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [serviceActionData, setServiceActionData] = useState([]);

  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "service"
      );
      setServiceActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      serviceActionData[0]?.view &&
      serviceActionData[0].view === "0" &&
      serviceActionData[0]?.edit &&
      serviceActionData[0].edit === "0" &&
      serviceActionData[0]?.add &&
      serviceActionData[0].add === "0" &&
      serviceActionData[0]?.delete &&
      serviceActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name ",
          selector: (row) => row.Name,
        },
        {
          name: "Brand",
          selector: (row) => row.brand,
        },
        {
          name: "Description",
          selector: (row) => row.description,
        },

        {
          name: "Status",
          selector: (row) => row.status,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name ",
          selector: (row) => row.Name,
        },
        {
          name: "Brand",
          selector: (row) => row.brand,
        },
        {
          name: "Description",
          selector: (row) => row.description,
        },

        {
          name: "Status",
          selector: (row) => row.status,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [serviceActionData]);

  /**
   * Show actions which are to be displayed in table
   */
  const showActions = {
    showView:
      (serviceActionData[0]?.view && serviceActionData[0].view === "1") ||
      !serviceActionData
        ? true
        : false,
    showEdit:
      (serviceActionData[0]?.edit && serviceActionData[0].edit === "1") ||
      !serviceActionData
        ? true
        : false,
    showDelete:
      (serviceActionData[0]?.delete && serviceActionData[0].delete === "1") ||
      !serviceActionData
        ? true
        : false,
    showUndo:
      (serviceActionData[0]?.edit && serviceActionData[0].edit === "1") ||
      !serviceActionData
        ? true
        : false,
    navigate:
      (serviceActionData[0]?.edit && serviceActionData[0].edit === "1") ||
      !serviceActionData
        ? "/add-service"
        : false,
  };

  useEffect(() => {
    if (servicePage) {
      setCurrentPage(servicePage);
    }
  }, [servicePage]);

  /**
   * Fetch service list data
   */
  const fetchServices = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (servicePage > 1) {
      requestBody.p_skip = (servicePage - 2) * 10;
      dispatch(getServicePageData(servicePage - 1));
    } else {
      requestBody.p_skip = (servicePage - 1) * 10;
      dispatch(getServicePageData(servicePage));
    }
    if (serviceFilter.category !== "" && serviceFilter.category !== null) {
      requestBody.p_category_id = parseInt(serviceFilter.category);
    }
    if (
      serviceFilter.subCategory !== "" &&
      serviceFilter.subCategory !== null
    ) {
      requestBody.p_sub_category_id = serviceFilter.subCategory;
    }
    if (serviceFilter.tag !== "" && serviceFilter.tag !== null) {
      requestBody.p_tag_id = serviceFilter.tag;
    }
    if (serviceFilter.branch !== "" && serviceFilter.branch !== null) {
      requestBody.p_branch_id = serviceFilter.branch;
    }
    if (serviceFilter.service !== "" && serviceFilter.service !== null) {
      requestBody.p_service_provider_id = serviceFilter.service;
    }
    if (serviceFilter.name !== "" && serviceFilter.name !== null) {
      requestBody.p_name = serviceFilter.name;
    }
    if (serviceFilter.isActive !== "" && serviceFilter.isActive !== null) {
      requestBody.p_is_active = serviceFilter.isActive;
    } else {
      requestBody.p_is_active = "1";
    }
    if (serviceFilter.isDelete !== "" && serviceFilter.isDelete !== null) {
      requestBody.p_IsDeleted = serviceFilter.isDelete;
    }
    if (serviceFilter.brand !== "" && serviceFilter.brand !== null) {
      requestBody.p_brand_id = serviceFilter.brand;
    }
    getAllService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (service && service.items.length === 0) {
      fetchServices();
    } else {
      if (
        service &&
        service.items &&
        service.items[0] &&
        service.items[0].data &&
        service.items[0].data.length > 0
      ) {
        const newServiceData = service.items[0].data.map((item) => ({
          serviceId: item.serviceId,
          name: item.name,
          brandName: item.brandName,
          description: item.description,
          isActive: item.isActive,
          isDeleted: item.isDeleted,
          createdBy: item.createdBy,
          modifiedBy: item.modifiedBy,
          createdAt: item.createdAt,
          modifiedAt: item.modifiedAt,
        }));
        setServiceData(newServiceData);
      } else {
        setServiceData([]);
      }
      if (
        serviceFilter.name !== "" ||
        serviceFilter.category !== "" ||
        serviceFilter.subCategory !== "" ||
        serviceFilter.tag !== "" ||
        serviceFilter.branch !== "" ||
        serviceFilter.brand !== "" ||
        serviceFilter.isActive !== "" ||
        serviceFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [service]);

  const [isToggled, setIsToggled] = useState(false);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Function for changing page
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (serviceFilter.category !== "" && serviceFilter.category !== null) {
        requestBody.p_category_id = parseInt(serviceFilter.category);
      }
      if (
        serviceFilter.subCategory !== "" &&
        serviceFilter.subCategory !== null
      ) {
        requestBody.p_sub_category_id = serviceFilter.subCategory;
      }
      if (serviceFilter.tag !== "" && serviceFilter.tag !== null) {
        requestBody.p_tag_id = serviceFilter.tag;
      }
      if (serviceFilter.branch !== "" && serviceFilter.branch !== null) {
        requestBody.p_branch_id = serviceFilter.branch;
      }
      if (serviceFilter.service !== "" && serviceFilter.service !== null) {
        requestBody.p_service_provider_id = serviceFilter.service;
      }
      if (serviceFilter.name !== "" && serviceFilter.name !== null) {
        requestBody.p_name = serviceFilter.name;
      }
      if (serviceFilter.isActive !== "" && serviceFilter.isActive !== null) {
        requestBody.p_is_active = serviceFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }
      if (serviceFilter.isDelete !== "" && serviceFilter.isDelete !== null) {
        requestBody.p_IsDeleted = serviceFilter.isDelete;
      }
      if (serviceFilter.brand !== "" && serviceFilter.brand !== null) {
        requestBody.p_brand_id = serviceFilter.brand;
      }
      dispatch(getServicePageData(pageNumber));
      getAllService(requestBody, tokenData, dispatch);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Opem import modal
   */
  const handleImportClick = () => {
    // Open the modal
    setIsModalOpen(true);
  };

  /**
   * Close import modal
   */
  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  /**
   * Handle file upload
   * @param {*} file
   */
  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(uploadServiceExcelService(file, tokenData, setIsModalOpen));
  };

  /**
   * Function for clearing filters
   */
  const clearFilters = () => {
    setIsToggled(false);
    dispatch(clearServicePageData());
    dispatch(clearServiceFilterData());
    dispatch(clearServiceData());
    dispatch(clearTotalServiceData());
  };

  return (
    <>
      <div>
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Service</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Service</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {serviceFilter.name !== "" ||
                serviceFilter.category !== "" ||
                serviceFilter.subCategory !== "" ||
                serviceFilter.tag !== "" ||
                serviceFilter.branch !== "" ||
                serviceFilter.brand !== "" ||
                serviceFilter.isActive !== "" ||
                serviceFilter.isDelete !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filters
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}

                <FileUploadModal
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onFileUpload={handleFileUpload}
                  isClose={isModalOpen}
                />

                <Button
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>

                {service &&
                  service.items &&
                  service.items[0]?.data &&
                  service.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={service.items[0].data}
                      fileName={"services"}
                    ></ExcelData>
                  )}
                {((serviceActionData[0]?.add &&
                  serviceActionData[0].add === "1") ||
                  !serviceActionData) && (
                  <Button
                    onClick={() => navigate("/add-service")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {isToggled && <Filter page="service"></Filter>}
            {service &&
              service.items &&
              service.items[0]?.data &&
              service.items[0].data && (
                <>
                  <CustomTable
                    page="service"
                    showToggle={showToggle}
                    showDescription={true}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    checkBool={false}
                    data={serviceData}
                    heading={columns}
                    disabled={
                      serviceActionData &&
                      serviceActionData[0]?.edit &&
                      serviceActionData[0].edit === "0"
                    }
                  />

                  {service.items[0].total_records >
                    service.items[0].data.length &&
                    service.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={service.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
}
