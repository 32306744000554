import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Button from "../../components/Button/Button";
import { FaFilter } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import Footer from "../../components/Footer/Footer";
import { GrMoreVertical } from "react-icons/gr";
import { getAllProductService, updateProductPriceService } from "../Product/ProductService/ProductService";
import { IoIosSave } from "react-icons/io";
import { clearProductPricingFilterData } from "../../redux/Actions/ProductPricingAction/ProductPricingFilterAction";
import { clearProductPricingData } from "../../redux/Actions/ProductPricingAction/ProductPricingAction";
import {
  clearProductPricingPageData,
  getProductPricingPageData,
} from "../../redux/Actions/ProductPricingAction/ProductPricingPageAction";
import "../ProductPricing/ProductPricing.scss";
import { toast } from "react-toastify";
import { handlesaveClick } from "../../components/Alertmessage/Alertmessage";

const ProductPricing = () => {
  const productData = useSelector((state) => state.productPricing);
  const token = UtilsData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const productFilter = useSelector((state) => state.productPricingFilter);
  const productPage = useSelector((state) => state.productpricingPage?.items);
  const [productList, setProductList] = useState([]);

  const [productPriceActionData, setProductPriceActionData] = useState([]);
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setProductPriceActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    skuCode: true,
    price: true,
    takeAwayPrice: true,
    zomatoPrice: true,
    swiggyPrice: true,
    otherPrice: true,
    cgst: true,
    sgst: true,
  };

  const showActions = {
    update: true,
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "SKU Code",
      selector: (row) => row.skuCode,
    },
    {
      name: "Dine-in",
      selector: (row) => row.price,
    },
    {
      name: "Take Away",
      selector: (row) => row.takeAwayPrice,
    },
    {
      name: "Zomato",
      selector: (row) => row.zomatoPrice,
    },
    {
      name: "Swiggy",
      selector: (row) => row.swiggyPrice,
    },
    {
      name: "Other",
      selector: (row) => row.otherPrice,
    },
    {
      name: "CGST",
      selector: (row) => row.cgst,
    },
    {
      name: "SGST",
      selector: (row) => row.sgst,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ];

  const styleData = {
    quantity: { type: { textAlign: "end" } },
    Name: { type: { minWidth: "230px" } },
    name: { type: { minWidth: "230px" } },
    Actions:{type:{minWidth:"100px"}},
    Actionstoggle:{type:{minWidth:"100px",width:"100px",maxWidth:"100px"}}
  };

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Fetch product data
   */
  const fetchProduct = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10,
    };
    if (productPage > 1) {
      requestBody.p_skip = (productPage - 2) * 10;
      dispatch(getProductPricingPageData(productPage - 1));
    } else {
      requestBody.p_skip = (productPage - 1) * 10;
      dispatch(getProductPricingPageData(productPage));
    }
    if (productFilter.name !== "" && productFilter.name !== null) {
      requestBody.p_name = productFilter.name;
    }
    if (productFilter.sku !== "" && productFilter.sku !== null) {
      requestBody.p_sku_code = productFilter.sku;
    }

    getAllProductService(requestBody, token, dispatch);
  };

  useEffect(() => {
    if (productData && productData.items && productData.items.length === 0) {
      fetchProduct();
    } else {
      if (productFilter.name !== "" || productFilter.sku !== "") {
        setIsToggled(true);
      }
    }

    if (productData.items[0]?.data && productData.items[0].data.length > 0) {
      const transformedProductPriceData = productData.items[0].data.map(
        (item) => ({
          name: item.name,
          skuCode: item.skuCode ? item.skuCode.split("-").pop() : "",
          price: item.price,
          takeAwayPrice: item.take_away_price,
          zomatoPrice: item.zomato_price,
          swiggyPrice: item.swiggy_price,
          otherPrice: item.other_price,
          cgst: item.cgst,
          sgst: item.sgst,
          id: item.productId,
        })
      );
      setProductList(transformedProductPriceData);
    } else {
      setProductList([]);
    }
    // eslint-disable-next-line
  }, [productData]);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * On page change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (productFilter.name !== "" && productFilter.name !== null) {
        requestBody.p_name = productFilter.name;
      }
      if (productFilter.sku !== "" && productFilter.sku !== null) {
        requestBody.p_sku_code = productFilter.sku;
      }

      getAllProductService(requestBody, token, dispatch);
    }
    dispatch(getProductPricingPageData(pageNumber));
    setCurrentPage(pageNumber);
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearProductPricingFilterData());
    setIsToggled(false);
    dispatch(clearProductPricingData());
    dispatch(clearProductPricingPageData());
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [productNewData, setProductNewData] = useState([]);
  // useEffect(() => {
  //   if (productList.length > 0) {
  //     setProductNewData(
  //       productList.map((item) => ({
  //         id: item.id,
  //         skuCode: item.skuCode || "",
  //         price: item.price || "0.00",
  //         takeAwayPrice: item.takeAwayPrice || "0.00",
  //         zomatoPrice: item.zomatoPrice || "0.00",
  //         swiggyPrice: item.swiggyPrice || "0.00",
  //         otherPrice: item.otherPrice || "0.00",
  //         cgst: item.cgst || "0.00",
  //         sgst: item.sgst || "0.00",
  //       }))
  //     );
  //   }
  // }, [productList]);
  const findChangedProducts = () => {
    const changedProducts = [];
    productNewData.forEach((newProduct) => {
      const oldProduct = productList.find(
        (product) => product.id === newProduct.id
      );

      if (oldProduct) {
        const isPriceValid =
          newProduct.price &&
          newProduct.price !== "0" &&
          newProduct.price !== "";
        const isSkuCodeValid = newProduct.skuCode !== "";
        if (!isPriceValid || !isSkuCodeValid) {
          return;
        }
        let isChanged = false;
        const propertiesToCompare = [
          "price",
          "takeAwayPrice",
          "zomatoPrice",
          "swiggyPrice",
          "otherPrice",
          "cgst",
          "sgst",
          "skuCode"
        ];
        propertiesToCompare.forEach((prop) => {
          if (newProduct[prop] !== oldProduct[prop]) {
            isChanged = true;
          }
        });
        if (isChanged) {
          changedProducts.push(newProduct);
        }
      }
    });
    return changedProducts;
  };
  const userData = useSelector((state) => state.auth?.user);
  const handleAllProductPricing = () => {

    const changedProducts = findChangedProducts();
    if (changedProducts.length > 0) {
      const finalChangedProduct = changedProducts.map((matchedData) => {
        return {
          id: matchedData.id,
          sku_code: `SKU-${userData.data.companyCode}-${matchedData.skuCode}`,
          sgst: matchedData.sgst !== "" ? Number(matchedData.sgst) : 0,
          cgst: matchedData.cgst !== "" ? Number(matchedData.cgst) : 0,
          dine_in_price: Number(matchedData.price),
          take_away_price:
            matchedData.takeAwayPrice !== "0.00"
              ? Number(matchedData.takeAwayPrice)
              : 0,
          zomato_price:
            matchedData.zomatoPrice !== "0.00"
              ? Number(matchedData.zomatoPrice)
              : 0,
          swiggy_price:
            matchedData.swiggyPrice !== "0.00"
              ? Number(matchedData.swiggyPrice)
              : 0,
          other_price:
            matchedData.otherPrice !== "0.00"
              ? Number(matchedData.otherPrice)
              : 0,
        };
      });
      const requestBody ={
        p_product_data: finalChangedProduct
      }
      handlesaveClick(
        dispatch,
        updateProductPriceService(requestBody, token, "update", navigate),
        "update"
      );
    } else {
      toast.error("No Updates were made by you!");
    }
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {productPriceActionData && productPriceActionData.length > 0
                      ? productPriceActionData[0].name
                      : "Product Pricing"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {productPriceActionData && productPriceActionData.length > 0
                      ? productPriceActionData[0].name
                      : "Product Pricing"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {productFilter.name !== "" || productFilter.sku !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-postion">
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                <Button
                  onClick={handleShow}
                  data-bs-toggle="tooltip"
                  title="Filter"
                  // onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                <Button
                  data-bs-toggle="tooltip"
                  title="Save All"
                  onClick={handleAllProductPricing}
                  className="btn save-all"
                  name={
                    <div className="filter">
                      <IoIosSave className="filter-icon" />
                    </div>
                  }
                ></Button>

                <Offcanvas show={show} onHide={handleClose} placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter Options</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Filter
                      page="product-pricing"
                      handleClose={handleClose}
                    ></Filter>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
            {productData &&
              productData.items[0]?.data &&
              productData.items[0].data && (
                <>
                  <CustomTable
                    page="product-pricing"
                    productNewData={productNewData}
                    setProductNewData={setProductNewData}
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActions}
                    showTooltip={true}
                    data={productList}
                    heading={columns}
                    styleData={styleData}
                    disabled={
                      productPriceActionData &&
                      productPriceActionData[0]?.edit &&
                      productPriceActionData[0].edit === "0"
                    }
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                  {productData.items[0].total_records >
                    productData.items[0].data.length &&
                    productData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={productData.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default ProductPricing;
