import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import "./Sidebar";
import { listPagePaths } from "../../Utils/Utils";
const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    y: "-100%",
    transition: {
      duration: 0,
    },
  }),
  show: (i) => ({
    y: 0,
    transition: {
      duration: 0,
    },
  }),
};

/**
 * Making active route menu open
 * @param {*} param0
 * @returns {*}
 */
const SidebarMenu = ({ route, showAnimation, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const [activeUrl, setActiveUrl] = useState(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  // useEffect(() => {
  //   if (!isOpen && !isMenuOpen) {
  //     setIsMenuOpen(false);
  //   }
  // }, [isOpen, isMenuOpen]);

  // const [isActive, setIsActive] = useState(false);

  // useEffect(() => {
  //   let isAnyActive = false;
  //   const checkRoutes = (subRoutes) => {
  //     for (const subRoute of subRoutes) {
  //       if (subRoute.path === location.pathname) {
  //         setActiveUrl(subRoute.path);
  //         isAnyActive = true;
  //         break;
  //       }
  //       if (
  //         subRoute.subRoutes &&
  //         Array.isArray(subRoute.subRoutes) &&
  //         subRoute.subRoutes.length > 0
  //       ) {
  //         const nestedActive = checkRoutes(subRoute.subRoutes);
  //         if (nestedActive) {
  //           isAnyActive = true;
  //           setActiveUrl(subRoute.path);
  //           break;
  //         }
  //       }
  // // Check if matched page paths for subRoute
  // const matchedPagePath = listPagePaths.find(
  //   (item) => item.pagePath === location.pathname
  // );
  // if (matchedPagePath && matchedPagePath.listPath === subRoute.path) {
  //   setActiveUrl(subRoute.path);
  //   isAnyActive = true;
  //   break;
  // }
  //     }
  //   };

  //   checkRoutes(route.subRoutes);
  //   setIsActive(isAnyActive);
  //   // eslint-disable-next-line
  // }, [location]);

  // useEffect(() => {
  //   if (!isOpen) {
  //     setIsMenuOpen(false);
  //   } else {
  //     if (isActive) {
  //       setIsMenuOpen(true);
  //     }
  //   }
  // }, [isOpen, isActive]);

  useEffect(() => {
    // Check if the current route matches the active route or its subroutes
    const isActiveRoute = (subRoutes) => {
      return subRoutes.some((subRoute) => {
        const matchedPagePath = listPagePaths.find(
          (item) => item.pagePath === location.pathname
        );
        if (
          subRoute.path === location.pathname ||
          matchedPagePath?.listPath === subRoute.path
        ) {
          setActiveUrl(subRoute.path);
          return true;
        }
        if (subRoute.subRoutes && Array.isArray(subRoute.subRoutes)) {
          return isActiveRoute(subRoute.subRoutes);
        }
        return false;
      });
    };

    // Set menu state based on active route
    if (isActiveRoute(route.subRoutes)) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
      setActiveUrl(null);
    }
  }, [location.pathname, route.subRoutes]);

  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu-item">
          <div className="icon">{route.icon}</div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="link_text"
              >
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <motion.div
            animate={
              isMenuOpen
                ? {
                    rotate: 0,
                  }
                : { rotate: 0 }
            }
          >
            {!isMenuOpen && <FaAngleDown />}
            {isMenuOpen && <FaAngleUp />}
          </motion.div>
        )}
      </div>
      {isMenuOpen && (
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              variants={menuAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="menu_container"
            >
              {route.subRoutes.map((subRoute, i) => {
                // Check if the subRoute has its own subRoutes
                if (
                  subRoute?.subRoutes &&
                  Array.isArray(subRoute?.subRoutes) &&
                  subRoute?.subRoutes.length > 0
                ) {
                  return (
                    <SidebarMenu
                      key={i}
                      setIsOpen={setIsOpen}
                      route={subRoute}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }
                return (
                  <motion.div variants={menuItemAnimation} key={i} custom={i}>
                    <NavLink
                      to={subRoute.path}
                      className={`link ${
                        activeUrl === subRoute.path ? "active" : ""
                      }`}
                      onClick={() => {
                        // If a subRoute is clicked, open the parent menu
                        setIsMenuOpen(true);
                      }}
                    >
                      <div className="icon">{subRoute.icon}</div>
                      <motion.div className="link_text">
                        {subRoute.name}
                      </motion.div>
                    </NavLink>
                  </motion.div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default SidebarMenu;
