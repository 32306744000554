import { Dispatch } from "redux";
import { addUpdateGRNRequest, getAllGRNRequest, getAllGRNResponse } from "./GoodsReceiveNoteModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, removePersistStorage, toastClass, ValidationMessage } from "../../../Utils/Utils";
import { clearGRNData, clearTotalGRNData, getGRNData, getTotalGRNData } from "../../../redux/Actions/GoodReceiveNoteAction/GRNAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import { clearGRNPageData, getGRNPageData } from "../../../redux/Actions/GoodReceiveNoteAction/GRNPageAction";
import Swal from "sweetalert2";
import { clearStockData, clearTotalStockData } from "../../../redux/Actions/StocksAction/StockAction";
import { clearStockHistoryData, clearTotalStockHistoryData } from "../../../redux/Actions/StockHistoryAction/StockHistoryAction";


let errorShow = false;

/*
 * Service to get GRN list
 */
export const getAllGoodReceiveNoteService = (
  GRNListRequest: getAllGRNRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllGRNResponse> => {
  // Update return type here
  return new Promise<getAllGRNResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        GRNListRequest.p_skip !== undefined &&
        GRNListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${GRNListRequest.p_skip}&`;
      }
      if (
        GRNListRequest.p_take !== undefined &&
        GRNListRequest.p_take !== null
      ) {
        queryParams += `p_take=${GRNListRequest.p_take}&`;
      }
      if (
        GRNListRequest.p_grn_no !== undefined &&
        GRNListRequest.p_grn_no !== null
      ) {
        queryParams += `p_grn_no=${GRNListRequest.p_grn_no}&`;
      }
      if (
        GRNListRequest.p_created_date !== undefined &&
        GRNListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${GRNListRequest.p_created_date}&`;
      }
      if (
        GRNListRequest.p_end_date !== undefined &&
        GRNListRequest.p_end_date !== null
      ) {
        queryParams += `p_end_date=${GRNListRequest.p_end_date}&`;
      }

      if (
        GRNListRequest.p_time_zone !== undefined &&
        GRNListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${GRNListRequest.p_time_zone}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.GRN +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalGRNData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getGRNData(response));
        } else {
          dispatch(getGRNData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Purchase Requisition permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};


/*
 * Service for adding and updating Purchase Order
 */
export const addUpdateGoodReceiveNoteService = (
    addUpdateGRNRequest: addUpdateGRNRequest,
    token: string,
    type: string,
    navigate?: any,
    pageNumber?: number
  ) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === "add") {
          response = await postApiService(
            API_URL.BASE_URL + API_URL.GRN,
            token,
            addUpdateGRNRequest
          );
        }
        if (type === "update") {
          response = await putApiService(
            API_URL.BASE_URL + API_URL.PURCHASEORDER,
            token,
            addUpdateGRNRequest
          );
        }
        if (
          response &&
          (response.status === 200 ||
            response.status === "200" ||
            response.status === "success")
        ) {
          dispatch(clearGRNData());
          dispatch(clearTotalGRNData());
          dispatch(clearStockData());
          dispatch(clearTotalStockData());
          dispatch(clearStockHistoryData());
          dispatch(clearTotalStockHistoryData());
          if (type === "add") {
            dispatch(clearGRNPageData());
            toast.success("Good Received Note added successfully!");
            navigate("/goods-received-note");
          }
          if (type === "update") {
            if (navigate && navigate === "undo") {
              toast.success("Good Received Note undeleted successfully!");
            } else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getGRNPageData(pageNumber + 1));
                } else {
                  dispatch(clearGRNPageData());
                }
                Swal.fire({
                  title: "Saved!",
                  text: "Good Received Note updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                  },
                }).then(() => {
                  navigate("/goods-received-note");
                });
              } else {
                toast.success("Purchase Order status updated successfully!");
              }
            }
          }
        } else {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
          errorShow = true;
          if (response?.message && response.message === "Unauthorized access!") {
            removePersistStorage(dispatch);
            // dispatch(logout());
          }
          if (pageNumber) {
            dispatch(getGRNPageData(pageNumber));
          }
        }
      } catch (error) {
        if (errorShow === false) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    };
  };