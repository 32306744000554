/**
 * Setting Admin User PR list data into reducer
 * @param {*} adminPR
 * @returns {*}
 */
export const getAdminUserPRData = (adminUserPR) => ({
  type: "GET_ADMIN_PR_DATA",
  payload: adminUserPR,
});

/**
 * clearing Admin User PR list data
 * @returns {*}
 */
export const clearAdminUserPRData = () => ({
  type: "CLEAR_ADMIN_PR_DATA",
});

/**
 * Setting total Admin User PR list data into reducer (No pagnation)
 * @param {*} adminPR
 * @returns {*}
 */
export const getTotalAdminUserPRData = (purchaseRequisition) => ({
  type: "GET_TOTAL_ADMIN_PR",
  payload: purchaseRequisition,
});

/**
 * Clearing total Purchase Requisition list data (No pagination)
 * @returns {*}
 */
export const clearTotalPurchaseRequisitionData = () => ({
  type: "CLEAR_TOTAL_ADMIN_PR",
});
