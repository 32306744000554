import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import Toggle from "../../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateSpotService, getAllSpotService } from "../SpotService/SpotService";
import { clearSpotPageData } from "../../../redux/Actions/spotAction/SpotPageAction";
import { modifyString } from "../../../services/Regular-services/StringService";
import { toast } from "react-toastify";

//Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
  size: Yup.string().required(ValidationMessage.SIZE_REQUIRED),
  price: Yup.string().required(ValidationMessage.PRICE_REQUIRED),
});

const AddSpots = () => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    size: "",
    price: "",
    is_active: 1,
    is_available: 1,
  });

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [spotActionData, setSpotActionData] = useState([]);
  const totalSpot = useSelector(state => state.totalSpot?.items);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "spot"
      );
      setSpotActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if((totalSpot && totalSpot.length === 0) || (!totalSpot)) {
      fetchTotalSpots();
    }
    // eslint-disable-next-line
  }, [totalSpot])

  const fetchTotalSpots = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000000,
      p_is_active: '1'
    };
    getAllSpotService(
      requestBody,
      token,
      dispatch,
      true
    )
  };

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  // const spotPage = useSelector(state => state.spotPage?.items);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const userData = useSelector(state => state.auth.user);
  const token = UtilsData();

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        id: Data.data.id,
        name: Data.data.name,
        size: Data.data.size,
        price: Data.data.price,
        is_active:
          Data.data.is_active === "0" ||
          Data.data.is_active === false ||
          Data.data.is_active === 0
            ? 0
            : 1,
        is_available:
          Data.data.is_available === "0" ||
          Data.data.is_available === false ||
          Data.data.is_available === 0
            ? 0
            : 1,
        is_deleted: Data.data.is_delete,
      });
    }
  }, [Data]);

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    if(totalSpot?.length && totalSpot[0]?.data && totalSpot[0].data?.length) {
      const selectedBool = totalSpot[0].data.some(item => item.name === values.name);
      if(selectedBool && (!Data || (Data && Data.data?.name !== values.name))) {
        toast.warn("table name should be unique");
        return;
      }
    }
    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_name: values.name,
      p_size: values.size,
      p_price: values.price,
      p_is_active:
        values.is_active === true ||
        values.is_active === 1 ||
        values.is_active === "1"
          ? "1"
          : "0",
      p_is_available:
        values.is_available === true ||
        values.is_available === 1 ||
        values.is_available === "1"
          ? "1"
          : "0",
      ...(Data && Data.data && { p_is_deleted: values.is_deleted }),
    };
    dispatch(clearSpotPageData());
    if (!Data) {
      dispatch(addUpdateSpotService(requestBody, token, "add", navigate));
    } else {
      handlesaveClick(
        dispatch,
        addUpdateSpotService(requestBody, token, "update", navigate),
        "update"
      );
    }
  };

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {spotActionData && spotActionData.length > 0
                      ? modifyString(spotActionData[0].name)
                      : "Spot"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/spot")}>
                    {spotActionData && spotActionData.length > 0
                      ? spotActionData[0].name
                      : "Spot"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {spotActionData && spotActionData.length > 0
                      ? modifyString(spotActionData[0].name)
                      : "Spot"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {spotActionData && spotActionData.length > 0
                  ? modifyString(spotActionData[0].name)
                  : "Spot"}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Name<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="name"
                          label="Name"
                          placeholder="Enter Name"
                          className="add-roles"
                          value={values.name}
                          Regex={/[^a-zA-Z0-9]/g}
                          disabled={Data && Data.isEdit === false}
                          note="* Please enter a unique value."
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Size<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="size"
                          label="Size"
                          placeholder="Enter Size"
                          className="add-roles"
                          value={values.size}
                          Regex={/[^0-9\s]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Price<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="price"
                          label="Price"
                          placeholder="Enter Price"
                          className="add-roles"
                          Regex={/[^0-9\s]/g}
                          value={values.price}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Active</h4>
                        <Field
                          name="is_active"
                          className="toggle"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Available</h4>
                        <Field
                          name="is_available"
                          className="toggle"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/spot", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
};

export default AddSpots;
