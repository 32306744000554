const newVendorInitialState = {
    items: []
}

/**
 * Store vendor list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const NewVendorReducer = (state = newVendorInitialState, action) => {
    switch (action.type) {
        case 'GET_NEW_VENDOR_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_NEW_VENDOR_DATA':
            return newVendorInitialState;
        default:
            return state;
    };
}

const totalNewVendorInitialState = {
    items: []
}

/**
 * Store total new vendor list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalNewVendorReducer = (state = totalNewVendorInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_NEW_VENDOR':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_NEW_VENDOR':
            return totalNewVendorInitialState
        default:
            return state;
    };
}