import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import "../Imagemanager/Imagemanager.scss";
import "../AdminUsers/AdminUsers.scss";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import { getUserListService } from "./User-service/UserService.tsx";
import UtilsData from "../../Utils/Utils";
import { formatUTCDate } from "../../services/Regular-services/DateService.js";
import CustomPagination from "../../components/Pagination/Pagination.js";
import {
  clearAdminPageData,
  getAdminPageData,
} from "../../redux/Actions/adminAction/AdminPageAction.js";
import { clearAdminData } from "../../redux/Actions/adminAction/AdminUserAction";
import { clearAdminFilterData } from "../../redux/Actions/adminAction/AdminFilterAction";

//Show keys which is to be displayed in table
const showKey = {
  // username: true,
  fullname: true,
  phoneNumber: true,
  roleName: true,
  createdAt: true,
};

const showToggle = {
  status: true,
  delete: false,
};

export default function AdminUsers() {
  const adminPageNumber = useSelector((state) => state.adminPage?.items);
  const [adminListData, setAdminListData] = useState([]);
  const [currentPageNumber, setCurrentPage] = useState(1); // Assuming initial page is 1
  const adminFilter = useSelector((state) => state.adminFilter); //Admin filter store

  const sideBarData = useSelector((state) => state.sideBar?.items);

  const [adminActionData, setAdminActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setAdminActionData(actionData);
    }
  }, [sideBarData]);

  const showActions = {
    showView:
      (adminActionData[0]?.view && adminActionData[0].view === "1") ||
      !adminActionData
        ? true
        : false,
    showEdit:
      (adminActionData[0]?.edit && adminActionData[0].edit === "1") ||
      !adminActionData
        ? true
        : false,
    showDelete:
      (adminActionData[0]?.delete && adminActionData[0].delete === "1") ||
      !adminActionData
        ? true
        : false,
    showUndo:
      (adminActionData[0]?.edit && adminActionData[0].edit === "1") ||
      !adminActionData
        ? true
        : false,
    navigate:
      (adminActionData[0]?.edit && adminActionData[0].edit === "1") ||
      !adminActionData
        ? "/edit-user"
        : false,
  };

  const [columns, setColumns] = useState([
    // {
    //     name: 'Username',
    //     selector: row => row.username
    // },
    {
      name: "Full Name",
      selector: (row) => row.fullname,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile,
    },
    {
      name: "Designation",
      selector: (row) => row.Role,
    },
    {
      name: "Creation Date",
      selector: (row) => row.Date,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
    },
  ]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      adminActionData[0]?.view &&
      adminActionData[0].view === "0" &&
      adminActionData[0]?.edit &&
      adminActionData[0].edit === "0" &&
      adminActionData[0]?.add &&
      adminActionData[0].add === "0" &&
      adminActionData[0]?.delete &&
      adminActionData[0].delete === "0"
    ) {
      setColumns([
        // {
        //     name: 'Username',
        //     selector: row => row.username
        // },
        {
          name: "Full Name",
          selector: (row) => row.fullname,
        },
        {
          name: "Mobile Number",
          selector: (row) => row.mobile,
        },
        {
          name: "Designation",
          selector: (row) => row.Role,
        },
        {
          name: "Creation Date",
          selector: (row) => row.Date,
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
      ]);
    } else {
      setShowActionBool(true);
      setColumns([
        // {
        //     name: 'Username',
        //     selector: row => row.username
        // },
        {
          name: "Full Name",
          selector: (row) => row.fullname,
        },
        {
          name: "Mobile Number",
          selector: (row) => row.mobile,
        },
        {
          name: "Designation",
          selector: (row) => row.Role,
        },
        {
          name: "Creation Date",
          selector: (row) => row.Date,
        },
        {
          name: "Status",
          selector: (row) => row.status,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        },
      ]);
    }
  }, [adminActionData]);

  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const adminUserList = useSelector((state) => state.adminUser);
  const dispatch = useDispatch();
  const token = UtilsData();

  useEffect(() => {
    setCurrentPage(adminPageNumber);
  }, [adminPageNumber]);

  /**
   * Fetch user data
   */
  const fetchUsers = () => {
    const requestData = {
      skip: 0,
      take: 10,
    };
    if (adminFilter.phone !== "" && adminFilter.phone !== null) {
      requestData.search_phone_number = adminFilter.phone;
    }
    if (adminFilter.isActive !== "" && adminFilter.isActive !== null) {
      requestData.search_is_active = adminFilter.isActive;
    } else {
      requestData.search_is_active = "1";
    }
    if (adminFilter.isDelete !== "" && adminFilter.isDelete !== null) {
      requestData.p_IsDeleted = adminFilter.isDelete;
    }
    if (adminPageNumber > 1) {
      requestData.skip = (adminPageNumber - 2) * 10;
      setCurrentPage(adminPageNumber - 1); // Update the current page state
      dispatch(getAdminPageData(adminPageNumber - 1));
    } else {
      requestData.skip = (adminPageNumber - 1) * 10;
      setCurrentPage(adminPageNumber); // Update the current page state
      dispatch(getAdminPageData(adminPageNumber));
    }
    getUserListService(requestData, token, dispatch);
  };

  useEffect(() => {
    if (
      adminUserList &&
      adminUserList.items &&
      adminUserList.items.length === 0
    ) {
      fetchUsers();
    } else {
      if (
        adminFilter.phone !== "" ||
        adminFilter.isActive !== "" ||
        adminFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }

    if (
      adminUserList.items[0]?.data &&
      adminUserList.items[0].data.length > 0
    ) {
      const transformedAdminData = adminUserList.items[0].data.map((item) => ({
        userId: item.userId,
        username: item.username,
        fullname: `${item.firstName} ${item.lastName}`,
        phoneNumber: item.phoneNumber,
        roleName: item.role_name,
        createdAt: formatUTCDate(item.createdAt),
        lastLoginAt: formatUTCDate(item.lastLoginAt),
        isActive: item.isActive,
        isDeleted: item.isDeleted,
        firstName: item.firstName,
        lastName: item.lastName,
        rolePermissionsId: item.rolePermissionsId,
        typeId: item.typeId,
        createdBy: item.createdBy,
      }));
      setAdminListData(transformedAdminData);
    } else {
      setAdminListData([]);
    }
    // eslint-disable-next-line
  }, [adminUserList]);

  const styleData = {
    phoneNumber: {type: {textAlign: 'end'}},
    createdAt: {type: {textAlign: 'center'}}
  }

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    const requestData = {
      skip: (pageNumber - 1) * 10,
      take: 10,
    };
    if (adminFilter.phone !== "" && adminFilter.phone !== null) {
      requestData.search_phone_number = adminFilter.phone;
    }
    if (adminFilter.isActive !== "" && adminFilter.isActive !== null) {
      requestData.search_is_active = adminFilter.isActive;
    } else {
      requestData.search_is_active = "1";
    }

    if (adminFilter.isDelete !== "" && adminFilter.isDelete !== null) {
      requestData.p_IsDeleted = adminFilter.isDelete;
    }

    if (pageNumber !== currentPageNumber) {
      getUserListService(requestData, token, dispatch);
    }
    setCurrentPage(pageNumber); // Update the current page state
    dispatch(getAdminPageData(pageNumber));
  };

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Clear Admin filters
   */
  const clearAdminFilters = () => {
    dispatch(clearAdminPageData());
    dispatch(clearAdminFilterData());
    dispatch(clearAdminData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {adminActionData && adminActionData.length > 0
                      ? adminActionData[0].name
                      : "Admin User"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {adminActionData && adminActionData.length > 0
                      ? adminActionData[0].name
                      : "Admin User"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {(adminFilter.isActive !== "" ||
                  adminFilter.isDelete !== "" ||
                  adminFilter.phone !== "") && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearAdminFilters}>
                        Clear Filters
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter
                page="users"
                handleClose={handleClose}
              ></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                {adminUserList.items[0]?.data &&
                  adminUserList.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={adminUserList.items[0].data}
                      fileName={"admin-users"}
                    ></ExcelData>
                  )}
                {((adminActionData[0]?.add && adminActionData[0].add === "1") ||
                  !adminActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add User"
                    onClick={() => navigate("/adduser")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {/* {isToggled && (
              <Filter
                page="users"
                handleButtonClick={handleButtonClick}
              ></Filter>
            )} */}

            {adminUserList &&
              adminUserList.items[0]?.data &&
              adminUserList.items[0].data && (
                <>
                  <CustomTable
                    showToggle={showToggle}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    checkBool={false}
                    showTooltip={true}
                    showId={true}
                    data={adminListData}
                    heading={columns}
                    styleData={styleData}
                    page="users"
                    disabled={
                      adminActionData[0]?.edit &&
                      adminActionData[0].edit === "0"
                    }
                  />

                  {adminUserList.items[0]?.total_records > 0 &&
                    adminUserList.items[0].total_records >
                      adminUserList.items[0].data.length &&
                    adminUserList.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={adminUserList.items[0].total_records}
                        currentPage={currentPageNumber}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}