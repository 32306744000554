import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../../Utils/Utils";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { handleCancleClick } from "../../../components/Alertmessage/Alertmessage";
import Button from "../../../components/Button/Button";
import Input from "../../../components/input/Input";
import { toast } from "react-toastify";
import {
  convertToBaseUnit,
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import { clearGRNPageData } from "../../../redux/Actions/GoodReceiveNoteAction/GRNPageAction";
import { addUpdateGoodReceiveNoteService } from "../GoodsReceiveNoteResponse/GoodsReceiveNoteService";
import { modifyString } from "../../../services/Regular-services/StringService";
import { Breadcrumb } from "react-bootstrap";
import "../AddGoodsReceiveNote/AddGoodsReceiveNote.scss";

const AddGoodsReceiveNote = () => {
  const [initialValues, setInitialValues] = useState({
    prNum: "",
    poNum: "",
    instructions: "",
    ingredients: [],
  });

  const location = useLocation();
  const { Data } = location.state || {};
  const { grnData } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [grnActionData, setGrnActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setGrnActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (grnData && grnData.ingredients) {
      const ingredientData = JSON.parse(grnData.ingredients);
      const addedIngredientVals = ingredientData.map((item) => {
        let quantityInOriginalUnit = parseFloat(item.value);
        let unit = item.unit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }
        return {
          ingredientName: item?.ingredientTitle,
          IngredientId: item?.ingredient_id,
          quantity: quantityInOriginalUnit,
          unit: unit,
          instruction: "",
          recived_value: '0',
          recived_unit: unit,
          recived_ingredient_instruction: "",
          damage_value: '0',
          damage_unit: unit,
          damage_ingredient_instruction: "",
          not_recived_value: "0",
          not_recived_unit: unit,
          not_recived_ingredient_instruction: "",
        };
      });

      setInitialValues((prev) => ({
        poNum: grnData.id,
        prNum: grnData.pr_id,
        poNum1: grnData.poNum,
        prNum1: grnData.prNum,
        instructions: grnData.instructions,
        ingredients: addedIngredientVals,
      }));
    }
  }, [grnData]);

  const convertQuantity = (quantity, unit) => {
    let convertedQuantity = parseFloat(quantity);

    if (
      unit === "gm" ||
      unit === "kg" ||
      unit === "l" ||
      unit === "ml" ||
      unit === "mg"
    ) {
      if (unit === "gm" && convertedQuantity > 999) {
        convertedQuantity = GramToKg(convertedQuantity);
        unit = "kg";
      } else if (unit === "gm" && convertedQuantity < 1) {
        convertedQuantity = GramToMg(convertedQuantity);
        unit = "mg";
      } else if (unit === "ml" && convertedQuantity > 999) {
        convertedQuantity = MlToLitre(convertedQuantity);
        unit = "l";
      }
    }

    return { quantity: convertedQuantity, unit };
  };

  useEffect(() => {
    if (Data) {
      const data = Data.data;
      const ingredientData = JSON.parse(data.ingredients);
      const addedIngredientVals = ingredientData.map((item) => {
        const { quantity: quantityInOriginalUnit, unit } =
          convertQuantity(item.value, item.unit);
        const { quantity: receivedQuantity, unit: receivedUnit } =
          convertQuantity(item.recived_value, item.recived_unit);
        const { quantity: damageQuantity, unit: damageUnit } = convertQuantity(
          item.damage_value,
          item.damage_unit
        );

        // Convert not received value
        const { quantity: notReceivedQuantity, unit: notReceivedUnit } =
          convertQuantity(item.not_recived_value, item.not_recived_unit);
        return {
          ingredientName: item?.ingredientTitle,
          IngredientId: item?.ingredient_id,
          quantity: quantityInOriginalUnit ? quantityInOriginalUnit : 0,
          unit: unit,
          instruction: "",
          recived_value: receivedQuantity,
          recived_unit:
            receivedQuantity !== "0" && receivedQuantity !== 0
              ? receivedUnit
              : damageUnit,
          recived_ingredient_instruction: item.recived_ingredient_instruction,
          damage_value: damageQuantity,
          damage_unit:
            damageQuantity !== "0" && damageQuantity !== 0
              ? damageUnit
              : receivedUnit,
          damage_ingredient_instruction: item.damage_ingredient_instruction,
          not_recived_value: notReceivedQuantity,
          not_recived_unit:
            notReceivedQuantity !== "0" && notReceivedQuantity !== 0
              ? notReceivedUnit
              : damageQuantity !== "0" && damageQuantity !== 0
              ? damageUnit
              : receivedUnit,
          not_recived_ingredient_instruction:
            item.not_recived_ingredient_instruction,
        };
      });

      setInitialValues((prev) => ({
        poNum1: data.poNum,
        prNum1: data.prNum,
        instructions: data.instructions,
        ingredients: addedIngredientVals,
      }));
    }
  }, [Data]);

  const handleReceivedChange = (
    index,
    event,
    totalQuantity,
    values,
    setFieldValue,
    ingredientName,
    Regex
  ) => {
    let newValue = event.target.value;
    if (Regex) {
      newValue = newValue.replace(Regex, "");
      if (newValue === "" || !newValue) {
        event.preventDefault();
        return;
      }
    }
    const receivedValue = parseFloat(newValue) || 0;
    const damagedValue =
      parseFloat(values.ingredients[index].damage_value) || 0;
    const notReceivedValue = totalQuantity - (receivedValue + damagedValue);
    if (damagedValue + receivedValue > totalQuantity) {
      toast.error(
        `Total of Received and Damaged of ${ingredientName} should not be more than Total Quantity`
      );
      setFieldValue(`ingredients.${index}.damage_value`, "0");
      setFieldValue(`ingredients.${index}.not_recived_value`, "0");
      setFieldValue(`ingredients.${index}.recived_value`, "0");
    } else if (receivedValue > totalQuantity) {
      toast.error(
        `Received Value of ${ingredientName} is more than Total Quantity`
      );
      setFieldValue(`ingredients.${index}.recived_value`, "0");
      setFieldValue(`ingredients.${index}.not_recived_value`, "0");
    } else {
      setFieldValue(`ingredients.${index}.recived_value`, newValue);
      setFieldValue(
        `ingredients.${index}.not_recived_value`,
        Math.max(notReceivedValue.toFixed(2), 0)
      ); // Ensure not negative
    }
  };

  const handleDamageChange = (
    index,
    event,
    totalQuantity,
    values,
    setFieldValue,
    ingredientName,
    Regex
  ) => {
    let newValue = event.target.value;
    if (Regex) {
      newValue = newValue.replace(Regex, "");
      if (newValue === "" || !newValue) {
        event.preventDefault();
        return;
      }
    }
    const damagedValue = parseFloat(newValue) || 0;
    const receivedValue =
      parseFloat(values.ingredients[index].recived_value) || 0;
    const notReceivedValue = totalQuantity - (receivedValue + damagedValue);
    if (damagedValue + receivedValue > totalQuantity) {
      toast.error(
        `Total of Received and Damaged of ${ingredientName} should not be more than Total Quantity`
      );
      setFieldValue(`ingredients.${index}.damage_value`, "0");
      setFieldValue(`ingredients.${index}.not_recived_value`, "0");
      setFieldValue(`ingredients.${index}.recived_value`, "0");
    } else if (damagedValue > totalQuantity) {
      toast.error(
        `Damaged Value of ${ingredientName} is more than Total Quantity`
      );
      setFieldValue(`ingredients.${index}.damage_value`, "0");
      setFieldValue(`ingredients.${index}.not_recived_value`, "0");
    } else {
      setFieldValue(`ingredients.${index}.damage_value`, newValue);
      setFieldValue(
        `ingredients.${index}.not_recived_value`,
        Math.max(notReceivedValue.toFixed(2), 0)
      );
    }
  };

  const handleInstruction = (e, setFieldValue, index, type) => {
    const msg = e.target.value;
    if (type === "received") {
      setFieldValue(`ingredients.${index}.recived_ingredient_instruction`, msg);
    } else if (type === "not-received") {
      setFieldValue(
        `ingredients.${index}.not_recived_ingredient_instruction`,
        msg
      );
    } else {
      setFieldValue(`ingredients.${index}.damage_ingredient_instruction`, msg);
    }
  };

  const handleSubmit = (values) => {
    const hasFilledField = values.ingredients.every((ingredient) => {
      const { recived_value, damage_value, not_recived_value } = ingredient;

      return (
        (recived_value === "0" || recived_value === "") &&
        (damage_value === "0" || damage_value === "") &&
        (not_recived_value === "0" || not_recived_value === "")
      );
    });

    if (hasFilledField) {
      toast.error(
        "Please add quantity for any one ingredient to generate GRN."
      );
      return; // Exit the function early
    }
    // Filter ingredients with non-zero received or damaged values
    const filteredIngredients = values.ingredients.filter((ingredient) => {
      const { recived_value, damage_value } = ingredient;
      return (
        (recived_value !== "0" && recived_value !== "") ||
        (damage_value !== "0" && damage_value !== "")
      );
    });

    const ingredientData = filteredIngredients.map((item) => {
      const { quantityInBaseUnit } = convertToBaseUnit(
        item.quantity,
        item.unit
      );

      console.log("quan: ", quantityInBaseUnit);
      

      const {
        quantityInBaseUnit: receivedQuantityInBaseUnit,
        unit: receivedUnit,
      } = convertToBaseUnit(item.recived_value, item.recived_unit);

      // Convert damaged value
      const {
        quantityInBaseUnit: damagedQuantityInBaseUnit,
        unit: damagedUnit,
      } = convertToBaseUnit(
        item.damage_value,
        item.damage_unit // Use the correct unit for damage
      );
      // Convert not received value
      const {
        quantityInBaseUnit: notReceivedQuantityInBaseUnit,
        unit: notReceivedUnit,
      } = convertToBaseUnit(
        item.not_recived_value,
        item.not_recived_unit // Use the correct unit for not received
      );
      return {
        ingredient_id: item.IngredientId,
        recived_value: receivedQuantityInBaseUnit
          ? receivedQuantityInBaseUnit
          : "0",
        recived_unit: receivedUnit,
        damage_value: damagedQuantityInBaseUnit
          ? damagedQuantityInBaseUnit
          : "0",
        damage_unit: damagedUnit,
        not_recived_value:
          notReceivedQuantityInBaseUnit !== 0 && notReceivedQuantityInBaseUnit
            ? notReceivedQuantityInBaseUnit
            : parseFloat(quantityInBaseUnit) -
              (parseFloat(receivedQuantityInBaseUnit) +
                parseFloat(damagedQuantityInBaseUnit)),
        not_recived_unit: notReceivedUnit,
        damage_ingredient_instruction: item.damage_ingredient_instruction,
        not_recived_ingredient_instruction:
          item.not_recived_ingredient_instruction,
        recived_ingredient_instruction: item.recived_ingredient_instruction,
      };
    });
    const requestBody = {
      p_pr_id: values.prNum,
      p_po_id: values.poNum,
      p_instruction: values.instructions,
      p_rejection_reason: "",
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_product_data: ingredientData,
    };
    dispatch(clearGRNPageData());
    dispatch(
      addUpdateGoodReceiveNoteService(requestBody, token, "add", navigate)
    );
  };

  return (
    <div className="main-section add-image-section">
      <div className="sidebar-content">
        <Sidebar />
      </div>
      <div className="body-content">
        <Header />
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  {grnActionData && grnActionData.length > 0
                    ? modifyString(grnActionData[0].name)
                    : "Goods Receive Notes"}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => navigate("/goods-received-note")}
                >
                  {grnActionData && grnActionData.length > 0
                    ? modifyString(grnActionData[0].name)
                    : "Goods Receive Notes"}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  {grnActionData && grnActionData.length > 0
                    ? modifyString(grnActionData[0].name)
                    : "Goods Receive Notes"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>
              {Data && Data.isEdit === true
                ? "Edit "
                : Data && Data.isEdit === false
                ? "View "
                : "Add "}{" "}
              {grnActionData && grnActionData.length > 0
                ? modifyString(grnActionData[0].name)
                : "Goods Receive Notes"}
            </h4>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({})}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          PR Number <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="prNum1"
                        label="prNum1"
                        placeholder="Enter PO Number"
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">
                          PO NUmber <span className="required-label"></span>
                        </h4>
                      </div>
                      <Input
                        type="text"
                        name="poNum1"
                        label="poNum1"
                        placeholder="Enter PO Number"
                        className="add-roles"
                        Regex={/[^a-zA-Z\s]/g}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="pb-1">
                    <div className="message-input">
                      <textarea
                        value={values.instructions}
                        placeholder="Would you like to add generalized instruction🧐? "
                        onChange={(event) =>
                          setFieldValue("instructions", event.target.value)
                        }
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h4>Ingredients</h4>
                    <div className="row py-2">
                      <div className="grn-data">
                        {values.ingredients.map((ingredient, index) => (
                          <div key={index}>
                            <div className="d-flex align-items-center mb-2">
                              <div className="col-12">
                                <span className="ingredientName">
                                  {ingredient.ingredientName}
                                </span>
                                {ingredient.quantity !== 0 &&
                                  ingredient.quantity !== "0" && (
                                    <div>
                                      <span>
                                        Total Quantity : {ingredient.quantity}
                                        {ingredient.unit}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                              <div className="col-3">
                                <Input
                                  type="text"
                                  name={`ingredients.${index}.recived_value`}
                                  placeholder="Received Quantity"
                                  className="add-roles"
                                  changeInputData={(event, Regex) =>
                                    handleReceivedChange(
                                      index,
                                      event,
                                      ingredient.quantity,
                                      values,
                                      setFieldValue,
                                      ingredient.ingredientName,
                                      Regex
                                    )
                                  }
                                  Regex={/[^0-9.\s]/g}
                                  disabled={Data}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{ marginLeft: "10px" }}
                              >
                                <Input
                                  type="text"
                                  name={`ingredients.${index}.recived_unit`}
                                  placeholder="Received Unit"
                                  className="add-roles"
                                  disabled={true}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{ marginLeft: "10px" }}
                              >
                                <textarea
                                  name={`ingredients.${index}.recived_ingredient_instruction`}
                                  placeholder="Received Instructions"
                                  className="add-roles px-2"
                                  disabled={Data}
                                  onChange={(e) =>
                                    handleInstruction(
                                      e,
                                      setFieldValue,
                                      index,
                                      "received"
                                    )
                                  }
                                  value={
                                    values.ingredients[index]
                                      .recived_ingredient_instruction
                                  }
                                />
                              </div>
                            </div>

                            <div className="d-flex align-items-center mb-2">
                              <div className="col-3">
                                <Input
                                  type="text"
                                  name={`ingredients.${index}.damage_value`}
                                  placeholder="Damaged Quantity"
                                  className="add-roles"
                                  changeInputData={(event, Regex) =>
                                    handleDamageChange(
                                      index,
                                      event,
                                      ingredient.quantity,
                                      values,
                                      setFieldValue,
                                      ingredient.ingredientName,
                                      Regex
                                    )
                                  }
                                  disabled={Data}
                                  Regex={/[^0-9.\s]/g}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{ marginLeft: "10px" }}
                              >
                                <Input
                                  type="text"
                                  name={`ingredients.${index}.damage_unit`}
                                  placeholder="Damaged Unit"
                                  className="add-roles"
                                  disabled={true}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{ marginLeft: "10px" }}
                              >
                                <textarea
                                  name={`ingredients.${index}.damage_ingredient_instruction`}
                                  placeholder="Damaged Instructions"
                                  className="add-roles px-2"
                                  disabled={Data}
                                  onChange={(e) =>
                                    handleInstruction(
                                      e,
                                      setFieldValue,
                                      index,
                                      "damaged"
                                    )
                                  }
                                  value={
                                    values.ingredients[index]
                                      .damage_ingredient_instruction
                                  }
                                />
                              </div>
                            </div>

                            <div className="d-flex align-items-center mb-2">
                              <div className="col-3">
                                <Input
                                  type="text"
                                  name={`ingredients.${index}.not_recived_value`}
                                  placeholder="Not Received Quantity"
                                  className="add-roles"
                                  // changeInputData={(event) =>
                                  //   handleNotReceivedChange(
                                  //     index,
                                  //     event.target.value,
                                  //     ingredient.quantity,
                                  //     values,
                                  //     setFieldValue,
                                  //     ingredient.ingredientName
                                  //   )
                                  // }
                                  disabled={true}
                                  Regex={/[^0-9]/g}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{ marginLeft: "10px" }}
                              >
                                <Input
                                  type="text"
                                  name={`ingredients.${index}.not_recived_unit`}
                                  placeholder="Not Received Unit"
                                  className="add-roles"
                                  disabled={true}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{ marginLeft: "10px" }}
                              >
                                <textarea
                                  name={`ingredients.${index}.not_recived_ingredient_instruction`}
                                  placeholder="Not Received Instructions"
                                  className="add-roles px-2"
                                  disabled={Data}
                                  onChange={(e) =>
                                    handleInstruction(
                                      e,
                                      setFieldValue,
                                      index,
                                      "not-received"
                                    )
                                  }
                                  value={
                                    values.ingredients[index]
                                      .not_recived_ingredient_instruction
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <>
                            <Button
                              type="submit"
                              name="Save"
                              className="btn login-btn"
                            />
                            <Button
                              type="button"
                              name="Cancel"
                              className="btn clear-btn login-btn"
                              onClick={() =>
                                handleCancleClick(
                                  "/goods-received-note",
                                  navigate
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddGoodsReceiveNote;
