const unitsPageInitialState = {
    items: 1
}

/**
 * Store units page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const UnitsPageReducer = (state = unitsPageInitialState, action) => {
    switch (action.type) {
        case 'GET_UNITS_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_UNITS_PAGE':
            return unitsPageInitialState;
        default:
            return state;
      };
}