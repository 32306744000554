import { Dispatch } from "redux";
import {
  AddUpdateNewVendorPersonRequest,
  getAllNewVendorPersonRequest,
  getAllNewVendorPersonResponse,
} from "./NewVendorServiceModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearNewVendorData,
  clearTotalNewVendorData,
  getNewVendorData,
  getTotalNewVendorData,
} from "../../../redux/Actions/NewVendorAction/NewVendorAction";
import {
  clearNewVendorPageData,
  getNewVendorPageData,
} from "../../../redux/Actions/NewVendorAction/NewVendorPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";
import { deleteApiService } from "../../../services/API-services/DeleteService";

let errorShow = false;

/*
 * Service to get vendor list
 */
export const getAllNewVendorPersonService = (
  newVendorListRequest: getAllNewVendorPersonRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllNewVendorPersonResponse> => {
  return new Promise<getAllNewVendorPersonResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        newVendorListRequest.p_skip !== undefined &&
        newVendorListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${newVendorListRequest.p_skip}&`;
      }
      if (
        newVendorListRequest.p_take !== undefined &&
        newVendorListRequest.p_take !== null
      ) {
        queryParams += `p_take=${newVendorListRequest.p_take}&`;
      }
      if (
        newVendorListRequest.p_first_name !== undefined &&
        newVendorListRequest.p_first_name !== null
      ) {
        queryParams += `p_first_name=${newVendorListRequest.p_first_name}&`;
      }
      if (
        newVendorListRequest.p_last_name !== undefined &&
        newVendorListRequest.p_last_name !== null
      ) {
        queryParams += `p_last_name=${newVendorListRequest.p_last_name}&`;
      }
      if (
        newVendorListRequest.p_phone_number !== undefined &&
        newVendorListRequest.p_phone_number !== null
      ) {
        queryParams += `p_phone_number=${newVendorListRequest.p_phone_number}&`;
      }
      if (
        newVendorListRequest.p_isActive !== undefined &&
        newVendorListRequest.p_isActive !== null
      ) {
        queryParams += `p_isActive=${newVendorListRequest.p_isActive}&`;
      }
      if (
        newVendorListRequest.p_IsDeleted !== undefined &&
        newVendorListRequest.p_IsDeleted !== null
      ) {
        queryParams += `p_IsDeleted=${newVendorListRequest.p_IsDeleted}&`;
      }

      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.VENDORS +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalNewVendorData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getNewVendorData(response));
        } else {
          dispatch(getNewVendorData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Vendor permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating Inventory Item
 */
export const addUpdateNewVendorPersonService = (
  addUpdateNewVendorPersonRequest: AddUpdateNewVendorPersonRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.VENDORS,
          token,
          addUpdateNewVendorPersonRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.VENDORS,
          token,
          addUpdateNewVendorPersonRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        
        
        dispatch(clearNewVendorData());
        dispatch(clearTotalNewVendorData());
        if (type === "add") {
          dispatch(clearNewVendorPageData());
          toast.success("Vendor added successfully!");
          navigate("/vendor-person");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Vendor undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getNewVendorPageData(pageNumber + 1));
              } else {
                dispatch(clearNewVendorPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Vendor updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/vendor-person");
              });
            } else {
              toast.success("Vendor status updated successfully!");
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getNewVendorPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting invertory item
 */
export const deleteNewVendorPersonService = (
  deleteId: number,
  token: string,
  pageNumber: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(
        API_URL.BASE_URL + API_URL.VENDORS + "/" + deleteId,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearNewVendorData());
        dispatch(clearTotalNewVendorData());
        toast.success("Vendor deleted successfully!");
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getNewVendorPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};
