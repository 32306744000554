const unitsInitialState = {
    items: []
}

/**
 * Store Units list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const UnitsReducer = (state = unitsInitialState, action) => {
    switch (action.type) {
        case 'GET_UNITS_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_UNITS_DATA':
            return unitsInitialState;
        default:
            return state;
    };
}

const totalUnitsInitialState = {
    items: []
}

/**
 * Store total units list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalUnitsReducer = (state = totalUnitsInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_UNITS':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_UNITS':
            return totalUnitsInitialState
        default:
            return state;
    };
}