const productInventoryInitialState = { 
    items: []
};

/**
 * Store product inventory list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ProductInventoryReducer = (state = productInventoryInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_INVENTORY_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_PRODUCT_INVENTORY_DATA':
            return productInventoryInitialState;
        default:
            return state;
      };
}

const totalProductInventoryInitialState = {
    items: []
}

/**
 * Store total product inventory list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalProductInventoryReducer = (state = totalProductInventoryInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_PRODUCT_INVENTORY':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_PRODUCT_INVENTORY':
            return totalProductInventoryInitialState
        default:
            return state;
      };
}