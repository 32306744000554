import { Dispatch } from "redux";
import { addUpdateProductInventoryRequest, getAllProductInventoryRequest, getAllProductInventoryResponse } from "./ProductInventoryModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import { API_URL, removePersistStorage, toastClass, ValidationMessage } from "../../../Utils/Utils";
import { clearProductInventoryData, clearTotalProductInventoryData, getProductInventoryData, getTotalProductInventoryData } from "../../../redux/Actions/ProductInventoryAction/ProductInventoryAction";
import { clearProductInventoryPageData, getProductInventoryPageData } from "../../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";
import { deleteApiService } from "../../../services/API-services/DeleteService";


let errorShow = false;

/*
 * Service to get brand list
*/
export const getAllProductInventoryService = (
  productInventoryRequest: getAllProductInventoryRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<getAllProductInventoryResponse> => {
  return new Promise<getAllProductInventoryResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (productInventoryRequest.p_skip !== undefined && productInventoryRequest.p_skip !== null) {
        queryParams += `p_skip=${productInventoryRequest.p_skip}&`;
      }
      if (productInventoryRequest.p_take !== undefined && productInventoryRequest.p_take !== null) {
        queryParams += `p_take=${productInventoryRequest.p_take}&`;
      }
      if (productInventoryRequest.p_product_id !== undefined && productInventoryRequest.p_product_id !== null) {
        queryParams += `p_product_id=${productInventoryRequest.p_product_id}&`;
      }

      // if (brandListRequest.p_is_active !== undefined && brandListRequest.p_is_active !== null) {
      //   queryParams += `p_is_active=${brandListRequest.p_is_active}&`
      // }
      // if (brandListRequest.p_IsDeleted !== undefined && brandListRequest.p_IsDeleted !== null) {
      //   queryParams += `p_IsDeleted=${brandListRequest.p_IsDeleted}&`
      // }
      const response = await getApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM + API_URL.PRODUCTINVENTORY + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalProductInventoryData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getProductInventoryData(response));
        }
        else {
          dispatch(getProductInventoryData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Product Inventory permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }

    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating Product Inventory
*/
export const addUpdateProductInventoryService = (
  addUpdateProductInventoryRequest: addUpdateProductInventoryRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM +API_URL.PRODUCTINVENTORY, token, addUpdateProductInventoryRequest);
      }
      if (type === 'update') {
        response = await postApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM +API_URL.PRODUCTINVENTORY, token, addUpdateProductInventoryRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearProductInventoryData());
        dispatch(clearTotalProductInventoryData());
        if (type === 'add') {
          dispatch(clearProductInventoryPageData());
          toast.success('Product added successfully!');
          navigate('/product-inventory');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Product undeleted successfully!');
          }
          else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getProductInventoryPageData(pageNumber + 1))
              }
              else {
                dispatch(clearProductInventoryPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Product updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/product-inventory');
              });
            }
            else {
              toast.success('Brand status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getProductInventoryPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }

    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting Product Inventory
*/
export const deleteProductInventoryService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
      try {
          dispatch(setLoader());
          const response = await deleteApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM +API_URL.PRODUCTINVENTORY + '/' + deleteId, token);
          if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
              dispatch(clearProductInventoryData());
              dispatch(clearTotalProductInventoryData());
              toast.success('Product Item deleted successfully!');
          }
          else {
              toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
              errorShow = true;
              if (response?.message && response.message === 'Unauthorized access!') {
                  removePersistStorage(dispatch);
                  // dispatch(logout());
              }
              dispatch(getProductInventoryPageData(pageNumber))
          }
      } catch (error) {
          if (errorShow === false) {
              toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
          }

      } finally {
          errorShow = false;
          dispatch(clearLoader());
      }
  };
};

/*
 * Service to get individual Product Inventory
*/
// export const getInventoryItemById = (
//   id: string, token: string,
//   dispatch: Dispatch): Promise<getProductInventoryByIdResponse> => {
//   return new Promise<getProductInventoryByIdResponse>(async (resolve, reject) => {
//     try {
//       dispatch(setLoader())
//       const response = await getApiService(API_URL.BASE_URL + API_URL.PRODUCTINVENTORY + '/' + id, token);
//       if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
//         resolve(response);
//       }
//       else {
//         if (!document.querySelector(toastClass)) {
//           toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
//         }
//         errorShow = true;
//         if (response?.message && response.message === 'Unauthorized access!') {
//           removePersistStorage(dispatch);
//           // dispatch(logout());
//         }
//       }
//       resolve(response);
//     } catch (error) {
//       if (errorShow === false) {
//         toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
//       }
      
//     } finally {
//       errorShow = false;
//       dispatch(clearLoader());
//     }
//   });
// };