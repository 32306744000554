import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Filter from "../../components/Filter/Filter";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import { clearPurchaseRequisitionFilterData } from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionFilterAction";
import {
  clearPurchaseRequisitionPageData,
  getPurchaseRequisitionPageData,
} from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionPageAction";
import {
  clearPurchaseRequisitionData,
  getPurchaseRequisitionData,
} from "../../redux/Actions/PurchaseRequisitionAction/PurchaseRequisitionAction";
import { getAllPurchaseRequisitionService } from "./PurchaseRequisitionService/PurchaseRequisitionService";
import { format } from "date-fns";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";

const PurchaseRequisition = () => {
  const purchaseRequisitionData = useSelector(
    (state) => state.purchaseRequisition
  );
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const purchaseRequisitionPage = useSelector(
    (state) => state.purchaseRequisitionPage?.items
  );
  const purchaseRequisitionFilter = useSelector(
    (state) => state.purchaseRequisitionFilter
  );
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [purchaseRequisitionActionData, setPurchaseRequisitionActionData] =
    useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setPurchaseRequisitionActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    phNum:true,
    prNum:true,
    orderStatus: true,
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.phNum,
    },
    {
      name: "PR number",
      selector: (row) => row.prNum,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    }
  ];

  const showActions = {
    showEdit: true,
    showView: true,
    showPO: true,
    navigate: "/add-purchase-requisition",
  };

  const styleData = {
    quantity: { type: { textAlign: "end" } },
  };

  const tooltipRef = useRef(null);

  /**
   * Fetch purchase requisition item data
   */
  const fetchPurchaseRequisition = () => {
    const requestBody = {
      p_skip:
        purchaseRequisitionPage > 1
          ? (purchaseRequisitionPage - 2) * 10
          : (purchaseRequisitionPage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (
      purchaseRequisitionFilter.purchaseRequisitionNo !== "" &&
      purchaseRequisitionFilter.purchaseRequisitionNo !== null
    ) {
      requestBody.p_purchase_requisition_no =
        purchaseRequisitionFilter.purchaseRequisitionNo;
    }
    if (
      purchaseRequisitionFilter.orderStartDate !== "" &&
      purchaseRequisitionFilter.orderStartDate !== null
    ) {
      requestBody.p_created_date = purchaseRequisitionFilter.orderStartDate;
    }
    if (
      purchaseRequisitionFilter.orderEndDate !== "" &&
      purchaseRequisitionFilter.orderEndDate !== null
    ) {
      requestBody.p_end_date = purchaseRequisitionFilter.orderEndDate;
    }
    if (
      purchaseRequisitionFilter.vendor !== "" &&
      purchaseRequisitionFilter.vendor !== null
    ) {
      requestBody.p_vendor_id = purchaseRequisitionFilter.vendor;
    }
    if (
      purchaseRequisitionFilter.status !== "" &&
      purchaseRequisitionFilter.status !== null
    ) {
      requestBody.p_status = purchaseRequisitionFilter.status;
    }
    // return;
    getAllPurchaseRequisitionService(requestBody, tokenData, dispatch);
    if (purchaseRequisitionPage > 1) {
      setCurrentPage(purchaseRequisitionPage - 1);
      dispatch(getPurchaseRequisitionPageData(purchaseRequisitionPage - 1));
    }
  };

  useEffect(() => {
    if (
      (purchaseRequisitionData &&
        purchaseRequisitionData.items &&
        purchaseRequisitionData.items.length === 0) ||
      !purchaseRequisitionData
    ) {
      fetchPurchaseRequisition();
    } else {
      if (
        purchaseRequisitionFilter?.purchaseRequisitionNo !== "" ||
        (purchaseRequisitionFilter?.orderStartDate &&
          purchaseRequisitionFilter?.orderStartDate !==
            format(new Date(), "yyyy-MM-dd")) ||
        purchaseRequisitionFilter?.orderEndDate !== "" ||
        (purchaseRequisitionFilter?.vendor &&
          purchaseRequisitionFilter.vendor !== "") ||
        purchaseRequisitionFilter?.status !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (
      purchaseRequisitionData.items[0]?.data &&
      purchaseRequisitionData.items[0].data.length > 0
    ) {
      const transformedPurchaseRequisitionData =
        purchaseRequisitionData.items[0].data.map((item) => {
          return {
            id: item.id,
            name: item.vendor_user_first_name + " " + item.vendor_user_last_name,
            phNum: item.vendor_user_phone_number,
            prNum: item.pr_number,
            prDate: item.pr_date,
            orderStatus: item.status,
            instructions: item.instructions,
            ingredients: item.ingredients_data,
            timeZone: item.timeZone,
            vendorUserId: item.vendor_user_id,
            modifiedAt: item.modified_at,
            vendor_user_id: item.vendor_user_id,
            assign_user_id:item.assign_user_id,
            assign_user_phone_number:item.assign_user_phone_number
          };
        });
      setTableData(transformedPurchaseRequisitionData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [purchaseRequisitionData, purchaseRequisitionFilter]);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (
        purchaseRequisitionFilter.purchaseRequisitionNo !== "" &&
        purchaseRequisitionFilter.purchaseRequisitionNo !== null
      ) {
        requestBody.p_purchase_requisition_no =
          purchaseRequisitionFilter.purchaseRequisitionNo;
      }
      if (
        purchaseRequisitionFilter.orderStartDate !== "" &&
        purchaseRequisitionFilter.orderStartDate !== null
      ) {
        const date = new Date(purchaseRequisitionFilter.orderStartDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }
      if (
        purchaseRequisitionFilter.orderEndDate !== "" &&
        purchaseRequisitionFilter.orderEndDate !== null
      ) {
        const date = new Date(purchaseRequisitionFilter.orderEndDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const dateTimeFormat = `${year}-${month}-${day}`;
        requestBody.p_end_date = dateTimeFormat;
      }
      if (
        purchaseRequisitionFilter.vendor !== "" &&
        purchaseRequisitionFilter.vendor !== null
      ) {
        requestBody.p_vendor_id = purchaseRequisitionFilter.vendor;
      }
      if (
        purchaseRequisitionFilter.status !== "" &&
        purchaseRequisitionFilter.status !== null
      ) {
        requestBody.p_status = purchaseRequisitionFilter.status;
      }

      getAllPurchaseRequisitionService(requestBody, tokenData, dispatch);
      dispatch(getPurchaseRequisitionData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearPurchaseRequisitionFilterData());
    dispatch(clearPurchaseRequisitionPageData());
    dispatch(clearPurchaseRequisitionData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {purchaseRequisitionActionData &&
                    purchaseRequisitionActionData.length > 0
                      ? purchaseRequisitionActionData[0].name
                      : "Purchase Requisition"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {purchaseRequisitionActionData &&
                    purchaseRequisitionActionData.length > 0
                      ? purchaseRequisitionActionData[0].name
                      : "Purchase Requisition"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {purchaseRequisitionFilter.purchaseRequisitionNo !== "" ||
                (purchaseRequisitionFilter?.orderStartDate !== "" &&
                  purchaseRequisitionFilter?.orderStartDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                (purchaseRequisitionFilter?.orderEndDate !== "" &&
                  purchaseRequisitionFilter?.orderEndDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                purchaseRequisitionFilter.vendor !== "" ||
                purchaseRequisitionFilter.status !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="purchase-requisition" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Purchase Requisition Item"
                  onClick={() => navigate("/add-purchase-requisition")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
              </div>
            </div>
            {/* {isToggled && <Filter page="purchase-requisition"></Filter>} */}
            {purchaseRequisitionData &&
              purchaseRequisitionData.items[0]?.data &&
              purchaseRequisitionData.items[0].data && (
                <>
                  <CustomTable
                    page="purchase-requisition"
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActions }
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    styleData={styleData}
                    disabled={
                      purchaseRequisitionActionData &&
                      purchaseRequisitionActionData[0]?.edit &&
                      purchaseRequisitionActionData[0].edit === "0"
                    }
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                  

                  {purchaseRequisitionData.items[0].total_records >
                    purchaseRequisitionData.items[0].data.length &&
                    purchaseRequisitionData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={
                          purchaseRequisitionData.items[0].total_records
                        }
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PurchaseRequisition;
