const stockHistoryPageInitialState = { 
    items: 1
};

/**
 * Store stock history page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const StockHistoryPageReducer = (state = stockHistoryPageInitialState, action) => {
    switch (action.type) {
        case 'GET_STOCK_HISTORY_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_STOCK_HISTORY_PAGE':
            return stockHistoryPageInitialState;
        default:
            return state;
      };
}