const GRNInitialState = {
  items: [],
};

/**
 * Store GRN list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const GRNReducer = (state = GRNInitialState, action) => {
  switch (action.type) {
    case "GET_GRN_DATA":
      return {
        ...state,
        items: [action.payload],
      };
    case "CLEAR_GRN_DATA":
      return GRNInitialState;
    default:
      return state;
  }
};

const totalGRNInitialState = {
  items: [],
};

/**
 * Store total GRN list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalGRNReducer = (state = totalGRNInitialState, action) => {
  switch (action.type) {
    case "GET_TOTAL_GRN":
      return {
        ...state,
        items: [action.payload],
      };
    case "CLEAR_TOTAL_GRN":
      return totalGRNInitialState;
    default:
      return state;
  }
};
