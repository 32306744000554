import { Dispatch } from "redux";
import { BranchTokenRequest, LoginResponse } from "./BranchTokenModal";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { updateAuthData } from "../../../redux/Actions/authAction/AuthAction";

let errorShow = false;

export const GetBranchTokenService = (
  requestBody: BranchTokenRequest,
  token: string,
  dispatch: Dispatch
) => {
  return new Promise<LoginResponse>(async (resolve, reject) => {
    dispatch(setLoader());
    try {
      const response = await postApiService(
        API_URL.BASE_URL + API_URL.BRANCH_TOKEN,
        token,
        requestBody
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(updateAuthData({user: response, reload: true}));
        // toast.success('Branch updated successfully!');
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};