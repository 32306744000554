import { Dispatch } from "redux";
import { getAllStockHistoryRequest, getAllStockHistoryResponse } from "./StockHistoryModal";
import { clearLoader, setLoader } from "../../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../../services/API-services/GetService";
import { API_URL, removePersistStorage, toastClass, ValidationMessage } from "../../../../Utils/Utils";
import { getStockHistoryData, getTotalStockHistoryData } from "../../../../redux/Actions/StockHistoryAction/StockHistoryAction";
import { toast } from "react-toastify";


let errorShow = false;

/*
 * Service to get vendor list
 */
export const getAllStockHistoryService = (
  stockHistoryListRequest: getAllStockHistoryRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllStockHistoryResponse> => {
  // Update return type here
  return new Promise<getAllStockHistoryResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        stockHistoryListRequest.p_skip !== undefined &&
        stockHistoryListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${stockHistoryListRequest.p_skip}&`;
      }
      if (
        stockHistoryListRequest.p_take !== undefined &&
        stockHistoryListRequest.p_take !== null
      ) {
        queryParams += `p_take=${stockHistoryListRequest.p_take}&`;
      }
      if (
        stockHistoryListRequest.p_ingredient_id !== undefined &&
        stockHistoryListRequest.p_ingredient_id !== null
      ) {
        queryParams += `p_ingredient_id=${stockHistoryListRequest.p_ingredient_id}&`;
      }
      if (
        stockHistoryListRequest.p_created_date !== undefined &&
        stockHistoryListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${stockHistoryListRequest.p_created_date}&`;
      }
      if (
        stockHistoryListRequest.p_created_time !== undefined &&
        stockHistoryListRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${stockHistoryListRequest.p_created_time}&`;
      }
      if (
        stockHistoryListRequest.p_time_zone !== undefined &&
        stockHistoryListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${stockHistoryListRequest.p_time_zone}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.STOCKS + API_URL.STOCKSHISTORY +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalStockHistoryData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getStockHistoryData(response));
        } else {
          dispatch(getStockHistoryData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Vendor permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};