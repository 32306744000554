import React, { useEffect, useRef, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useSelector } from "react-redux";
import "../IngredientCard/IngredientCard.scss";

const IngredientCard = (props) => {
  const {
    addedIngredientData,
    ingredientData,
    addedInventory,
    handleRemoveIngrediant,
    handleUpdateIngredient,
    showAddBtn,
    Data,
  } = props;

  const tooltipRef = useRef(null);
  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const [quantity, setQuantity] = useState("0");
  const [unit, setUnit] = useState("");
  const [instruction, setInstruction] = useState("");
  const [generalInstruction, setGeneralInstruction] = useState("");
  const [prStatus, setPrStatus] = useState("0");

  const statusOptions = [
    {
      id: 1,
      value: "0",
      label: "Pending",
    },
    {
      id: 1,
      value: "1",
      label: "Approved",
    },
    {
      id: 2,
      value: "2",
      label: "Rejected",
    },
  ];

  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);
  const [unitOptions, setUnitOptions] = useState([]);
  useEffect(() => {
    if (
      totalUnitsDropdown &&
      totalUnitsDropdown.items &&
      totalUnitsDropdown.items.length > 0 &&
      totalUnitsDropdown.items[0]?.data &&
      totalUnitsDropdown.items[0].data.length > 0
    ) {
      const unitData = totalUnitsDropdown.items[0].data.map((unit) => ({
        value: unit.unit_value,
        label: unit.unit,
      }));

      setUnitOptions(unitData);
    } else {
      setUnitOptions([]);
    }
  }, [totalUnitsDropdown]);

  useEffect(() => {
    if (addedIngredientData) {
      setQuantity(addedIngredientData.quantity);
      setUnit(addedIngredientData.unit);
      setInstruction(addedIngredientData.instruction || "");
      setGeneralInstruction(addedIngredientData.generalInstruction || "");
      setPrStatus(addedIngredientData.status);
    }
  }, [addedIngredientData]);

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(value);
      handleUpdateIngredient({
        ...addedIngredientData,
        quantity: value,
        unit,
        instruction,
        status: prStatus,
        generalInstruction,
      });
    }
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setUnit(newUnit);
    handleUpdateIngredient({
      ...addedIngredientData,
      quantity,
      unit: newUnit,
      instruction,
      status: prStatus,
      generalInstruction,
    });
  };
  const handleGenInstruction = (e) => {
    const newMsg = e.target.value;
    setGeneralInstruction(newMsg);
    handleUpdateIngredient({
      ...addedIngredientData,
      quantity,
      unit: unit,
      instruction,
      status: prStatus,
      generalInstruction: newMsg,
    });
  };

  const handleInstructionChange = (e) => {
    const newInstruction = e.target.value;

    setInstruction(newInstruction);
    handleUpdateIngredient({
      ...addedIngredientData,
      quantity,
      unit,
      instruction: newInstruction,
      status: prStatus,
      generalInstruction,
    });
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setPrStatus(newStatus);
    if (newStatus === "0" || newStatus === "1") {
      handleUpdateIngredient({
        ...addedIngredientData,
        quantity,
        unit,
        instruction: "",
        status: newStatus,
        generalInstruction,
      });
    } else {
      handleUpdateIngredient({
        ...addedIngredientData,
        quantity,
        unit,
        instruction,
        status: newStatus,
        generalInstruction,
      });
    }
  };

  return (
    <>
      {showAddBtn && (
        <div className="new-product-card">
          <div className="ingredient-detail">
            <h6>{ingredientData.ingredientName}</h6>
            <div data-bs-toggle="tooltip" title="Add" className="add-button1">
              <FaPlus />
            </div>
          </div>
        </div>
      )}

      {!showAddBtn && addedInventory && addedInventory.length > 0 && (
        <div className="new-product-card">
          <div className="ingredient-detail">
            <h6>{addedIngredientData.ingredientName}</h6>
            <div
              data-bs-toggle="tooltip"
              title="Remove"
              className="add-button1"
              onClick={() => {
                if (Data && Data.isEdit === true) {
                  handleRemoveIngrediant(addedIngredientData);
                } else if (!Data) {
                  handleRemoveIngrediant(addedIngredientData);
                }
              }}
            >
              <FaMinus />
            </div>
          </div>
          <div className="ingredient-container">
            <div className="ingredient-inputs">
              <div>
                <input
                  type="text"
                  value={quantity}
                  onChange={handleQuantityChange}
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0,10);
                    }
                  }}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowUp",
                      "ArrowDown",
                      "ArrowLeft",
                      "ArrowRight",
                    ];
                    if (!/^\d*$/.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="add-roles form-control"
                  min="1"
                  disabled={Data && Data.isEdit === false}
                />
              </div>
              <div>
                <select
                  value={unit}
                  onChange={handleUnitChange}
                  className="add-roles custom-dropdown"
                  disabled={Data && Data.isEdit === false} // Disable condition
                >
                  <option value="" disabled>
                    Select unit
                  </option>
                  {unitOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <textarea
              // style={{
              //   marginLeft: addedIngredientData.status === "2" ? "20px" : "0",
              // }}
              value={generalInstruction}
              placeholder="IngredientInstruction"
              onChange={handleGenInstruction}
              disabled={Data && Data.isEdit === false}
            />
            {Data && (
              <div className="ingredient-inputs">
                <div>
                  <select
                    value={prStatus}
                    onChange={handleStatusChange}
                    className="add-roles custom-dropdown-1"
                    disabled={Data && Data.isEdit === false}
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                {addedIngredientData.status === "2" && (
                  <div>
                    <textarea
                      style={{
                        marginLeft:
                          addedIngredientData.status === "2" ? "20px" : "0",
                      }}
                      value={instruction}
                      placeholder="Reason to reject?"
                      onChange={handleInstructionChange}
                      disabled={Data && Data.isEdit === false} // Disable condition
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default IngredientCard;
