import React from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import Toggle from '../../../components/Toggle/Toggle';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationMessage } from '../../../Utils/Utils';
import FileInput from '../../../components/input/FileInput';
import { handleCancleClick } from '../../../components/Alertmessage/Alertmessage';

//Setting initial values
const initialValues = {
    upload1: null,
    Title: '',
    Url: '',
    category: '',
    description:'',
    status: true
}

//Validation schema
const validationSchema = Yup.object({
  Title: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  Url: Yup.string().required(ValidationMessage.URL_REQUIRED),
  category: Yup.string().required(ValidationMessage.SINGLE_CATEGORY_SELECT),
  description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
})
  
//Handling form submission
const handleSubmit = (values)=>{

}
  

export default function AddTv() {

  const navigate = useNavigate();

  const brandtypeOptions = [
    { value: "Category 1", label: "Category 1" },
    { value: "Category 2", label: "Category 2" },
  ];

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Add Tv</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate('/tv')}>Tv</Breadcrumb.Item>
                  <Breadcrumb.Item active>Add Tv</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>Add Tv</h4>
            </div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Upload Thumbnail</h4>
                        </div>
                        <Field id="upload1" name='upload1' maxCount={1} component={FileInput} placeholder='No image selected' accept=".jpg, .jpeg, .png, .webp" className="upload"></Field>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Title<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="Title"
                          label="Title"
                          placeholder="Title"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.Title}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Url<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="Url"
                          label="Url"
                          placeholder="Url"
                          className="add-roles"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Category<span className="required-label"></span></h4>
                        <Field
                          className="select-menu mb-2"
                          name="category"
                          options={brandtypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Description<span className="required-label"></span></h4>
                        <Field name="description" component={CKEditorCpmponent} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Show on Blog</h4>
                        <Field name='status' setFieldValue={setFieldValue} component={Toggle} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          <Button type="submit" name="Save" className="btn login-btn" />
                          <Button
                            type="button"
                            name="Cancel"
                            className="btn clear-btn login-btn"
                            onClick={()=> handleCancleClick('/tv', navigate)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
