const unitsFilterInitialState = {
    unitId: '',
    isActive: '',
    isDelete: '',
};

/**
 * Store units filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const unitsFilterReducer = (state = unitsFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_UNITS_FILTER':
            return {
                ...state,
                title:action.payload.title,
                isActive: action.payload.isActive,
                // isDelete: action.payload.isDelete,
            }
        case 'CLEAR_UNITS_FILTER':
            return unitsFilterInitialState;
        default:
            return state;
    };
}