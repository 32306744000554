export const getApiService = async (Url, token, bodyData) => {
  if (!token && !bodyData) {
    try {
      const response = await fetch(Url, { method: 'GET' });
      if (!response.ok) {
        const data = await response.json();
        return data;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("get api error: ", error);
      // toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  }
  else {
    try {
      const response = await fetch(Url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }, body: JSON.stringify(bodyData),
      });
      if (!response.ok) {
        const data = await response.json();
        return data;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("get api error: ", error);
      // toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  }
}