/**
 * set purchase order filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getPurchaseOrderFilterData = ({
  purchaseOrderNo,
  orderStartDate,
  orderEndDate,
}) => ({
  type: "GET_PURCHASE_ORDER_FILTER",
  payload: {
    purchaseOrderNo,
    orderStartDate,
    orderEndDate,
  },
});

/**
 * clear purchase order filtered data
 * @returns {*}
 */
export const clearPurchaseOrderFilterData = () => ({
  type: "CLEAR_PURCHASE_ORDER_FILTER",
});
