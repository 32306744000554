import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import CustomTable from "../../components/Table/Table";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import { getAllInventoryService } from "./InventoryService/InventoryService";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearInventoryPageData,
  getInventoryPageData,
} from "../../redux/Actions/InventoryAction/InventoryPageAction";
import { clearInventoryData } from "../../redux/Actions/InventoryAction/InventoryAction";
import { clearInventoryFilterData } from "../../redux/Actions/InventoryAction/InventoryFilterAction";

//Show keys which is to be displayed in table
const showKey = {
  product_name: true,
  sku: true,
  product_code: true,
  warehouse_name: true,
  remaining_stock: true,
};

export default function Inventory() {
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const inventoryData = useSelector((state) => state.inventory);
  const inventoryPage = useSelector((state) => state.inventoryPage?.items);
  const inventoryFilter = useSelector((state) => state.inventoryFilter);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [inventoryActionData, setinventoryActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setinventoryActionData(actionData);
    }
  }, [sideBarData]);

  const showActions = {
    showPlus:
      (inventoryActionData[0]?.add && inventoryActionData[0].add === "1") || !inventoryActionData
        ? true
        : false,
    navigate:
      (inventoryActionData[0]?.add && inventoryActionData[0].add === "1") || !inventoryActionData
        ? "/add-inventory"
        : false,
  };

  // const showActions = {
  //   showPlus: true,
  //   navigate: "/add-inventory",
  // };

  const [columns, setColumns] = useState([
    {
      name: "Product Name",
      selector: (row) => row.Name,
    },
    {
      name: "SKU",
      selector: (row) => row.Value,
    },
    {
      name: "Product code",
      selector: (row) => row.Code,
    },
    {
      name: "Warehouse",
      selector: (row) => row.location,
    },
    {
      name: "Current Stock",
      selector: (row) => row.available,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      inventoryActionData[0]?.view &&
      inventoryActionData[0].view === "0" &&
      inventoryActionData[0]?.edit &&
      inventoryActionData[0].edit === "0" &&
      inventoryActionData[0]?.add &&
      inventoryActionData[0].add === "0" &&
      inventoryActionData[0]?.delete &&
      inventoryActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Product Name",
          selector: (row) => row.Name,
        },
        {
          name: "SKU",
          selector: (row) => row.Value,
        },
        {
          name: "Product code",
          selector: (row) => row.Code,
        },
        {
          name: "Warehouse",
          selector: (row) => row.location,
        },
        {
          name: "Current Stock",
          selector: (row) => row.available,
        }
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Product Name",
          selector: (row) => row.Name,
        },
        {
          name: "SKU",
          selector: (row) => row.Value,
        },
        {
          name: "Product code",
          selector: (row) => row.Code,
        },
        {
          name: "Warehouse",
          selector: (row) => row.location,
        },
        {
          name: "Current Stock",
          selector: (row) => row.available,
        },
        {
          name: "Actions",
          selector: (row) => row.actions,
        }
      ]);
    }
  }, [inventoryActionData]);

  useEffect(() => {
    setCurrentPage(inventoryPage);
  }, [inventoryPage]);

  const token = UtilsData();
  const dispatch = useDispatch();

  /**
   * Fetch inventory list
   */
  const fetchInventory = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (inventoryPage > 1) {
      requestBody.p_skip = (inventoryPage - 2) * 10;
      dispatch(getInventoryPageData(inventoryPage - 1));
    } else {
      requestBody.p_skip = (inventoryPage - 1) * 10;
      dispatch(getInventoryPageData(inventoryPage));
    }
    if (
      inventoryFilter.productCode !== "" &&
      inventoryFilter.productCode !== null
    ) {
      requestBody.p_productCode = inventoryFilter.productCode;
    }
    if (inventoryFilter.sku !== "" && inventoryFilter.sku !== null) {
      requestBody.p_sku_code = inventoryFilter.sku;
    }
    if (inventoryFilter.name !== "" && inventoryFilter.name !== null) {
      requestBody.p_name = inventoryFilter.name;
    }
    if (
      inventoryFilter.warehouse !== "" &&
      inventoryFilter.warehouse !== null
    ) {
      requestBody.p_warehouse_id = inventoryFilter.warehouse;
    }
    if (inventoryFilter.isActive !== "" && inventoryFilter.isActive !== null) {
      requestBody.p_is_active = inventoryFilter.isActive;
    }
    // else {
    //   requestBody.p_is_active = '1';
    // }
    if (inventoryFilter.isDelete !== "" && inventoryFilter.isDelete !== null) {
      requestBody.p_isDeleted = inventoryFilter.isDelete;
    }
    getAllInventoryService(requestBody, token, dispatch);
  };

  useEffect(() => {
    if (
      inventoryData &&
      inventoryData.items &&
      inventoryData.items.length === 0
    ) {
      fetchInventory();
    } else {
      if (
        inventoryFilter.productCode !== "" ||
        inventoryFilter.sku !== "" ||
        inventoryFilter.name !== "" ||
        inventoryFilter.warehouse !== "" ||
        inventoryFilter.isActive !== "" ||
        inventoryFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [inventoryData]);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * On page change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      dispatch(getInventoryPageData(pageNumber));

      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (
        inventoryFilter.productCode !== "" &&
        inventoryFilter.productCode !== null
      ) {
        requestBody.p_productCode = inventoryFilter.productCode;
      }
      if (inventoryFilter.sku !== "" && inventoryFilter.sku !== null) {
        requestBody.p_sku_code = inventoryFilter.sku;
      }
      if (inventoryFilter.name !== "" && inventoryFilter.name !== null) {
        requestBody.p_name = inventoryFilter.name;
      }
      if (
        inventoryFilter.warehouse !== "" &&
        inventoryFilter.warehouse !== null
      ) {
        requestBody.p_warehouse_id = inventoryFilter.warehouse;
      }
      if (
        inventoryFilter.isActive !== "" &&
        inventoryFilter.isActive !== null
      ) {
        requestBody.p_is_active = inventoryFilter.isActive;
      }
      // else {
      //   requestBody.p_is_active = '1';
      // }
      if (
        inventoryFilter.isDelete !== "" &&
        inventoryFilter.isDelete !== null
      ) {
        requestBody.p_isDeleted = inventoryFilter.isDelete;
      }
      getAllInventoryService(requestBody, token, dispatch);
    }
  };

  /**
   * Clear filter data
   */
  const clearFilters = () => {
    dispatch(clearInventoryData());
    dispatch(clearInventoryFilterData());
    dispatch(clearInventoryPageData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div >
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{inventoryActionData && inventoryActionData.length > 0
                      ? inventoryActionData[0].name
                      : "Inventory"}</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {inventoryActionData && inventoryActionData.length > 0
                      ? inventoryActionData[0].name
                      : "Inventory"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                <Dropdown className="header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>Delete</Dropdown.Item>
                    <Dropdown.Item>Active</Dropdown.Item>
                    <Dropdown.Item>Inactive</Dropdown.Item>
                    <Dropdown.Item>Undo</Dropdown.Item>
                    {inventoryFilter &&
                      (inventoryFilter.productCode !== "" ||
                        inventoryFilter.sku !== "" ||
                        inventoryFilter.name !== "" ||
                        inventoryFilter.warehouse !== "" ||
                        inventoryFilter.isActive !== "" ||
                        inventoryFilter.isDelete !== "") && (
                        <Dropdown.Item onClick={clearFilters}>
                          Clear Filters
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="inventory"  handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                {inventoryData &&
                  inventoryData.items &&
                  inventoryData.items[0]?.data &&
                  inventoryData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={inventoryData.items[0].data}
                      fileName={"discount"}
                    ></ExcelData>
                  )}
              </div>
            </div>
            {/* {isToggled && <Filter page="inventory"></Filter>} */}
            {inventoryData &&
              inventoryData.items[0]?.data &&
              inventoryData.items[0].data && (
                <CustomTable
                  page="inventory"
                  showKey={showKey}
                  showActions={showActionBool ? showActions : false}
                  showTooltip={true}
                  data={inventoryData.items[0].data}
                  heading={columns}
                  checkBool={false}
                />
              )}
            {inventoryData &&
              inventoryData.items &&
              inventoryData.items[0]?.data &&
              inventoryData.items[0].data.length > 0 && (
                <>
                  {inventoryData.items[0].total_records >
                    inventoryData.items[0].data.length &&
                    inventoryData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={inventoryData.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
}
