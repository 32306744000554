import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import * as Yup from "yup";
import { ValidationMessage } from "../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import UploadImage from "../../components/UploadImage/UploadImage";
import ShowImage from "../../components/ShowImage/ShowImage";
import CKEditorCpmponent from "../../components/CKEditor-component/CKEditorCpmponent";

//Validation schema
const validationSchema = Yup.object({
  tagTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
});

//Handling form submission
const handleSubmit = (values) => {

};

export default function OrderDetail() {
  const [errorImages, setErrorImages] = useState([]);
  const [showImage, setShowImage] = useState(false);
  //Setting initial values
  const [initialValues, setInitialValues] = useState({
    orderID: "",
    productName: "",
    contactNumber: "",
    email: "",
    address: "",
    items: "",
    paymentDetails: "",
    subtotal: "",
    discounts: "",
    totalAmount: "",
    shippingCharges: "",
    trackingInformation: "",
    status: true,
  });

  const navigate = useNavigate();

  //Getting data from customer page
  const location = useLocation();
  const { Data } = location.state || {};

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        orderID: Data.data.id || "",
        productName: Data.data.name || "",
        contactNumber: Data.data.contactNumber || "",
        email: Data.data.email || "",
        address: Data.data.address || "",
        items: Data.data.items || "",
        paymentDetails: Data.data.paymentDetails || "",
        subtotal: Data.data.subtotal,
        discounts: Data.data.discounts || "",
        totalAmount: Data.data.totalAmount || "",
        shippingCharges: Data.data.shippingCharges || "",
        trackingInformation: Data.data.trackingInformation || "",
        status: Data.data.isActive || true,
      });
    }
  }, [Data, setInitialValues]);

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Order Detail</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/orders")}>
                    Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Order Detail</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>Order Detail</h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Order ID <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="orderID"
                          label="Order ID"
                          placeholder="Enter Order ID"
                          className="add-roles"
                          maxLength={50}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Product Name{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="productName"
                          label="Product Name"
                          placeholder="Enter Product Name"
                          className="add-roles"
                          maxLength={50}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Product Image</h4>
                        </div>
                        <UploadImage
                          name="upload1"
                          onChange={setFieldValue}
                          value={values.upload1}
                          disabled={Data && Data.isEdit === false}
                          setErrorImages={setErrorImages}
                          setShowImage={setShowImage}
                        />
                        <ShowImage
                          value={values.upload1}
                          errorImages={errorImages}
                          showImage={showImage}
                        />
                        {/* <UploadImage name='subCategoryImage'
                          onChange={setFieldValue} value={values.subCategoryImage}
                          disabled={Data && Data.isEdit === false} filterByType={6} setErrorImages={setErrorImages} setShowImage={setShowImage}></UploadImage>
                        <ShowImage value={values.subCategoryImage} errorImages={errorImages} showImage={showImage} ></ShowImage> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Customer Name{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="CustomerName"
                          label="Customer Name"
                          placeholder="Enter Customer Name"
                          className="add-roles"
                          maxLength={50}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Contact Number{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="contactNumber"
                          label="Contact Number"
                          placeholder="Enter Contact Number"
                          className="add-roles"
                          maxLength={50}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Email<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Enter Email"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Shipping Address
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="textarea"
                          name="address"
                          placeholder="Enter Shipping Address"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Billing Address
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="textarea"
                          name="address Billing"
                          placeholder="Enter Billing Address"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Items<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="Items"
                          name="items"
                          placeholder="Enter Items"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Payment Details
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="Payment Details"
                          name="paymentDetails"
                          placeholder="Enter Payment Details"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Subtotal <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="subtotal"
                          label="Subtotal"
                          placeholder="Enter Subtotal"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Discounts <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="discounts"
                          label="Discounts"
                          placeholder="Enter Discounts"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Total Amount{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="totalAmount"
                          label="Total Amount"
                          placeholder="Enter Total Amount"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Shipping Charges{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="shippingCharges"
                          label="Shipping Charges"
                          placeholder="Enter Shipping Charges"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Tracking Information{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="trackingInformation"
                          label="Tracking Information"
                          placeholder="Enter Tracking Information"
                          className="add-roles"
                          maxLength={70}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Order Notes</h4>
                        <Field
                          name="Order Notes"
                          component={CKEditorCpmponent}
                          disabled={Data && Data.isEdit === false}
                          maxLength={255}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Order Summary</h4>
                        <Field
                          name="description"
                          component={CKEditorCpmponent}
                          disabled={Data && Data.isEdit === false}
                          maxLength={255}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field
                          name="status"
                          setFieldValue={setFieldValue}
                          className="toggle"
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
}