/**
 * Setting Wallet list data into reducer
 * @param {*} wallet
 * @returns {*}
 */
export const getWalletData = (wallet) => ({
  type: "GET_WALLET_DATA",
  payload: wallet,
});

/**
 * clearing Wallet list data
 * @returns {*}
 */
export const clearWalletData = () => ({
  type: "CLEAR_WALLET_DATA",
});

/**
 * Setting total Wallet list data into reducer (No pagnation)
 * @param {*} wallet
 * @returns {*}
 */
export const getTotalWalletData = (wallet) => ({
  type: "GET_TOTAL_WALLET",
  payload: wallet,
});

/**
 * Clearing total Wallet list data (No pagination)
 * @returns {*}
 */
export const clearTotalWalletData = () => ({
  type: "CLEAR_TOTAL_WALLET",
});
