import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { getAllStocksService } from "./StocksService/StocksService";
import {
  clearStockPageData,
  getStockPageData,
} from "../../redux/Actions/StocksAction/StockPageAction";
import { clearStockFilterData } from "../../redux/Actions/StocksAction/StockFilterAction";
import { clearStockData } from "../../redux/Actions/StocksAction/StockAction";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Filter from "../../components/Filter/Filter";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import { GrMoreVertical } from "react-icons/gr";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus, FaHistory } from "react-icons/fa";
import {
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../services/Regular-services/ConvertUnitService";

const Stocks = () => {
  const stockData = useSelector((state) => state.stock) || { items: [] };
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const stockPage = useSelector((state) => state.stockPage?.items);
  const stockFilter = useSelector((state) => state.stockFilter);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [stockActionData, setStockActionData] = useState([]);
  // const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setStockActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    thresholdValue: true,
    thresholdUnit: true,
    value: true,
    unit: true,
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Threshold Quantity",
      selector: (row) => row.thresholdValue,
    },
    {
      name: "Threshold Unit",
      selector: (row) => row.thresholdUnit,
    },
    {
      name: "Remaining Quantity",
      selector: (row) => row.value,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
    },
    // {
    //   name: "Actions",
    //   cell: (row) => <Toggle checked={row.toggle} />,
    // },
  ];

  useEffect(() => {
    setCurrentPage(stockPage);
  }, [stockPage]);

  const fetchStock = () => {
    const requestBody = {
      p_skip: stockPage > 1 ? (stockPage - 2) * 10 : (stockPage - 1) * 10,
      p_take: 10,
    };
    if (stockFilter.ingredientId !== "" && stockFilter.ingredientId !== null) {
      requestBody.p_ingredient_id = stockFilter.ingredientId;
    }
    getAllStocksService(requestBody, tokenData, dispatch);
    if (stockPage > 1) {
      setCurrentPage(stockPage - 1); // Update the current page state
      dispatch(getStockPageData(stockPage - 1));
    }
  };

  useEffect(() => {
    if (stockData.items.length === 0) {
      fetchStock();
    } else {
      setIsToggled(stockFilter?.ingredientId !== "");
    }

    if (stockData.items[0]?.data && stockData.items[0].data.length > 0) {
      const transformedStockData = stockData.items[0].data.map((item) => {
        // Extract and convert threshold value and unit
        let thresholdValue = parseFloat(item.thresoldValue);
        let thresholdUnit = item.thresoldUnit;
        if (
          thresholdUnit === "gm" ||
          thresholdUnit === "kg" ||
          thresholdUnit === "ml" ||
          thresholdUnit === "l" ||
          thresholdUnit === "mg"
        ) {
          if (thresholdUnit === "gm" && Math.abs(Number(thresholdValue)) > 999) {
            thresholdValue = GramToKg(thresholdValue);
            thresholdUnit = "kg";
          } else if (thresholdUnit === "gm" && Math.abs(Number(thresholdValue)) < 1) {
            
            thresholdValue = GramToMg(thresholdValue);
            thresholdUnit = "mg";
          } else if (thresholdUnit === "ml" && Math.abs(Number(thresholdValue)) > 999) {
            thresholdValue = MlToLitre(thresholdValue);
            thresholdUnit = "l";
          }
        }

        // Extract and convert item value and unit
        let value = parseFloat(item.value);
        let unit = item.unit;

        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && Math.abs(Number(value)) > 999) {
            value = GramToKg(value);
            unit = "kg";
          } else if (unit === "gm" && Math.abs(Number(value)) < 1) {
            value = GramToMg(value);
            unit = "mg";
          } else if (unit === "ml" && Math.abs(Number(value)) > 999) {
            value = MlToLitre(value);
            unit = "l";
          }
        }
        return {
          id: item.stockId,
          name: item.ingredient_name,
          thresholdValue: thresholdValue,
          thresholdUnit: thresholdUnit,
          value: value,
          unit: unit,
          ingredient_id: item.ingredient_id,
          created_at: item.created_at,
        };
      });
      setTableData(transformedStockData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [stockData, stockFilter]);
  const styleData = {
    thresholdValue: { type: { textAlign: "end" } },
    value: { type: { textAlign: "end" } },
  };

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (
        stockFilter.ingredientId !== "" &&
        stockFilter.ingredientId !== null
      ) {
        requestBody.p_ingredient_id = stockFilter.ingredientId;
      }
      getAllStocksService(requestBody, tokenData, dispatch);
      dispatch(getStockPageData(pageNumber));
    }
  };

  const clearFilters = () => {
    dispatch(clearStockFilterData());
    dispatch(clearStockPageData());
    dispatch(clearStockData());
    setIsToggled(false);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div>
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {stockActionData && stockActionData.length > 0
                      ? stockActionData[0].name
                      : "Stock"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {stockActionData && stockActionData.length > 0
                      ? stockActionData[0].name
                      : "Stock"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {stockFilter.ingredientId !== "" ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {stockFilter.ingredientId !== "" && (
                        <Dropdown.Item onClick={clearFilters}>
                          Clear Filter
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="stocks" handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                {/* {((stockActionData &&
                  stockActionData[0]?.add &&
                  stockActionData[0].add === "1") ||
                  !stockActionData) && ( */}
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Stock"
                  onClick={() => navigate("/add-remaining-stock")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>
                {/* )} */}
                {/* {stockFilter?.items?.[0]?.data?.length > 0 && (
                  <ExcelData
                    excelData={stockFilter.items[0].data}
                    fileName={"stocks"}
                  />
                )} */}
                <Button
                  data-bs-toggle="tooltip"
                  title="Stock History"
                  onClick={() => navigate("/stock-history")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="History">
                      <FaHistory />
                    </div>
                  }
                ></Button>
              </div>
            </div>
            {/* {isToggled && <Filter page="stocks" />} */}

            {stockData?.items?.[0]?.data && (
              <>
                <CustomTable
                  page="stocks"
                  showToggle={false}
                  checkBool={false}
                  showKey={showKey}
                  showActions={false}
                  showTooltip={true}
                  data={tableData}
                  heading={columns}
                  disabled={stockData?.items?.[0]?.edit === "0"}
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                  styleData={styleData}
                />

                {stockData.items[0].total_records > stockData.items[0].data.length &&
                  stockData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={stockData.items[0].total_records}
                      currentPage={currentPageNumber}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </div>
  );
};

export default Stocks;
