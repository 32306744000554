const accountProductConfigInitialState = {
  items: [],
};

/**
 * Store Account Product Config list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const AccountProductConfigReducer = (
  state = accountProductConfigInitialState,
  action
) => {
  switch (action.type) {
    case "GET_ACC_PRODUCT_CONFIG_DATA":
      return {
        ...state,
        items: [action.payload],
      };
    case "CLEAR_ACC_PRODUCT_CONFIG_DATA":
      return accountProductConfigInitialState;
    default:
      return state;
  }
};
