/**
 * Setting units filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getUnitsFilterData = ({unitId, isActive}) => ({
    type: 'GET_UNITS_FILTER',
    payload: {unitId, isActive}
})

/**
 * Clearing units filtered data
 * @returns {*}
 */
export const clearUnitsFilterData = () => ({
    type: 'CLEAR_UNITS_FILTER'
})