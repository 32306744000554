import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import Footer from "../../../components/Footer/Footer";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import { Breadcrumb } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import Toggle from "../../../components/Toggle/Toggle";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { addUpdateInventoryItemService } from "../InventoryItemService/InventoryItemService";
import { clearInventoryItemPageData } from "../../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import { convertToBaseUnit } from "../../../services/Regular-services/ConvertUnitService";
import { modifyString } from "../../../services/Regular-services/StringService";
import { getAllCategoryService } from "../../Category/Category-service/CategoryService";

const validationSchema = Yup.object({
  name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
  quantity: Yup.string().required(ValidationMessage.QUANTITY_REQUIRED),
  unit: Yup.string().required(ValidationMessage.UNIT_REQUIRED),
  category: Yup.string().required(ValidationMessage.CATEGORY_REQUIRED),
});

export default function AddInventoryItem() {
  const [initialValues, setInitialValues] = useState({
    name: "",
    quantity: "",
    unit: "",
    status: 1,
    category: "",
    message: "",
  });

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [inventoryItemActionData, setInventoryItemActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "inventory-item"
      );
      setInventoryItemActionData(actionData);
    }
  }, [sideBarData]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const totalCategory = useSelector((state) => state.totalCategory);
  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);
  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    if (
      totalUnitsDropdown &&
      totalUnitsDropdown?.items &&
      totalUnitsDropdown.items.length > 0 &&
      totalUnitsDropdown.items[0]?.data &&
      totalUnitsDropdown.items[0].data.length > 0
    ) {
      const unitData = totalUnitsDropdown.items[0].data.map((unit) => ({
        value: unit.unit_value,
        label: unit.unit,
        id: unit.id,
      }));
      setUnitOptions(unitData);
    } else {
      setUnitOptions([]);
    }
  }, [totalUnitsDropdown]);

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        id: Data.data.id,
        name: Data.data.name,
        quantity: Data.data.quantity,
        unit: Data.data.unit,
        status:
          Data.data.status === "1" ||
          Data.data.status === 1 ||
          Data.data.status === true ||
          Data.data.status === "true"
            ? 1
            : 0,
        is_deleted: Data.data.is_delete,
        category: Data.data.category_id,
        category_name: Data.data.category_name,
        message: Data.data.instruction,
      });
    }
  }, [Data]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const fetchCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 100000000,
    };
    getAllCategoryService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalCategory &&
      totalCategory.items &&
      totalCategory.items.length === 0
    ) {
      fetchCategory();
    } else {
      if (
        totalCategory.items[0]?.data &&
        totalCategory.items[0].data.length > 0
      ) {
        const filteredData = totalCategory.items[0].data.filter(
          (item) => item.type === "4" || item.type === 4
        );
        const newCategory = filteredData.map((item) => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
        }));
        setCategoryOptionValue(newCategory);
      }
    }
    // eslint-disable-next-line
  }, [totalCategory]);

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    let { quantityInBaseUnit, unit } = convertToBaseUnit(
      values.quantity,
      values.unit
    );
    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_title: values.name,
      p_thresoldvalue: String(quantityInBaseUnit),
      p_thresoldunit: unit,
      p_isActive:
        values.status === true || values.status === 1 || values.status === "1"
          ? "1"
          : "0",
      p_category_id: String(values.category),
      p_instruction: values.message,
      ...(Data && Data.data && { p_IsDeleted: values.is_deleted }),
    };
    dispatch(clearInventoryItemPageData());
    if (!Data) {
      dispatch(
        addUpdateInventoryItemService(requestBody, token, "add", navigate)
      );
    } else {
      handlesaveClick(
        dispatch,
        addUpdateInventoryItemService(requestBody, token, "update", navigate),
        "update"
      );
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {inventoryItemActionData &&
                    inventoryItemActionData.length > 0
                      ? modifyString(inventoryItemActionData[0].name)
                      : "Inventory Item"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/inventory-item")}>
                    {inventoryItemActionData &&
                    inventoryItemActionData.length > 0
                      ? inventoryItemActionData[0].name
                      : "Inventory Item"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {inventoryItemActionData &&
                    inventoryItemActionData.length > 0
                      ? modifyString(inventoryItemActionData[0].name)
                      : "Inventory Item"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {inventoryItemActionData && inventoryItemActionData.length > 0
                  ? modifyString(inventoryItemActionData[0].name)
                  : "Inventory Item"}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Name <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="name"
                          label="Name"
                          placeholder="Enter name"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Threshold Quantity
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="quantity"
                          label="Quantity"
                          placeholder="Enter quantity"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Threshold Unit
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="unit"
                          options={unitOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.unit}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Ingredient Category{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="category"
                          options={categoryOptionValue}
                          component={CustomSelect}
                          placeholder="Select Category Name..."
                          isMulti={false}
                          value={values.category}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="message-input">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Ingredient Instruction{" "}
                          </h4>
                        </div>
                        <textarea
                          value={values.message}
                          placeholder="Would you like to add generalized instruction🧐? "
                          onChange={(event) =>
                            setFieldValue("message", event.target.value)
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field
                          name="status"
                          className="toggle"
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/inventory-item", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
