import { Dispatch } from "redux";
import {
  ImageAddRequest,
  ImageUpdateRequest,
  getAllImagesRequest,
  getAllImagesResponse,
} from "./ImageModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearImageData,
  clearTotalImageData,
  getImageData,
  getTotalImageData,
} from "../../../redux/Actions/imageGalleryAction/ImageGallaryAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearImagePageData,
  getImagePageData,
} from "../../../redux/Actions/imageGalleryAction/ImageGalleryPageAction";

let errorShow = false;

/*
 * Service to get image list
 */
export const getAllImageService = (
  imageListRequest: getAllImagesRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllImagesResponse> => {
  return new Promise<getAllImagesResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        imageListRequest.p_skip !== undefined &&
        imageListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${imageListRequest.p_skip}&`;
      }
      if (
        imageListRequest.p_take !== undefined &&
        imageListRequest.p_take !== null
      ) {
        queryParams += `p_take=${imageListRequest.p_take}&`;
      }
      if (
        imageListRequest.p_type_id !== undefined &&
        imageListRequest.p_type_id !== null
      ) {
        queryParams += `p_type_id=${imageListRequest.p_type_id}&`;
      }
      if (
        imageListRequest.p_IsDeleted !== undefined &&
        imageListRequest.p_IsDeleted !== null
      ) {
        queryParams += `p_IsDeleted=${imageListRequest.p_IsDeleted}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.IMAGEMANAGER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalImageData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getImageData(response));
        } else {
          dispatch(getImageData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Image permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding images
 */
export const addImageService = (
  addUpdateImageRequest: ImageAddRequest,
  token: string,
  toggle: any
) => {
  return async (dispatch: Dispatch) => {
    const formData = new FormData();
    addUpdateImageRequest.images.forEach((image, index) => {
      formData.append("images", image);
    });

    // Append other fields to the FormData object
    formData.append("type_id", addUpdateImageRequest.type_id.toString());
    formData.append("created_by", addUpdateImageRequest.created_by);
    try {
      dispatch(setLoader());
      const response = await fetch(API_URL.BASE_URL + API_URL.IMAGEMANAGER, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });
      dispatch(clearImageData());
      dispatch(clearTotalImageData());
      if (!response.ok) {
        const data = await response.json();
        toast.error(
          data?.message ? data.message : ValidationMessage.SOMETHING_WENT_WRONG
        );
        return data;
        // throw new Error('Network response was not ok');
      } else {
        toast.success("Images added successfully");
        const data = await response.json();
        dispatch(clearImagePageData());
        toggle();
        return data;
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for updating image
 */
export const updateImageService = (
  updateImageRequest: ImageUpdateRequest,
  token: string,
  toggle: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    const formData = new FormData();
    updateImageRequest.images.forEach((image, index) => {
      formData.append("images", image);
    });

    updateImageRequest.image_ids.forEach((item) => {
      formData.append("image_ids", JSON.stringify(item));
    });

    // Append other fields to the FormData object
    formData.append("type_id", updateImageRequest.type_id.toString());
    formData.append("created_by", updateImageRequest.created_by);
    formData.append("modified_by", updateImageRequest.modified_by);
    formData.append("IsDeleted", updateImageRequest.IsDeleted);
    try {
      dispatch(setLoader());
      const response = await fetch(API_URL.BASE_URL + API_URL.IMAGEMANAGER, {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });
      if (!response.ok) {
        const data = await response.json();
        toast.error(
          data?.message ? data.message : ValidationMessage.SOMETHING_WENT_WRONG
        );
        return data;
      } else {
        const data = await response.json();
        if (toggle && toggle === "undo") {
          toast.success("Image undeleted successfully");
        } else {
          toast.success("Image updated successfully");
          if (toggle) {
            if (pageNumber) {
              dispatch(getImagePageData(pageNumber + 1));
            } else {
              dispatch(clearImagePageData());
            }
            toggle();
          }
          dispatch(clearImageData());
          dispatch(clearTotalImageData());
        }
        return data;
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting image
 */
export const deleteImageService = (
  deleteId: number,
  token: string,
  pageNumber: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(
        API_URL.BASE_URL + API_URL.IMAGEMANAGER + "/" + deleteId,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearImageData());
        dispatch(clearTotalImageData());
        toast.success("Image deleted successfully!");
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getImagePageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};