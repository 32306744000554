/**
 * Set product pricing filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getProductPricingFilterData = ({ name, sku }) => ({
  type: "GET_PRODUCT_PRICING_FILTER",
  payload: { name, sku },
});

/**
 * Clear product pricing filtered data
 * @returns {*}
 */
export const clearProductPricingFilterData = () => ({
  type: "CLEAR_PRODUCT_PRICING_FILTER",
});
