const productPricingInitialState = { 
    items: []
};

/**
 * Store product Pricing list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ProductPricingReducer = (state = productPricingInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_PRICING_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_PRODUCT_PRICING_DATA':
            return productPricingInitialState;
        default:
            return state;
      };
}

const totalProductPricingInitialState = {
    items: []
}

/**
 * Store total product pricing list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalProductPricingReducer = (state = totalProductPricingInitialState, action) => {
    switch (action.type) {
        case 'TOTAL_PRODUCT_PRICING_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_PRICING_PRODUCT':
            return totalProductPricingInitialState
        default:
            return state;
      };
}