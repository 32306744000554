import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Breadcrumb, Dropdown, Offcanvas } from "react-bootstrap";
import Filter from "../../components/Filter/Filter";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import { FaFilter } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Button from "../../components/Button/Button";
import { getAllGoodReceiveNoteService } from "./GoodsReceiveNoteResponse/GoodsReceiveNoteService";
import {
  clearGRNPageData,
  getGRNPageData,
} from "../../redux/Actions/GoodReceiveNoteAction/GRNPageAction";
import UtilsData from "../../Utils/Utils";
import { format } from "date-fns";
import { clearGRNFilterData } from "../../redux/Actions/GoodReceiveNoteAction/GRNFilterAction";
import { clearGRNData } from "../../redux/Actions/GoodReceiveNoteAction/GRNAction";
import Toggle from "../../components/Toggle/Toggle";
import "../GoodsReceiveNote/GoodsReceiveNotes.scss";

const GoodsReceiveNote = () => {
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const token = UtilsData();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [GRNActionData, setGRNActionData] = useState([]);
  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setGRNActionData(actionData);
    }
  }, [sideBarData]);
  const GRNData = useSelector((state) => state.GRN);
  const GRNFilter = useSelector((state) => state.GRNFilter);
  const GRNpage = useSelector((state) => state.GRNpage?.items);
  const [tableData, setTableData] = useState([]);

  const showKey = {
    name: true,
    assignedUserName: true,
    grnNum: true,
    poNum: true,
    prNum: true,
  };


  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Assigned User",
      selector: (row) => row.assignedUserName,
    },
    {
      name: "GRN Number",
      selector: (row) => row.grnNum,
    },
    {
      name: "PO number",
      selector: (row) => row.poNum,
    },
    {
      name: "PR number",
      selector: (row) => row.prNum,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ];

  const showActions = {
    showView: true,
    navigate: "/add-goods-received-note",
  };

  const styleData = {
    // grnNum: {
    //   type: {
    //     maxWidth: "100%",
    //     // overflow: "hidden",
    //     // textOverflow: "ellipsis",
    //     whiteSpace: "nowrap",
    //   },
    // },
    name: {
      type: {
        width: "55%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    Name: {
      type: {
        width: "55%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    "Assigned User": {
      type: {
        width: "55%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    assignedUserName: {
      type: {
        width: "55%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    grnNum: {
      type: {
        maxWidth: "100%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        wordWrap: "break-word",
        overflowWrap: "break-word",
      },
    },
    poNum: {
      type: {
        maxWidth: "100%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        wordWrap: "break-word",
        overflowWrap: "break-word",
      },
    },
    prNum: {
      type: {
        maxWidth: "100%",
        // overflow: "hidden",
        // textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        wordWrap: "break-word",
        overflowWrap: "break-word",
      },
    },
  };

  /**
   * Fetch GRN item data
   */
  const fetchGRN = () => {
    const requestBody = {
      p_skip: GRNpage > 1 ? (GRNpage - 2) * 10 : (GRNpage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (GRNFilter.GRNNo !== "" && GRNFilter.GRNNo !== null) {
      requestBody.p_grn_no = GRNFilter.GRNNo;
    }
    if (GRNFilter.orderStartDate !== "" && GRNFilter.orderStartDate !== null) {
      requestBody.p_created_date = GRNFilter.orderStartDate;
    }
    if (GRNFilter.orderEndDate !== "" && GRNFilter.orderEndDate !== null) {
      requestBody.p_end_date = GRNFilter.orderEndDate;
    }
    getAllGoodReceiveNoteService(requestBody, token, dispatch);
    if (GRNpage > 1) {
      setCurrentPage(GRNpage - 1);
      dispatch(getGRNPageData(GRNpage - 1));
    }
  };

  useEffect(() => {
    if ((GRNData && GRNData.items && GRNData.items.length === 0) || !GRNData) {
      fetchGRN();
    } else {
      if (
        GRNFilter?.GRNNo !== "" ||
        (GRNFilter?.orderStartDate &&
          GRNFilter?.orderStartDate !== format(new Date(), "yyyy-MM-dd")) ||
        GRNFilter?.orderEndDate !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (GRNData.items[0]?.data && GRNData.items[0].data.length > 0) {
      const transformedGRNData = GRNData.items[0].data.map((item) => {
        return {
          id: item.id,
          name: item.vendor_user_first_name + " " + item.vendor_user_last_name,
          assignedUserName:
            item.assign_user_first_name + " " + item.assign_user_last_name,
          grnNum: item.grn_number,
          poNum: item.po_number,
          prNum: item.pr_number,
          ingredients: item.ingredients_data,
          po_id: item.po_id,
          pr_id: item.pr_id,
          instructions: item.instructions,
        };
      });
      setTableData(transformedGRNData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [GRNData, GRNFilter]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };
  // console.log("grn ", GRNData);
  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (GRNFilter.GRNNo !== "" && GRNFilter.GRNNo !== null) {
        requestBody.p_grn_no = GRNFilter.GRNNo;
      }
      if (
        GRNFilter.orderStartDate !== "" &&
        GRNFilter.orderStartDate !== null
      ) {
        const date = new Date(GRNFilter.orderStartDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }
      if (GRNFilter.orderEndDate !== "" && GRNFilter.orderEndDate !== null) {
        const date = new Date(GRNFilter.orderEndDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const dateTimeFormat = `${year}-${month}-${day}`;
        requestBody.p_end_date = dateTimeFormat;
      }

      getAllGoodReceiveNoteService(requestBody, token, dispatch);
      dispatch(getGRNPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearGRNFilterData());
    dispatch(clearGRNPageData());
    dispatch(clearGRNData());
    setIsToggled(false);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {GRNActionData && GRNActionData.length > 0
                      ? GRNActionData[0].name
                      : "Purchase Order"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {GRNActionData && GRNActionData.length > 0
                      ? GRNActionData[0].name
                      : "Goods Receive Notes"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {GRNFilter.GRNNo !== "" ||
                (GRNFilter?.orderStartDate !== "" &&
                  GRNFilter?.orderStartDate !==
                    format(new Date(), "yyyy-MM-dd")) ||
                (GRNFilter?.orderEndDate !== "" &&
                  GRNFilter?.orderEndDate !==
                    format(new Date(), "yyyy-MM-dd")) ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-postion">
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                 <Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter page="grn"  handleClose={handleClose}></Filter>
        </Offcanvas.Body>
      </Offcanvas>
              </div>
            </div>
            {/* {isToggled && <Filter page="grn"></Filter>} */}
            {GRNData && GRNData.items[0]?.data && GRNData.items[0].data && (
              <>
                <CustomTable
                  page="grn"
                  showToggle={false}
                  checkBool={false}
                  showKey={showKey}
                  showActions={showActions}
                  showTooltip={true}
                  data={tableData}
                  heading={columns}
                  styleData={styleData}
                  disabled={
                    GRNActionData &&
                    GRNActionData[0]?.edit &&
                    GRNActionData[0].edit === "0"
                  }
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />

                {GRNData.items[0].total_records >
                  GRNData.items[0].data.length &&
                  GRNData.items[0].total_records > 10 && (
                    <CustomPagination
                      totalRecords={GRNData.items[0].total_records}
                      currentPage={currentPage}
                      onPageChange={onPageChange}
                    />
                  )}
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default GoodsReceiveNote;
