const orderDashboardInitialState = {
    items: [],
    timeStamp: "",
    totalRecords: 0,
    api: false,
    dataCount: 0,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  
  /**
   * Store leaf level category list data
   * @param {*} state
   * @param {*} action
   * @returns {*}
   */
  export const DashboardOrderReducer = (state = orderDashboardInitialState, action) => {
    switch (action.type) {
      case "GET_DASHBOARD_ORDER_DATA":
  
        const pendingOrdersDataCount =
        action.payload?.order && action.payload.order.length > 0
          ? action.payload.order.filter((order) => order.status === "0").length
          : 0;
  
        return {
          ...state,
          items: action.payload.order,
          timeStamp: action.payload.time,
          totalRecords: action.payload.totalRecords,
          api: action.payload.api,
          dataCount: pendingOrdersDataCount,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      case "ADD_DASHBOARD_ORDER":
        const newOrders = action.payload.order; // Assuming payload contains an array of orders
  
        let updatedItems = [...state.items];
  
        if (updatedItems && updatedItems.length > 0) {
          newOrders.forEach(newOrder => {
            const newOrderId = newOrder.orderId; // Assuming each order has a unique 'orderId'
            const existingOrderIndex = updatedItems.findIndex(
              order => order.orderId === newOrderId,
            );
  
            if (existingOrderIndex >= 0) {
              // Update existing order
              updatedItems = [
                ...updatedItems.slice(0, existingOrderIndex),
                newOrder,
                ...updatedItems.slice(existingOrderIndex + 1),
              ];
            } else {
              // Add new order
              updatedItems = [...updatedItems, newOrder];
            }
          });
        } else {
          updatedItems = [...state.items, ...action.payload.order];
        }
  
        const pendingOrdersCount =
          updatedItems && updatedItems.length > 0
            ? updatedItems.filter(order => order.status === '0').length
            : state.dataCount;
  
        return {
          ...state,
          // items: [...state.items, ...action.payload.order],
          items: updatedItems,
          totalRecords: action.payload.totalRecords || state.totalRecords,
          timeStamp: action.payload.time || state.timeStamp,
          api: action.payload.api || state.api,
          dataCount: pendingOrdersCount,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      case "UPDATE_DASHBOARD_ORDER_STATUS":
        return {
          ...state,
          items: state.items?.length
            ? state.items.map((item) =>
                item.orderId === action.payload.orderId
                  ? { ...item, status: action.payload.status }
                  : item
              )
            : [],
          totalRecords: state.totalRecords,
          timeStamp: state.timeStamp,
          api: state.api,
          dataCount: state.dataCount,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      case "CLEAR_DASHBOARD_ORDER_DATA":
        return orderDashboardInitialState;
      default:
        return state;
    }
  };
  
  const totalDashboardOrderInitialState = {
    items: [],
  };
  
  /**
   * Store total leaf level category list data (No pagination)
   * @param {*} state
   * @param {*} action
   * @returns {*}
   */
  export const TotalDashboardOrderReducer = (state = totalDashboardOrderInitialState, action) => {
    switch (action.type) {
      case "GET_TOTAL_DASHBOARD_ORDER_DATA":
        return {
          ...state,
          items: [action.payload],
        };
      case "CLEAR_TOTAL_DASHBOARD_ORDER_DATA":
        return totalDashboardOrderInitialState;
      default:
        return state;
    }
  };
  