import React, { useEffect, useState } from "react";
import "./CRM.scss";
import ProfileIcon from "../../../assets/images/profile-icon.jpg";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCustomersOrderService } from "../CustomerService/CustomerService";
import {
  clearCustomerOrderPageData,
  getCustomerOrderPageData,
} from "../../../redux/Actions/CustomerAction/CustomerOrderPageAction";
import UtilsData from "../../../Utils/Utils";
import {
  clearCustomerOrderData,
  clearTotalCustomerOrder,
} from "../../../redux/Actions/CustomerAction/CustomerOrderAction";
import { modifyString } from "../../../services/Regular-services/StringService";

function CRM() {
  const navigate = useNavigate();
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const [activeTab, setActiveTab] = useState("Basic Details");
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [customerActionData, setcustomerActionData] = useState([]);
  const customerOrders = useSelector((state) => state.customerOrders);
  const [tableData, setTableData] = useState([]);
  const customerOrdersPage = useSelector(
    (state) => state.customerOrdersPage?.items
  );
  const [currentPageNumber, setCurrentPage] = useState(1);
  const location = useLocation();
  const { Data } = location.state || {};
  const [summary, setSummary] = useState({
    totalOrders: 0,
    totalOrderAmt: 0,
    name: "",
    phoneNumber: "",
    email: "",
  });

  const tabs = [
    "Basic Details",
    "Order List",
    "Notes",
    "Preferences",
    "Analytics",
  ];

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setcustomerActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      const data = Data.data;
      setSummary((prevSummary) => ({
        ...prevSummary,
        totalOrders: data.orders,
        totalOrderAmt: Number(data.total_order_amount).toFixed(2),
        name: data.name || "",
        phoneNumber: data.number || "",
        email: data.email || "",
      }));
      if (data.userId) {
        if (
          customerOrders &&
          customerOrders.items &&
          customerOrders.items.length === 0
        ) {
          setTableData([]);
          fetchCustomerOrders(data.userId);
        } else {
          // onPlay();
        }
      } else {
        toast.error("User doesnot exists!");
      }
    }
  }, [Data, customerOrders]);

  useEffect(() => {
    dispatch(clearCustomerOrderData());
    dispatch(clearTotalCustomerOrder());
    dispatch(clearCustomerOrderPageData());
  }, []);

  /**
   * Fetch order data
   */
  const fetchCustomerOrders = (userId) => {
    if (customerOrdersPage > 1) {
      const requestBody = {
        p_skip: (customerOrdersPage - 2) * 10,
        p_take: 100,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: userId,
      };

      setCurrentPage(customerOrdersPage - 1);
      dispatch(getCustomerOrderPageData(customerOrdersPage - 1));
      getCustomersOrderService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (customerOrdersPage - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        p_user_id: userId,
      };
      getCustomersOrderService(requestBody, tokenData, dispatch);
    }
  };

  //Show actions which is to be displayed in table
  const showCustomerOrdersActions = {
    showView: false,
    showEdit: false,
    showDelete: false,
    navigate: "/view-crm",
  };



  return (
    <div className="table-body-content">
      <div>
        <div className="title">
          <h4>
            {customerActionData && customerActionData.length > 0
              ? customerActionData[0].name
              : "Customer"}
          </h4>
        </div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate("/home")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/customer")}>
            {customerActionData && customerActionData.length > 0
              ? customerActionData[0]?.name
              : "CRM"}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {Data && Data.isEdit === true
              ? "Edit "
              : Data && Data.isEdit === false
              ? "View "
              : "Add "}{" "}
            {customerActionData && customerActionData.length > 0
              ? modifyString(customerActionData[0].name)
              : "CRM"}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="crm-container">
        <div className="crm-header ">
          <div className="profile-section">
            <img src={ProfileIcon} alt="Profile" className="profile-icon" />
            <span className="profile-name">Top Notch</span>
          </div>
          <div className="stats-container">
            <div className="stat-box">
              <h3>Total Orders</h3>
              <p>{summary.totalOrders}</p>
            </div>
            <div className="stat-box">
              <h3>Total Amount</h3>
              <p>{summary.totalOrderAmt}</p>
            </div>
          </div>
        </div>
        {/* Tabs Section */}
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="tab-content">
          {activeTab === "Order Details" ? <></> : <></>}
        </div>
      </div>
    </div>
  );
}

export default CRM;
