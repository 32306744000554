/**
 * Set Vendor page data
 * @param {*} page 
 * @returns {*}
 */
export const getStockHistoryPageData = (page) => ({
    type: 'GET_STOCK_HISTORY_PAGE',
    payload: page
})

/**
 * Clear Vendor page data
 * @returns {*}
 */
export const clearStockHistoryPageData = () => ({
    type: 'CLEAR_STOCK_HISTORY_PAGE'
})