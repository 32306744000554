import React, { useEffect, useRef, useState } from "react";
import "./AddOrder.scss";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../components/input/Input";
import UtilsData, { toastClass, ValidationMessage } from "../../../Utils/Utils";
import Button from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { getAllProductService } from "../../Product/ProductService/ProductService";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailService,
  placeOrderService,
} from "../OrderService/OrderService";
import OrderCart from "../../../components/OrderCart/OrderCart";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {
  getAllDiscountService,
  getDiscountById,
} from "../../Discount/DiscountService/DiscountService";
import { addUpdateOrderRequest } from "./AddUpdateOrderRequest/AddUpdateOrderRequest";
import { MdOutlineCancel } from "react-icons/md";
import { modifyString } from "../../../services/Regular-services/StringService";
import { getAllSpotService } from "../../Spot/SpotService/SpotService";
import { FaPlus } from "react-icons/fa6";
import { getAllCategoryService } from "../../Category/Category-service/CategoryService";
import { getAllSubCategoryService } from "../../SubCategory/SubCategoryService/SubCategoryService";
import { clearTotalSubCategoryData } from "../../../redux/Actions/subCategoryAction/SubCategoryAction";
import { clearTotalProductData } from "../../../redux/Actions/productAction/ProductAction";
import generateInvoiceHtml from "../../../components/generateInvoiceHtml/generateInvoiceHtml";
import { format } from "date-fns";

export default function AddOrder() {
  /**
   * Getting data from brand table
   */
  const location = useLocation();
  const { Data } = location.state || {};
  const { tableNo } = location.state || {};
  const navigate = useNavigate();
  const totalProduct = useSelector((state) => state.totalProduct);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [addedProducts, setAddedProducts] = useState([]);
  const [totalAmountData, setTotalAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [appliedDiscountCode, setAppliedDiscountCode] = useState(null);
  const [updatedTotalAmt, setUpdatedTotalAmt] = useState(null);
  const [discountAmt, setDiscountAmt] = useState(null);
  const [discountID, setDiscountID] = useState(null);
  const [discountDetail, setDiscountDetail] = useState(null);
  const totalCategory = useSelector((state) => state.totalCategory);
  const [serviceCharge, setServiceCharge] = useState(0);

  const orderCartRef = useRef();

  const openModal = (productData) => {
    if (orderCartRef.current) {
      orderCartRef.current.openModal(productData); // Pass product data
    }
  };

  const [message, setMessage] = useState("");
  const authuserData = useSelector((state) => state.auth?.user);

  // const totalBrand = useSelector((state) => state.totalBrand);
  // const [activeIndex, setActiveIndex] = useState(null);
  // const [brandData, setBrandData] = useState([]);
  const [discountOption, setDiscountOption] = useState([]);

  const [userDetailsModal, setUserDetailModal] = useState(false);

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [orderActionData, setOrderActionData] = useState([]);
  const spotData = useSelector((state) => state.spot);
  const [tableOptions, setTableOptions] = useState([]);
  // const [attributeModal, setAttributeModal] = useState(false);

  const [productCategory, setProductCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [subCategoryActiveIndex, setSubCategoryActiveIndex] = useState(null);
  const subCategoryData = useSelector((state) => state.totalSubCategory);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "orders"
      );
      setOrderActionData(actionData);
    }
  }, [sideBarData]);

  const totalDiscount = useSelector((state) => state.totalDiscount);
  const dispatch = useDispatch();
  const token = UtilsData();

  const fetchTotalDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 50000,
    };
    getAllDiscountService(requestBody, token, dispatch, true);
    // eslint-disable-next-line
  };

  const fetchCategory = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 500000,
      p_is_active: "1",
      p_type: "0",
    };
    getAllCategoryService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalCategory &&
      totalCategory.items &&
      totalCategory.items.length === 0
    ) {
      fetchCategory();
    } else {
      if (
        totalCategory.items[0]?.data &&
        totalCategory.items[0].data.length > 0
      ) {
        setProductCategory(totalCategory.items[0].data);
      } else {
        setProductCategory([]);
      }
    }
    // eslint-disable-next-line
  }, [totalCategory]);

  /**
   * Click on category filter
   * @param {*} category
   * @param {*} index
   * @returns
   */
  const clickCategory = (category, index) => {
    if (Data && Data?.isEdit === false) {
      return;
    }
    const categoryData = [category.categoryId];
    if (categoryId && categoryId.length > 0) {
      const duplicateCategory = categoryId.find(
        (item) => item === category.categoryId
      );
      if (duplicateCategory) {
        setActiveIndex(null);
        setSubCategoryActiveIndex(null);
        setCategoryId([]);
        setSubCategoryId([]);
        dispatch(clearTotalSubCategoryData());
        const productRequestBody = {
          p_skip: 0,
          p_take: 5000,
          p_is_active: "1",
        };
        getAllProductService(productRequestBody, token, dispatch, true, true);
        return;
      }
    }
    setActiveIndex(index);
    setSubCategoryActiveIndex(null);
    setCategoryId(categoryData);
    setSubCategoryId([]);
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_categoryIds: categoryData.join(","),
      p_is_active: "1",
    };
    getAllSubCategoryService(requestBody, token, dispatch, true, true);

    const productRequestBody = {
      p_skip: 0,
      p_take: 5000,
      p_category_id: categoryData.toString(),
      p_is_active: "1",
    };
    getAllProductService(productRequestBody, token, dispatch, true, true);
  };

  /**
   * Click on sub-category filter
   * @param {*} subCategory
   * @param {*} index
   * @returns
   */
  const clickSubCategory = (subCategory, index) => {
    if (Data && Data?.isEdit === false) {
      return;
    }
    const subCategoryData = [subCategory.id];
    if (subCategoryId && subCategoryId.length > 0) {
      const duplicateSubCategory = subCategoryId.find(
        (item) => item === subCategory.id
      );
      if (duplicateSubCategory) {
        setSubCategoryId([]);
        setSubCategoryActiveIndex(null);
        const productRequestBody = {
          p_skip: 0,
          p_take: 5000,
          p_category_id: categoryId.join(","),
          p_is_active: "1",
        };
        getAllProductService(productRequestBody, token, dispatch, true, true);
        return;
      }
    }
    setSubCategoryId(subCategoryData);
    setSubCategoryActiveIndex(index);
    const productRequestBody = {
      p_skip: 0,
      p_take: 5000,
      p_category_id: categoryId.join(","),
      p_sub_category_id: subCategoryData.join(","),
      p_is_active: "1",
    };
    getAllProductService(productRequestBody, token, dispatch, true, true);
  };

  useEffect(() => {
    if (
      totalDiscount &&
      totalDiscount.items &&
      totalDiscount.items.length === 0
    ) {
      fetchTotalDiscount();
    } else {
      if (
        totalDiscount.items &&
        totalDiscount.items[0] &&
        totalDiscount.items[0]?.data &&
        totalDiscount.items[0].data.length > 0
      ) {
        const currentDate = new Date();
        const validDiscounts = totalDiscount.items[0].data.filter((item) => {
          const endDate = new Date(item.end_date);
          return endDate > currentDate;
        });

        const optionData = validDiscounts.map((item) => ({
          value: item.discountId,
          label: item.discountCode,
        }));
        setDiscountOption(optionData);
      } else {
        setDiscountOption([]);
      }
    }
    // eslint-disable-next-line
  }, [totalDiscount, Data]);

  const [productOptions, setProductOptions] = useState([]);

  const quantityOptions = [];

  for (let i = 1; i <= 100; i++) {
    quantityOptions.push({ value: i.toString(), label: i.toString() });
  }

  const [dineOptions, setDineOptions] = useState([
    { value: "0", label: "Dine In" },
    { value: "1", label: "Take Away" },
    { value: "2", label: "Delivery" },
    { value: "3", label: "Zomato" },
    { value: "4", label: "Swiggy" },
    { value: "5", label: "Other" },
  ]);

  const [inputData, setInputData] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);

  const [initialValues, setInitialValues] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    tableNo: "",
    product: "",
    size: "",
    price: "",
    quantity: "",
    dineIn: "0",
    status: "1",
    payment: "0",
    zomatoSwiggyPrice: null,
    discount: "",
    productCode: "",
  });

  const [disableName, setDisableName] = useState(true);

  const paymentOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "UPI" },
    { value: "2", label: "Card" },
    { value: "3", label: "Cash" },
    { value: "5", label: "Zomato" },
    { value: "6", label: "Swiggy" },
    { value: "4", label: "Other" },
  ];

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
      payment: Yup.string().required("Payment is required!"),
    })
  );

  // changes by sakshi
  const handleSelectedAttributes = (data) => {
    // Function to check if data is grouped
    const isGrouped = (data) => {
      return (
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).length > 0
      );
    };

    // Group data if it's not already grouped
    const groupedAttributes = isGrouped(data)
      ? data
      : data.reduce((acc, attr) => {
          const { cat_name } = attr;

          if (!acc[cat_name]) {
            acc[cat_name] = [];
          }

          acc[cat_name].push(attr);
          return acc;
        }, {});

    return groupedAttributes;
  };

  useEffect(() => {
    dispatch(clearTotalProductData());
    dispatch(clearTotalSubCategoryData());
    // eslint-disable-next-line
  }, []);

  const fetchVeiwOnlyOrderDetail = () => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      setServiceCharge(Number(Data.data?.service_charge));
      if (Data.data?.type === "2") {
        setDeliveryCharge(Number(Data.data?.delivery_charge));
      }

      const filteredProductData = Data?.data?.productData?.map(
        (addedProduct) => {
          const product = totalProduct.items[0].data.find(
            (product) => product.productId === addedProduct.productId
          );

          // recent hot bug fix related to pricing 21-11-24
          return {
            ...product,
            quantity: addedProduct.quantity,
            food_type_one:
              addedProduct?.food_type_1 &&
              addedProduct?.food_type_1 !== "" &&
              addedProduct?.food_type_1 !== "null" &&
              addedProduct?.food_type_1 !== "NULL"
                ? addedProduct.food_type_1
                : "0",
            food_type_jain_regular:
              addedProduct?.food_type_2 &&
              addedProduct.food_type_2 !== "" &&
              addedProduct.food_type_2 !== "null" &&
              addedProduct.food_type_2 !== "NULL"
                ? addedProduct.food_type_2
                : "2",
            product_instruction:
              addedProduct?.product_instruction &&
              addedProduct.product_instruction !== "" &&
              addedProduct.product_instruction !== "NULL" &&
              addedProduct.product_instruction !== "null"
                ? addedProduct.product_instruction
                : null,
            // changes by sakshi
            selectedAttributes: addedProduct?.product_attribute
              ? handleSelectedAttributes(addedProduct.product_attribute)
              : null,
            price: product.price,
            map_table_id: addedProduct?.map_table_id
              ? addedProduct.map_table_id
              : 0,
            status: addedProduct.productStatus,
            discountedAmount: Number(Data.data?.total_discount_amount),
            discountCode:
              Data.data?.discount_id &&
              Data.data.discount_id !== "" &&
              Data.data.discount_id !== "null"
                ? Data.data?.Discount_Code
                : 0,
            discountId:
              Data.data?.discount_id &&
              Data.data.discount_id !== "" &&
              Data.data.discount_id !== "null"
                ? Data.data.discount_id
                : 0,
          };
        }
      );

      const updatedAddedProduct = filteredProductData.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          // Flattening data dynamically
          const flattenedData = Object.values(item.selectedAttributes)
            .flat()
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value),
            }));

          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : Data.data.type === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });

      if (
        Data.data?.discount_id &&
        Data.data.discount_id !== "" &&
        Data.data.discount_id !== "null"
      ) {
        setDiscountAmt(Number(Data.data?.total_discount_amount));
        setDiscountID(Data.data.discount_id);
        setAppliedDiscountCode(Data.data?.Discount_Code);
      }
      setAddedProducts(updatedAddedProduct);

      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.firstName &&
          Data.data.firstName !== "" &&
          Data.data.firstName !== "N/A"
            ? Data.data.firstName
            : "",
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Number(Data.data.tableNo) : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: Data.data.payment,
        zomatoSwiggyPrice: Data.data.zomatoSwiggyPrice,
        discount: Data.data.discount_id,
      });
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      if (Data?.isEdit === true) {
        fetchOrderDetail();
      } else {
        fetchVeiwOnlyOrderDetail();
      }
    } else {
      setDineOptions([
        { value: "0", label: "Dine In" },
        { value: "1", label: "Take Away" },
        { value: "3", label: "Zomato" },
        { value: "4", label: "Swiggy" },
        { value: "5", label: "Other" },
      ]);
    }
    // eslint-disable-next-line
  }, [Data, totalProduct]);

  useEffect(() => {
    if (initialValues.dineIn === "0") {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    } else if (
      initialValues.dineIn === "1" ||
      initialValues.dineIn === "2" ||
      initialValues.dineIn === "3" ||
      initialValues.dineIn === "4" ||
      initialValues.dineIn === "5"
    ) {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    }
  }, [initialValues]);

  useEffect(() => {
    if (tableNo && !Data) {
      setInitialValues({
        mobile: "",
        firstName: "",
        lastName: "",
        tableNo: Number(tableNo.table_no),
        product: "",
        size: "",
        price: "",
        quantity: "",
        dineIn: "0",
        status: "1",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "",
      });
    }
  }, [tableNo, Data]);

  const fetchOrderDetail = async () => {
    if (Data.data.type === "2" || Data.data.type === 2) {
      setDineOptions([
        { value: "0", label: "Dine In" },
        { value: "1", label: "Take Away" },
        { value: "2", label: "Delivery" },
        { value: "3", label: "Zomato" },
        { value: "4", label: "Swiggy" },
        { value: "5", label: "Other" },
      ]);
    } else {
      setDineOptions([
        { value: "0", label: "Dine In" },
        { value: "1", label: "Take Away" },
        { value: "3", label: "Zomato" },
        { value: "4", label: "Swiggy" },
        { value: "5", label: "Other" },
      ]);
    }
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      let discountOnEdit = { status: "Fail" };
      let discountApplyBool = false;
      if (
        Data.data?.discount_id &&
        Data.data?.discount_id !== "" &&
        Data.data?.discount_id !== "NULL" &&
        Data.data?.discount_id !== "Null" &&
        Data.data?.discount_id !== "null"
      ) {
        discountOnEdit = await getDiscountById(
          Data.data.discount_id,
          token,
          dispatch,
          true
        );

        if (
          discountOnEdit?.status &&
          (discountOnEdit.status === "success" ||
            discountOnEdit.status === "200" ||
            discountOnEdit.status === 200)
        ) {
          const currentDate = new Date();
          const endDate = new Date(discountOnEdit?.data?.end_date);
          if (endDate > currentDate) {
            discountApplyBool = true;
            setAppliedDiscountCode(Data.data.Discount_Code);
            setDiscountAmt(Data.data.total_discount_amount);

            setDiscountID(Data.data.discount_id);
            setDiscountDetail(discountOnEdit?.data || null);
          } else {
            if (!document.querySelector(toastClass)) {
              toast.warn(
                "Discount code (" + Data.data.Discount_Code + ") is expired!"
              );
            }
          }
        } else {
          if (!document.querySelector(toastClass)) {
            toast.warn(
              "Discount code (" +
                Data.data.Discount_Code +
                ") is no more applicable!"
            );
          }
        }
      }

      setDeliveryCharge(
        Data.data?.delivery_charge ? Number(Data.data.delivery_charge) : 0
      );
      const filteredProductData = Data?.data?.productData?.map(
        (addedProduct) => {
          const product = totalProduct.items[0].data.find(
            (product) => product.productId === addedProduct.productId
          );

          // recent hot bug fix related to pricing 21-11-24
          return {
            ...product,
            quantity: addedProduct.quantity,
            food_type_one:
              addedProduct?.food_type_1 &&
              addedProduct?.food_type_1 !== "" &&
              addedProduct?.food_type_1 !== "null" &&
              addedProduct?.food_type_1 !== "NULL"
                ? addedProduct.food_type_1
                : "0",
            food_type_jain_regular:
              addedProduct?.food_type_2 &&
              addedProduct.food_type_2 !== "" &&
              addedProduct.food_type_2 !== "null" &&
              addedProduct.food_type_2 !== "NULL"
                ? addedProduct.food_type_2
                : "2",
            product_instruction:
              addedProduct?.product_instruction &&
              addedProduct.product_instruction !== "" &&
              addedProduct.product_instruction !== "NULL" &&
              addedProduct.product_instruction !== "null"
                ? addedProduct.product_instruction
                : null,
            // changes by sakshi
            selectedAttributes: addedProduct?.product_attribute
              ? handleSelectedAttributes(addedProduct.product_attribute)
              : null,
            price: product?.price,
            map_table_id: addedProduct?.map_table_id
              ? addedProduct.map_table_id
              : 0,
            status: addedProduct.productStatus,
          };
        }
      );

      const updatedAddedProduct = filteredProductData.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          // Flattening data dynamically
          const flattenedData = Object.values(item.selectedAttributes) // Get all values (arrays) of the object
            .flat() // Flatten the arrays into one single array
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value), // Convert 'value' to a number
            }));

          // Calculate the total value
          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            Data.data.type === "1"
              ? item.take_away_price
              : Data.data.type === "3"
              ? item.zomato_price
              : Data.data.type === "4"
              ? item.swiggy_price
              : Data.data.type === "5"
              ? item.other_price
              : Data.data.type === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });
      if (Data.data.discount_id && discountApplyBool) {
        onSelectDiscount(
          Data.data.discount_id,
          false,
          false,
          updatedAddedProduct
        );
      } else {
        setAddedProducts(updatedAddedProduct);
      }

      setMessage(Data.data.instructions);
      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.firstName &&
          Data.data.firstName !== "" &&
          Data.data.firstName !== "N/A"
            ? Data.data.firstName
            : "",
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Number(Data.data.tableNo) : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: Data.data.payment,
        zomatoSwiggyPrice: Data.data.zomatoSwiggyPrice,
        discount: discountApplyBool ? Data.data.discount_id : "",
      });
    } else {
      setInitialValues({
        mobile:
          Data.data?.phoneNumber &&
          Data.data.phoneNumber !== "" &&
          Data.data.phoneNumber !== "N/A"
            ? Data.data.phoneNumber
            : "",
        firstName:
          Data.data?.firstName &&
          Data.data.firstName !== "" &&
          Data.data.firstName !== "N/A"
            ? Data.data.firstName
            : "",
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Data.data.table_no : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: "0",
        discount: "",
        zomatoSwiggyPrice: "0",
      });
      setUpdatedTotalAmt(0);
    }

    if (
      Data.data?.phoneNumber &&
      Data.data.phoneNumber !== "" &&
      Data.data.phoneNumber !== "N/A"
    ) {
      setDisableName(false);
    }
  };

  const changeDineInData = (value, setFieldValue) => {
    if (value === "0") {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
    } else {
      setValidationSchema(
        Yup.object({
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!"),
        })
      );
      setFieldValue("tableNo", "");
    }
    if (tableOptions.length === 0 && value === "0") {
      toast.error("All Tables are occupied!");
    }
    setFieldValue("dineIn", value);
    setInitialValues((prevState) => ({
      ...prevState,
      dineIn: value,
    }));
    if (addedProducts && addedProducts.length > 0) {
      if (inputData && inputData !== "") {
        const filteredInputProduct = totalProduct.items[0].data.filter(
          (product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const updatedData = filteredInputProduct.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      } else {
        const updatedData = productOptions.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });
        // Set the updated data
        setProductOptions(updatedData);
      }

      const updatedAddedProduct = addedProducts.map((item) => {
        if (!item.attribute_data) {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }

          return {
            ...item,
            newPrice: newPrice,
          };
        } else {
          const flattenedData = Object.values(item.selectedAttributes)
            .flat()
            .map((item) => ({
              name: item.name,
              value: parseFloat(item.value),
            }));

          // Calculate the total value
          const totalValue = flattenedData.reduce(
            (total, item) => total + item.value,
            0
          );
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: Number(newPrice) + Number(totalValue),
          };
        }
      });

      if (discountID) {
        onSelectDiscount(discountID, setFieldValue, true, updatedAddedProduct);
      } else {
        setAddedProducts(updatedAddedProduct);
        const totalAmount = updatedAddedProduct.reduce(
          (sum, product) =>
            sum + Number(product.newPrice) * Number(product.quantity),
          0
        );
        setTotalAmount(totalAmount);
        setUpdatedTotalAmt(totalAmount - discountAmt);
      }
    } else {
      if (inputData && inputData !== "") {
        const filteredInputProduct = totalProduct.items[0].data.filter(
          (product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
        );
        const updatedData = filteredInputProduct.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      } else {
        const updatedData = productOptions.map((item) => {
          let newPrice =
            value === "1"
              ? item.take_away_price
              : value === "3"
              ? item.zomato_price
              : value === "4"
              ? item.swiggy_price
              : value === "5"
              ? item.other_price
              : value === "2"
              ? item.take_away_price
              : item.price;

          if (!newPrice || newPrice === "0.00") {
            newPrice = item.price;
          }
          return {
            ...item,
            newPrice: newPrice,
          };
        });

        // Set the updated data
        setProductOptions(updatedData);
      }
      setUpdatedTotalAmt(0);
    }
  };

  useEffect(() => {
    if (
      (totalProduct && totalProduct.items && totalProduct.items.length <= 0) ||
      !totalProduct
    ) {
      const requestBody = {
        p_skip: 0,
        p_take: 5000,
        p_is_active: "1",
      };
      getAllProductService(requestBody, token, dispatch, true);
    }
    // eslint-disable-next-line
  }, [totalProduct]);

  useEffect(() => {
    if (!discountID) {
      const productData = addedProducts.map((item) => ({
        ...item,
        discountedAmount: 0,
        discountId: 0,
        discountCode: "",
      }));
      setAddedProducts(productData);
    }
    // eslint-disable-next-line
  }, [discountID, Data]);

  useEffect(() => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      if (addedProducts && addedProducts.length > 0) {
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          const updatedData = filteredInputProduct.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });
          // Set the updated data
          setProductOptions(updatedData);
        } else {
          const data = totalProduct.items[0].data;
          const updatedData = data.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });
          // Set the updated data
          setProductOptions(updatedData);
        }

        // const totalAmount = addedProducts.reduce(
        //   (sum, product) =>
        //     sum + Number(product.newPrice) * Number(product.quantity),
        //   0
        // );
        // setTotalAmount(totalAmount);
        // setUpdatedTotalAmt(totalAmount - discountAmt);
      } else {
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          const updatedData = filteredInputProduct.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          // Set the updated data
          setProductOptions(updatedData);
        } else {
          const data = totalProduct.items[0].data;
          const updatedData = data.map((item) => {
            let newPrice =
              initialValues.dineIn === "1"
                ? item.take_away_price
                : initialValues.dineIn === "3"
                ? item.zomato_price
                : initialValues.dineIn === "4"
                ? item.swiggy_price
                : initialValues.dineIn === "5"
                ? item.other_price
                : initialValues.dineIn === "2"
                ? item.take_away_price
                : item.price;

            if (!newPrice || newPrice === "0.00") {
              newPrice = item.price;
            }
            return {
              ...item,
              newPrice: newPrice,
            };
          });

          setProductOptions(updatedData);
        }
        // setUpdatedTotalAmt(0);
      }
    } else {
      setProductOptions([]);
    }

    // eslint-disable-next-line
  }, [totalProduct, addedProducts, inputData]);

  const onChangeTableNo = (value, setFieldValue, values) => {
    if (value === "") {
      if (initialValues.dineIn === "0") {
        setValidationSchema(
          Yup.object({
            dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            payment: Yup.string().required("Payment is required!"),
          })
        );
      } else {
        setValidationSchema(
          Yup.object({
            dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            payment: Yup.string().required("Payment is required!"),
          })
        );
      }
    }
  };
  const handlePaymentValidation = (option, setFieldValue, values) => {
    setFieldValue("zomatoSwiggyPrice", "");
  };

  const handlePhoneChange = (event, setFieldValue) => {
    const newValue = event.target.value.replace(/[^0-9\s]/g, "");
    if (newValue) {
      const trimmedValue = newValue.slice(0, 10);
      if (trimmedValue.length === 10) {
        getUserDetailService(trimmedValue, dispatch).then((res) => {
          if (
            res &&
            (res.status === 200 ||
              res.status === "200" ||
              res.status === "success")
          ) {
            setDisableName(false);
            setFieldValue("firstName", res.data.firstName);
            setFieldValue("lastName", res.data.lastName);
          } else {
            setDisableName(false);
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
          }
        });
      } else {
        setDisableName(true);
        setFieldValue("firstName", "");
        setFieldValue("lastName", "");
      }
    } else {
      setDisableName(true);
      setFieldValue("firstName", "");
      setFieldValue("lastName", "");
    }
  };

  useEffect(() => {
    if (addedProducts && addedProducts.length > 0) {
      const total = addedProducts.reduce(
        (sum, product) => sum + product.newPrice * product.quantity,
        0
      );
      const totalTax = addedProducts.reduce((sum, product) => {
        const price = Number(product?.newPrice) || 0;
        const quantity = Number(product?.quantity) || 0;
        const discountedAmount = Number(product?.discountedAmount) || 0;
        const cgst = Number(product?.cgst) || 0;
        const sgst = Number(product?.sgst) || 0;
        const vat = Number(product?.vat) || 0;
        const taxType = authuserData?.data?.branchTaxType;

        const applicableTaxRate =
          taxType === "GST" ? cgst + sgst : taxType === "VAT" ? vat : 0;

        const taxableAmount = price * quantity - discountedAmount;
        const taxForProduct =
          taxableAmount > 0 ? (taxableAmount * applicableTaxRate) / 100 : 0;

        return sum + taxForProduct;
      }, 0);
      setTaxAmount(totalTax.toFixed(2));

      let discount = 0;

      if (discountID && discountID > 0 && discountDetail) {
        let eligibleTotal = 0;
        let totalCartAmount = 0;

        addedProducts.forEach((product) => {
          totalCartAmount += product.newPrice * product.quantity;

          if (isProductEligible(product, discountDetail)) {
            eligibleTotal += product.newPrice * product.quantity;
          }
        });

        if (discountDetail?.spacific_item === "0") {
          if (totalCartAmount >= discountDetail?.min_cart_value) {
            const discountAmount =
              (totalCartAmount * discountDetail.discount_amount) / 100;
            let newDiscount = Math.min(
              discountAmount,
              discountDetail.max_discount_ammount
            );
            discount = newDiscount;
            setDiscountAmt(newDiscount);
          } else {
            toast.warn(
              `Add more products to apply the discount. Minimum cart value: ${
                authuserData?.data?.symbol || "₹"
              }${discountDetail?.min_cart_value}!`
            );
            discount = 0;
            setAppliedDiscountCode(null);
            setDiscountAmt(0);
            setDiscountDetail(null);
            setDiscountID(null);
          }
        } else if (eligibleTotal < discountDetail.min_cart_value) {
          toast.warn(
            "Not enough eligible products in cart to apply the discount!"
          );
          discount = 0;
          setAppliedDiscountCode(null);
          setDiscountAmt(0);
          setDiscountDetail(null);
          setDiscountID(null);
        } else {
          const discountAmount =
            (eligibleTotal * discountDetail?.discount_amount) / 100;
          let newDiscount = Math.min(
            discountAmount,
            discountDetail.max_discount_ammount
          );
          discount = newDiscount;
          setDiscountAmt(newDiscount);
        }
      } else {
        discount = 0;
      }

      const deliveryData = deliveryCharge;
      const finalTotal = total + totalTax + deliveryData - discount;
      // let roundedValue;
      // if (finalTotal % 1 >= 0.3) {
      //   roundedValue = Math.ceil(finalTotal);
      // } else {
      //   roundedValue = Math.floor(finalTotal);
      // }
      setTotalAmount(Number(Number(finalTotal).toFixed(2)));
      setUpdatedTotalAmt(Number(Number(finalTotal).toFixed(2)));
      setDeliveryCharge(deliveryCharge);
    } else {
      setTotalAmount(0);
      setUpdatedTotalAmt(0);
      setTaxAmount(0);
      setDeliveryCharge(deliveryCharge);
      setDiscountAmt(0);
      setDiscountID(null);
    }
    // eslint-disable-next-line
  }, [addedProducts]);

  const isProductEligible = (product, discountDetails) => {
    const {
      product_ids,
      brand_ids,
      category_ids,
      sub_category_ids,
      leaf_level_category_ids,
    } = discountDetails;
    // Check if the product ID matches
    if (product_ids && product_ids.includes(product.productId.toString())) {
      return true;
    }

    // Check if the brand ID matches
    if (brand_ids && brand_ids.includes(product.brand_id.toString())) {
      return true;
    }

    // Check if the category ID matches
    if (category_ids && category_ids.includes(product.category_ids)) {
      return true;
    }

    // Check if the sub-category ID matches
    if (
      sub_category_ids &&
      sub_category_ids.includes(product.sub_category_ids)
    ) {
      return true;
    }

    // Check if the leaf level category ID matches
    if (
      leaf_level_category_ids &&
      leaf_level_category_ids.includes(product.leaf_level_category_ids)
    ) {
      return true;
    }
    return false;
  };

  /**
   * Set discount
   * @param {*} value
   * @param {*} setFieldValue
   * @returns
   */

  const onSelectDiscount = async (
    value,
    setFieldValue,
    boolValue,
    newCartProducts
  ) => {
    try {
      const viewCartProducts = newCartProducts
        ? newCartProducts
        : addedProducts;
      if (viewCartProducts.length > 0) {
        let newData = {};
        if (discountDetail && discountID && discountID === value) {
          newData = {
            status: "success",
            data: discountDetail,
          };
        }
        const discountData =
          discountDetail && discountID && discountID === value
            ? newData
            : await getDiscountById(value, token, dispatch);
        if (
          discountData?.status &&
          (discountData.status === 200 ||
            discountData.status === "200" ||
            discountData.status === "success" ||
            discountData.status === "Success")
        ) {
          let eligibleTotal = 0;
          let totalCartAmount = 0;
          // let eligibleLength = 0;

          viewCartProducts.forEach((product) => {
            totalCartAmount += product.newPrice * product.quantity;

            if (isProductEligible(product, discountData.data)) {
              // eligibleLength += 1;
              eligibleTotal += product.newPrice * product.quantity;
            }
          });

          if (discountData.data?.spacific_item === "0") {
            if (totalCartAmount >= discountData.data?.min_cart_value) {
              const discountAmount =
                discountData.data.discount_type === "1"
                  ? (totalCartAmount * discountData.data.discount_amount) / 100
                  : discountData.data.discount_amount;
              let newDiscount = Math.min(
                discountAmount,
                discountData.data.max_discount_ammount
              );

              const branchProductsWithDiscount = viewCartProducts.map(
                (product) => {
                  const productTotal = product.newPrice * product.quantity;
                  const productDiscount =
                    (productTotal / totalCartAmount) * newDiscount;
                  return {
                    ...product,
                    discountedAmount: productDiscount,
                    discountCode:
                      productDiscount > 0 ? discountData.data.code : "",
                    discountId: productDiscount > 0 ? discountData.data.id : 0,
                  };
                }
              );

              setAddedProducts(branchProductsWithDiscount);
              setDiscountAmt(newDiscount);
              if (boolValue) {
                setTotalAmount(totalAmountData - newDiscount);
                setUpdatedTotalAmt(updatedTotalAmt - newDiscount);
                setAppliedDiscountCode(discountData.data?.code);
                setDiscountDetail(discountData.data);
                setDiscountID(discountData.data?.id || null);
                setFieldValue("discount", discountData.data?.id || "");
              }
            } else {
              if (boolValue) {
                toast.warn(
                  `Add more products to apply the discount. Minimum cart value: ${
                    authuserData?.data?.symbol || "₹"
                  }${discountData.data?.min_cart_value}`
                );
              }
              if (setFieldValue) {
                setFieldValue("discount", "");
              }
            }
          } else if (eligibleTotal < discountData.data.min_cart_value) {
            if (boolValue) {
              toast.warn(
                "Not enough eligible products in cart to apply the discount!"
              );
            }
            if (setFieldValue) {
              setFieldValue("discount", "");
            }
          } else {
            const discountAmount =
              discountData.data.discount_type === "1"
                ? (eligibleTotal * discountData.data.discount_amount) / 100
                : discountData.data.discount_amount;
            let newDiscount = Math.min(
              discountAmount,
              discountData.data.max_discount_ammount
            );
            let cartProducts = viewCartProducts.map((product) => {
              let discountedAmount = 0;
              if (
                eligibleTotal >= discountData.data.min_cart_value ||
                totalCartAmount >= discountData.data.min_cart_value
              ) {
                if (isProductEligible(product, discountData.data)) {
                  const productTotal = product.newPrice * product.quantity;
                  discountedAmount =
                    (productTotal / eligibleTotal) * newDiscount;
                }
              }
              return {
                ...product,
                discountedAmount:
                  discountedAmount > 0
                    ? Number(Number(discountedAmount).toFixed(2))
                    : 0,
                discountCode:
                  discountedAmount > 0 ? discountData.data.code : "",
                discountId: discountedAmount > 0 ? discountData.data.id : 0,
              };
            });
            setAddedProducts(cartProducts);
            setDiscountAmt(newDiscount);
            if (boolValue) {
              setTotalAmount(totalAmountData - newDiscount);
              setUpdatedTotalAmt(updatedTotalAmt - newDiscount);
              setAppliedDiscountCode(discountData.data?.code);
              setDiscountDetail(discountData.data);
              setDiscountID(discountData.data?.id || null);
              setFieldValue("discount", discountData.data?.id || "");
            }
          }
        }
      } else {
        if (boolValue) {
          toast.warn("Cart is empty!");
        }
        if (setFieldValue) {
          setFieldValue("discount", "");
        }
        setUpdatedTotalAmt(0);
        setTotalAmount(0);
        setDiscountDetail(null);
        setDiscountID(null);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleRemoveDiscount = (setFieldValue) => {
    setFieldValue("discount", "");
    setTotalAmount(totalAmountData + discountAmt);
    setUpdatedTotalAmt(updatedTotalAmt + discountAmt);
    setDiscountDetail(null);
    setDiscountAmt(0);
    setDiscountID(null);
    const updatedProduct = addedProducts.map((product) => {
      return {
        ...product,
        discountedAmount: 0,
        discountId: 0,
        discountCode: "0",
      };
    });
    setAddedProducts(updatedProduct);
  };

  const handleUserDetailModal = () => {
    setUserDetailModal(!userDetailsModal);
  };
  const fetchSpot = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
      is_active: "1",
    };
    getAllSpotService(requestBody, token, dispatch);
  };
  const order = useSelector((state) => state.order);

  useEffect(() => {
    if (spotData && spotData.items && spotData.items.length === 0) {
      fetchSpot();
    } else {
      if (spotData.items[0]?.data && spotData.items[0].data.length > 0) {
        const newSpotData = spotData.items[0].data
          .map((spot) => {
            const matchingOrder = order.items.filter((order) => {
              // Check if Data.data.table_no is present, if it is, exclude those orders
              const isOrderExcluded = Data?.data.table_no
                ? Number(order.table_no) === Number(Data.data.table_no)
                : false;

              // Return true only if the order should not be excluded
              return (
                Number(order.table_no) === Number(spot.id) && !isOrderExcluded
              );
            });

            const matchComplete =
              matchingOrder && matchingOrder.length > 0
                ? matchingOrder.filter(
                    (item) => item.status !== "3" && item.status !== "4"
                  )
                : [];

            if (matchComplete.length === 0) {
              return {
                value: spot.id,
                label: spot.name,
              };
            }
            return null;
          })
          .filter((spot) => spot !== null);
        setTableOptions(newSpotData);
        // After setting the tableOptions, check if it's empty and show a toast

        if (newSpotData.length === 0) {
          toast.error("All Tables are occupied!");
        }
      } else {
        setTableOptions([]);
      }
    }
    // eslint-disable-next-line
  }, [spotData]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const printInvoice = (data) => {
    const {
      companyOwnerName,
      companyPhneNumber,
      branchGstNumber,
      branchFSSAI_licence_number,
    } = authuserData.data;
    const transformedProductData = data.p_product_data.map((product) => ({
      productName: `Product ${product.product_id}`, // Replace with actual product name if available
      quantity: product.quantity,
      food_type_two:
        product.food_type_two === "0"
          ? "Jain"
          : product.food_type_two === "1"
          ? "No onion/garlic"
          : "Regular",
      productPrice: product.product_price,
      productInstruction: product.product_instruction,
      attribute_data: product.attribute_data,
    }));
    const orderData = {
      type: data.p_type,
      tableNo: String(data.p_table_no),
      createdAt: format(new Date(), "d-MM-yy"),
      product_instruction: data.p_instruction,
      productData: transformedProductData,
    };

    // Create a new window for printing
    const printWindow = window.open("", "", "height=600,width=800");
    // Define the HTML structure for the table with inline CSS
    const htmlContent = `
    <html>
    <head>
      <title>Order Details</title>
    </head>
    <body style="font-family: Arial, sans-serif; margin: 0 auto; padding: 0; width: 80mm; text-align: center;">
      <h1 style="text-align: center; font-size: 16px; margin-top: 10px;">Order Details</h1>
      <div style="margin: 10px; text-align: left;">
        <p style="margin: 5px 0; font-size: 12px; border-top: 1px solid black;">
        <br>
          Dine In: ${orderData?.type === "0" ? "Yes" : "No"}<br>
          Table Number: ${
            orderData?.type === "0" ? orderData.tableNo : "Parcel"
          }<br>
          Date: ${orderData?.createdAt ? orderData.createdAt : ""}<br>
         
        </p>
        ${
          orderData?.instructions
            ? `<p style="font-size: 12px; margin-bottom:5px">General Instruction</p>
                <p style="font-size: 10px;">${orderData.instructions}</p> `
            : ""
        }

           <tbody>
        ${
          orderData?.productData && orderData.productData.length > 0
            ? orderData.productData
                .map(
                  (product) => `
            <tr>
             <td style=" font-size: 12px;">
${product.quantity} x ${product.productName} - ${product.food_type_two}<br>
${
  product?.product_instruction
    ? `<span class="instructions" style="font-style: italic; font-size: 12px;">Instruction: ${product.product_instruction}</span>`
    : ""
}
${
  product?.attribute_data &&
  product.attribute_data !== "" &&
  product.attribute_data !== "null" &&
  product.attribute_data !== "NULL"
    ? `<div class="attribute-data" style="font-style: italic; font-size: 12px; color: gray;">${JSON.parse(
        product.attribute_data
      )
        .map(
          (attr) => `
      ${attr.cat_name}: ${attr.name} 
    `
        )
        .join("<br>")}</div>`
    : ""
}
</td>

            </tr>
          `
                )
                .join("")
            : "<tr><td>No products available.</td></tr>"
        }
      </tbody>
      </div>
      <div style="margin-top: 10px; text-align: center; font-size: 12px;">
        <p>Thanks for visiting!</p>
      </div>
      <div style="display: flex; padding: 0 10px; justify-content: flex-end;">
        <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin-left: auto;">
          <!-- Your icon or content here -->
        </div>
      </div>
    </body>
    </html>
  `;
    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();

    // Trigger the print dialog
    printWindow.print();
  };

  const handleSubmit = (values) => {
    if (addedProducts.length < 1) {
      toast.error("Please add product!");
      return;
    }

    if (values.mobile && values.mobile !== "") {
      const isValidLength = values.mobile.length === 10;
      const startsWithValidDigit = /^[6789]/.test(values.mobile);
      if (!isValidLength || !startsWithValidDigit) {
        toast.error(
          "Please add a valid 10-digit mobile number starting with 6, 7, 8, or 9!"
        );
        return;
      }
    }

    const requestBody = addUpdateOrderRequest(
      addedProducts,
      values,
      Data,
      deliveryCharge,
      discountAmt,
      discountID,
      appliedDiscountCode,
      message,
      updatedTotalAmt,
      taxAmount
    );
    if (Data && Data.data) {
      handlesaveClick(
        dispatch,
        placeOrderService(
          requestBody,
          token,
          "update",
          navigate,
          orderPage,
          false,
          printInvoice
        ),
        "update"
      );
    } else {
      dispatch(placeOrderService(requestBody, token, "add", navigate)).then(
        () => {
          printInvoice(requestBody);
        }
      );
    }
  };
  const inputRef = useRef(null);

  const handleAddedProduct = (values, setFieldValue) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (values.productCode !== "") {
      const product = productOptions.find((item) => {
        const itemLastPart = item.skuCode.split("-").pop();
        return itemLastPart === values.productCode;
      });
      if (product) {
        const existingProduct = addedProducts.find(
          (item) => item.productId === product.productId
        );
        if (!product.attribute_data) {
          if (existingProduct) {
            const updatedProducts = addedProducts.map((item) =>
              item.productId === product.productId
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
            setAddedProducts(updatedProducts);
            toast.success("Product quantity updated!");
          } else {
            // If it doesn't exist, add it
            const newProduct = {
              ...product,
              quantity: 1,
              food_type_jain_regular: "2",
              product_instruction: "",
              price: product.price,
              map_table_id:
                addedProducts.length > 0 ? addedProducts.length - 1 : 0,
              status: "0",
            };
            setAddedProducts((prevProducts) => [...prevProducts, newProduct]);
            toast.success("Product added!");
          }
        } else {
          openModal(product);
        }
      } else {
        toast.error("No product found with the given code");
      }
      setFieldValue("productCode", "");
    } else {
      toast.error("Enter SKU Code");
    }
  };
  const handleKeyPress = (event, values, setFieldValue) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (values.productCode === "") {
        toast.error("Enter SKU Code");
      } else {
        const product = productOptions.find((item) => {
          const itemLastPart = item.skuCode.split("-").pop();
          return itemLastPart === values.productCode;
        });

        if (product) {
          if (product.attribute_data) {
            // Open modal if product has attributes
            openModal(product);
          } else {
            // Handle adding product directly
            handleAddedProduct(values, setFieldValue);
          }
        } else {
          toast.error("No product found with the given code");
        }

        // Clear the input field after processing
        setFieldValue("productCode", "");
      }
    }
  };

  return (
    <>
      <div className="add-image-section">
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  {orderActionData && orderActionData.length > 0
                    ? modifyString(orderActionData[0].name)
                    : "Order"}
                </h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate("/home")}>
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate("/orders")}>
                  {orderActionData && orderActionData.length > 0
                    ? orderActionData[0].name
                    : "Orders"}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {Data && Data.isEdit === true
                    ? "Edit "
                    : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                  {orderActionData && orderActionData.length > 0
                    ? modifyString(orderActionData[0].name)
                    : "Order"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="add-roles-form row">
                  {userDetailsModal && (
                    <div className="modal-overlay">
                      <div className="modal-content-order">
                        <div>
                          <h4>Enter Customer Details</h4>
                          <button
                            className="modal-close"
                            onClick={() => handleUserDetailModal()}
                          >
                            <MdOutlineCancel />
                          </button>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Customer Mobile
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="mobile"
                                  label="Mobile Number"
                                  placeholder="Enter Mobile Number"
                                  className="add-roles"
                                  maxLength={10}
                                  Regex={/[^0-9\s]/g}
                                  value={values.mobile}
                                  hidelength="true"
                                  disabled={Data}
                                  onInput={(event) =>
                                    handlePhoneChange(event, setFieldValue)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    First Name
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="firstName"
                                  label="Name"
                                  placeholder="Enter First Name"
                                  className="add-roles"
                                  Regex={/[^a-zA-z\s]/g}
                                  value={values.firstName}
                                  hidelength="true"
                                  disabled={
                                    (Data && Data.isEdit === false) ||
                                    disableName
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Last Name
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                                <Input
                                  type="text"
                                  name="lastName"
                                  label="Name"
                                  placeholder="Enter Last Name"
                                  className="add-roles"
                                  Regex={/[^a-zA-z\s]/g}
                                  value={values.lastName}
                                  hidelength="true"
                                  disabled={
                                    (Data && Data.isEdit === false) ||
                                    disableName
                                  }
                                />
                              </div>
                            </div>

                            <div className={"col-md-4"}>
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Dine In/Take Away
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="dineIn"
                                  options={dineOptions}
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  changeData={(value) => {
                                    changeDineInData(value, setFieldValue);
                                  }}
                                  value={
                                    values.dineIn === "" ||
                                    values.dineIn === null
                                      ? false
                                      : values.dineIn
                                  }
                                  disabled={
                                    (Data && Data.isEdit === false) ||
                                    (Data?.data && Data.data?.type === "2")
                                  }
                                />
                              </div>
                            </div>
                            {values.dineIn === "0" && (
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      Table Number
                                      <span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <Field
                                    className="select-menu mb-2"
                                    name="tableNo"
                                    options={tableOptions}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    value={values.tableNo}
                                    disabled={Data && Data.isEdit === false}
                                    styles={customStyles}
                                    onchangedata={(value) =>
                                      onChangeTableNo(
                                        value,
                                        setFieldValue,
                                        values
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div className={"col-md-4"}>
                              <div className="name-type">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    Payment
                                    <span className="required-label"></span>
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="payment"
                                  options={paymentOption}
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  onchangedata={(value) =>
                                    handlePaymentValidation(
                                      value,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                  value={
                                    values.payment === "" ||
                                    values.payment === null
                                      ? false
                                      : values.payment
                                  }
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                            </div>

                            {(values.payment === "5" ||
                              values.payment === "6") && (
                              <div className="col-md-4">
                                <div className="name-type">
                                  <div className="paragraph-editor ">
                                    <h4 className="paragraph-title">
                                      Zomato/Swiggy Price
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                  <Input
                                    type="text"
                                    name="zomatoSwiggyPrice"
                                    label="zomato/Swiggy Price"
                                    placeholder="Enter Price"
                                    isRequired={true}
                                    Regex={/[^0-9.\s]/g}
                                    // maxLength={7}
                                    hidelength={"true"}
                                    value={values.zomatoSwiggyPrice}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                              </div>
                            )}

                            <div className={"col-md-4"}>
                              <div className="name-type">
                                <div className="paragraph-editor ">
                                  <h4 className="paragraph-title">
                                    Discount
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                                <Field
                                  className="select-menu mb-2"
                                  name="discount"
                                  options={discountOption}
                                  component={CustomSelect}
                                  placeholder="Select..."
                                  isMulti={false}
                                  changeData={(value) =>
                                    onSelectDiscount(value, setFieldValue, true)
                                  }
                                  value={
                                    values.discount === "" ||
                                    values.discount === null
                                      ? false
                                      : values.discount
                                  }
                                  disabled={Data && Data.isEdit === false}
                                />
                                {discountID &&
                                ((Data && Data.isEdit === true) || !Data) ? (
                                  <Button
                                    name="Remove discount"
                                    type="button"
                                    onClick={() => {
                                      handleRemoveDiscount(setFieldValue);
                                    }}
                                  ></Button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          {((Data && Data.isEdit === true) || !Data) && (
                            <div className="col-lg-5 col-md-6">
                              <div className="paragraph-editor">
                                <div className="btn-submit order-submit-btn">
                                  <Button
                                    type="submit"
                                    name="Place Order"
                                    className="btn login-btn  place-order-btn"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                    }}
                  >
                    {/* Search Bar Section */}
                    <div
                      className="col-lg-6 col-sm-12 col-12"
                      style={{
                        flex: "1",
                        minWidth: "250px",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="name-type">
                        <div className="search-bar">
                          <SearchBar
                            inputData={inputData}
                            setInputData={setInputData}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Dine In/Take Away and Table Number Section */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                        flex: "1",
                        minWidth: "250px",
                        marginBottom: "10px",
                        marginLeft: 10,
                      }}
                    >
                      {/* Dine In/Take Away Field */}
                      <div
                        className="dropdown-addorder" // Your CSS class
                        style={{
                          flex: "1",
                          padding: "10px",
                          marginRight: 10,
                          minWidth: "50px",
                        }}
                      >
                        <h4
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "8px",
                          }}
                        >
                          Service Type
                        </h4>
                        <Field
                          name="dineIn"
                          options={dineOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          changeData={(value) =>
                            changeDineInData(value, setFieldValue)
                          }
                          value={
                            values.dineIn === "" || values.dineIn === null
                              ? false
                              : values.dineIn
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>

                      {/* Table Number Field (Only visible when dineIn === "0") */}
                      {values.dineIn === "0" && (
                        <div
                          className="dropdown-addorder" // Your CSS class
                          style={{
                            flex: "1", // Inline styles
                            padding: "10px",
                            minWidth: "50px",
                          }}
                        >
                          <h4
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              marginBottom: "8px",
                            }}
                          >
                            Table
                          </h4>
                          <Field
                            name="tableNo"
                            options={tableOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            value={values.tableNo}
                            disabled={Data && Data.isEdit === false}
                            onchangedata={(value) =>
                              onChangeTableNo(value, setFieldValue, values)
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 col-sm-12 col-lg-8">
                      <div className="category hide-scrollbar">
                        <ul className="category-list">
                          {productCategory && productCategory.length > 0 ? (
                            productCategory.map((category, index) => (
                              <li
                                key={index}
                                className={
                                  activeIndex === index ? "cat-active" : ""
                                }
                                onClick={() => clickCategory(category, index)}
                              >
                                {(Data && Data.data === true) || !Data ? (
                                  <Link>{category.name}</Link>
                                ) : (
                                  <span
                                    onClick={() =>
                                      clickCategory(category, index)
                                    }
                                  >
                                    {category.name}
                                  </span>
                                )}
                              </li>
                            ))
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                      {subCategoryData &&
                      subCategoryData.items &&
                      subCategoryData.items.length > 0 &&
                      subCategoryData.items[0].data &&
                      subCategoryData.items[0].data.length > 0 ? (
                        // <div className="col-md-8 col-sm-12 col-lg-8">
                        <div className="category hide-scrollbar sub-category">
                          <ul className="sub-category-list">
                            {subCategoryData.items[0].data.map(
                              (subCategory, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    clickSubCategory(subCategory, index)
                                  }
                                  className={
                                    subCategoryActiveIndex === index
                                      ? "sub-active"
                                      : ""
                                  }
                                >
                                  {(Data && Data.isEdit === true) || !Data ? (
                                    <Link>{subCategory.name}</Link>
                                  ) : (
                                    <span>{subCategory.name}</span>
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        // </div>
                        <></>
                      )}
                    </div>

                    <div className="col-md-4 input-product-code">
                      <div className="name-type product-Code">
                        <div style={{ width: "100%" }}>
                          <Input
                            inputRef={inputRef}
                            type="text"
                            name="productCode"
                            label="Product Code"
                            placeholder="Enter SKU Code"
                            className="add-roles"
                            maxLength={10}
                            Regex={/[^0-9\s]/g}
                            value={values.productCode}
                            hidelength="true"
                            disabled={Data && Data.isEdit === false}
                            onInput={(event) =>
                              setFieldValue("productCode", event.target.value)
                            }
                            onKeyPress={(e) =>
                              handleKeyPress(e, values, setFieldValue)
                            }
                          />
                        </div>

                        <div
                          className="pc-btn-new"
                          onClick={() =>
                            handleAddedProduct(values, setFieldValue)
                          }
                        >
                          <FaPlus
                            className={`add-new-btn ${Data ? "disabled" : ""}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <br /> */}
                  <div className="row">
                    {productOptions && productOptions.length > 0 ? (
                      <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                        <div className="product-order">
                          <div className="row">
                            {productOptions.map((item, index) => (
                              <div
                                className="col-lg-4 col-md-6 col-12"
                                key={index}
                              >
                                {/* OrderCart */}
                                <OrderCart
                                  productData={item}
                                  setAddedProducts={setAddedProducts}
                                  showAddButton={true}
                                  addedProducts={addedProducts}
                                  productOptions={productOptions}
                                  setProductOptions={setProductOptions}
                                  disabled={Data && Data.isEdit === false}
                                  setFieldValue={setFieldValue}
                                  discountID={discountID}
                                  onSelectDiscount={onSelectDiscount}
                                  ref={orderCartRef}
                                  Data={Data}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`col-lg-8 col-md-6 col-sm-12 col-12`}>
                        <div className="product-order">
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                              <b>No Product Found!</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="col-lg-4 col-md-6 col-sm-12 col-12 position-relative"
                      style={{
                        backgroundColor: "#c2bfbf",
                        border: 1,
                        borderRadius: 10,
                      }}
                    >
                      {addedProducts && addedProducts.length > 0 ? (
                        <>
                          <div className="new-order-detail">
                            <h6>Order Details</h6>
                            {addedProducts.map((item, index) => (
                              <div key={index}>
                                <OrderCart
                                  productData={item}
                                  showAddButton={false}
                                  showMessageInput={true}
                                  addedProducts={addedProducts}
                                  setAddedProducts={setAddedProducts}
                                  productOptions={productOptions}
                                  setProductOptions={setProductOptions}
                                  showRadio={true}
                                  disabled={Data && Data.isEdit === false}
                                  setFieldValue={setFieldValue}
                                  discountID={discountID}
                                  onSelectDiscount={onSelectDiscount}
                                  ref={orderCartRef}
                                  Data={Data}
                                />
                              </div>
                            ))}
                          </div>
                          {/* Message Input and Total Amount Section */}
                          <div className="pb-3">
                            <div className="message-input">
                              <textarea
                                value={message}
                                placeholder="Would you like to add generalized instruction to chef 🧐?"
                                onChange={(event) =>
                                  setMessage(event.target.value)
                                }
                                disabled={Data && Data.isEdit === false}
                              />
                            </div>
                          </div>
                          {/* Total Amount and Buttons */}
                          <div className="row total-amount-btn">
                            <div className="col-lg-7 col-md-6">
                              <div className="name-type">
                                <div className="paragraph-editor">
                                  <h5 className="paragraph-title">
                                    Total Amount :{" "}
                                    {authuserData?.data?.symbol
                                      ? authuserData.data.symbol
                                      : "₹"}
                                    {Data && Data.isEdit === false
                                      ? Number(
                                          Data.data?.total_amount_with_tax
                                        ) + Number(Data.data?.service_charge)
                                      : updatedTotalAmt !== 0
                                      ? updatedTotalAmt
                                      : totalAmountData}
                                  </h5>
                                  {discountID ? (
                                    <span
                                      style={{
                                        fontSize: 12,
                                        display: "block",
                                      }}
                                    >
                                      Discount :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {discountAmt}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {(Data &&
                                    Data?.isEdit === false &&
                                    Number(Data.data?.total_tax_amount) > 0) ||
                                  (taxAmount && taxAmount > 0) ? (
                                    <span
                                      style={{ fontSize: 12, display: "block" }}
                                    >
                                      {authuserData?.data?.branchTaxType ===
                                      "GST"
                                        ? "GST"
                                        : authuserData?.data?.branchTaxType ===
                                          "VAT"
                                        ? "VAT"
                                        : "TAX"}{" "}
                                      :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {Data && Data?.isEdit === false
                                        ? Number(Data.data?.total_tax_amount)
                                        : taxAmount}
                                    </span>
                                  ) : (
                                    <></>
                                  )}

                                  {serviceCharge ? (
                                    <span
                                      style={{
                                        fontSize: 12,
                                        display: "block",
                                      }}
                                    >
                                      Service Charge :{" "}
                                      {authuserData?.data?.symbol
                                        ? authuserData.data.symbol
                                        : "₹"}
                                      {serviceCharge}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* Button Section */}
                            {(Data && Data.isEdit === true) || !Data ? (
                              <div className="col-lg-5 col-md-6">
                                <div className="paragraph-editor">
                                  <div className="btn-submit">
                                    <Button
                                      type="button"
                                      name="Place Order"
                                      className="btn login-btn place-order-btn width-100"
                                      onClick={() => handleUserDetailModal()}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-lg-5 col-md-6">
                                <div className="paragraph-editor">
                                  <div className="btn-submit">
                                    <Button
                                      type="button"
                                      name="Details"
                                      className="btn login-btn place-order-btn"
                                      onClick={() => handleUserDetailModal()}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="no-data-card">
                            <h6 className="no-data-title">No Products Added</h6>
                            <p className="no-data-description">
                              Your cart is currently empty. Please add some
                              products to proceed.
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
