const industryPageInitialState = {
    items: 1
};

/**
 * Store industry page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */

export const IndustryPageReducer = (state = industryPageInitialState, action) => {
    switch (action.type) {
        case 'GET_INDUSTRY_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_INDUSTRY_PAGE':
            return industryPageInitialState;
        default:
            return state;
    };
}