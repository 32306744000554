const purchaseOrderPageInitialState = {
    items: 1,
  };
  
  /**
   * Store PURCHASE ORDER page data
   * @param {number} state
   * @param {*} action
   * @returns {number}
   */
  export const PurchaseOrderPageReducer = (
    state = purchaseOrderPageInitialState,
    action
  ) => {
    switch (action.type) {
      case "GET_PURCHASE_ORDER_PAGE":
        return {
          ...state,
          items: action.payload,
        };
      case "CLEAR_PURCHASE_ORDER_PAGE":
        return purchaseOrderPageInitialState;
      default:
        return state;
    }
  };
  