/**
 * set purchase requisition filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getPurchaseRequisitionFilterData = ({
  purchaseRequisitionNo,
  orderStartDate,
  orderEndDate,
  vendor,
  status,
}) => ({
  type: "GET_PURCHASE_REQUISITION_FILTER",
  payload: {
    purchaseRequisitionNo,
    orderStartDate,
    orderEndDate,
    vendor,
    status,
  },
});

/**
 * clear purchase requisition filtered data
 * @returns {*}
 */
export const clearPurchaseRequisitionFilterData = () => ({
  type: "CLEAR_PURCHASE_REQUISITION_FILTER",
});
