import { Dispatch } from "redux";
import { addUpdateInventoryItemRequest, getAllInventoryItemRequest, getAllInventoryItemResponse, getInventoryItemByIdResponse } from "./InventoryItemModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, removePersistStorage, toastClass, ValidationMessage } from "../../../Utils/Utils";
import { clearInventoryItemData, clearTotalInventoryItemData, getInventoryItemData, getTotalInventoryItemData } from "../../../redux/Actions/InventoryItemAction/InventoryItemAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import { clearInventoryItemPageData, getInventoryItemPageData } from "../../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";

let errorShow = false;

/*
 * Service to get inventory item list
*/
export const getAllInventoryItemService = (
    inventoryItemListRequest: getAllInventoryItemRequest,
    token: string, dispatch: Dispatch, total?: any
): Promise<getAllInventoryItemResponse> => {
    return new Promise<getAllInventoryItemResponse>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            let queryParams = '';
            // Adding query parameters if they are provided
            if (inventoryItemListRequest.p_skip !== undefined && inventoryItemListRequest.p_skip !== null) {
                queryParams += `p_skip=${inventoryItemListRequest.p_skip}&`;
            }
            if (inventoryItemListRequest.p_limit !== undefined && inventoryItemListRequest.p_limit !== null) {
                queryParams += `p_limit=${inventoryItemListRequest.p_limit}&`;
            }
            if (inventoryItemListRequest.p_title !== undefined && inventoryItemListRequest.p_title !== null) {
                queryParams += `p_title=${inventoryItemListRequest.p_title}&`;
            }
            if (inventoryItemListRequest.p_isActive !== undefined && inventoryItemListRequest.p_isActive !== null) {
                queryParams += `p_isActive=${inventoryItemListRequest.p_isActive}&`
            }
            if (inventoryItemListRequest.p_IsDeleted !== undefined && inventoryItemListRequest.p_IsDeleted !== null) {
                queryParams += `p_IsDeleted=${inventoryItemListRequest.p_IsDeleted}&`
            }

            const response = await getApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                if (total && total === true) {
                    dispatch(getTotalInventoryItemData(response));
                }
                else if (total && total === 'filter') {
                    toast.success('Filter success!');
                    dispatch(getInventoryItemData(response));
                }
                else {
                    dispatch(getInventoryItemData(response));
                }
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Inventory item permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};

/*
 * Service for adding and updating Inventory Item 
*/
export const addUpdateInventoryItemService = (
    addUpdateInventoryItemRequest: addUpdateInventoryItemRequest,
    token: string, type: string, navigate?: any, pageNumber?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setLoader());
            let response: any;
            if (type === 'add') {
                response = await postApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM, token, addUpdateInventoryItemRequest);
            }
            if (type === 'update') {
                response = await putApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM, token, addUpdateInventoryItemRequest);
            }
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearInventoryItemData());
                dispatch(clearTotalInventoryItemData());
                if (type === 'add') {
                    dispatch(clearInventoryItemPageData());
                    toast.success('Inventory Item added successfully!');
                    navigate('/inventory-item');
                }
                if (type === 'update') {
                    if (navigate && navigate === 'undo') {
                        toast.success('Inventory Item  undeleted successfully!');
                    }
                    else {
                        if (navigate) {
                            if (pageNumber) {
                                dispatch(getInventoryItemPageData(pageNumber + 1))
                            }
                            else {
                                dispatch(clearInventoryItemPageData());
                            }
                            Swal.fire(
                                {
                                    title: "Saved!",
                                    text: "Inventory item updated successfully!",
                                    icon: "success",
                                    timer: 2000,
                                    showClass: {
                                        popup: 'animate__animated animate__fadeInDown'
                                    },
                                    hideClass: {
                                        popup: 'animate__animated animate__fadeOutUp'
                                    }
                                }
                            ).then(() => {
                                navigate('/inventory-item');
                            });
                        }
                        else {
                            toast.success('Inventory Item status updated successfully!');
                        }
                    }
                }
            }
            else {
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
                if (pageNumber) {
                    dispatch(getInventoryItemPageData(pageNumber));
                }
            }
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    };
};

/*
 * Service for deleting invertory item
*/
export const deleteInventoryItemService = (deleteId: number, token: string, pageNumber: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setLoader());
            const response = await deleteApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM + '/' + deleteId, token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearInventoryItemData());
                dispatch(clearTotalInventoryItemData());
                toast.success('Inventory Item deleted successfully!');
            }
            else {
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
                dispatch(getInventoryItemPageData(pageNumber))
            }
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    };
};

/*
 * Service to get individual inventory item
*/
export const getInventoryItemById = (
    id: string, token: string,
    dispatch: Dispatch): Promise<getInventoryItemByIdResponse> => {
    return new Promise<getInventoryItemByIdResponse>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            const response = await getApiService(API_URL.BASE_URL + API_URL.INVENTORYITEM + '/' + id, token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                resolve(response);
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
            resolve(response);
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }

        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};