import { toast } from "react-toastify";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { AddUpdateCompanyRequest, companyListAPIRequest, companyListAPIResponse, compnayDetailResponse } from "./companyAPIModule";
import { postApiService } from "../../../services/API-services/PostService";
import Swal from "sweetalert2";
import { API_URL, removePersistStorage, toastClass, ValidationMessage } from "../../../Utils/Utils";
import { Dispatch } from "redux";
import { logout } from "../../../redux/Actions/authAction/AuthAction";
import { clearCompanyData, clearTotalCompanyData, getCompanyData, getTotalCompanyData } from "../../../redux/Actions/companyAction/CompanyAction";
import { getApiService } from "../../../services/API-services/GetService";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearCompanyPageData, getCompanyPageData } from "../../../redux/Actions/companyAction/CompanyPageAction";

let errorShow = false;

/*
 * Service to get Company list
*/
export const getCompanyService = (
  companyListRequest: companyListAPIRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<companyListAPIResponse> => {
  return new Promise<companyListAPIResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (companyListRequest.p_skip !== undefined && companyListRequest.p_skip !== null) {
        queryParams += `p_skip=${companyListRequest.p_skip}&`;
      }

      if (companyListRequest.p_take !== undefined && companyListRequest.p_take !== null) {
        queryParams += `p_take=${companyListRequest.p_take}&`;
      }

      if (companyListRequest.p_pincode !== undefined && companyListRequest.p_pincode !== null) {
        queryParams += `p_pincode=${companyListRequest.p_pincode}&`
      }

      if (companyListRequest.p_address !== undefined && companyListRequest.p_address !== null) {
        queryParams += `address=${companyListRequest.p_address}&`;
      }

      if (companyListRequest.p_company_name !== undefined && companyListRequest.p_company_name !== null) {
        queryParams += `company_name=${companyListRequest.p_company_name}&`;
      }
      
      if (companyListRequest.p_IsDeleted !== undefined && companyListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${companyListRequest.p_IsDeleted}&`
      }
         
      const response = await getApiService(API_URL.BASE_URL + API_URL.COMPANY + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success' || response.success === true)) {
        if (total && total === true) {
          dispatch(getTotalCompanyData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getCompanyData(response));
        }
        else {
          dispatch(getCompanyData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Company permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};


/*
 * Service to get individual company
*/
export const getCompanyById = (
  id: number, token: string,
  dispatch: Dispatch): Promise<compnayDetailResponse> => {
  return new Promise<compnayDetailResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.COMPANY + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        resolve(response);
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};


/*
 * Service for adding and updating company
*/
export const addUpdateCompanyService = (
    addUpdateCompanyRequest: AddUpdateCompanyRequest,
    token: string, type: string, navigate?: any, pageNumber?: number) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(setLoader());
        let response: any;
        if (type === 'add') {
          response = await postApiService(API_URL.BASE_URL + API_URL.COMPANY, token, addUpdateCompanyRequest);
        }
        if (type === 'update') {
          response = await postApiService(API_URL.BASE_URL + API_URL.COMPANY, token, addUpdateCompanyRequest);
        }
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          dispatch(clearCompanyData()); 
          dispatch(clearTotalCompanyData());  
          if (type === 'add') {
            dispatch(clearCompanyPageData()); 
            toast.success('Company added successfully!');
            navigate('/company');
          }
          if (type === 'update') {
            if (navigate && navigate === 'undo') {
              toast.success('Company undeleted successfully!');
            }
            else {
              if (navigate) {
                if (pageNumber) {
                  dispatch(getCompanyPageData(pageNumber + 1));
                }
                else {
                  dispatch(clearCompanyPageData());
                }
                Swal.fire(
                  {
                    title: "Saved!",
                    text: "Company updated successfully!",
                    icon: "success",
                    timer: 2000,
                    showClass: {
                      popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                      popup: 'animate__animated animate__fadeOutUp'
                    }
                  }
                ).then(() => {
                  navigate('/company');
                });
              }
              else {
                toast.success('Company status updated successfully!');
              }
            }
          }
        }
        else {

          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
          errorShow = true;
          if (response?.message && response.message === 'Unauthorized access!') {
            removePersistStorage(dispatch);
            dispatch(logout());   
          }
          if (pageNumber) {
            // dispatch(getCompanyPageData(pageNumber)); 
          }
        }
      } catch (error) {
        if (errorShow === false) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
        
      } finally {
        errorShow = false;
        dispatch(clearLoader()); 
      }
    };
  };

  

/*
 * Service to delete company
*/
export const deleteCompanyService = (
  id: string,
  token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.COMPANY + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearCompanyData());
        dispatch(clearTotalCompanyData());
        toast.success('Company deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout()); 
        }
        dispatch(getCompanyPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};