const spotPageInitialState={
    items:1
};

/**
 * Store spot page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */

export const SpotPageReducer=(state=spotPageInitialState,action)=>{
    switch (action.type) {
        case 'GET_SPOT_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_SPOT_PAGE':
            return spotPageInitialState;
        default:
            return state;
      };
}