const newVendorFilterInitialState = {
  firstName: "",
  lastName: "",
  phNum: "",
  isActive: "",
  isDelete: "",
};

/**
 * Store new vendor's filtered data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const newVendorFilterReducer = (
  state = newVendorFilterInitialState,
  action
) => {
  switch (action.type) {
    case "GET_NEW_VENDOR_FILTER":
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phNum: action.payload.phNum,
        isActive: action.payload.isActive,
        isDelete: action.payload.isDelete,
      };
    case "CLEAR_NEW_VENDOR_FILTER":
      return newVendorFilterInitialState;
    default:
      return state;
  }
};
