/**
 * Set Purchase Order page data
 * @param {*} page
 * @returns {*}
 */
export const getPurchaseOrderPageData = (page) => ({
    type: "GET_PURCHASE_ORDER_PAGE",
    payload: page,
  });
  
  /**
   * Clear Purchase Requisition page data
   * @returns {*}
   */
  export const clearPurchaseOrderPageData = () => ({
    type: "CLEAR_PURCHASE_ORDER_PAGE",
  });
  