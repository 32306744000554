export const addUpdateProductRequest = (values, Data, fields, userData) => {
  const tagData =
    values.tags && values.tags.length > 0
      ? values.tags.map((item) => ({
          id: item,
        }))
      : [];

  const categoryData =
    values.category && values.category.length > 0
      ? values.category.map((item) => ({
          id: item,
        }))
      : [];

  const subCategoryData =
    values.subCategory && values.subCategory.length > 0
      ? values.subCategory.map((item) => ({
          id: item,
        }))
      : [];

  const leafData =
    values.leafCategory && values.leafCategory.length > 0
      ? values.leafCategory.map((item) => ({
          id: item,
        }))
      : [];

  let attributeData =
    fields.length > 0
      ? fields.map((item) => ({
          id: item.attributes,
          value: item.attributeValues,
          imageId:
            item.attributeImage &&
            item.attributeImage[0] &&
            item.attributeImage[0].imageId
              ? item.attributeImage[0].imageId
              : null,
          type_id:
            item.attributeImage &&
            item.attributeImage[0] &&
            item.attributeImage[0].imageType
              ? item.attributeImage[0].imageType
              : null,
          category_id: item.attributeCategory,
        }))
      : [];

  if (attributeData.length < 1) {
    if (
      values.attributes !== "" &&
      values.attributeValues !== "" &&
      values.attributeCategory !== ""
    ) {
      attributeData.push({});
      attributeData[0].id = values.attributes;
      attributeData[0].value = values.attributeValues;
      attributeData[0].imageId =
        values.attributeImage &&
        values.attributeImage[0] &&
        values.attributeImage[0].imageId
          ? values.attributeImage[0].imageId
          : null;
      attributeData[0].type_id =
        values.attributeImage &&
        values.attributeImage[0] &&
        values.attributeImage[0].imageType
          ? values.attributeImage[0].imageType
          : null;
      attributeData[0].category_id = values.attributeCategory;
    }
  } else {
    if (
      values.attributes !== "" &&
      values.attributeValues !== "" &&
      values.attributeCategory !== ""
    ) {
      attributeData.push({
        id: values.attributes,
        value: values.attributeValues,
        imageId:
          values.attributeImage &&
          values.attributeImage[0] &&
          values.attributeImage[0].imageId
            ? values.attributeImage[0].imageId
            : null,
        type_id:
          values.attributeImage &&
          values.attributeImage[0] &&
          values.attributeImage[0].imageType
            ? values.attributeImage[0].imageType
            : null,
        category_id: values.attributeCategory,
      });
    }
  }

  const imageIds =
    values.productImage && values.productImage.length > 0
      ? values.productImage.map((item) => ({
          id: item.imageId,
          type_id: item.imageType,
        }))
      : [];

  const requestBody = {
    ...(Data && Data.data && { id: Data.data.productId }),
    warehouse_id: values.warehouse,
    brand_id: values.brand,
    manufacturer_id: values.manufacturer,
    packer_id: values.packer,
    importer_id: values.importer,
    name: values.productName,
    description: values.description,
    additional_description: values.additionalDescription,
    product_code: values.code,
    hsn: values.hsn,
    price: Number(values.price),
    p_purchase_price:
      values.purchase_amount && values.purchase_amount !== ""
        ? Number(values.purchase_amount)
        : 0,
    unit: values.units.toString(),
    sku: `SKU-${userData.data.companyCode}-${values.sku}`,
    size: values.size,
    color_code: values.color_code,
    country_of_origin: values.country,
    color_name: values.color_name,
    Tags: tagData,
    SKID: values.skid,
    EAN_CODES: values.ean,
    imageIds: imageIds,
    category_ids: categoryData,
    sub_category_ids: subCategoryData,
    leaf_level_ids: leafData,
    attributeData: attributeData,
    Unit_Fill_Pack_Size: values.Unit_Fill_Pack_Size,
    Unit_Pack_Size: values.Unit_Pack_Size,
    Length_of_outer_package: values.length_of_outer_package,
    Breadth_of_outer_package: values.breadth_of_outer_package,
    Height_of_outer_package: values.height_of_outer_package,
    Weight_including_packaging: values.weight,
    ...(!Data && { created_by: userData.data.userId }),
    is_active:
      values.status === true || values.status === 1 || values.status === "1"
        ? "1"
        : "0",
    p_veg_nonveg:
      values.veg === true || values.veg === 1 || values.veg === "1" ? "0" : "1",
    p_jain_regular:
      values.veg === true || values.veg === 1 || values.veg === "1"
        ? values.regular
        : "2",
    p_exclude_filter:
      values.excludeFilters === true ||
      values.excludeFilters === 1 ||
      values.excludeFilters === "1"
        ? "1"
        : "0",
    p_is_vegan:
      (values.veg === true || values.veg === 1 || values.veg === "1") &&
      (values.vegan === true || values.vegan === 1 || values.vegan === "1")
        ? "1"
        : "0",
    p_is_jain:
      (values.veg === true || values.veg === 1 || values.veg === "1") &&
      (values.jain === true || values.jain === 1 || values.jain === "1")
        ? "1"
        : "0",
    p_sgst: values?.sgst && values.sgst !== "" ? values.sgst : null,
    p_cgst: values?.cgst && values.cgst !== "" ? values.cgst : null,
    p_igst: values?.igst && values.igst !== "" ? values.igst : null,
    p_vat: values?.vat && values.vat !== "" ? values.vat : null,
  };
  return requestBody;
};