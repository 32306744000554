/**
 * Set product pricing list data into reducer
 * @param {*} data 
 * @returns {*}
 */
export const getProductPricingData = (data) => ({
    type: 'GET_PRODUCT_PRICING_DATA',
    payload: data
})

/**
 * clear product list data
 * @returns {*}
 */
export const clearProductPricingData = () => ({
    type: 'CLEAR_PRODUCT_PRICING_DATA'
})

/**
 * Set total product list data into reducer (No pagination)
 * @param {*} data 
 * @returns {*}
 */
export const getTotalProductPricingData = (data) => ({
    type: 'TOTAL_PRODUCT_PRICING_DATA',
    payload: data
})

/**
 * Clear total product list data (No pagination)
 * @returns {*}
 */
export const clearTotalProductPricingData = () => ({
    type: 'CLEAR_TOTAL_PRICING_PRODUCT'
})