import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { handleCancleClick } from "../../../components/Alertmessage/Alertmessage";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import "../../Inventory/AddInventory/AddInventory.scss";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { getAllWarehouseService } from "../../Warehouse/WarehouseService/WarehouseService";
import { format } from "date-fns";
import { addInventoryService } from "../InventoryService/InventoryService";
import { modifyString } from "../../../services/Regular-services/StringService";

/*
 *Validation schema
 */
const validationSchema = Yup.object({
  productCode: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  sku: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  currentStock: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  warehouse: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  addStock: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  batch: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  expiryDate: Yup.string().required(ValidationMessage.DATE_REQUIRED),
});

export default function AddInventory() {
  /**
   * Setting initial values
   */
  const [initialValues, setInitialValues] = useState({
    productCode: "",
    sku: "",
    currentStock: "",
    warehouse: "",
    addStock: "",
    batch: "",
    expiryDate: "",
  });

  /**
   * Getting data from leaf-level-category-list page
   */
  const location = useLocation();
  const { Data } = location.state || {};

  const inventoryPage = useSelector((state) => state.inventoryPage?.items);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [inventoryActionData, setinventoryActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "inventory"
      );
      setinventoryActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        productCode: Data.data.product_code,
        sku: Data.data.sku,
        currentStock: Data.data.remaining_stock,
        warehouse: Data.data.warehouse_id,
        addStock: "",
        batch: "",
        expiryDate: "",
      });
    }
  }, [Data]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const user = useSelector((state) => state.auth.user);

  /**
   * Setting Brand Options
   */
  const totalWarehouse = useSelector((state) => state.totalWarehouse);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  /**
   * Fetch warehouse data
   */
  const fetchWarehouse = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllWarehouseService(requestBody, tokenData, dispatch, true);
  };
  useEffect(() => {
    if (totalWarehouse && totalWarehouse.items.length === 0) {
      fetchWarehouse();
    } else {
      if (
        totalWarehouse.items[0]?.data &&
        totalWarehouse.items[0].data.length > 0
      ) {
        const warehouse = totalWarehouse.items[0].data.map((item) => ({
          value: item.warehouseId,
          label: item.name,
          is_active: item.is_active,
        }));
        setWarehouseOptions(warehouse);
      }
    }
    // eslint-disable-next-line
  }, [totalWarehouse]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    const requestBody = {
      p_product_id: Data.data.product_id,
      p_product_code: values.productCode,
      p_sku_code: values.sku,
      p_warehouse_id: values.warehouse,
      p_current_stock: parseInt(values.currentStock),
      p_new_added_stock: parseInt(values.addStock),
      p_batch_no: values.batch,
      p_expiry_date: format(new Date(values.expiryDate), "yyyy-MM-dd"),
      p_created_by: user.data.userId,
    };
    if (Data?.length && inventoryPage > 1) {
      dispatch(
        addInventoryService(requestBody, tokenData, navigate, inventoryPage)
      );
    } else {
      dispatch(addInventoryService(requestBody, tokenData, navigate));
    }
  };

  return (
    <>
      <div className="add-image-section">
        {/* <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header /> */}
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    Add{" "}
                    {inventoryActionData && inventoryActionData.length > 0
                      ? modifyString(inventoryActionData[0].name)
                      : "Inventory"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/inventory")}>
                    {inventoryActionData && inventoryActionData.length > 0
                      ? inventoryActionData[0].name
                      : "Inventory"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    Add{" "}
                    {inventoryActionData && inventoryActionData.length > 0
                      ? modifyString(inventoryActionData[0].name)
                      : "Inventory"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                Add{" "}
                {inventoryActionData && inventoryActionData.length > 0
                  ? modifyString(inventoryActionData[0].name)
                  : "Inventory"}
              </h4>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Product Code{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="productCode"
                          label="Product Code"
                          placeholder="Enter Product Code"
                          className="add-roles"
                          maxLength={70}
                          disabled={true}
                          hidelength={"true"}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            SKU <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="sku"
                          label="SKU"
                          placeholder="Enter SKU"
                          className="add-roles"
                          maxLength={70}
                          disabled={true}
                          hidelength={"true"}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Current Stock{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="Number"
                          name="currentStock"
                          label="Current Stock"
                          placeholder="Enter Current Stock"
                          className="add-roles"
                          maxLength={70}
                          disabled={true}
                          hidelength={"true"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Select Warehouse
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="warehouse"
                          options={warehouseOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          styles={customStyles}
                          value={values.warehouse}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Add More Stock{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="addStock"
                          label="Add More Stock"
                          placeholder="Enter Add More Stock"
                          className="add-roles"
                          maxLength={70}
                          Regex={/[^-0-9]/g}
                          hidelength={"true"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Batch Number{" "}
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="batch"
                          label="batch number"
                          placeholder="Enter Batch Number"
                          className="add-roles"
                          maxLength={70}
                          hidelength={"true"}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 datepicker-width">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Expiry Date <span className="required-label"></span>
                          </h4>
                        </div>
                        <DatePicker
                          selected={values.expiryDate}
                          onChange={(date) => {
                            setFieldValue("expiryDate", date);
                          }}
                          selectsStart
                          startDate={values.expiryDate}
                          endDate={values.expiryDate}
                          placeholderText="Expiry Date"
                          showIcon
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          <Button
                            type="submit"
                            name="Save"
                            className="btn login-btn"
                          />
                          <Button
                            type="button"
                            name="Cancel"
                            className="btn clear-btn login-btn"
                            onClick={() => handleCancleClick("/tags", navigate)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <Footer />
        </div> */}
      </div>
    </>
  );
}
