import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import "../Imagemanager/Imagemanager.scss";
import Toggle from "../../components/Toggle/Toggle";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import {
  getAllLeafLevelCategoryService,
  uploadLeafExcelService,
} from "./LeafLevelService/LeafLevelService";
import UtilsData from "../../Utils/Utils";
import { formatUTCDate } from "../../services/Regular-services/DateService";
import { getAllSubCategoryService } from "../SubCategory/SubCategoryService/SubCategoryService";
import { clearLeafFilterData } from "../../redux/Actions/leafLevelCategoryAction/LeafFilterAction";
import { clearLeafLevelData } from "../../redux/Actions/leafLevelCategoryAction/LeafLevelCategoryAction";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearLeafPageData,
  getLeafPageData,
} from "../../redux/Actions/leafLevelCategoryAction/LeafPageAction";
import { TfiImport } from "react-icons/tfi";
import FileUploadModal from "../../components/Import-component/ImportComponent";
import sampleLeafExcelFile from "../../assets/files/leaf-level-category.xlsx";
import DownloadExcel from "../../services/Regular-services/DownloadExcelService";

//Show keys which is to be displayed in table
const showKey = {
  Title: true,
  Description: true,
  SubCategoryName: true,
};

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

//Handle toggle change
const handleToggleChange = (id) => {};
export default function LeafLevelCategory() {
  const tokenData = UtilsData();
  const dispatch = useDispatch();

  const leafFilter = useSelector((state) => state.leafFilter);
  const leafPage = useSelector((state) => state.leafPage?.items);

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [leafActionData, setleafActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setleafActionData(actionData);
    }
  }, [sideBarData]);

  const [columns, setColumns] = useState([]);

  const [showActionBool, setShowActionBool] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (
      leafActionData[0]?.view &&
      leafActionData[0].view === "0" &&
      leafActionData[0]?.edit &&
      leafActionData[0].edit === "0" &&
      leafActionData[0]?.add &&
      leafActionData[0].add === "0" &&
      leafActionData[0]?.delete &&
      leafActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Title",
          selector: (row) => row.title,
        },
        {
          name: "Description",
          selector: (row) => row.description,
        },
        {
          name: "Sub category",
          selector: (row) => row.parentcategory,
        },
        {
          name: " Status",
          selector: (row) => row.active,
          cell: (row) => (
            <Toggle
              checked={row.toggle}
              onChange={() => handleToggleChange(row.id)}
            />
          ),
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Title",
          selector: (row) => row.title,
        },
        {
          name: "Description",
          selector: (row) => row.description,
        },
        {
          name: "Sub category",
          selector: (row) => row.parentcategory,
        },
        {
          name: " Status",
          selector: (row) => row.active,
          cell: (row) => (
            <Toggle
              checked={row.toggle}
              onChange={() => handleToggleChange(row.id)}
            />
          ),
        },
        {
          name: "Actions",
          selector: (row) => row.action,
        },
      ]);
    }
  }, [leafActionData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (leafActionData[0]?.view && leafActionData[0].view === "1") ||
      !leafActionData
        ? true
        : false,
    showEdit:
      (leafActionData[0]?.edit && leafActionData[0].edit === "1") ||
      !leafActionData
        ? true
        : false,
    showDelete:
      (leafActionData[0]?.delete && leafActionData[0].delete === "1") ||
      !leafActionData
        ? true
        : false,
    showUndo:
      (leafActionData[0]?.edit && leafActionData[0].edit === "1") ||
      !leafActionData
        ? true
        : false,
    navigate:
      (leafActionData[0]?.edit && leafActionData[0].edit === "1") ||
      !leafActionData
        ? "/edit-leaf-level-category"
        : false,
  };

  useEffect(() => {
    setCurrentPage(leafPage);
  }, [leafPage]);

  const [tableData, setTableData] = useState([]);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const subCategoryListData = useSelector((state) => state.totalSubCategory);

  /**
   * Fetching sub-category data
   */
  const fetchSubCategory = () => {
    const subCategoryRequestBody = {
      p_skip: 0,
      p_take: 10000000,
    };
    getAllSubCategoryService(subCategoryRequestBody, tokenData, dispatch, true);
  };

  useEffect(() => {
    if (
      subCategoryListData &&
      subCategoryListData.items &&
      subCategoryListData.items.length === 0
    ) {
      fetchSubCategory();
    }
    // eslint-disable-next-line
  }, [subCategoryListData]);

  //Get data from reducer
  const leafCategoryData = useSelector((state) => state.leafCategory);

  /**
   * Fetching leaf level category data
   */
  const fetchLeafCategory = () => {
    if (leafPage > 1) {
      const requestBody = {
        p_skip: (leafPage - 2) * 10,
        p_take: 10,
      };
      if (leafFilter.name !== "" && leafFilter.name !== null) {
        requestBody.p_name = leafFilter.name;
      }
      if (leafFilter.subCategory !== "" && leafFilter.subCategory !== null) {
        requestBody.p_sub_categoryIds = leafFilter.subCategory;
      }

      if (leafFilter.isActive !== "" && leafFilter.isActive !== null) {
        requestBody.p_is_active = leafFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (leafFilter.isDelete !== "" && leafFilter.isDelete !== null) {
        requestBody.p_isDeleted = leafFilter.isDelete;
      }
      dispatch(getLeafPageData(leafPage - 1));
      getAllLeafLevelCategoryService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (leafPage - 1) * 10,
        p_take: 10,
      };
      if (leafFilter.name !== "" && leafFilter.name !== null) {
        requestBody.p_name = leafFilter.name;
      }
      if (leafFilter.subCategory !== "" && leafFilter.subCategory !== null) {
        requestBody.p_sub_categoryIds = leafFilter.subCategory;
      }

      if (leafFilter.isActive !== "" && leafFilter.isActive !== null) {
        requestBody.p_is_active = leafFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (leafFilter.isDelete !== "" && leafFilter.isDelete !== null) {
        requestBody.p_isDeleted = leafFilter.isDelete;
      }
      getAllLeafLevelCategoryService(requestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    if (
      leafCategoryData &&
      leafCategoryData.items &&
      leafCategoryData.items.length === 0
    ) {
      fetchLeafCategory();
    } else {
      if (
        leafFilter.name !== "" ||
        leafFilter.subCategory !== "" ||
        leafFilter.isActive !== "" ||
        leafFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }

    if (
      leafCategoryData.items[0]?.data &&
      leafCategoryData.items[0].data.length > 0
    ) {
      const transformedLeafData = leafCategoryData.items[0].data.map(
        (item) => ({
          id: item.id,
          Title: item.Title,
          Description: item.Description,
          SubcategoryId: item.SubcategoryId,
          SubCategoryName: "",
          createdAt: formatUTCDate(item.created_at),
          sequence: item.id,
          isActive: item.is_active,
          isDeleted: item.IsDeleted,
          createdBy: item.created_by,
          modifiedBy: item.modified_by,
          modifiedAt: item.modified_at,
        })
      );
      if (
        subCategoryListData &&
        subCategoryListData.items &&
        subCategoryListData.items[0]?.data &&
        subCategoryListData.items[0].data.length > 0
      ) {
        for (let i = 0; i < leafCategoryData.items[0].data.length; i++) {
          const subcategoryId = leafCategoryData.items[0].data[i].SubcategoryId;
          const matchingObject = subCategoryListData.items[0].data.find(
            (obj) => obj.id === subcategoryId
          );
          if (matchingObject) {
            transformedLeafData[i].SubCategoryName = matchingObject.name;
          }
        }
      }
      setTableData(transformedLeafData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [leafCategoryData]);

  const navigate = useNavigate();

  const [isToggled, setIsToggled] = useState(false);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Clear filtered data
   */
  const clearFilters = () => {
    dispatch(clearLeafFilterData());
    dispatch(clearLeafLevelData());
    dispatch(clearLeafPageData());
    setIsToggled(false);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (leafFilter.name !== "" && leafFilter.name !== null) {
        requestBody.p_name = leafFilter.name;
      }
      if (leafFilter.subCategory !== "" && leafFilter.subCategory !== null) {
        requestBody.p_sub_categoryIds = leafFilter.subCategory;
      }

      if (leafFilter.isActive !== "" && leafFilter.isActive !== null) {
        requestBody.p_is_active = leafFilter.isActive;
      } else {
        requestBody.p_is_active = "1";
      }

      if (leafFilter.isDelete !== "" && leafFilter.isDelete !== null) {
        requestBody.p_isDeleted = leafFilter.isDelete;
      }
      getAllLeafLevelCategoryService(requestBody, tokenData, dispatch);
    }
    setCurrentPage(pageNumber);
    dispatch(getLeafPageData(pageNumber));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImportClick = () => {
    // Open the modal
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleFileUpload = (file) => {
    // Process the uploaded file here
    dispatch(uploadLeafExcelService(file, tokenData, setIsModalOpen));
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{leafActionData && leafActionData.length > 0 ? leafActionData[0].name : 'Leaf Level Categories'}</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {leafActionData && leafActionData.length > 0 ? leafActionData[0].name : 'Leaf Level Categories'}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {leafFilter &&
                  (leafFilter.name !== "" ||
                    leafFilter.subCategory !== "" ||
                    leafFilter.isActive !== "" ||
                    leafFilter.isDelete !== "") && (
                    <Dropdown className="header-dropdown action-btn">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        data-bs-toggle="tooltip"
                        title="Action"
                      >
                        <GrMoreVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={clearFilters}>
                          Clear Filter
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                {/* {((leafActionData[0]?.add && leafActionData[0].add === "1") ||
                  !leafActionData) && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <TfiImport />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleImportClick}>
                        Import
                      </Dropdown.Item>
                      <DownloadExcel
                        excelFile={sampleLeafExcelFile}
                        fileName="leaf-level-category.xlsx"
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}

                <FileUploadModal
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onFileUpload={handleFileUpload}
                  isClose={isModalOpen}
                />

<Button
        onClick={handleShow}
        data-bs-toggle="tooltip"
        title="Filter"
        // onClick={handleButtonClick}
        className="btn btn-fillter-main"
        name={
          <div className="filter">
            <FaFilter className="filter-icon" />
          </div>
        }
      >
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Filter
                page="leafCategory"
                handleClose={handleClose}
              ></Filter>
        </Offcanvas.Body>
      </Offcanvas>
                {leafCategoryData &&
                  leafCategoryData.items &&
                  leafCategoryData.items[0]?.data &&
                  leafCategoryData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={leafCategoryData.items[0].data}
                      fileName={"leaf-level-category"}
                    ></ExcelData>
                  )}
                {((leafActionData[0]?.add && leafActionData[0].add === "1") ||
                  !leafActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add leaf-level-category"
                    onClick={() => navigate("/add-leaf-level-category")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {/* {isToggled && (
              <Filter
                page="leafCategory"
                handleButtonClick={handleButtonClick}
              ></Filter>
            )} */}
            {leafCategoryData &&
              leafCategoryData.items[0]?.data &&
              leafCategoryData.items[0].data && (
                <>
                  <CustomTable
                    totalRecords={
                      leafCategoryData.items[0].total_records
                        ? leafCategoryData.items[0].total_records
                        : 0
                    }
                    showDescription={true}
                    showToggle={showToggle}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    page="leafCategory"
                    disabled={
                      leafActionData[0]?.edit && leafActionData[0].edit === "0"
                    }
                  />

                  {leafCategoryData.items[0].total_records >
                    leafCategoryData.items[0].data.length &&
                    leafCategoryData.items[0].total_records > 10 && (
                      <div className="custom-pagination">
                        <CustomPagination
                          totalRecords={leafCategoryData.items[0].total_records}
                          currentPage={currentPage}
                          onPageChange={onPageChange}
                        />
                      </div>
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}