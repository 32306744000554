/**
 * Setting fetched Payment Detail after create Account data into reducer
 * @param {*} paymentDetail
 * @returns {*}
 */
export const getPaymentDetailData = (paymentDetail) => ({
  type: "GET_PAYMENT_DETAIL_DATA",
  payload: paymentDetail,
});

/**
 * clearing Payment Detail list data
 * @returns {*}
 */
export const clearPaymentDetailData = () => ({
  type: "CLEAR_PAYMENT_DETAIL_DATA",
});
