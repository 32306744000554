import { Dispatch } from "redux";
import {
  addUpdatePurchaseOrderRequest,
  getAllPurchaseOrderRequest,
  getAllPurchaseOrderResponse,
  getGRNDetailRequest,
  getGRNDetailResponse,
  UploadPDFRequest,
} from "./PurchaseOrderModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearPurchaseOrderData,
  clearTotalPurchaseOrderData,
  getPurchaseOrderData,
  getTotalPurchaseOrderData,
} from "../../../redux/Actions/PurchaseOrderAction/PurchaseOrderAction";
import {
  clearPurchaseOrderPageData,
  getPurchaseOrderPageData,
} from "../../../redux/Actions/PurchaseOrderAction/PurchaseOrderPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";
import { postApiServiceWp } from "../../../services/API-services/PostServiceWp";

let errorShow = false;

/*
 * Service to get PR list
 */
export const getAllPurchaseOrderService = (
  purchaseOrderListRequest: getAllPurchaseOrderRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllPurchaseOrderResponse> => {
  // Update return type here
  return new Promise<getAllPurchaseOrderResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        purchaseOrderListRequest.p_skip !== undefined &&
        purchaseOrderListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${purchaseOrderListRequest.p_skip}&`;
      }
      if (
        purchaseOrderListRequest.p_take !== undefined &&
        purchaseOrderListRequest.p_take !== null
      ) {
        queryParams += `p_take=${purchaseOrderListRequest.p_take}&`;
      }
      if (
        purchaseOrderListRequest.p_purchase_order_no !== undefined &&
        purchaseOrderListRequest.p_purchase_order_no !== null
      ) {
        queryParams += `p_purchase_order_no=${purchaseOrderListRequest.p_purchase_order_no}&`;
      }
      if (
        purchaseOrderListRequest.p_created_date !== undefined &&
        purchaseOrderListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${purchaseOrderListRequest.p_created_date}&`;
      }
      if (
        purchaseOrderListRequest.p_end_date !== undefined &&
        purchaseOrderListRequest.p_end_date !== null
      ) {
        queryParams += `p_end_date=${purchaseOrderListRequest.p_end_date}&`;
      }

      if (
        purchaseOrderListRequest.p_time_zone !== undefined &&
        purchaseOrderListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${purchaseOrderListRequest.p_time_zone}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.PURCHASEORDER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalPurchaseOrderData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getPurchaseOrderData(response));
        } else {
          dispatch(getPurchaseOrderData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Purchase Requisition permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating Purchase Order
 */
export const addUpdatePurchaseOrderService = (
  addUpdatePurchaseOrderRequest: addUpdatePurchaseOrderRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.PURCHASEORDER,
          token,
          addUpdatePurchaseOrderRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.PURCHASEORDER,
          token,
          addUpdatePurchaseOrderRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearPurchaseOrderData());
        dispatch(clearTotalPurchaseOrderData());
        if (type === "add") {
          dispatch(clearPurchaseOrderPageData());
          toast.success("Purchase Order added successfully!");
          navigate("/purchase-requisition");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Purchase Requisition undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getPurchaseOrderPageData(pageNumber + 1));
              } else {
                dispatch(clearPurchaseOrderPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Purchase Order updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/purchase-Order");
              });
            } else {
              toast.success("Purchase Order status updated successfully!");
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getPurchaseOrderPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service to get PR list
 */
export const getGRNDetailService = (
  getGRNDetailRequest: getGRNDetailRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getGRNDetailResponse> => {
  // Update return type here
  return new Promise<getGRNDetailResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        getGRNDetailRequest.p_pr_id !== undefined &&
        getGRNDetailRequest.p_pr_id !== null
      ) {
        queryParams += `p_pr_id=${getGRNDetailRequest.p_pr_id}&`;
      }
      if (
        getGRNDetailRequest.p_po_id !== undefined &&
        getGRNDetailRequest.p_po_id !== null
      ) {
        queryParams += `p_po_id=${getGRNDetailRequest.p_po_id}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.PURCHASEORDER + API_URL.GRNDETAIL +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "GRN Detail permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};


export const uploadPDFServicePO = (
  uploadPDFRequest: UploadPDFRequest,
  token: string,
  navigate?: any
) => {

  return async (dispatch: Dispatch) => {
    let errorShow = false;

    try {
      dispatch(setLoader());

      // Prepare FormData
      const formData = new FormData();
      formData.append("pdf", uploadPDFRequest.pdf);
      formData.append(
        "p_vendor_phone_number",
        uploadPDFRequest.p_vendor_phone_number
      );
      formData.append(
        "p_branch_user_phone_number",
        uploadPDFRequest.p_branch_user_phone_number
      );
      formData.append(
        "p_branch_user_name",
        uploadPDFRequest.p_branch_user_name
      );
      formData.append("p_company_name", uploadPDFRequest.p_company_name);
      formData.append("p_branch_Address", uploadPDFRequest.p_branch_Address);
    
      // Make API call
      const response = await postApiServiceWp(
        API_URL.BASE_URL + API_URL.VENDOR + API_URL.WHATSAPP,
        token,
        formData
      );

      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success("Message sent to vendor successfully!");

        if (navigate) {
          Swal.fire({
            title: "Success!",
            text: "Message sent to vendor successfully!",
            icon: "success",
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            navigate("/purchase-order");
          });
        }
      } else {
        toast.error(
          response?.message ? response.message : "Something went wrong"
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          // Handle unauthorized access, e.g., logout or remove persist storage
        }
      }
    } catch (error) {
      if (!errorShow) {
        toast.error("Something went wrong");
      }
      console.error("Error in uploadPDFService:", error);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};