import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ProfileIcon from "../../assets/images/profile-icon.jpg";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogoutClick } from "../Alertmessage/Alertmessage";
import Notification from "../Notification/Notification";
import { store } from "../../redux/Store";
import { FaShoppingCart } from "react-icons/fa";
import { getAllBranchService } from "../../pages/Branches/BranchService/BranchService";
import UtilsData from "../../Utils/Utils";
import { Formik, Form, Field } from "formik";
import CustomSelect from "../CustomSelect/CustomSelect";
import { GetBranchTokenService } from "./BranchTokenService/BranchTokenService";
import { getAllOrderService } from "../../pages/Order/OrderService/OrderService";
import { orderDate } from "../../services/Regular-services/DateService";
import { clearOrderFilterData } from "../../redux/Actions/orderAction/OrderFilterAction";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const sidebar = useSelector(state => state.sidebar?.items);
  const order = useSelector((state) => state.order);
  const totalBranch = useSelector((state) => state.totalBranch);
  const [branchOptions, setBranchOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    branch: "",
  });
  const token = UtilsData();
  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    if (user && user && user?.data) {
      setInitialValues({
        branch: user.data?.branchId ? user.data?.branchId : "",
      });
    }
  }, [user]);

  const fetchOrder = (tokenData) => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_created_date: orderDate(),
    };
    dispatch(clearOrderFilterData());
    getAllOrderService(requestBody, tokenData, dispatch).finally(() =>
      window.location.reload()
    );
  };

  /**
   * Dispatching logout action
   */
  const onLogout = () => {
    handleLogoutClick(dispatch, navigate, store);
  };

  const fetchBranch = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
      p_is_active: "1",
    };
    getAllBranchService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (totalBranch && totalBranch.items && totalBranch.items.length === 0) {
      fetchBranch();
    } else {
      if (totalBranch.items[0]?.data && totalBranch.items[0].data.length > 0) {
        const newBranch = totalBranch.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.branchId,
        }));
        setBranchOptions(newBranch);
      }
    }
    // eslint-disable-next-line
  }, [totalBranch]);

  const onChangeBranch = async (value) => {
    if (user && user?.data && user.data?.phoneNumber) {
      if (user.data?.branchId && value === user.data.branchId) {
        return;
      }
      const requestBody = {
        p_branch_id: value,
        phone_number: user.data.phoneNumber,
      };
      await GetBranchTokenService(requestBody, token, dispatch).then((res) => {
        if (
          res &&
          (res.status === 200 ||
            res.status === "200" ||
            res.status === "success")
        ) {
          fetchOrder(res.token);
        }
      });
    }
  };

  return (
    <>
      <div className="header-dropdown">
        <div>
          <Formik initialValues={initialValues} enableReinitialize={true}>
            {({ values }) => (
              <Form>
                <Field
                  className="basic-single"
                  name="branch"
                  options={branchOptions}
                  component={CustomSelect}
                  placeholder="Select branch"
                  value={values.branch}
                  changeData={onChangeBranch}
                />
              </Form>
            )}
          </Formik>

          {/* <h1 className="logo">
            <img src={Logo} alt='Logo' />
          </h1> */}
        </div>
        {/*Notification Data show*/}
        <div className="d-flex align-items-center">
          <div className="shopping-icon">
            <FaShoppingCart onClick={() => navigate("/orders")} />
            {order?.dataCount > 0 && (
              <div className="shopping-count">
                <span>{order.dataCount}</span>
              </div>
            )}
          </div>
          <Notification />
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="profile">
                <img src={ProfileIcon} alt="" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item onClick={() => navigate("/profile")}>
                My Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/settings")}>
                Settings
              </Dropdown.Item> */}
              <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}