/**
 * Set Purchase Requisition page data
 * @param {*} page
 * @returns {*}
 */
export const getPurchaseRequisitionPageData = (page) => ({
  type: "GET_PURCHASE_REQUISITION_PAGE",
  payload: page,
});

/**
 * Clear Purchase Requisition page data
 * @returns {*}
 */
export const clearPurchaseRequisitionPageData = () => ({
  type: "CLEAR_PURCHASE_REQUISITION_PAGE",
});
