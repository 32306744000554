import { combineReducers } from "redux";
import AuthReducer from "./Reducers/authReducer/AuthReducer";
import { PolicyReducer } from "./Reducers/policyReducer/PolicyReducer";
import {
  BrandReducer,
  TotalBrandReducer,
} from "./Reducers/brandReducer/BrandReducer";
import {
  CategoryReducer,
  TotalCategoryReducer,
} from "./Reducers/categoryReducer/CategoryReducer";
import {
  SubCategoryReducer,
  TotalSubCategoryReducer,
} from "./Reducers/subCategoryReducer/SubCategoryReducer";
import {
  ImageGalleryReducer,
  TotalImageReducer,
} from "./Reducers/imageGalleryReducer/ImageGallaryReducer";
import {
  LeafCategoryReducer,
  TotalLeafReducer,
} from "./Reducers/leafLevelCategoryReducer/LeafLevelCategoryReducer";
import {
  ProductReducer,
  TotalProductReducer,
} from "./Reducers/productReducer/ProductReducer";
import { BlogReducer } from "./Reducers/blogReducer/BlogReducer";
import { FaqReducer } from "./Reducers/faqReducer/FaqReducer";
import {
  RolesReducer,
  TotalRolesReducer,
} from "./Reducers/rolesReducer/RolesReducer";
import { uploadImageReducer } from "./Reducers/uploadImageReducer/UploadImageReducer";
import { TotalTagReducer, tagReducer } from "./Reducers/tagReducer/TagReducer";
import { LoaderReducer } from "./Reducers/LoaderReducer";
import {
  AdminUserReducer,
  UserTypeReducer,
} from "./Reducers/adminReducer/AdminUserReducer";
import { RolePageReducer } from "./Reducers/rolesReducer/RolesPageReducer";
import { AdminPageReducer } from "./Reducers/adminReducer/AdminPageReducer";
import { RoleFilterReducer } from "./Reducers/rolesReducer/RolesFilterReducer";
import { adminFilterReducer } from "./Reducers/adminReducer/AdminFilterReducer";
import { SidebarReducer } from "./Reducers/SidebarReducer/SidebarReducer";
import { categoryFilterReducer } from "./Reducers/categoryReducer/CategoryFilterReducer";
import { CategoryPageReducer } from "./Reducers/categoryReducer/CategoryPageReducer";
import { SubCategoryPageReducer } from "./Reducers/subCategoryReducer/SubCategoryPageReducer";
import { subCategoryFilterReducer } from "./Reducers/subCategoryReducer/SubCategoryFilterReducer";
import { CheckboxReducer } from "./Reducers/checkboxReducer/CheckboxReducer";
import { leafFilterReducer } from "./Reducers/leafLevelCategoryReducer/LeafFilterReducer";
import { LeafPageReducer } from "./Reducers/leafLevelCategoryReducer/LeafPageReducer";
import { TagPageReducer } from "./Reducers/tagReducer/TagPageReducer";
import { tagFilterReducer } from "./Reducers/tagReducer/TagFilterReducer";
import { imagePageReducer } from "./Reducers/imageGalleryReducer/ImageGalleryPageReducer";
import { imageFilterReducer } from "./Reducers/imageGalleryReducer/ImageFilterReducer";
import { brandFilterReducer } from "./Reducers/brandReducer/BrandFilterReducer";
import { brandPageReducer } from "./Reducers/brandReducer/BrandPageReducer";
import {
  TotalAttributeReducer,
  attributeReducer,
} from "./Reducers/attributeReducer/AttributeReducer";
import {
  TotalPackagerReducer,
  packagerReducer,
} from "./Reducers/packagerReducer/PackagerReducer";
import {
  TotalWarehouseReducer,
  warehouseReducer,
} from "./Reducers/warehouseReducer/WarehouseReducer";
import { productPageReducer } from "./Reducers/productReducer/ProductPageReducer";
import { productFilterReducer } from "./Reducers/productReducer/ProductFilterReducer";
import { attributeFilterReducer } from "./Reducers/attributeReducer/AttributeFilterReducer";
import { attributePageReducer } from "./Reducers/attributeReducer/AttributePageReducer";
import { warehouseFilterReducer } from "./Reducers/warehouseReducer/WarehouseFilterReducer";
import { warehousePageReducer } from "./Reducers/warehouseReducer/WarehousePageReducer";
import { packagerPageReducer } from "./Reducers/packagerReducer/PackagerPageReducer";
import { packagerFilterReducer } from "./Reducers/packagerReducer/PackagerFilterReducer";
import {
  DiscountReducer,
  TotalDiscountReducer,
} from "./Reducers/discountReducer/DiscountReducer";
import { discountFilterReducer } from "./Reducers/discountReducer/DiscountFilterReducer";
import { DiscountBrandReducer } from "./Reducers/discountReducer/DiscountBrandReducer";
import { DiscountPageReducer } from "./Reducers/discountReducer/DiscountPageReducer";
import {
  DiscountCatalogueReducer,
  TotalDiscountCatalogueReducer,
} from "./Reducers/discountCatalogueReducer/DiscountCatalogueReducer";
import { DiscountCataloguePageReducer } from "./Reducers/discountCatalogueReducer/DiscountCataloguePageReducer";
import { discountCatalogueFilterReducer } from "./Reducers/discountCatalogueReducer/DiscountCatalogueFilterReducer";
import {
  InventoryReducer,
  TotalInventoryReducer,
} from "./Reducers/inventoryReducer/InventoryReducer";
import { InventoryPageReducer } from "./Reducers/inventoryReducer/InventoryPageReducer";
import { inventoryFilterReducer } from "./Reducers/inventoryReducer/InventoryFilterReducer";
import {
  BranchReducer,
  TotalBranchReducer,
} from "./Reducers/branchReducer/BranchReducer";
import { BranchFilterReducer } from "./Reducers/branchReducer/BranchFilterReducer";
import { branchPageReducer } from "./Reducers/branchReducer/BranchPageReducer";
import {
  ServiceProviderReducer,
  TotalServiceProviderReducer,
} from "./Reducers/serviceProviderReducer/ServiceProviderReducer";
import { ServiceProviderFilterReducer } from "./Reducers/serviceProviderReducer/ServiceProviderFilterReducer";
import { ServiceProviderPageReducer } from "./Reducers/serviceProviderReducer/ServiceProviderPageReducer";
import {
  ServiceReducer,
  TotalServiceReducer,
} from "./Reducers/serviceReducer/ServiceReducer";
import { ServicePageReducer } from "./Reducers/serviceReducer/ServicePageReducer";
import { ServiceFilterReducer } from "./Reducers/serviceReducer/ServiceFilterReducer";
import { notificationReducer } from "./Reducers/NotificationReducer/NotificationReducer";
import { NotificationLoaderReducer } from "./Reducers/NotificationReducer/NotificationLoaderReducer";
import {
  OrderReducer,
  TotalOrderReducer,
} from "./Reducers/orderReducer/OrderReducer";
import { ClearLocalOrderFilterReducer, OrderFilterReducer } from "./Reducers/orderReducer/OrderFilterReducer";
import { OrderPageReducer } from "./Reducers/orderReducer/OrderPageReducer";
import { sidebarReducer } from "./Reducers/OpenSideBarReducer/OpenSideBarReducer";
import {
  CustomerReducer,
  TotalCustomerReducer,
} from "./Reducers/CustomerReducer/CustomerReducer";
import { CustomerFilterReducer } from "./Reducers/CustomerReducer/CustomerFilterReducer";
import { CustomerPageReducer } from "./Reducers/CustomerReducer/CustomerPageReducer";
import {
  CompanyReducer,
  TotalCompanyReducer,
} from "./Reducers/companyReducer/companyReducer";
import { CompanyFilterReducer } from "./Reducers/companyReducer/CompanyFilterReducer";
import { CompanyPageReducer } from "./Reducers/companyReducer/CompanyPageReducer";
import { notificationSoundReducer } from "./Reducers/NotificationReducer/NotificationSoundReducer";
import {
  spotReducer,
  TotalSpotReducer,
} from "./Reducers/spotReducer/SpotReducer";
import { SpotPageReducer } from "./Reducers/spotReducer/SpotPageReducer";
import { spotFilterReducer } from "./Reducers/spotReducer/SpotFilterReducer";
import {
  industryReducer,
  TotalIndustryReducer,
} from "./Reducers/IndustryReducer/IndustryReducer";
import { IndustryPageReducer } from "./Reducers/IndustryReducer/IndustryPageReducer";
import { InventoryItemPageReducer } from "./Reducers/InventoryItemReducer/InventoryItemPageReducer";
import {
  InventoryItemReducer,
  TotalInventoryItemReducer,
} from "./Reducers/InventoryItemReducer/InventoryItemReducer";
import { inventoryItemFilterReducer } from "./Reducers/InventoryItemReducer/InventoryItemFilterReducer";
import {
  ProductInventoryReducer,
  TotalProductInventoryReducer,
} from "./Reducers/ProductInventoryReducer/ProductInventoryReducer";
import { ProductInventoryPageReducer } from "./Reducers/ProductInventoryReducer/ProductInventoryPageReducer";
import { ProductInventoryFilterReducer } from "./Reducers/ProductInventoryReducer/ProductInventoryFilterReducer";
import {
  TotalVendorReducer,
  VendorReducer,
} from "./Reducers/VendorReducer/VendorReducer";
import { VendorPageReducer } from "./Reducers/VendorReducer/VendorPageReducer";
import { VendorFilterReducer } from "./Reducers/VendorReducer/VendorFilterReducer";
import {
  StockReducer,
  TotalStockReducer,
} from "./Reducers/StocksReducer/StocksReducer";
import { StockPageReducer } from "./Reducers/StocksReducer/StocksPageReducer";
import { StockFilterReducer } from "./Reducers/StocksReducer/StocksFilterReducer";
import {
  StockHistoryReducer,
  TotalStockHistoryReducer,
} from "./Reducers/StocksHistory.js/StockHistoryReducer";
import { StockHistoryFilterReducer } from "./Reducers/StocksHistory.js/StockHistoryFilterReducer";
import { StockHistoryPageReducer } from "./Reducers/StocksHistory.js/StockHistoryPageReducer";
import {
  NewVendorReducer,
  TotalNewVendorReducer,
} from "./Reducers/NewVendorReducer/NewVendorReducer";
import { NewVendorPageReducer } from "./Reducers/NewVendorReducer/NewVendorPageReducer";
import { newVendorFilterReducer } from "./Reducers/NewVendorReducer/NewVendorFilterReducer";
import {
  TotalUnitsReducer,
  UnitsReducer,
} from "./Reducers/UnitsReducer/UnitsReducer";
import { UnitsPageReducer } from "./Reducers/UnitsReducer/UnitsPageReducer";
import { unitsFilterReducer } from "./Reducers/UnitsReducer/UnitsFilterReducer";
import {
  TotalUnitsDropdownReducer,
  UnitsDropdownReducer,
} from "./Reducers/UnitsReducer/UnitsDropdownReducer";
import {
  DashboardOrderReducer,
  TotalDashboardOrderReducer,
} from "./Reducers/orderReducer/OrderDashboardReducer";
import { DashboardOrderFilterReducer } from "./Reducers/orderReducer/OrderDashboardFilterReducder";
import {
  PurchaseRequisitionReducer,
  TotalPurchaseRequisitionReducer,
} from "./Reducers/PurchaseRequisitionReducer/PurchaseRequisitionReducer";
import { PurchaseRequisitionPageReducer } from "./Reducers/PurchaseRequisitionReducer/PurchaseRequisitionPageReducer";
import { PurchaseRequisitionFilterReducer } from "./Reducers/PurchaseRequisitionReducer/PurchaseRequisitionFilterReducer";
import {
  AdminPRUserReducer,
  TotalAdminPRReducer,
} from "./Reducers/adminReducer/AdminPRUserReducer";
import {
  PurchaseOrderReducer,
  TotalPurchaseOrderReducer,
} from "./Reducers/PurchaseOrderReducer/PurchaseOrderReducer";
import { PurchaseOrderFilterReducer } from "./Reducers/PurchaseOrderReducer/PurchaseOrderFilterReducer";
import { PurchaseOrderPageReducer } from "./Reducers/PurchaseOrderReducer/PurchaseOrderPageReducer";
import {
  GRNReducer,
  TotalGRNReducer,
} from "./Reducers/GoodReceiveNoteReducer/GRNReducer";
import { GRNPageReducer } from "./Reducers/GoodReceiveNoteReducer/GRNPageReducer";
import { GRNFilterReducer } from "./Reducers/GoodReceiveNoteReducer/GRNFilterReducer";
import {
  TotalWalletReducer,
  WalletReducer,
} from "./Reducers/WalletReducer/WalletReducer";
import { WalletPageReducer } from "./Reducers/WalletReducer/WalletPageReducer";
import { WalletFilterReducer } from "./Reducers/WalletReducer/WalletFIilterReducer";
import {
  ProductPricingReducer,
  TotalProductPricingReducer,
} from "./Reducers/ProductPricingReducer/ProductPricingReducer";
import { productPricingFilterReducer } from "./Reducers/ProductPricingReducer/ProductPricingFilterReducer";
import { productPricingPageReducer } from "./Reducers/ProductPricingReducer/ProductPricingPageReducer";
import { WalletTotalReducer } from "./Reducers/WalletReducer/WalletTotalAmtReducer";
import { PaymentDetailReducer } from "./Reducers/PaymentDetailReducer/PaymentDetailReducer";
import { AccountDetailReducer } from "./Reducers/PaymentDetailReducer/AccountDetailsReducer";
import { StackHolderDetailReducer } from "./Reducers/PaymentDetailReducer/StakeHolderDetailReducer";
import { AccountProductConfigReducer } from "./Reducers/PaymentDetailReducer/AccountProductConfigReducer";
import { VerifyAccountReducer } from "./Reducers/PaymentDetailReducer/VerifyAccountReducer";
import { CustomerOrdersReducer, TotalCustomerOrdersReducer } from "./Reducers/CustomerReducer/CustomerOrderReducer";
import { CustomerOrdersPageReducer } from "./Reducers/CustomerReducer/CustomerOrderPageReducer";

const rootReducer = combineReducers({
  auth: AuthReducer, //Login reducer

  policy: PolicyReducer, //Policy Reducer

  brand: BrandReducer, //Brand Reducer
  totalBrand: TotalBrandReducer, //Total brand reducer
  brandPage: brandPageReducer,
  brandFilter: brandFilterReducer,

  category: CategoryReducer, //Category Reducer
  categoryFilter: categoryFilterReducer, //Category Filter Reducer
  categoryPage: CategoryPageReducer, //Category Page Reducer
  totalCategory: TotalCategoryReducer, //Total category reducer

  subcategory: SubCategoryReducer, //Subcategory Reducer
  totalSubCategory: TotalSubCategoryReducer, //Total sub category reducer
  SubCategoryPage: SubCategoryPageReducer, //Sub category page reducer
  SubCategoryFilter: subCategoryFilterReducer, //Sub category filter reducer

  imageGallery: ImageGalleryReducer, //Image gallery Reducer
  imagePage: imagePageReducer, //Image gallery page reducer
  imageFilter: imageFilterReducer, //Image filter reducer
  totalImage: TotalImageReducer, //Total image reducer

  leafCategory: LeafCategoryReducer, //Leaf level category reducer
  leafFilter: leafFilterReducer, //Leaf level category filter reducer
  leafPage: LeafPageReducer, //Leaf level category page reducer
  totalLeaf: TotalLeafReducer, //Total leaf level category data reducer

  product: ProductReducer, //Product reducer
  productPage: productPageReducer, //Product page reducer
  productFilter: productFilterReducer, //Product filter reducer
  totalProduct: TotalProductReducer, //total product reducer

  productPricing: ProductPricingReducer,
  totalProductPricing: TotalProductPricingReducer,
  productPricingFilter: productPricingFilterReducer,
  productpricingPage: productPricingPageReducer,

  blog: BlogReducer, //Blog reducer

  faq: FaqReducer, //Faq reducer

  checkbox: CheckboxReducer,

  roles: RolesReducer, //Roles reducer
  rolePage: RolePageReducer, //Role page reducer
  roleFilter: RoleFilterReducer, //Role filter reducer
  totalRoles: TotalRolesReducer, //Totalroles reducer

  uploadImage: uploadImageReducer, //Image upload reducer

  tag: tagReducer, //Tag reducer
  tagPage: TagPageReducer, //Tag page reducer
  tagFilter: tagFilterReducer, //Tag filter reducer
  totalTag: TotalTagReducer, //Total tag reducer

  spot: spotReducer, // Spot reducer
  spotPage: SpotPageReducer, // Spot page reducer
  spotFilter: spotFilterReducer, // Spot filter reducer
  totalSpot: TotalSpotReducer, // Total spot reducer

  industry: industryReducer,
  industryPage: IndustryPageReducer,
  totalIndustry: TotalIndustryReducer,

  loader: LoaderReducer, //Loader reducer

  notificationLoader: NotificationLoaderReducer,

  adminUser: AdminUserReducer, //Admin user reducer
  adminPage: AdminPageReducer, //Admin user page reducer
  adminFilter: adminFilterReducer, //Admin user filter redicer
  sideBar: SidebarReducer, //Sidebar reducer

  adminPR: AdminPRUserReducer,
  totalAdminPR: TotalAdminPRReducer,

  userType: UserTypeReducer, //User type reducer

  attribute: attributeReducer, //Attribute reducer
  totalAttribute: TotalAttributeReducer, //Total attribute reducer
  attributeFilter: attributeFilterReducer, //Attribute filter reducer
  attributePage: attributePageReducer, //Attribute page reducer

  packager: packagerReducer, //Packager reducer
  totalPackager: TotalPackagerReducer, //total packager reducer
  packagerPage: packagerPageReducer, //packager page reducer
  packagerFilter: packagerFilterReducer, //packager filter reducer

  warehouse: warehouseReducer, //warehouse reducer
  totalWarehouse: TotalWarehouseReducer, //total warehouse reducer
  warehouseFilter: warehouseFilterReducer, //warehouse filter reducer
  warehousePage: warehousePageReducer, //warehouse page reducer

  discount: DiscountReducer, //discount reducer
  totalDiscount: TotalDiscountReducer, //total discount reducer
  discountFilter: discountFilterReducer, //discount filter reducer
  discountPage: DiscountPageReducer, //discount page reducer
  discountBrand: DiscountBrandReducer, //discount brand reducer

  discountCatalogue: DiscountCatalogueReducer, //discount catalogue reducer
  totalDiscountCatalogue: TotalDiscountCatalogueReducer, //total discount catalogue reducer
  discountCataloguePage: DiscountCataloguePageReducer, //discount catalogue page reducer
  discountCatalogueFilter: discountCatalogueFilterReducer, //discount catalogue filter reducer

  inventory: InventoryReducer, //inventory reducer
  totalInventory: TotalInventoryReducer, //total inventory reducer
  inventoryPage: InventoryPageReducer, //inventory page reducer
  inventoryFilter: inventoryFilterReducer, //inventory filter reducer

  branch: BranchReducer, //branch reducer
  totalBranch: TotalBranchReducer, //total branch reducer
  branchFilter: BranchFilterReducer, //branch filter reducer
  branchPage: branchPageReducer, //branch page reducer

  serviceProvider: ServiceProviderReducer, //service provider reducer
  totalServiceProvider: TotalServiceProviderReducer, //total service provider reducer
  ServiceProviderFilter: ServiceProviderFilterReducer, //service provider filter reducer
  ServiceProviderPage: ServiceProviderPageReducer, //service provider page reducer

  service: ServiceReducer, //service reducer
  totalService: TotalServiceReducer, //total service reducer
  servicePage: ServicePageReducer, //service page reducer
  serviceFilter: ServiceFilterReducer, //service filter reducer

  notification: notificationReducer, //Notification reducer
  notificationSound: notificationSoundReducer, //Sound

  order: OrderReducer,
  totalOrder: TotalOrderReducer,
  OrderFilter: OrderFilterReducer,
  orderPage: OrderPageReducer,

  dashboardOrder: DashboardOrderReducer,
  totalDashboardOrder: TotalDashboardOrderReducer,
  DashboardOrderFilter: DashboardOrderFilterReducer,

  sidebar: sidebarReducer,

  customer: CustomerReducer,
  customerFilter: CustomerFilterReducer,
  customerPage: CustomerPageReducer,
  totalCustomer: TotalCustomerReducer,

  customerOrders: CustomerOrdersReducer,
  totalCustomerOrders: TotalCustomerOrdersReducer,
  customerOrdersPage: CustomerOrdersPageReducer,

  company: CompanyReducer,
  totalCompany: TotalCompanyReducer,
  companyFilter: CompanyFilterReducer,
  companyPage: CompanyPageReducer,

  inventoryItem: InventoryItemReducer,
  totalInventoryItem: TotalInventoryItemReducer,
  inventoryItemFilter: inventoryItemFilterReducer,
  inventoryItemPage: InventoryItemPageReducer,

  productInventory: ProductInventoryReducer,
  totalProductInventory: TotalProductInventoryReducer,
  productInventoryFilter: ProductInventoryFilterReducer,
  productInventoryPage: ProductInventoryPageReducer,

  vendor: VendorReducer,
  totalVendor: TotalVendorReducer,
  vendorPage: VendorPageReducer,
  vendorFilter: VendorFilterReducer,

  newVendor: NewVendorReducer,
  totalNewVendor: TotalNewVendorReducer,
  newVendorPage: NewVendorPageReducer,
  newVendorFilter: newVendorFilterReducer,

  purchaseRequisition: PurchaseRequisitionReducer,
  totalPurchaseRequisition: TotalPurchaseRequisitionReducer,
  purchaseRequisitionPage: PurchaseRequisitionPageReducer,
  purchaseRequisitionFilter: PurchaseRequisitionFilterReducer,

  purchaseOrder: PurchaseOrderReducer,
  totalPurchaseOrder: TotalPurchaseOrderReducer,
  purchaseOrderFilter: PurchaseOrderFilterReducer,
  purchaseOrderPage: PurchaseOrderPageReducer,

  GRN: GRNReducer,
  totalGRN: TotalGRNReducer,
  GRNpage: GRNPageReducer,
  GRNFilter: GRNFilterReducer,

  stock: StockReducer,
  totalStock: TotalStockReducer,
  stockPage: StockPageReducer,
  stockFilter: StockFilterReducer,

  stockHistory: StockHistoryReducer,
  totalHistoryStock: TotalStockHistoryReducer,
  stockHistoryPage: StockHistoryPageReducer,
  stockHistoryFilter: StockHistoryFilterReducer,

  wallet: WalletReducer,
  totalWallet: TotalWalletReducer,
  walletPage: WalletPageReducer,
  walletFilter: WalletFilterReducer,
  walletTotalAmt: WalletTotalReducer,

  paymentDetail: PaymentDetailReducer,
  accountDetail: AccountDetailReducer,
  stakeHolderDetail: StackHolderDetailReducer,
  accountProductConfig: AccountProductConfigReducer,
  verifyAccount: VerifyAccountReducer,

  units: UnitsReducer,
  totalUnits: TotalUnitsReducer,
  unitsPage: UnitsPageReducer,
  unitsFilter: unitsFilterReducer,

  unitsDropdown: UnitsDropdownReducer,
  totalUnitsDropdown: TotalUnitsDropdownReducer,

  clearLocalOrderFilter: ClearLocalOrderFilterReducer
});

export default rootReducer;
