import { Dispatch } from "redux";
import {
  getAllDashboardOrderRequest,
  getAllOrderRequest,
  getUserDetailResponse,
  placeOrderRequest,
  placeProductRequest,
  UploadPDFRequest,
} from "./OrderModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  addOrderData,
  clearOrderData,
  clearTotalOrderData,
  getOrderData,
  getTotalOrderData,
} from "../../../redux/Actions/orderAction/OrderAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearOrderPageData,
  getOrderPageData,
} from "../../../redux/Actions/orderAction/OrderPAgeAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { formatDateToUTC } from "../../../services/Regular-services/DateService";
import { format } from "date-fns";
import {
  clearOrderFilterData,
  setClearLocalOrderFilter,
} from "../../../redux/Actions/orderAction/OrderFilterAction";
import {
  clearStockData,
  clearTotalStockData,
} from "../../../redux/Actions/StocksAction/StockAction";
import { clearTotalStockHistoryData } from "../../../redux/Actions/StockHistoryAction/StockHistoryAction";
import { postApiServiceWp } from "../../../services/API-services/PostServiceWp";
import {
  addDashboardOrderData,
  clearDashboardOrderData,
  clearTotalDashboardOrderData,
  getDashboardOrderData,
} from "../../../redux/Actions/orderAction/OrderDashboardAction";

let errorShow = false;
/*
 * Service to get Packager and Manufacturer list
 */
export const getAllOrderService = (
  orderListRequest: getAllOrderRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        orderListRequest.p_skip !== undefined &&
        orderListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${orderListRequest.p_skip}&`;
      }
      if (
        orderListRequest.p_take !== undefined &&
        orderListRequest.p_take !== null
      ) {
        queryParams += `p_take=${orderListRequest.p_take}&`;
      }
      if (
        orderListRequest.p_order_no !== undefined &&
        orderListRequest.p_order_no !== null
      ) {
        queryParams += `p_order_no=${orderListRequest.p_order_no}&`;
      }
      if (
        orderListRequest.p_created_date !== undefined &&
        orderListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${orderListRequest.p_created_date}&`;
      }
      if (
        orderListRequest.p_created_time !== undefined &&
        orderListRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${orderListRequest.p_created_time}&`;
      }
      if (
        orderListRequest.p_status !== undefined &&
        orderListRequest.p_status !== null
      ) {
        queryParams += `p_status=${orderListRequest.p_status}&`;
      }
      if (
        orderListRequest.p_type !== undefined &&
        orderListRequest.p_type !== null
      ) {
        queryParams += `p_type=${orderListRequest.p_type}&`;
      }
      if (
        orderListRequest.p_time_zone !== undefined &&
        orderListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${orderListRequest.p_time_zone}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.ORDER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );

      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalOrderData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(
            getOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: null,
            })
          );
        } else {
          if (response.data && response.data.length > 0) {
            const pendingCount = response.data.filter(
              (item: any) => item.status === "0"
            ).length;
            dispatch(
              getOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: pendingCount,
              })
            );
          } else {
            dispatch(
              getOrderData({
                order: response.data,
                time: formatDateToUTC(new Date()),
                totalRecords: response.total_records,
                api: true,
                dataCount: 0,
              })
            );
          }
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

export const getAllTimeStampOrderService = (
  orderListRequest: getAllOrderRequest,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        orderListRequest.p_skip !== undefined &&
        orderListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${orderListRequest.p_skip}&`;
      }
      if (
        orderListRequest.p_take !== undefined &&
        orderListRequest.p_take !== null
      ) {
        queryParams += `p_take=${orderListRequest.p_take}&`;
      }
      if (
        orderListRequest.p_order_no !== undefined &&
        orderListRequest.p_order_no !== null
      ) {
        queryParams += `p_order_no=${orderListRequest.p_order_no}&`;
      }
      if (
        orderListRequest.p_created_date !== undefined &&
        orderListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${orderListRequest.p_created_date}&`;
      }
      if (
        orderListRequest.p_created_time !== undefined &&
        orderListRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${orderListRequest.p_created_time}&`;
      }
      if (
        orderListRequest.p_time_zone !== undefined &&
        orderListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${orderListRequest.p_time_zone}&`;
      }

      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.ORDER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (response.data && response.data.length > 0) {
          const pendingCount = response.data.filter(
            (item: any) => item.status === "0"
          ).length;
          dispatch(
            addOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: pendingCount,
            })
          );
        } else {
          dispatch(
            addOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: 0,
            })
          );
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
    }
  });
};

/*
 * Service to get individual order
 */
export const getOrderById = (
  id: string,
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      const response = await getApiService(
        API_URL.BASE_URL + API_URL.ORDER + "/" + id,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating order
 */
export const placeOrderService = (
  placeOrderRequest: placeOrderRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number,
  payment?: boolean,
  printInvoice?: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.PLACE_ORDER,
          token,
          placeOrderRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.PLACE_ORDER,
          token,
          placeOrderRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearDashboardOrderData());
        dispatch(clearTotalDashboardOrderData());
        dispatch(clearTotalOrderData());
        dispatch(clearStockData());
        dispatch(clearTotalStockData());
        dispatch(clearTotalStockHistoryData());
        if (type === "add") {
          dispatch(clearOrderData());
          dispatch(clearOrderPageData());
          dispatch(clearOrderFilterData());
          toast.success("Order added successfully!");
          navigate("/orders");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            dispatch(clearOrderData());
            toast.success("Order undeleted successfully!");
          } else {
            if (navigate) {
              dispatch(clearOrderData());
              if (pageNumber) {
                dispatch(getOrderPageData(pageNumber + 1));
              } else {
                dispatch(clearOrderPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Order updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/orders");

                if (printInvoice) {
                  printInvoice(placeOrderRequest);
                }
              });
            } else {
              if (payment) {
                if (pageNumber) {
                  dispatch(getOrderPageData(pageNumber + 1));
                  dispatch(clearOrderData());
                } else {
                  dispatch(clearOrderPageData());
                  dispatch(clearOrderData());
                }
                toast.success("Payment status updated successfully!");
              } else {
                if (pageNumber) {
                  dispatch(getOrderPageData(pageNumber + 1));
                  dispatch(clearOrderData());
                } else {
                  dispatch(clearOrderPageData());
                  dispatch(clearOrderData());
                }
                toast.success("Order status updated successfully!");
              }
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
        if (pageNumber) {
          dispatch(getOrderPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

// changes by sakshi
export const LineLevelPlaceOrderService = (
  placeProductRequest: placeProductRequest,
  token: string,
  pageNumber?: number,
  updatedOrder?: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await postApiService(
        API_URL.BASE_URL + API_URL.ORDER + "/" + API_URL.PRODUCT,
        token,
        placeProductRequest
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearDashboardOrderData());
        dispatch(clearTotalDashboardOrderData());
        toast.success("Product delivered successfully!");

        if (pageNumber) {
          dispatch(getOrderPageData(pageNumber + 1));
        } else {
          dispatch(clearOrderPageData());
        }
        dispatch(clearOrderData());
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
    } catch (error) {
      if (errorShow === false) {
        if (!document.querySelector(toastClass)) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

export const viewOrdersService = (
  token: string,
  dispatch: Dispatch
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = `?p_order_status=0&p_created_at=${format(
        new Date(),
        "yyyy-MM-dd"
      )}`;
      // Adding query parameters if they are provided
      const response = await getApiService(
        API_URL.BASE_URL + API_URL.ORDER + API_URL.PRODUCT_COUNT + queryParams,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for deleting sub category
 */
export const deleteOrderService = (
  deleteId: number,
  token: string,
  pageNumber: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(
        API_URL.BASE_URL + API_URL.ORDER + "/" + deleteId,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearOrderData());
        dispatch(clearTotalOrderData());
        toast.success("Order deleted successfully!");
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
        dispatch(getOrderPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/**
 * Service for get user detail API Integration
 */

export const getUserDetailService = (
  mobile: number,
  dispatch: Dispatch
): Promise<getUserDetailResponse> => {
  return new Promise<getUserDetailResponse>(async (resolve, reject) => {
    try {
      // dispatch(setLoader())
      const response = await getApiService(
        API_URL.BASE_URL + API_URL.USER_DETAIL + "?p_mobile_no=" + mobile
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        resolve(response);
      } else {
        // if (!document.querySelector(toastClass)) {
        //   toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'User permission does not exists.' : response.message) : ValidationMessages.SOMETHING_WENT_WRONG);
        // }
        errorShow = true;
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        // toast.error(ValidationMessages.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      // dispatch(clearLoader());
    }
  });
};

/**
 * Service for get user detail API Integration
 */

export const deleteLineLevelProduct = (
  requestBody: any,
  dispatch: Dispatch,
  token: string
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      const response = await postApiService(
        API_URL.BASE_URL + API_URL.LINE_LEVEL_DELETE,
        token,
        requestBody
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success("Product removed successfully!");
        dispatch(clearOrderData());
        // dispatch(clearDashboardOrderData());
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        if (!document.querySelector(toastClass)) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for file upload for whatsapp
 */
export const uploadOrderPDFService = (
  uploadPDFRequest: UploadPDFRequest,
  token: string,
  navigate?: any
) => {
  return async (dispatch: Dispatch) => {
    let errorShow = false;

    try {
      dispatch(setLoader());

      // Prepare FormData
      const formData = new FormData();
      formData.append("pdf", uploadPDFRequest.pdf);
      formData.append("p_order_user", uploadPDFRequest.p_order_user);
      formData.append(
        "p_order_phone_number",
        uploadPDFRequest.p_order_phone_number
      );
      formData.append("p_total_amt", uploadPDFRequest.p_total_amt);
      formData.append("p_company_name", uploadPDFRequest.p_company_name);
      // return
      const response = await postApiServiceWp(
        API_URL.BASE_URL + API_URL.ORDER + API_URL.WHATSAPP,
        token,
        formData
      );

      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        toast.success(`${response.message}`);

        if (navigate) {
          Swal.fire({
            title: "Success!",
            text: "Message sent to vendor successfully!",
            icon: "success",
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            navigate("/orders");
          });
        }
      } else {
        toast.error(
          response?.message ? response.message : "Something went wrong"
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          // Handle unauthorized access, e.g., logout or remove persist storage
        }
      }
    } catch (error) {
      if (!errorShow) {
        toast.error("Something went wrong");
      }
      console.error("Error in uploadPDFService:", error);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

export const getAllDashboardOrderTimeStampService = (
  orderListRequest: getAllDashboardOrderRequest,
  token: string,
  dispatch: Dispatch,
  orderFilter?: any
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        orderListRequest.p_skip !== undefined &&
        orderListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${orderListRequest.p_skip}&`;
      }
      if (
        orderListRequest.p_take !== undefined &&
        orderListRequest.p_take !== null
      ) {
        queryParams += `p_take=${orderListRequest.p_take}&`;
      }
      if (
        orderListRequest.p_order_no !== undefined &&
        orderListRequest.p_order_no !== null
      ) {
        queryParams += `p_order_no=${orderListRequest.p_order_no}&`;
      }
      if (
        orderListRequest.p_created_date !== undefined &&
        orderListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${orderListRequest.p_created_date}&`;
      }
      if (
        orderListRequest.p_created_time !== undefined &&
        orderListRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${orderListRequest.p_created_time}&`;
      }
      if (
        orderListRequest.p_time_zone !== undefined &&
        orderListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${orderListRequest.p_time_zone}&`;
      }

      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.ORDER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (response.data && response.data.length > 0) {
          const pendingCount = response.data.filter(
            (item: any) => item.status === "0"
          ).length;
          dispatch(
            addDashboardOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: pendingCount,
            })
          );
          const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
          dispatch(setClearLocalOrderFilter(true));
          if (
            orderFilter?.orderDate !== currentDateFormatted ||
            (orderFilter?.status && orderFilter?.status !== "") ||
            (orderFilter?.orderType && orderFilter?.orderType !== "")
          ) {
            toast.warn("Filter removed to display the newly added order");
            dispatch(clearOrderFilterData());
            dispatch(clearOrderData());
          }
        } else {
          dispatch(
            addDashboardOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: 0,
            })
          );
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
    }
  });
};

/*
 * Service to get Packager and Manufacturer list
 */
export const getAllDashboardOrderService = (
  orderListRequest: getAllDashboardOrderRequest,
  token: string,
  dispatch: Dispatch,
  orderFilter?: any
): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        orderListRequest.p_skip !== undefined &&
        orderListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${orderListRequest.p_skip}&`;
      }
      if (
        orderListRequest.p_take !== undefined &&
        orderListRequest.p_take !== null
      ) {
        queryParams += `p_take=${orderListRequest.p_take}&`;
      }
      if (
        orderListRequest.p_order_no !== undefined &&
        orderListRequest.p_order_no !== null
      ) {
        queryParams += `p_order_no=${orderListRequest.p_order_no}&`;
      }
      if (
        orderListRequest.p_created_date !== undefined &&
        orderListRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${orderListRequest.p_created_date}&`;
      }
      if (
        orderListRequest.p_created_time !== undefined &&
        orderListRequest.p_created_time !== null
      ) {
        queryParams += `p_created_time=${orderListRequest.p_created_time}&`;
      }
      if (
        orderListRequest.p_status !== undefined &&
        orderListRequest.p_status !== null
      ) {
        queryParams += `p_status=${orderListRequest.p_status}&`;
      }
      if (
        orderListRequest.p_type !== undefined &&
        orderListRequest.p_type !== null
      ) {
        queryParams += `p_type=${orderListRequest.p_type}&`;
      }
      if (
        orderListRequest.p_time_zone !== undefined &&
        orderListRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${orderListRequest.p_time_zone}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.ORDER +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (response.data && response.data.length > 0) {
          const pendingCount = response.data.filter(
            (item: any) => item.status === "0"
          ).length;
          dispatch(
            getDashboardOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: pendingCount,
            })
          );

          const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
          dispatch(setClearLocalOrderFilter(true));
          if (
            orderFilter?.orderDate !== currentDateFormatted ||
            (orderFilter?.status && orderFilter?.status !== "") ||
            (orderFilter?.orderType && orderFilter?.orderType !== "")
          ) {
            toast.warn("Filter removed to display the newly added order");
            dispatch(clearOrderFilterData());
            dispatch(clearOrderData());
          }
        } else {
          dispatch(
            getDashboardOrderData({
              order: response.data,
              time: formatDateToUTC(new Date()),
              totalRecords: response.total_records,
              api: true,
              dataCount: 0,
            })
          );
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Order permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};
