const walletAmountInitialState = {
    items: [],
  };
  
  /**
   * Store Wallet list data
   * @param {*} state
   * @param {*} action
   * @returns {*}
   */
  export const WalletTotalReducer = (state = walletAmountInitialState, action) => {
    switch (action.type) {
      case "GET_TOTAL_WALLET_AMT":
        return {
          ...state,
          items: [action.payload],
        };
      case "CLEAR_TOTAL_WALLET_AMT":
        return walletAmountInitialState;
      default:
        return state;
    }
  };
  

  