import { toast } from "react-toastify";
import { ValidationMessage } from "../../Utils/Utils";

export const postApiServiceWp = async (Url, token, bodyData) => {
  try {
    const response = await fetch(Url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: bodyData,
    });
    if (!response.ok) {
      const data = await response.json();
      return data;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    console.error("Error in postApiServiceWp:", error);
  }
};
