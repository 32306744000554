import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Button from '../../../components/Button/Button';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import Toggle from '../../../components/Toggle/Toggle';
import { useDispatch } from 'react-redux';

const validationSchema = Yup.object({
    name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    percent: Yup.number().required(ValidationMessage.FIELD_REQUIRED).min(1, "Cannot add value below 1").max(100, "Cannot add value above 100")
})

const AddIndustry = () => {
    const [initialValues, setInitialValues] = useState({
        name: '',
        percent: '',
        is_active: true,
    });
    const location = useLocation();
    const { Data } = location.state || {}
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = UtilsData();
    const handleSubmit = (values) => {
        const requestBody = {
            // ...(Data && Data.data && { p_id: values.id }),
            // p_name: values.name,
            // p_size: values.size,
            // p_price: values.price,
            // p_is_active: (values.is_active === true || values.is_active === 1 || values.is_active === '1') ? '1' : '0',
            // p_is_available: (values.is_available === true || values.is_available === 1 || values.is_available === '1') ? '1' : '0',
            // ...(Data && Data.data && { p_is_deleted: values.is_deleted })
        }
        // dispatch(clearSpotPageData());
        // if (!Data) {
        //     dispatch(addUpdateSpotService(requestBody, token, 'add', navigate))
        // }
        // else {
        //     handlesaveClick(dispatch, addUpdateSpotService(requestBody, token, "update", navigate), "update");
        // }

    }

    return (
        <>
            <div className="main-section add-image-section">
                <div className="sidebar-content">
                    <Sidebar />
                </div>
                <div className="body-content">
                    <Header />
                    <div className="table-body-content">
                        <div className="roles">
                            <div>
                                <div className="title">
                                    <h4>{Data && Data.isEdit === true
                                        ? "Edit "
                                        : Data && Data.isEdit === false
                                            ? "View "
                                            : "Add "} Industry</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => navigate("/industry")}>Industry</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{Data && Data.isEdit === true ? "Edit " : Data && Data.isEdit === false
                                        ? "View " : "Add "} Industry</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className="add-roles-title">
                            <h4>{Data && Data.isEdit === true
                                ? "Edit "
                                : Data && Data.isEdit === false
                                    ? "View "
                                    : "Add "} Industry</h4>
                        </div>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <div className="add-roles-form row">
                                        <div className="col-md-6">
                                            <div className="name-type">
                                                <div className="paragraph-editor ">
                                                    <h4 className="paragraph-title">Name<span className="required-label"></span></h4>
                                                </div>
                                                <Input
                                                    type="text"
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Enter Name"
                                                    className="add-roles"
                                                    value={values.name}
                                                    Regex={/[^a-zA-Z\s]/g}
                                                    disabled={Data && Data.isEdit === false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="name-type">
                                                <div className="paragraph-editor ">
                                                    <h4 className="paragraph-title">Percent<span className="required-label"></span></h4>
                                                </div>
                                                <Input
                                                    type="text"
                                                    name="percent"
                                                    label="Percent"
                                                    placeholder="Enter Percent"
                                                    className="add-roles"
                                                    value={values.percent}
                                                    Regex={/[^0-9\s]/g}
                                                    disabled={Data && Data.isEdit === false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="paragraph-editor">
                                                <div className="btn-submit mt-3">
                                                    {((Data && Data.isEdit === true) || (!Data)) && (
                                                        <>
                                                            <Button type="submit" name="Save" className="btn login-btn" />
                                                            <Button type="button"
                                                                name="Cancel"
                                                                className="btn clear-btn login-btn"
                                                                onClick={() => handleCancleClick('/industry', navigate)}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default AddIndustry