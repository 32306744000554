import { Dispatch } from "redux";
import {
  addUpdateWalletRequest,
  getAllWalletRequest,
  getAllWalletResponse,
} from "./WalletModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import { putApiService } from "../../../services/API-services/PutService";
import {
  clearTotalWalletData,
  clearWalletData,
  getTotalWalletData,
  getWalletData,
} from "../../../redux/Actions/WalletAction/WalletAction";
import {
  clearWalletPageData,
  getWalletPageData,
} from "../../../redux/Actions/WalletAction/WalletPageAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getApiService } from "../../../services/API-services/GetService";

let errorShow = false;

/*
 * Service to get wallet list
 */
export const getAllWalletService = (
  walletRequest: getAllWalletRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllWalletResponse> => {
  // Update return type here
  return new Promise<getAllWalletResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (walletRequest.p_skip !== undefined && walletRequest.p_skip !== null) {
        queryParams += `p_skip=${walletRequest.p_skip}&`;
      }
      if (walletRequest.p_take !== undefined && walletRequest.p_take !== null) {
        queryParams += `p_take=${walletRequest.p_take}&`;
      }

      if (
        walletRequest.p_time_zone !== undefined &&
        walletRequest.p_time_zone !== null
      ) {
        queryParams += `p_time_zone=${walletRequest.p_time_zone}&`;
      }
      if (
        walletRequest.p_created_date !== undefined &&
        walletRequest.p_created_date !== null
      ) {
        queryParams += `p_created_date=${walletRequest.p_created_date}&`;
      }
      if (
        walletRequest.p_end_date !== undefined &&
        walletRequest.p_end_date !== null
      ) {
        queryParams += `p_end_date=${walletRequest.p_end_date}&`;
      }
      if (
        walletRequest.p_status !== undefined &&
        walletRequest.p_status !== null
      ) {
        queryParams += `p_status=${walletRequest.p_status}&`;
      }

      if (walletRequest.p_type !== undefined && walletRequest.p_type !== null) {
        queryParams += `p_type=${walletRequest.p_type}&`;
      }

      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.TRANSACTION +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalWalletData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getWalletData(response));
        } else {
          dispatch(getWalletData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Wallet permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating wallet
 */
export const addUpdateWalletService = (
  addUpdateWalletRequest: addUpdateWalletRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.TRANSACTION,
          token,
          addUpdateWalletRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.TRANSACTION,
          token,
          addUpdateWalletRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearWalletData());
        dispatch(clearTotalWalletData());
        if (type === "add") {
          dispatch(clearWalletPageData());
          toast.success("Transaction added successfully!");
          navigate("/wallet");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Transaction undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getWalletPageData(pageNumber + 1));
              } else {
                dispatch(clearWalletPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Wallet updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/wallet");
              });
            } else {
              toast.success("Wallet status updated successfully!");
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getWalletPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};
