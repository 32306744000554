/**
 * Set product pricing page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getProductPricingPageData = (page) => ({
    type: 'GET_PRODUCT_PRICING_PAGE',
    payload: page
})

/**
 * Clear product page data
 * @returns {*}
 */
export const clearProductPricingPageData = () => ({
    type: 'CLEAR_PRODUCT_PRICING_PAGE'
})