import { BiAnalyse, BiSolidCategory, BiSolidCategoryAlt } from "react-icons/bi";
import {
  FaCartPlus,
  FaFileImage,
  FaFlagCheckered,
  FaProductHunt,
  FaShoppingCart,
  FaStarHalfAlt,
  FaTags,
} from "react-icons/fa";
import { FaCartArrowDown, FaFileLines } from "react-icons/fa6";
import {
  MdCategory,
  MdGroups,
  MdInventory,
  MdLiveTv,
  MdOutlineInventory,
  MdOutlineManageAccounts,
  MdWork,
} from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { PiPercentFill, PiUserSwitchFill, PiWarehouseFill } from "react-icons/pi";
import { LuTableProperties } from "react-icons/lu";
import { HiMiniBuildingOffice } from "react-icons/hi2";
import { IoFastFood } from "react-icons/io5";
import { BsBuildingFill, BsCartCheckFill } from "react-icons/bs";
import { ImFlattr } from "react-icons/im";
import { GrUserWorker } from "react-icons/gr";

export const getIcon = (iconString) => {
  let match;
  if (iconString) {
    match = iconString.match(/<([A-Za-z]+)([A-Za-z0-9]+) \/>/);
  }
  if (match && match[1] && match[2]) {
    const library = match[1].substring(0, 2).toLowerCase();
    const iconName = match[1] + match[2];
    let iconComponent = null;
    try {
      switch (iconString) {
        case "<MdGroups />":
          return <MdGroups />;
        case "<FaShoppingCart />":
          return <FaShoppingCart />;
        case "<FaCartPlus />":
          return <FaCartPlus />;
        case "<BiAnalyse />":
          return <BiAnalyse />;
        case "<PiUserSwitchFill />":
          return <PiUserSwitchFill />;
        case "<PiPercentFill />":
          return <PiPercentFill />;
        case "<FaFileLines />":
          return <FaFileLines />;
        case "<FaFlagCheckered />":
          return <FaFlagCheckered />;
        case "<FaStarHalfAlt />":
          return <FaStarHalfAlt />;
        case "<MdLiveTv />":
          return <MdLiveTv />;
        case "<MdOutlineInventory />":
          return <MdOutlineInventory />;
        case "<MdOutlineManageAccounts />":
          return <MdOutlineManageAccounts />;
        case "<GoOrganization />":
          return <GoOrganization />;
        case "<MdInventory />":
          return <MdInventory />;
        case "<LuTableProperties />":
          return <LuTableProperties />;
        case "<HiMiniBuildingOffice />":
          return <HiMiniBuildingOffice />;
        case "<IoFastFood />":
          return <IoFastFood />;
        case "<FaCartArrowDown />":
          return <FaCartArrowDown />;
        case "<BsCartCheckFill />":
          return <BsCartCheckFill />;
        case "<BsBuildingFill />":
          return <BsBuildingFill />;
        case "<MdCategory />":
          return <MdCategory />;
        case "<BiSolidCategory />":
          return <BiSolidCategory />;
        case "<BiSolidCategoryAlt />":
          return <BiSolidCategoryAlt />;
        case "<FaProductHunt />":
          return <FaProductHunt />;
        case "<FaFileImage />":
          return <FaFileImage />;
        case "<FaTags />":
          return <FaTags />;
        case "<ImFlattr />":
          return <ImFlattr />;
        case "<PiWarehouseFill />":
          return <PiWarehouseFill />;
        case "<MdWork />":
          return <MdWork />;
        case "<GrUserWorker />":
          return <GrUserWorker />
        default:
          console.error(`Unknown icon library: ${library}`);
          return iconString;
      }
    } catch (error) {
      console.error(`Error loading ${library} icon: ${iconName}`);
    }
    return iconComponent;
  }
};
