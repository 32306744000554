/**
 * Setting stock list data into reducer
 * @param {*} Stocks 
 * @returns {*}
 */
export const getStockData = (Stocks) => ({
    type: 'GET_STOCK_DATA',
    payload: Stocks
})

/**
 * clearing stock list data
 * @returns {*}
 */
export const clearStockData = () => ({
    type: 'CLEAR_STOCK_DATA'
})

/**
 * setting total stock data into reducer (No pagination)
 * @param {*} Stocks  
 * @returns {*}
 */
export const getTotalStockData = (Stocks) => ({
    type: 'GET_TOTAL_STOCK',
    payload: Stocks
})

/**
 * cleating total stock list data
 * @returns {*}
 */
export const clearTotalStockData = () => ({
    type: 'CLEAR_TOTAL_STOCK'
})