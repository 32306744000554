const productPricingFilterInitialState = {
    name: '',
    // category: [],
    // subCategory: [],
    // leafCategory: [],
    // tags: [],
    sku: '',
    // brand: '',
    // isActive: '',
    // isDelete: ''
};

/**
 * Store prodict pricing filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const productPricingFilterReducer = (state = productPricingFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_PRICING_FILTER':
            return {
                ...state,
                name: action.payload.name,
                // category: action.payload.category,
                // subCategory: action.payload.subCategory,
                // leafCategory: action.payload.leafCategory,
                // tags: action.payload.tags,
                sku: action.payload.sku,
                // brand: action.payload.brand,
                // isActive: action.payload.isActive,
                // isDelete: action.payload.isDelete
            }
        case 'CLEAR_PRODUCT_PRICING_FILTER':
            return productPricingFilterInitialState;
        default:
            return state;
    };
}