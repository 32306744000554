/**
 * set Wallet filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getWalletFilterData = ({ startDate, endDate, type, status }) => ({
  type: "GET_WALLET_FILTER",
  payload: {
    startDate,
    endDate,
    status,
    type,
  },
});

/**
 * clear wallet filtered data
 * @returns {*}
 */
export const clearWalletFilterData = () => ({
  type: "CLEAR_WALLET_FILTER",
});
