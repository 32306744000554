const walletInitialState = {
    items: 1,
  };
  
  /**
   * Store WALLET page data
   * @param {number} state
   * @param {*} action
   * @returns {number}
   */
  export const WalletPageReducer = (
    state = walletInitialState,
    action
  ) => {
    switch (action.type) {
      case "GET_WALLET_PAGE":
        return {
          ...state,
          items: action.payload,
        };
      case "CLEAR_WALLET_PAGE":
        return walletInitialState;
      default:
        return state;
    }
  };
  