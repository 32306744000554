/**
 * Setting Account Detail list data into reducer
 * @param {*} accountDetail
 * @returns {*}
 */
export const getAccountDetailData = (accountDetail) => ({
  type: "GET_ACCOUNT_DETAIL_DATA",
  payload: accountDetail,
});

/**
 * clearing Account list data
 * @returns {*}
 */
export const clearAccountDetailData = () => ({
  type: "CLEAR_ACCOUNT_DETAIL_DATA",
});
