import { toast } from "react-toastify";
import {
  API_URL,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { getApiService } from "../../../services/API-services/GetService";
import {
  clearRoleData,
  clearTotalRoleData,
  getRoleData,
  getTotalRoleData,
} from "../../../redux/Actions/rolesAction/RolesAction";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import {
  AddUpdateRoleRequest,
  roleDetailResponse,
  roleListAPIRequest,
  roleListAPIResponse,
} from "./RoleAPIModule";
import { Dispatch } from "redux";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearRolePageData,
  getRolePageData,
} from "../../../redux/Actions/rolesAction/RolesPageAction";

let errorShow = false;

/*
 * Service to get Role list
 */
export const getRolesService = (
  roleListRequest: roleListAPIRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<roleListAPIResponse> => {
  return new Promise<roleListAPIResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        roleListRequest.p_skip !== undefined &&
        roleListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${roleListRequest.p_skip}&`;
      }
      if (
        roleListRequest.p_take !== undefined &&
        roleListRequest.p_take !== null
      ) {
        queryParams += `p_take=${roleListRequest.p_take}&`;
      }
      if (
        roleListRequest.p_search_title !== undefined &&
        roleListRequest.p_search_title !== null
      ) {
        queryParams += `p_search_title=${roleListRequest.p_search_title}&`;
      }
      if (
        roleListRequest.p_is_active !== undefined &&
        roleListRequest.p_is_active !== null
      ) {
        queryParams += `p_is_active=${roleListRequest.p_is_active}&`;
      }
      if (
        roleListRequest.p_IsDeleted !== undefined &&
        roleListRequest.p_IsDeleted !== null
      ) {
        queryParams += `p_IsDeleted=${roleListRequest.p_IsDeleted}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.ROLE +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalRoleData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getRoleData(response));
        } else {
          dispatch(getRoleData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Role permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service to get individual role
 */
export const getRoleById = (
  id: number,
  token: string,
  dispatch: Dispatch
): Promise<roleDetailResponse> => {
  return new Promise<roleDetailResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      const response = await getApiService(
        API_URL.BASE_URL + API_URL.ROLE + "/" + id,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating role
 */
export const addUpdateRolesService = (
  addUpdateRoleRequest: AddUpdateRoleRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.ROLE,
          token,
          addUpdateRoleRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.ROLE,
          token,
          addUpdateRoleRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearRoleData());
        dispatch(clearTotalRoleData());
        if (type === "add") {
          dispatch(clearRolePageData());
          toast.success("Role added successfully!");
          navigate("/roles");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Role undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getRolePageData(pageNumber + 1));
              } else {
                dispatch(clearRolePageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Role updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/roles");
              });
            } else {
              toast.success("Role status updated successfully!");
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getRolePageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service to delete role
 */
export const deleteRolesService = (
  id: string,
  token: string,
  pageNumber: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(
        API_URL.BASE_URL + API_URL.ROLE + "/" + id,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearRoleData());
        dispatch(clearTotalRoleData());
        toast.success("Role deleted successfully!");
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getRolePageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};