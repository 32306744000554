const customerOrdersInitialState = {
  items: [],
};

/**
 * Store CUSTOMER ORDERS list data
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const CustomerOrdersReducer = (
  state = customerOrdersInitialState,
  action
) => {
  switch (action.type) {
    case "GET_CUSTOMER_ORDERS_DATA":
      return {
        ...state,
        items: [action.payload],
      };
    case "CLEAR_CUSTOMER_ORDERS_DATA":
      return customerOrdersInitialState;
    default:
      return state;
  }
};

const totalCustomerOrdersInitialState = {
  items: [],
};

/**
 * Store total CUSTOMER ORDERS list data (No pagination)
 * @param {*} state
 * @param {*} action
 * @returns {*}
 */
export const TotalCustomerOrdersReducer = (
  state = totalCustomerOrdersInitialState,
  action
) => {
  switch (action.type) {
    case "GET_TOTAL_CUSTOMER_ORDERS":
      return {
        ...state,
        items: [action.payload],
      };
    case "CLEAR_TOTAL_CUSTOMER_ORDERS":
      return totalCustomerOrdersInitialState;
    default:
      return state;
  }
};
