/**
 * set GRN filtered data into reducer
 * @param {*} param0
 * @returns {*}
 */
export const getGRNFilterData = ({ GRNNo, orderStartDate, orderEndDate }) => ({
  type: "GET_GRN_FILTER",
  payload: {
    GRNNo,
    orderStartDate,
    orderEndDate,
  },
});

/**
 * clear GRN filtered data
 * @returns {*}
 */
export const clearGRNFilterData = () => ({
  type: "CLEAR_GRN_FILTER",
});
