import React, { useEffect, useRef, useState } from "react";
import "./Table.scss";
import Table from "react-bootstrap/Table";
import Toggle from "../Toggle/Toggle";
import { FaEye, FaPlus, FaStarHalfAlt } from "react-icons/fa";
import { FaQrcode } from "react-icons/fa6";
import Checkbox from "../../components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit, MdOutlineCancel, MdCancel } from "react-icons/md";
import {
  handledeletClick,
  handleLineLevelOrderConfirmationClick,
  handleLineLevelOrderDeleteClick,
  handleOrderConfirmationClick,
  handlesaveClick,
  handleToggleChange,
  handleUndoDelete,
} from "../Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import { policyUpdate } from "../../redux/Actions/policyAction/PolicyAction";
import { formatDate } from "../../services/Regular-services/DateService";
import "react-datepicker/dist/react-datepicker.css";
import { blogUpdate } from "../../redux/Actions/blogAction/BlogAction";
import { faqUpdate } from "../../redux/Actions/faqAction/FaqAction";
import UtilsData, {
  API_URL,
  ValidationMessage,
  imageUrlToFile,
  orderPaymentTableArray,
} from "../../Utils/Utils";
import CustomPagination from "../Pagination/Pagination";
import { GrUndo } from "react-icons/gr";
import { getCheckboxData } from "../../redux/Actions/CheckboxAction/CheckboxAction";
import { toast } from "react-toastify";
import ErrorImage from "./../../assets/images/placeholder.png";
import { updateSequenceService } from "../../services/Sequence-service/SequenceService";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import { placeOrderService } from "../../pages/Order/OrderService/OrderService";
import {
  IoMdPrint,
  IoLogoWhatsapp,
  IoIosCheckmarkCircle,
  IoIosSave,
} from "react-icons/io";
import { OrderRequestBodyOnToggle } from "../../pages/Order/OrderRequestBodyOnToggle/OrderRequestBodyOnToggle";
import { buildVendorRequestBody } from "../../pages/Vendors/buildVendorRequestBody/buildVendorRequestBody";
import { addUpdateVendorService } from "../../pages/Vendors/VendorsService.js/VendorService";
import LOGO from "../../assets/images/Bespeakk-logo.png";
import {
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../services/Regular-services/ConvertUnitService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { MdDeleteForever } from "react-icons/md";
import Button from "../Button/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../input/Input";
import { PurchaseRequisitionReqBody } from "../../pages/PurchaseRequisition/PurchaseRequisitionStatusReqbody/PurchaseRequisitionReqBody";
import { addUpdatePurchaseRequisitionService } from "../../pages/PurchaseRequisition/PurchaseRequisitionService/PurchaseRequisitionService";
import { clearPurchaseOrderPageData } from "../../redux/Actions/PurchaseOrderAction/PurchaseOrderPageAction";
import {
  addUpdatePurchaseOrderService,
  getGRNDetailService,
  uploadPDFServicePO,
} from "../../pages/PurchaseOrder/PurchaseOrderService/PurchaseOrderService";
import { updateProductPriceService } from "../../pages/Product/ProductService/ProductService";
import generateInvoiceHtml from "../generateInvoiceHtml/generateInvoiceHtml";

// import ReactDOMServer from 'react-dom/server';
export default function CustomTable(props) {
  /**
   * Getting page data
   */
  const categoryPageData = useSelector((state) => state.categoryPage?.items);
  const SubCategoryPage = useSelector((state) => state.SubCategoryPage?.items);
  const imagePage = useSelector((state) => state.imagePage?.items);
  const rolePage = useSelector((state) => state.rolePage?.items);
  const companyPage = useSelector((state) => state.companyPage?.items);
  const adminPage = useSelector((state) => state.adminPage?.items);
  const leafPage = useSelector((state) => state.leafPage?.items);
  const productPage = useSelector((state) => state.productPage?.items);
  const tagPage = useSelector((state) => state.tagPage?.items);
  const spotPage = useSelector((state) => state.spotPage?.items);
  const brandPage = useSelector((state) => state.brandPage?.items);
  const attributePage = useSelector((state) => state.attributePage?.items);
  const warehousePage = useSelector((state) => state.warehousePage?.items);
  const packagerPage = useSelector((state) => state.packagerPage?.items);
  const discountPage = useSelector((state) => state.discountPage?.items);
  const inventoryItemPage = useSelector(
    (state) => state.inventoryItemPage?.items
  );
  const productInventoryPage = useSelector(
    (state) => state.productInventoryPage?.items
  );
  const newVendorPage = useSelector((state) => state.newVendorPage?.items);
  const discountCataloguePage = useSelector(
    (state) => state.discountCataloguePage?.items
  );
  const branchPage = useSelector((state) => state.branchPage?.items);
  const servicePage = useSelector((state) => state.servicePage?.items);

  const categoryFilter = useSelector((state) => state.categoryFilter);
  const SubCategoryFilter = useSelector((state) => state.SubCategoryFilter);
  const ServiceProviderPage = useSelector(
    (state) => state.ServiceProviderPage?.items
  );
  const orderPage = useSelector((state) => state.orderPage?.items);
  // const vendorPage = useSelector((state) => state.vendorPage?.items);
  const purchaseRequisitionPage = useSelector(
    (state) => state.purchaseRequisitionPage?.items
  );

  const tooltipRef = useRef(null);

  const userData = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [selectAllValue, setSelectAllValue] = useState([]);

  const dispatch = useDispatch();
  const [disableArray, setDisableArray] = useState([]);

  const [sequenceArray, setSequenceArray] = useState([]);

  const [paymentSequenceArray, setPaymentSequenceArray] = useState([]);

  const [selectSequenceData, setSelectSequenceData] = useState(false);
  const [selectPaymentSequenceData, setPaymentSelectSequenceData] =
    useState(false);

  const loader = useSelector((state) => state.loader?.items);

  const user = useSelector((state) => state.auth?.user);
  const tokenData = UtilsData();

  const [currentPage, setCurrentPage] = useState(1); // Assuming initial page is 1
  const totalRecords = 10; // Assuming there are 100 records

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update the current page state
  };

  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  //Handling Navigation
  const handleNavigate = (route, data, isEdit) => {
    const Data = {
      data: data,
      isEdit: isEdit,
      length: props.page === "product" ? props.productLength : tableData.length,
    };
    if (props.page === "imageManager") {
      props.handleToggle(data, tableData.length);
    } else {
      navigate(route, { state: { Data } });
    }
  };

  let { data, heading, showId, showDescription } = props;
  const [tableData, setTableData] = useState([]);

  //Setting data on initialization
  const [imageErrors, setImageErrors] = useState([]);
  const [notificationImageErrors, setNotificationImageErrors] = useState([]);
  useEffect(() => {
    if (data && data?.items) {
      setTableData(data.items);
    } else {
      setTableData(data);
    }
  }, [data]);

  /**
   * Function for showing static image if image is broken
   * @param {*} index
   */
  const handleImageError = (index) => {
    setImageErrors((prevErrors) => {
      const newErrors = [...prevErrors, index];
      return newErrors;
    });
  };

  /**
   * Function for showing static image if image is broken
   * @param {*} index
   */
  const handleNotificationImageError = (index) => {
    setNotificationImageErrors((prevErrors) => {
      const newErrors = [...prevErrors, index];
      return newErrors;
    });
  };

  // Handling checkbox for uploading image
  useEffect(() => {
    if (props.page === "uploadImage") {
      if (props.uploadImageValue && props.uploadImageValue.length > 0) {
        if (data.items) {
          const updateIndex = [];
          data.items.filter((item, index) => {
            return props.uploadImageValue.some((product) => {
              if (product.imageId === item.imageId) {
                updateIndex.push(index);
              }
              return product.imageId === item.imageId;
            });
          });
          setSelectedItems(updateIndex);
        } else {
          const updateIndex = [];
          data.filter((item, index) => {
            return props.uploadImageValue.some((product) => {
              if (product.imageId === item.imageId) {
                updateIndex.push(index);
              }
              return product.imageId === item.imageId;
            });
          });
          setSelectedItems(updateIndex);
        }
      }
    }
  }, [data, props.page, props.uploadImageValue]);

  //Disable other toggle keys if value of isAllow toggle key is false
  useEffect(() => {
    if (props.page === "addRoles" || props.page === "add-company") {
      let disableId = [];
      let selectValue = [];
      if (data && data?.items) {
        data.items.filter((allow) => {
          if (
            allow.allow === false ||
            allow.allow === 0 ||
            allow.allow === "0"
          ) {
            disableId = [...disableId, allow.name];
            return true;
          } else {
            if (
              (allow.allow === 1 ||
                allow.allow === true ||
                allow.allow === "1") &&
              (allow.add === 1 || allow.add === true || allow.add === "1") &&
              (allow.edit === 1 || allow.edit === true || allow.edit === "1") &&
              (allow.view === 1 || allow.view === true || allow.view === "1") &&
              (allow.delete === 1 ||
                allow.delete === true ||
                allow.delete === "1")
            ) {
              selectValue = [...selectValue, allow.name];
            }
            return false;
          }
        });
      } else {
        data?.filter((allow) => {
          if (
            allow.allow === false ||
            allow.allow === 0 ||
            allow.allow === "0"
          ) {
            disableId = [...disableId, allow.name];
            return true;
          } else {
            if (
              (allow.allow === 1 ||
                allow.allow === true ||
                allow.allow === "1") &&
              (allow.add === 1 || allow.add === true || allow.add === "1") &&
              (allow.edit === 1 || allow.edit === true || allow.edit === "1") &&
              (allow.view === 1 || allow.view === true || allow.view === "1") &&
              (allow.delete === 1 ||
                allow.delete === true ||
                allow.delete === "1")
            ) {
              selectValue = [...selectValue, allow.name];
            }
            return false;
          }
        });
      }
      setSelectAllValue(selectValue);
      setDisableArray(disableId);
    }
  }, [props.page, data]);

  //Handling Toggle
  const handleToggle = (itemData, itemType, checked) => {
    const currentDate = new Date();
    const itemId = itemData.id;
    const formattedDate = formatDate(currentDate);
    const updatedData =
      tableData &&
      tableData.map((item) => {
        if (item.id && item.id === itemId) {
          let updatedItem = item; //Data of element which we are toggling
          if (
            itemType !== "isActive" &&
            itemType !== "toggle" &&
            itemType !== "status" &&
            itemType !== "deleteItem"
          ) {
            updatedItem[itemType + "Toggle"] =
              !updatedItem[itemType + "Toggle"];
            return updatedItem;
          } else {
            //If page = policies
            if (
              props.page &&
              (props.page === "Policies" ||
                props.page === "Policy" ||
                props.page === "policy")
            ) {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  policyUpdate({
                    id: updatedItem.id,
                    name: updatedItem.name,
                    type: updatedItem.type,
                    isActive: !updatedItem.isActive,
                    date: formattedDate,
                    description: updatedItem.description,
                    isDelete: updatedItem.isDelete,
                  }),
                  !updatedItem.isActive
                );
              }
            }

            //If page = category
            if (
              props.page &&
              (props.page === "category" ||
                props.page === "categories" ||
                props.page === "Category" ||
                props.page === "Categories")
            ) {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "category",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  categoryPageData
                );
              }
            }

            //If page = sub-category
            if (
              props.page === "subCategory" ||
              props.page === "Subcategory" ||
              props.page === "subcategory" ||
              props.page === "SubCategory"
            ) {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "subCategory",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  SubCategoryPage
                );
              }
            }

            //If page = leaf-level-category
            if (props.page === "leaf" || props.page === "leafCategory") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "leaf",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  leafPage
                );
              }
            }

            //If page = product
            if (props.page === "product" || props.page === "Product") {
              handleToggleChange(
                dispatch,
                "product",
                checked,
                tokenData,
                user.data.userId,
                updatedItem,
                props.productLength,
                productPage
              );
            }
            // changes by sakshi
            if (props.page === "company") {
              handleToggleChange(
                dispatch,
                "company",
                checked,
                tokenData,
                user.data.userId,
                updatedItem,
                props.data.length,
                companyPage
              );
            }

            //If page = blog
            if (props.page === "blog") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  blogUpdate({
                    id: updatedItem.id,
                    name: updatedItem.name,
                    author: updatedItem.author,
                    date: updatedItem.date,
                    category: updatedItem.category,
                    tags: updatedItem.tags,
                    isActive: !updatedItem.isActive,
                    isDelete: updatedItem.isDelete,
                    restData: updatedItem.restData,
                  }),
                  !updatedItem.isActive
                );
              }
            }
            //If page = faq
            if (props.page === "faq") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  faqUpdate({
                    id: updatedItem.id,
                    question: updatedItem.question,
                    answer: updatedItem.answer,
                    category: updatedItem.category,
                    tags: updatedItem.tags,
                    date: updatedItem.date,
                    isActive: !updatedItem.isActive,
                    isDelete: updatedItem.isDelete,
                  }),
                  !updatedItem.isActive
                );
              }
            }
            // If page = roles
            if (props.page === "roles" || props.page === "role") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "roles",
                  checked,
                  tokenData,
                  user.data.userId,
                  updatedItem,
                  tableData.length,
                  rolePage
                );
              }
            }
            //If page = tags
            if (props.page === "tag" || props.page === "tags") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "tags",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  tagPage
                );
              }
            }

            // spot
            if (props.page === "spots" || props.page === "spot") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "spots",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  spotPage
                );
              }
            }

            // inventory-item
            if (
              props.page === "inventory-items" ||
              props.page === "inventory-item"
            ) {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "inventory-item",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  inventoryItemPage
                );
              }
            }

            if (props.page === "new-vendor-person") {
              if (itemType === "status") {
                handleToggleChange(
                  dispatch,
                  "new-vendor-person",
                  checked,
                  tokenData,
                  userData.user.data.userId,
                  updatedItem,
                  tableData.length,
                  newVendorPage
                );
              }
            }

            if (props.page === "customer") {
              if (itemType === "status") {
                return { ...item, [itemType]: !item[itemType] };
              }
            }
          }
        } else {
          if (item.name && item.name === itemData) {
            let updatedItem = item;
            //If page = add-roles
            if (props.page === "addRoles" || props.page === "add-company") {
              if (itemType === "selectAll") {
                setSelectAllValue(
                  checked === true
                    ? [...selectAllValue, itemData]
                    : selectAllValue.filter((id) => id !== itemData)
                );
                const togglesToUpdate = [
                  "allow",
                  "delete",
                  "add",
                  "view",
                  "edit",
                  "selectAll",
                ];
                togglesToUpdate.forEach((toggleKey) => {
                  updatedItem[toggleKey] = checked ? 1 : 0;
                });
                if (checked === false) {
                  setDisableArray([...disableArray, itemData]);
                } else {
                  const mapId = disableArray.filter((id) => id === itemData);
                  if (mapId && mapId.length > 0) {
                    setDisableArray(
                      disableArray.filter((id) => id !== itemData)
                    );
                  }
                }
              } else {
                updatedItem[itemType] = checked ? 1 : 0;
                if (checked === false) {
                  setSelectAllValue(
                    selectAllValue.filter((id) => id !== itemData)
                  );
                  if (itemType === "allow") {
                    const togglesToUpdate = [
                      "allow",
                      "delete",
                      "add",
                      "view",
                      "edit",
                    ];
                    togglesToUpdate.forEach((toggleKey) => {
                      updatedItem[toggleKey] = checked ? 1 : 0;
                    });
                    setDisableArray([...disableArray, itemData]);
                  }
                } else {
                  if (itemType === "allow") {
                    const mapId = disableArray.filter((id) => id === itemData);
                    if (mapId && mapId.length > 0) {
                      setDisableArray(
                        disableArray.filter((id) => id !== itemData)
                      );
                    }
                  }
                }
              }
              return updatedItem;
            }
          }
          if (props.page === "tag" || props.page === "tags") {
            if (itemType === "status") {
              handleToggleChange(
                dispatch,
                "tags",
                checked,
                tokenData,
                userData.user.data.userId,
                itemData,
                tableData.length,
                tagPage
              );
            }
          }
          if (
            props.page &&
            (props.page === "brand" || props.page === "Brand")
          ) {
            if (itemType === "status") {
              handleToggleChange(
                dispatch,
                "brand",
                checked,
                tokenData,
                userData.user.data.userId,
                itemData,
                tableData.length,
                brandPage
              );
            }
          }
          if (props.page === "product" || props.page === "Product") {
            handleToggleChange(
              dispatch,
              "product",
              checked,
              tokenData,
              user.data.userId,
              itemData,
              props.productLength,
              productPage
            );
          }
        }

        if (props.page === "users") {
          if (itemType === "status") {
            handleToggleChange(
              dispatch,
              "users",
              checked,
              tokenData,
              user.data.userId,
              itemData,
              tableData.length,
              adminPage
            );
          }
        }
        if (props.page === "attributes" || props.page === "attribute") {
          handleToggleChange(
            dispatch,
            "attributes",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            attributePage
          );
        } else if (props.page === "warehouse") {
          handleToggleChange(
            dispatch,
            "warehouse",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            warehousePage
          );
        } else if (props.page === "packager") {
          const requestBody = {
            p_id: itemData.productPackagerId,
            p_name: itemData.name,
            p_address: itemData.address,
            p_type: itemData.type,
            p_pincode: itemData.pincode,
            p_isActive: itemData.is_active === "1" ? "0" : "1",
            p_IsDeleted: itemData.IsDeleted,
            p_modified_by: user.data.userId,
          };
          handleToggleChange(
            dispatch,
            "packager",
            checked,
            tokenData,
            user.data.userId,
            requestBody,
            tableData.length,
            packagerPage
          );
        } else if (props.page === "discount") {
          handleToggleChange(
            dispatch,
            "discount",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            discountPage
          );
        } else if (props.page === "discountCatalogue") {
          handleToggleChange(
            dispatch,
            "discountCatalogue",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            discountCataloguePage
          );
        } else if (props.page === "branch") {
          handleToggleChange(
            dispatch,
            "branch",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            branchPage
          );
        } else if (props.page === "serviceProvider") {
          handleToggleChange(
            dispatch,
            "serviceProvider",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            ServiceProviderPage
          );
        } else if (props.page === "service") {
          handleToggleChange(
            dispatch,
            "service",
            checked,
            tokenData,
            user.data.userId,
            itemData,
            tableData.length,
            servicePage
          );
        }
        return item;
      });
    setTableData(updatedData);
  };

  /**
   * Function for undo the deleted data
   */
  const handleUndoClick = (item) => {
    if (props.page === "roles" || props.page === "role") {
      handleUndoDelete(
        dispatch,
        "roles",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        rolePage
      );
    } else if (props.page === "users") {
      handleUndoDelete(
        dispatch,
        "users",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        adminPage
      );
    } else if (props.page === "category" || props.page === "categories") {
      handleUndoDelete(
        dispatch,
        "category",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        categoryPageData
      );
    } else if (props.page === "subCategory") {
      handleUndoDelete(
        dispatch,
        "subCategory",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        SubCategoryPage
      );
    } else if (props.page === "leafCategory") {
      handleUndoDelete(
        dispatch,
        "leafCategory",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        leafPage
      );
    } else if (props.page === "tag" || props.page === "tags") {
      handleUndoDelete(
        dispatch,
        "tags",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        tagPage
      );
    } else if (props.page === "imageManager" || props.page === "imagemanager") {
      let data = item;
      imageUrlToFile(API_URL.TOKEN_URL + item.imagePath)
        .then((file) => {
          data.imagePath = [file];
          handleUndoDelete(
            dispatch,
            "imageManager",
            data,
            tokenData,
            user.data.userId,
            tableData.length,
            imagePage
          );
        })
        .catch((error) => {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        });
    } else if (props.page === "brand" || props.page === "Brand") {
      handleUndoDelete(
        dispatch,
        "brand",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        brandPage
      );
    } else if (props.page === "product" || props.page === "Product") {
      handleUndoDelete(
        dispatch,
        "product",
        item,
        tokenData,
        user.data.userId,
        props.productLength,
        productPage
      );
    } else if (props.page === "attributes" || props.page === "attribute") {
      handleUndoDelete(
        dispatch,
        "attributes",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        attributePage
      );
    } else if (props.page === "warehouse") {
      handleUndoDelete(
        dispatch,
        "warehouse",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        warehousePage
      );
    } else if (props.page === "packager") {
      handleUndoDelete(
        dispatch,
        "packager",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        packagerPage
      );
    } else if (props.page === "discount") {
      handleUndoDelete(
        dispatch,
        "discount",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        discountPage
      );
    } else if (props.page === "discountCatalogue") {
      handleUndoDelete(
        dispatch,
        "discountCatalogue",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        discountCataloguePage
      );
    } else if (props.page === "branch") {
      handleUndoDelete(
        dispatch,
        "branch",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        branchPage
      );
    } else if (props.page === "serviceProvider") {
      handleUndoDelete(
        dispatch,
        "serviceProvider",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        ServiceProviderPage
      );
    } else if (props.page === "service") {
      handleUndoDelete(
        dispatch,
        "service",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        servicePage
      );
    } else if (props.page === "spots") {
      handleUndoDelete(
        dispatch,
        "spots",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        spotPage
      );
    } else if (props.page === "inventory-item") {
      handleUndoDelete(
        dispatch,
        "inventory-item",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        inventoryItemPage
      );
    } else if (props.page === "new-vendor-person") {
      handleUndoDelete(
        dispatch,
        "new-vendor-person",
        item,
        tokenData,
        user.data.userId,
        tableData.length,
        newVendorPage
      );
    }
  };

  /**
   * Function for undo the deleted data
   */
  const handleDeleteClick = (item) => {
    if (props.page === "roles" || props.page === "role") {
      handledeletClick(
        item,
        dispatch,
        "roles",
        tokenData,
        tableData.length,
        rolePage
      );
    } else if (props.page === "company") {
      handledeletClick(
        item,
        dispatch,
        "company",
        tokenData,
        tableData.length,
        companyPage
      );
    } else if (props.page === "users") {
      handledeletClick(
        item,
        dispatch,
        "users",
        tokenData,
        tableData.length,
        adminPage
      );
    } else if (props.page === "category" || props.page === "categories") {
      handledeletClick(
        item,
        dispatch,
        "category",
        tokenData,
        tableData.length,
        categoryPageData
      );
    } else if (props.page === "subCategory") {
      handledeletClick(
        item,
        dispatch,
        "subCategory",
        tokenData,
        tableData.length,
        SubCategoryPage
      );
    } else if (props.page === "leafCategory") {
      handledeletClick(
        item,
        dispatch,
        "leafCategory",
        tokenData,
        tableData.length,
        leafPage
      );
    } else if (props.page === "tag" || props.page === "tags") {
      handledeletClick(
        item,
        dispatch,
        "tags",
        tokenData,
        tableData.length,
        tagPage
      );
    } else if (props.page === "spots" || props.page === "spot") {
      handledeletClick(
        item,
        dispatch,
        "spots",
        tokenData,
        tableData.length,
        spotPage
      );
    } else if (
      props.page === "inventory-items" ||
      props.page === "inventory-item"
    ) {
      handledeletClick(
        item,
        dispatch,
        "inventory-items",
        tokenData,
        tableData.length,
        inventoryItemPage
      );
    } else if (props.page === "new-vendor-person") {
      handledeletClick(
        item,
        dispatch,
        "new-vendor-person",
        tokenData,
        tableData.length,
        newVendorPage
      );
    } else if (props.page === "product-inventory") {
      handledeletClick(
        item,
        dispatch,
        "product-inventory",
        tokenData,
        tableData.length,
        productInventoryPage
      );
    } else if (props.page === "imageManager" || props.page === "imagemanager") {
      handledeletClick(
        item,
        dispatch,
        "imageManager",
        tokenData,
        tableData.length,
        imagePage
      );
    } else if (props.page === "brand" || props.page === "Brand") {
      handledeletClick(
        item,
        dispatch,
        "brand",
        tokenData,
        tableData.length,
        brandPage
      );
    } else if (props.page === "product" || props.page === "Product") {
      handledeletClick(
        item,
        dispatch,
        "product",
        tokenData,
        props.productLength,
        productPage
      );
    } else if (props.page === "attributes" || props.page === "attribute") {
      handledeletClick(
        item,
        dispatch,
        "attributes",
        tokenData,
        tableData.length,
        attributePage
      );
    } else if (props.page === "warehouse") {
      handledeletClick(
        item,
        dispatch,
        "warehouse",
        tokenData,
        tableData.length,
        warehousePage
      );
    } else if (props.page === "packager") {
      handledeletClick(
        item,
        dispatch,
        "packager",
        tokenData,
        tableData.length,
        packagerPage
      );
    } else if (props.page === "discount") {
      handledeletClick(
        item,
        dispatch,
        "discount",
        tokenData,
        tableData.length,
        discountPage
      );
    } else if (props.page === "discountCatalogue") {
      handledeletClick(
        item,
        dispatch,
        "discountCatalogue",
        tokenData,
        tableData.length,
        discountCataloguePage
      );
    } else if (props.page === "branch") {
      handledeletClick(
        item,
        dispatch,
        "branch",
        tokenData,
        tableData.length,
        branchPage
      );
    } else if (props.page === "serviceProvider") {
      handledeletClick(
        item,
        dispatch,
        "serviceProvider",
        tokenData,
        tableData.length,
        ServiceProviderPage
      );
    } else if (props.page === "service") {
      handledeletClick(
        item,
        dispatch,
        "service",
        tokenData,
        tableData.length,
        servicePage
      );
    } else if (props.page === "order") {
      handledeletClick(
        item,
        dispatch,
        "order",
        tokenData,
        tableData.length,
        orderPage
      );
    }
  };

  const printOrderDetails = (orderData) => {
    if (props.page === "vendors") {
      const printWindow = window.open("", "", "height=600,width=800");
      // Parse and convert ingredient data
      const data = JSON.parse(orderData.ingredients);
      const ingredientData = data.map((item) => {
        let quantityInOriginalUnit = parseFloat(item.value);
        let unit = item.unit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }

        return {
          ingredientName: item?.ingredientName,
          IngredientId: item?.ingredient_id,
          quantity: quantityInOriginalUnit,
          unit: unit,
          instruction: item.ingredient_instruction,
          status: item.status,
          ingredient_price: item.ingredient_price,
        };
      });
      const { companyLogo, companyName, companyOwnerName, companyPhneNumber } =
        user.data;

      // Define the HTML structure for printing
      const vendorHtmlContent = `
      <html>
      <head>
        <title>Order Details - Vendor</title>
      </head>
      <body>
        <div style="font-family: Arial, sans-serif; margin: 0; padding: 0; text-align: center; max-width: 80%; margin: 0 auto;">
          <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: flex-start;">
            <img src="${LOGO}" alt="Company Logo" style="width: 100px; height: auto;" />
            <div>
              <img src="${companyLogo}" alt="Company Logo" style="width: 100px; height: auto;" />
            </div>
          </div>
          <div style="margin-top: 10px;">
            <div style="display: flex; justify-content: space-between;">
              <div style="text-align: left;">
                <h5>Order Details</h5>
                <p style="margin: 5px 0; font-size: 12px;">
                  Order No. - <strong>${orderData?.orderNum || "N/A"}</strong>
                </p>
                <p style="margin: 5px 0; font-size: 12px;">Name: ${
                  orderData?.name || "N/A"
                }</p>
                <p style="margin: 5px 0; font-size: 12px;">Phone Number: ${
                  orderData?.phNum || "N/A"
                }</p>
                <p style="margin: 5px 0; font-size: 12px;">
                  Order Date: ${
                    orderData?.orderDate
                      ? new Date(orderData?.orderDate).toLocaleString()
                      : "N/A"
                  }
                </p>
                <p style="margin: 5px 0; font-size: 12px;">
                  <br />
                  Payment: ${orderData?.payment === "0" ? "Not Paid" : "Paid"}
                  <br />
                  Order Status: ${
                    orderData?.orderStatus === "0" ? "Pending" : "Completed"
                  }
                  <br />
                </p>
                ${
                  orderData?.instructions
                    ? `
                  <p style="font-size: 12px;">General Instruction : ${orderData.instructions}</p>
                `
                    : ""
                }
              </div>
              <div style="text-align: end;">
                <p style="margin: 0;">Company: ${companyName}</p>
                <p style="margin: 0;">Owner: ${companyOwnerName}</p>
                <p style="margin: 0;">Phone: ${companyPhneNumber}</p>
              </div>
            </div>
      
            <table style="width: 100%; border-collapse: collapse; margin: 10px auto;">
              <thead>
                <tr>
                  <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Items</th>
                  <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Quantity</th>
                  <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Unit</th>
                  <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Price</th>
                  <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Instructions</th>
                </tr>
              </thead>
              <tbody>
                ${
                  ingredientData.length > 0
                    ? ingredientData
                        .map(
                          (ingredient) => `
                    <tr>
                      <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                        ingredient.ingredientName
                      }</td>
                      <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                        ingredient.quantity
                      }</td>
                      <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                        ingredient.unit
                      }</td>
                      <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                        "₹" + ingredient.ingredient_price
                      }</td>
                      <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                        ingredient.instruction.length > 0
                          ? ingredient.instruction
                          : "N/A"
                      }</td>
                    </tr>
                  `
                        )
                        .join("")
                    : `
                  <tr>
                    <td colspan="4" style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">
                      No ingredients available.
                    </td>
                  </tr>`
                }
              </tbody>
            </table>
            <div style="margin-top: 10px; font-size: 12px;">
              <p>Thanks for your business!</p>
            </div>
            <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin: 20px auto;">
              <!-- Your icon or content here -->
            </div>
          </div>
        </div>
      </body>
      </html>
      `;

      // Write the HTML content to the new window
      printWindow.document.open();
      printWindow.document.write(vendorHtmlContent);
      printWindow.document.close();

      // Trigger the print dialog
      printWindow.print();
    } else {
      // Create a new window for printing
      const printWindow = window.open("", "", "height=600,width=800");
      // Define the HTML structure for the table with inline CSS
      const htmlContent = `
    <html>
    <head>
      <title>Order Details</title>
    </head>
    <body style="font-family: Arial, sans-serif; margin: 0 auto; padding: 0; width: 80mm; text-align: center;">
      <h1 style="text-align: center; font-size: 16px; margin-top: 10px;">Order Details</h1>
      <div style="margin: 10px; text-align: left;">
        <p style="margin: 5px 0; font-size: 12px;">Order No. - <strong>${
          orderData?.orderNumber
        }</strong></p>
        <p style="margin: 5px 0; font-size: 12px; border-top: 1px solid black;">
        <br>
          Dine In: ${orderData?.type === "0" ? "Yes" : "No"}<br>
          Table Number: ${
            orderData?.type === "0" ? orderData.tableNo : "Parcel"
          }<br>
          Date: ${orderData?.createdAt ? orderData.createdAt : ""}<br>
         
        </p>
        ${
          orderData?.instructions
            ? `<p style="font-size: 12px; margin-bottom:5px">General Instruction</p>
                <p style="font-size: 10px;">${orderData.instructions}</p> `
            : ""
        }

           <tbody>
        ${
          orderData?.productData && orderData.productData.length > 0
            ? orderData.productData
                .map(
                  (product) => `
            <tr>
             <td style=" font-size: 12px;">
${product.quantity} x ${product.productName} - ${product.food_type_two}<br>
${
  product?.product_instruction
    ? `<span class="instructions" style="font-style: italic; font-size: 12px;">Instruction: ${product.product_instruction}</span>`
    : ""
}
${
  product?.attribute_data &&
  product.attribute_data !== "" &&
  product.attribute_data !== "null" &&
  product.attribute_data !== "NULL"
    ? `<div class="attribute-data" style="font-style: italic; font-size: 12px; color: gray;">${JSON.parse(
        product.attribute_data
      )
        .map(
          (attr) => `
      ${attr.cat_name}: ${attr.name} 
    `
        )
        .join("<br>")}</div>`
    : ""
}
</td>

            </tr>
          `
                )
                .join("")
            : "<tr><td>No products available.</td></tr>"
        }
      </tbody>
      </div>
      <div style="margin-top: 10px; text-align: center; font-size: 12px;">
        <p>Thanks for visiting!</p>
      </div>
      <div style="display: flex; padding: 0 10px; justify-content: flex-end;">
        <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin-left: auto;">
          <!-- Your icon or content here -->
        </div>
      </div>
    </body>
    </html>
  `;
      // Write the HTML content to the new window
      printWindow.document.open();
      printWindow.document.write(htmlContent);
      printWindow.document.close();

      // Trigger the print dialog
      printWindow.print();
    }
  };
  const authuserData = useSelector((state) => state.auth?.user);
  const printInvoice = (orderData) => {
    const {
      companyOwnerName,
      companyPhneNumber,
      branchGstNumber,
      branchFSSAI_licence_number,
    } = user.data;

    const printWindow = window.open("", "", "height=600,width=800");
    const invoiceHtml1 = generateInvoiceHtml(orderData, authuserData);
    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(invoiceHtml1);
    printWindow.document.close();

    // Trigger the print dialog
    printWindow.print();
  };
  const generatePDFAndUpload = async (orderData) => {
    const requestBody = {
      p_pr_id: orderData.pr_id,
      p_po_id: orderData.id,
    };
    const res = await getGRNDetailService(requestBody, tokenData, dispatch);

    const originalIngredients = JSON.parse(orderData.ingredients);
    if (originalIngredients[0].ingredient_id === null) {
      toast.error("Ingredients values are null");
    } else {
      // Parse the GRN data
      const ingredientData = res.data.map((item) => {
        return JSON.parse(item.ingredients_json);
      });
      // Aggregate total received (including damage) values
      const totalValues = ingredientData.flat().reduce((acc, ingredient) => {
        const { ingredient_id } = ingredient;
        const receivedValue = parseFloat(ingredient.recived_value) || 0;
        const damageValue = parseFloat(ingredient.damage_value) || 0;

        // Initialize if the ingredient_id is not already present
        if (!acc[ingredient_id]) {
          acc[ingredient_id] = {
            ingredient_id,
            totalReceived: 0,
          };
        }

        // Sum the received and damaged values into one totalReceived
        acc[ingredient_id].totalReceived += receivedValue + damageValue;

        return acc;
      }, {});

      // Convert the aggregated totals back to an array
      const totalValuesArray = Object.values(totalValues);

      // Update original ingredients based on total received values
      const updatedIngredients = originalIngredients.map(
        (originalIngredient) => {
          const matchedTotal = totalValuesArray.find(
            (item) => item.ingredient_id === originalIngredient.ingredient_id
          );

          if (matchedTotal) {
            const originalValue = parseFloat(originalIngredient.value) || 0;
            const updatedQuantity = originalValue - matchedTotal.totalReceived;

            return {
              ...originalIngredient,
              value: updatedQuantity.toString(), // Update quantity as string
            };
          }

          return originalIngredient; // Return original ingredient if not present in GRN even for once
        }
      );

      // Filter out ingredients with a value of 0
      const finalIngredients = updatedIngredients.filter(
        (ingredient) => parseFloat(ingredient.value) !== 0
      );
      if (finalIngredients && finalIngredients.length > 0) {
        // Replace ingredients in data
        const updatedData = {
          ...orderData,
          ingredients: JSON.stringify(finalIngredients), // Replace with the final ingredients
        };
        const {
          companyLogo,
          companyName,
          companyOwnerName,
          companyPhneNumber,
        } = user.data;
        const data = JSON.parse(updatedData.ingredients);
        // Map ingredients data
        const ingredientData = data.map((item) => {
          let quantityInOriginalUnit = parseFloat(item.value);
          let unit = item.unit;

          if (
            unit === "gm" ||
            unit === "kg" ||
            unit === "l" ||
            unit === "ml" ||
            unit === "mg"
          ) {
            if (unit === "gm" && quantityInOriginalUnit > 999) {
              quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
              unit = "kg";
            } else if (unit === "gm" && quantityInOriginalUnit < 1) {
              quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
              unit = "mg";
            } else if (unit === "ml" && quantityInOriginalUnit > 999) {
              quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
              unit = "l";
            }
          }

          return {
            ingredientName: item?.ingredientTitle,
            IngredientId: item?.ingredient_id,
            quantity: quantityInOriginalUnit,
            unit: unit,
            instruction:
              item.pr_ingredient_instruction !== "null" &&
              item.pr_ingredient_instruction !== null &&
              item.pr_ingredient_instruction !== ""
                ? item.pr_ingredient_instruction
                : "",
            status: item.status,
          };
        });

        // Create HTML content
        const vendorHtmlContent = `
        <html>
        <head>
          <title>Order Details - Vendor</title>
        </head>
        <body>
          <div style="font-family: Arial, sans-serif; margin: 0; padding: 0; text-align: center; max-width: 80%; margin: 0 auto;">
            <div style="margin-top: 10px; display: flex; justify-content: space-between; align-items: flex-start;">
              <img src="${LOGO}" alt="Company Logo" style="width: 100px; height: auto;" />
              <div>
                <img src="${companyLogo}" alt="Company Logo" style="width: 100px; height: auto;" />
              </div>
            </div>
            <div style="margin-top: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <div style="text-align: left;">
                  <h5>Order Details</h5>
                  <p style="margin: 5px 0; font-size: 12px;">
                    Order No. - <strong>${updatedData?.poNum || "N/A"}</strong>
                  </p>
                  <p style="margin: 5px 0; font-size: 12px;">Name: ${
                    updatedData?.name || "N/A"
                  }</p>
                  <p style="margin: 5px 0; font-size: 12px;">Phone Number: ${
                    updatedData?.phNum || "N/A"
                  }</p>
                  <p style="margin: 5px 0; font-size: 12px;">
                    Order Date: ${
                      updatedData?.prDate
                        ? new Date(updatedData?.prDate).toLocaleString()
                        : "N/A"
                    }
                  </p>
                  ${
                    updatedData?.instructions
                      ? `
                    <p style="font-size: 12px; margin-bottom:5px">General Instruction:</p>
                    <p style="font-size: 10px;">${updatedData.instructions}</p>
                  `
                      : ""
                  }
                </div>
                <div style="text-align: end;">
                <p style="margin: 0;">Company: ${companyName}</p>
                <p style="margin: 0;">Owner: ${companyOwnerName}</p>
                <p style="margin: 0;">Phone: ${companyPhneNumber}</p>
                </div>
              </div>

              <table style="width: 100%; border-collapse: collapse; margin: 10px auto;">
                <thead>
                 <tr>
                      <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Items</th>
                      <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Quantity</th>
                      <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Unit</th>
                    
                      <th style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">Instructions</th>
                    </tr>
                </thead>
                <tbody>
                  ${
                    ingredientData.length > 0
                      ? ingredientData
                          .map(
                            (ingredient) => `
                      <tr>
                          <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                            ingredient.ingredientName
                          }</td>
                          <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                            ingredient.quantity
                          }</td>
                          <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                            ingredient.unit
                          }</td>
                          <td style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">${
                            ingredient.instruction.length > 0
                              ? ingredient.instruction
                              : "N/A"
                          }</td>
                        </tr>
                    `
                          )
                          .join("")
                      : `
                    <tr>
                      <td colspan="4" style="border: 1px solid black; padding: 5px; text-align: center; font-size: 12px;">
                        No ingredients available.
                      </td>
                    </tr>`
                  }
                </tbody>
              </table>
              <div style="margin-top: 10px; font-size: 12px;">
                <p>Thanks for your business!</p>
              </div>
              <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin: 20px auto;">
                <!-- Your icon or content here -->
              </div>
            </div>
          </div>
        </body>
        </html>
        `;

        // Create a temporary div to render the HTML content
        const tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.left = "-9999px";
        tempDiv.style.top = "-9999px";
        tempDiv.style.width = "210mm";
        tempDiv.style.height = "297mm";
        tempDiv.style.overflow = "hidden";
        tempDiv.innerHTML = vendorHtmlContent;
        document.body.appendChild(tempDiv);

        try {
          // Convert HTML to Canvas
          const canvas = await html2canvas(tempDiv, { scale: 1.5 });
          const imgData = canvas.toDataURL("image/jpeg", 0.7);
          const pdf = new jsPDF({
            orientation: "p",
            unit: "mm",
            format: "a4",
          });
          const imgWidth = 210; // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight); // Use JPEG

          // Convert PDF to Blob
          const pdfBlob = pdf.output("blob");
          const file = new File(
            [pdfBlob],
            `order-details-${updatedData?.poNum}.pdf`,
            {
              type: "application/pdf",
            }
          );
          // issue here
          // const file = new File([pdfBlob], "order-details.pdf", {
          //   type: "application/pdf",
          // });

          // Prepare upload data
          const uploadData = {
            pdf: file,
            p_vendor_phone_number: orderData.phNum,
            p_branch_user_phone_number: companyPhneNumber,
            p_branch_user_name: companyOwnerName,
            p_company_name: companyName,
            p_branch_Address: user.data.branchAddress,
          };

          dispatch(uploadPDFServicePO(uploadData, tokenData, navigate));
        } catch (error) {
          console.error("Error generating PDF or uploading:", error);
        } finally {
          // Clean up by removing the temporary div
          document.body.removeChild(tempDiv);
        }
      } else {
        toast.error("Goods Receive Notes of all Products are generated");
      }
    }
  };

  const handlePO = (data) => {
    const requestBody = PurchaseRequisitionReqBody(data, "4");
    const ingredientData = requestBody.p_product_data.filter(
      (item) => item.ingredient_status === "1"
    );
    const requestPOBody = {
      p_pr_id: requestBody.p_id,
      p_instruction: requestBody.p_instruction,
      p_product_data: ingredientData,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      p_status: "4",
    };
    dispatch(
      addUpdatePurchaseRequisitionService(
        requestBody,
        tokenData,
        "update",
        false,
        purchaseRequisitionPage
      )
    );
    dispatch(clearPurchaseOrderPageData());
    dispatch(
      addUpdatePurchaseOrderService(requestPOBody, tokenData, "add", navigate)
    );
  };
  const handleGRN = async (route, data) => {
    const requestBody = {
      p_pr_id: data.pr_id,
      p_po_id: data.id,
    };
    const res = await getGRNDetailService(requestBody, tokenData, dispatch);

    const originalIngredients = JSON.parse(data.ingredients);
    if (originalIngredients[0].ingredient_id === null) {
      toast.error("Ingredients values are null");
    } else {
      // Parse the GRN data
      const ingredientData = res.data.map((item) => {
        return JSON.parse(item.ingredients_json);
      });
      // Aggregate total received (including damage) values
      const totalValues = ingredientData.flat().reduce((acc, ingredient) => {
        const { ingredient_id } = ingredient;
        const receivedValue = parseFloat(ingredient.recived_value) || 0;
        const damageValue = parseFloat(ingredient.damage_value) || 0;

        // Initialize if the ingredient_id is not already present
        if (!acc[ingredient_id]) {
          acc[ingredient_id] = {
            ingredient_id,
            totalReceived: 0,
          };
        }

        // Sum the received and damaged values into one totalReceived
        acc[ingredient_id].totalReceived += receivedValue + damageValue;

        return acc;
      }, {});

      // Convert the aggregated totals back to an array
      const totalValuesArray = Object.values(totalValues);

      // Update original ingredients based on total received values
      const updatedIngredients = originalIngredients.map(
        (originalIngredient) => {
          const matchedTotal = totalValuesArray.find(
            (item) => item.ingredient_id === originalIngredient.ingredient_id
          );

          if (matchedTotal) {
            const originalValue = parseFloat(originalIngredient.value) || 0;
            const updatedQuantity = originalValue - matchedTotal.totalReceived;

            return {
              ...originalIngredient,
              value: updatedQuantity.toString(), // Update quantity as string
            };
          }

          return originalIngredient; // Return original ingredient if not present in GRN even for once
        }
      );

      // Filter out ingredients with a value of 0
      const finalIngredients = updatedIngredients.filter(
        (ingredient) => parseFloat(ingredient.value) !== 0
      );
      if (finalIngredients && finalIngredients.length > 0) {
        // Replace ingredients in data
        const updatedData = {
          ...data,
          ingredients: JSON.stringify(finalIngredients), // Replace with the final ingredients
        };
        // Navigate with updated data
        navigate(route, { state: { grnData: updatedData } });
      } else {
        toast.error("Goods Receive Notes of all Products are generated");
      }
    }
  };

  // const [productNewData, setProductNewData] = useState([]);
  const { productNewData, setProductNewData } = props;
  // Set initial data from props when component mounts
  useEffect(() => {
    if (props.page === "product-pricing") {
      setProductNewData(
        props.data.map((item) => ({
          id: item.id,
          skuCode: item.skuCode || "",
          price: item.price || "0.00",
          takeAwayPrice: item.takeAwayPrice || "0.00",
          zomatoPrice: item.zomatoPrice || "0.00",
          swiggyPrice: item.swiggyPrice || "0.00",
          otherPrice: item.otherPrice || "0.00",
          cgst: item.cgst || "0.00",
          sgst: item.sgst || "0.00",
        }))
      );
    }
  }, [props.data, props.page]);

  const handlePricingValue = (id, key) => {
    const newData = productNewData.find((data) => data.id === id);
    return newData[key];
  };
  const handleProductPricingUpdate = (data) => {
    const matchedData = productNewData.find((item) => item.id === data.id);
    if (matchedData.skuCode === "") {
      toast.error("SKU code value cannot be empty");
    } else if (
      matchedData.price === "" ||
      matchedData.price === 0 ||
      matchedData.price === "0"
    ) {
      toast.error("Price value cannot be empty or zero");
    } else {
      const finalProductData = {
        id: matchedData.id,
        sku_code: `SKU-${userData.user.data.companyCode}-${matchedData.skuCode}`,
        sgst: matchedData.sgst !== "" ? Number(matchedData.sgst) : 0,
        cgst: matchedData.cgst !== "" ? Number(matchedData.cgst) : 0,
        dine_in_price: Number(matchedData.price),
        take_away_price:
          matchedData.takeAwayPrice !== "0.00"
            ? Number(matchedData.takeAwayPrice)
            : 0,
        zomato_price:
          matchedData.zomatoPrice !== "0.00"
            ? Number(matchedData.zomatoPrice)
            : 0,
        swiggy_price:
          matchedData.swiggyPrice !== "0.00"
            ? Number(matchedData.swiggyPrice)
            : 0,
        other_price:
          matchedData.otherPrice !== "0.00"
            ? Number(matchedData.otherPrice)
            : 0,
      };
      const requestBody = {
        p_product_data: [finalProductData],
      };
      handlesaveClick(
        dispatch,
        updateProductPriceService(requestBody, tokenData, "update", navigate),
        "update"
      );
    }
  };

  const handleInputChange = (rowIndex, key, value) => {
    const updatedData = [...productNewData];
    updatedData[rowIndex][key] = value;
    setProductNewData(updatedData);
  };

  const handleOnInput = (e, limit) => {
    // Limit the input length to 10 characters
    if (e.target.value.length > limit) {
      e.target.value = e.target.value.slice(0, limit);
    }
  };

  const handleKeyDown = (e) => {
    // Allow common navigation and function keys
    const allowedKeys = [
      "Backspace",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    // If the user presses a Ctrl key combination, allow it
    if (e.ctrlKey) {
      return; // Allow Ctrl + [any key] actions, including Ctrl+A, Ctrl+C, Ctrl+V, etc.
    }

    // Check for valid number characters, decimal, and allowed special keys
    const isValidKey =
      /^\d$/.test(e.key) || e.key === "." || allowedKeys.includes(e.key);

    // Prevent multiple decimal points
    if (e.key === "." && e.target.value.includes(".")) {
      e.preventDefault();
    }

    // Prevent invalid keys (not numbers, decimal, or allowed special keys)
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (props.totalRecords && props.totalRecords !== 0) {
      const newArray = Array.from(
        { length: props.totalRecords },
        (_, index) => ({ value: index + 1, label: String(index + 1) })
      );
      setSequenceArray(newArray);
      setPaymentSequenceArray(newArray);
    } else {
      if (tableData && tableData.length > 0) {
        const newArray = Array.from(
          { length: tableData.length },
          (_, index) => ({ value: index + 1, label: String(index + 1) })
        );
        setSequenceArray(newArray);
        setPaymentSequenceArray(newArray);
      }
    }
  }, [tableData, props.totalRecords]);

  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  //Checkbox change on checking from header
  const handleHeaderCheckboxChange = (event) => {
    const { checked } = event.target;
    setSelectAllCheck(checked);
    const updatedSelectedItems = checked
      ? tableData.map((_, index) => index)
      : [];
    setSelectedItems(updatedSelectedItems);
  };

  //Checkbox cheange on checking from body
  const handleRowCheckboxChange = (index, event) => {
    const { checked } = event.target;
    let updatedSelectedItems = [...selectedItems];
    if (checked) {
      if (props.restrictOneImage && props.restrictOneImage === true) {
        if (updatedSelectedItems.length >= 1) {
          // If maximum limit reached, uncheck the first checked image
          updatedSelectedItems.shift();
          updatedSelectedItems.push(index);
        } else {
          updatedSelectedItems.push(index);
        }
      } else {
        if (props.restrictFive === true && updatedSelectedItems.length >= 5) {
          // If maximum limit reached, uncheck the first checked image
          toast.warn("Only 5 images are allowed!");
        } else {
          updatedSelectedItems.push(index);
        }
      }
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(
        (item) => item !== index
      );
    }
    if (props.page === "uploadImage") {
      const filteredTableData =
        tableData &&
        tableData.filter((item, index) => {
          return updatedSelectedItems && updatedSelectedItems.includes(index);
        });
      props.onCheckChange({
        filterData: filteredTableData,
        errorImageList: imageErrors,
      });
    }
    setSelectedItems(updatedSelectedItems);
    setSelectAllCheck(updatedSelectedItems.length === tableData.length);
    dispatch(getCheckboxData({ data: updatedSelectedItems }));
  };

  /**
   * Function for updating sequence in category and sub-category
   * @param {*} event
   * @param {*} item
   */

  // changes by sakshi
  /**
   * Functions for total amount val in onChange and  changeOrderPaymentStatus
   * @param {*} event
   * @param {*} item
   */

  // function parseCurrency(value) {
  //   return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  // }
  // function formatCurrency(value) {
  //   return `₹${value.toFixed(2)}`;
  // }
  const onChange = (event, item) => {
    if (props.page === "category") {
      const requestBody = {
        p_Id: item.id,
        p_NewSequence: parseInt(event.target.value),
        p_Type: "category",
      };
      dispatch(
        updateSequenceService(requestBody, tokenData, categoryPageData + 1)
      );
    } else if (props.page === "subCategory") {
      const requestBody = {
        p_Id: item.id,
        p_NewSequence: parseInt(event.target.value),
        p_Type: "sub_category",
      };
      dispatch(
        updateSequenceService(requestBody, tokenData, SubCategoryPage + 1)
      );
    } else if (props.page === "order") {
      const requestBody = OrderRequestBodyOnToggle({
        item: item,
        status: event.target.value,
      });
      
      let orderStatusData =
        event.target.value === "0"
          ? "Pending"
          : event.target.value === "1"
          ? "Confirm"
          : event.target.value === "2"
          ? "In-progress"
          : event.target.value === "3"
          ? "Complete"
          : event.target.value === "4"
          ? "Cancel"
          : "";

      if (orderStatusData === "Confirm" || orderStatusData === "Cancel") {
        handleToggleChange(
          dispatch,
          "order",
          orderStatusData,
          tokenData,
          user.data.userId,
          requestBody,
          tableData.length,
          orderPage
        );
      } else {
        dispatch(
          placeOrderService(requestBody, tokenData, "update", false, orderPage)
        );
      }
    } else if (props.page === "vendors") {
      const requestBody = buildVendorRequestBody(
        item,
        null,
        event.target.value
      );

      // dispatch call
      dispatch(
        addUpdateVendorService(
          requestBody,
          tokenData,
          "update",
          false,
          orderPage
        )
      );
    } else if (props.page === "purchase-requisition") {
      const requestBody = PurchaseRequisitionReqBody(item, event.target.value);
      dispatch(
        addUpdatePurchaseRequisitionService(
          requestBody,
          tokenData,
          "update",
          false,
          purchaseRequisitionPage
        )
      );
    }
  };

  const changeOrderPaymentStatus = (event, item) => {
    if (props.page === "order") {
      if (event.target.value === "5" || event.target.value === "6") {
        setZomatoSwiggyModal(!zomatoSwiggyModal);
        setInitialValues({
          zomatoSwiggyPrice: "",
          payment: event.target.value,
          item: item,
        });
      } else {
        const requestBody = OrderRequestBodyOnToggle({
          item: item,
          payment: event.target.value,
        });
        dispatch(
          placeOrderService(
            requestBody,
            tokenData,
            "update",
            false,
            orderPage,
            true
          )
        );
      }
    } else if (props.page === "vendors") {
      const requestBody = buildVendorRequestBody(
        item,
        event.target.value,
        null
      );

      // dispatch call
      dispatch(
        addUpdateVendorService(
          requestBody,
          tokenData,
          "update",
          false,
          orderPage,
          true
        )
      );
    }
  };
  const [initialValues, setInitialValues] = useState(() => {
    if (props.page === "order") {
      return { zomatoSwiggyPrice: "" };
    } else {
      return {
        /* other initial values here */
      };
    }
  });
  const [validationSchema, setValidationSchema] = useState(() =>
    Yup.object({})
  );

  useEffect(() => {
    if (props.page === "order") {
      setValidationSchema(() =>
        Yup.object({
          zomatoSwiggyPrice: Yup.string().required(
            ValidationMessage.FIELD_REQUIRED
          ),
        })
      );
    } else {
      setValidationSchema(() => Yup.object({}));
    }
  }, [props.page]);

  const [zomatoSwiggyModal, setZomatoSwiggyModal] = useState(false);

  const handleSubmit = (values) => {
    const requestBody = OrderRequestBodyOnToggle({
      item: values.item,
      payment: values.payment,
      zomatoSwiggyPrice: values.zomatoSwiggyPrice,
    });

    dispatch(
      placeOrderService(
        requestBody,
        tokenData,
        "update",
        false,
        orderPage,
        true
      )
    );
  };

  const deleteLineLevelProduct = (item) => {
    handleLineLevelOrderDeleteClick(
      item,
      tokenData,
      dispatch,
      props.newOrderData
    );
  };

  const orderStatusArray =
    props.page === "vendors"
      ? [
          {
            id: 1,
            value: "0",
            status: "Pending",
          },
          {
            id: 2,
            value: "1",
            status: "Completed",
          },
          {
            id: 3,
            value: "2",
            status: "Cancelled",
          },
        ]
      : props.page === "purchase-requisition"
      ? [
          {
            id: 1,
            value: "0",
            status: "Pending",
          },
          {
            id: 2,
            value: "1",
            status: "Approved",
          },
          {
            id: 3,
            value: "2",
            status: "Partially Approved",
          },
          {
            id: 4,
            value: "3",
            status: "Rejected",
          },
          {
            id: 5,
            value: "4",
            status: "PO Generated",
          },
        ]
      : [
          {
            id: 1,
            value: "0",
            status: "Pending",
          },
          {
            id: 2,
            value: "1",
            status: "Confirmed",
          },
          {
            id: 3,
            value: "2",
            status: "In Progress",
          },
          {
            id: 4,
            value: "3",
            status: "Completed",
          },
          {
            id: 5,
            value: "4",
            status: "Cancelled",
          },
        ];

  const [orderPaymentArray, setOrderPaymentArray] = useState([]);

  useEffect(() => {
    if (props.page === "vendors" && orderPaymentTableArray.length > 0) {
      const paymentArray = orderPaymentTableArray.filter(
        (item) => item.value !== "5" && item.value !== "6"
      );
      setOrderPaymentArray(paymentArray);
    } else {
      setOrderPaymentArray(orderPaymentTableArray);
    }
    // eslint-disable-next-line
  }, [props.page, orderPaymentTableArray]);

  return (
    <>
      <div className="py-3">
        {(!tableData || tableData.length <= 0) && loader === false && (
          <>
            <div className="input-search">
              <div className="no-record">
                {props.page === "import" ? (
                  <h3
                    data-bs-toggle="tooltip"
                    title="No records!"
                    align="center"
                  >
                    Imported data might be in wrong format or too long!
                  </h3>
                ) : (
                  <h3 data-bs-toggle="tooltip" title="No records!">
                    No records!
                  </h3>
                )}
              </div>
            </div>
          </>
        )}
        {tableData && tableData.length > 0 && (
          <>
            <Table className="pt-3 custom-table" bordered responsive>
              <thead>
                <tr>
                  {props.checkBool &&
                    props.page !== "uploadImage" &&
                    props.page !== "product" && (
                      <th
                        style={{
                          width: "50px",
                          minWidth: "50px",
                          maxWidth: "50px",
                        }}
                      >
                        <Checkbox
                          name="checkHead"
                          className="table-checkbox"
                          checked={selectAllCheck}
                          onChange={handleHeaderCheckboxChange}
                        ></Checkbox>
                      </th>
                    )}

                  {props.checkBool && props.page === "product" && (
                    <th
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                      }}
                    >
                      <input
                        type="checkbox"
                        name="checkHead"
                        className="table-checkbox"
                        checked={selectAllCheck}
                        onChange={handleHeaderCheckboxChange}
                      ></input>
                    </th>
                  )}

                  {props.checkBool && props.page === "uploadImage" && (
                    <th
                      style={{
                        width: "50px",
                        minWidth: "50px",
                        maxWidth: "50px",
                        backgroundColor: "#234047",
                        color: "#fff",
                      }}
                    ></th>
                  )}
                  {heading.map((item, index) => {
                    const headingStyle =
                      props?.styleData && props.styleData[item.name]
                        ? props.styleData[item.name]?.type || {}
                        : {};

                    const commonStyle = {
                      textAlign: "center",
                      backgroundColor: "#234047",
                      color: "#fff",
                    };

                    const actionStyle =
                      item.name === "Actions"
                        ? {
                            width: "200px",
                            maxWidth: "200px",
                            minWidth: "200px",
                          }
                        : {};

                    return (
                      <th
                        key={index}
                        style={{
                          ...commonStyle,
                          ...actionStyle,
                          ...headingStyle,
                        }}
                      >
                        {item.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((item, index) => (
                    <tr key={index}>
                      {props.checkBool && props.page !== "product" && (
                        <td
                          key={`checkbox_${index}`}
                          style={{
                            width: "50px",
                            minWidth: "50px",
                            maxWidth: "50px",
                          }}
                        >
                          <Checkbox
                            name={"check" + index}
                            className="table-checkbox"
                            checked={selectedItems.includes(index)}
                            onChange={(event) =>
                              handleRowCheckboxChange(index, event)
                            }
                          />
                        </td>
                      )}
                      {props.checkBool && props.page === "product" && (
                        <td key={`product_checkbox_${index}`}>
                          <input
                            type="checkbox"
                            name={"check" + index}
                            className="table-checkbox"
                            checked={selectedItems.includes(index)}
                            onChange={(event) =>
                              handleRowCheckboxChange(index, event)
                            }
                          />
                        </td>
                      )}
                      {Object.keys(item).map((key, subIndex) => {
                        if (
                          key === "allow" ||
                          key === "add" ||
                          key === "view" ||
                          key === "edit" ||
                          key === "delete"
                        ) {
                          return (
                            <td
                              key={subIndex}
                              // style={{
                              //   width: "120px",
                              //   minWidth: "120px",
                              //   maxWidth: "120px",
                              // }}
                              data-bs-toggle={
                                props.showTooltip ? "tooltip" : undefined
                              }
                              title={
                                props.showTooltip
                                  ? item[key] === true
                                    ? "active"
                                    : "inactive"
                                  : undefined
                              }
                            >
                              <Toggle
                                disabled={
                                  (props.disabled === true &&
                                    (props.page === "addRoles" ||
                                      props.page === "add-company")) ||
                                  (disableArray &&
                                    disableArray.includes &&
                                    disableArray.includes(item.name) &&
                                    key !== "allow" &&
                                    key !== "selectAll")
                                }
                                value={
                                  item[key] === "1"
                                    ? 1
                                    : item[key] === "0"
                                    ? 0
                                    : item[key]
                                }
                                onChange={(e) =>
                                  handleToggle(item.name, key, e.target.checked)
                                }
                              />
                            </td>
                          );
                        } else if (key === "banner" || key === "imageUrl") {
                          return (
                            <td key={subIndex}>
                              {imageErrors.includes(item.imageId) ? (
                                <img src={ErrorImage} alt="broken_image" />
                              ) : (
                                <img
                                  src={
                                    item.banner ||
                                    URL.createObjectURL(item.imageUrl)
                                  }
                                  alt="banner"
                                  onError={() => handleImageError(item.imageId)}
                                />
                              )}
                            </td>
                          );
                        } else if (
                          (key === "image" || key === "imagePath") &&
                          props.showImage
                        ) {
                          if (
                            item.image &&
                            item.image.length > 0 &&
                            item.image[0].imageUrl
                          ) {
                            return (
                              <td key={subIndex}>
                                {imageErrors.includes(
                                  item.image[index].imageId
                                ) ? (
                                  <img src={ErrorImage} alt="broken_image" />
                                ) : (
                                  <img
                                    src={URL.createObjectURL(
                                      item.image[0].imageUrl
                                    )}
                                    alt="data"
                                    onError={() =>
                                      handleImageError(
                                        item.image[index].imageId
                                      )
                                    }
                                  />
                                )}
                              </td>
                            );
                          } else if (item.imagePath) {
                            return (
                              <td key={subIndex}>
                                {imageErrors.includes(item.imageId) ? (
                                  <img src={ErrorImage} alt="broken_image" />
                                ) : (
                                  <img
                                    src={API_URL.TOKEN_URL + item.imagePath}
                                    alt="data"
                                    onError={() =>
                                      handleImageError(item.imageId)
                                    }
                                  />
                                )}
                              </td>
                            );
                          } else {
                            return (
                              <td key={subIndex}>
                                {/* <img alt="data" /> */}
                                <p>No image to display</p>
                              </td>
                            );
                          }
                        } else if (
                          key === "description" ||
                          key === "question" ||
                          key === "answer" ||
                          key === "Description"
                        ) {
                          if (showDescription) {
                            const isError =
                              item?.message && item.message !== "Done"
                                ? true
                                : false;
                            return (
                              <td
                                key={subIndex}
                                className={isError ? "error-cell" : undefined}
                              >
                                {item[key] && typeof item[key] === "string"
                                  ? new DOMParser().parseFromString(
                                      item[key],
                                      "text/html"
                                    ).body.innerText
                                  : item[key]}
                              </td>
                            );
                          } else {
                            return null;
                          }
                        } else if (key === "id" || key === "parent_id") {
                          if (showId) {
                            const isError =
                              item?.message && item.message !== "Done"
                                ? true
                                : false;
                            return (
                              <td
                                key={subIndex}
                                className={isError ? "error-cell" : undefined}
                              >
                                {item[key]}
                              </td>
                            );
                          }
                          return null;
                        } else if (props.showKey[key]) {
                          const displayValue = Array.isArray(item[key])
                            ? item[key].join(", ")
                            : item[key];
                          const isError =
                            item?.message && item.message !== "Done"
                              ? true
                              : false;

                          //If key = sequence
                          if (key === "sequence") {
                            const currentSelectedValue =
                              sequenceArray && sequenceArray.length > 0
                                ? sequenceArray.find(
                                    (option) => option.value === item.sequence
                                  )?.value || item.sequence
                                : item.sequence; //Current Index
                            if (selectSequenceData !== currentSelectedValue) {
                              return (
                                //Sequence dropdown open on click
                                <>
                                  {(props.page === "category" &&
                                    categoryFilter.isDelete === "1") ||
                                  (props.page === "subCategory" &&
                                    SubCategoryFilter.isDelete === "1") ? (
                                    <td
                                      key={item.sequence}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? displayValue
                                          : undefined
                                      }
                                    >
                                      <p>{currentSelectedValue}</p>
                                    </td>
                                  ) : (
                                    <td
                                      onClick={() =>
                                        setSelectSequenceData(
                                          currentSelectedValue
                                        )
                                      }
                                      key={item.sequence}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? displayValue
                                          : undefined
                                      }
                                    >
                                      <p>{currentSelectedValue}</p>
                                    </td>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                //Sequence dropdown close on change
                                <td key={item.sequence}>
                                  <div className="select-dropdown">
                                    <select
                                      onChange={(event) =>
                                        onChange(event, item)
                                      }
                                      value={currentSelectedValue}
                                    >
                                      <option disabled={true}>Select...</option>
                                      {sequenceArray.map(
                                        (item, sequenceIndex) => (
                                          <option
                                            key={sequenceIndex}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                              );
                            }
                          }

                          if (props.page === "product-pricing") {
                            if (key === "skuCode") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        key,
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => handleOnInput(e, 5)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "price") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => handleOnInput(e, 10)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "takeAwayPrice") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "takeAwayPrice",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => handleOnInput(e, 10)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "zomatoPrice") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "zomatoPrice",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => handleOnInput(e, 10)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "swiggyPrice") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "swiggyPrice",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => handleOnInput(e, 10)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "otherPrice") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "otherPrice",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => handleOnInput(e, 10)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "cgst") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "cgst",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => {
                                      let value = e.target.value;

                                      // Regex to allow up to 100 before the decimal and up to 3 digits after the decimal
                                      const regex =
                                        /^(100(\.\d{0,3})?|(\d{0,2})(\.\d{0,3})?)$/;

                                      // Apply the regex to the input value
                                      const matchedValue = value.match(regex);

                                      if (matchedValue) {
                                        // Update the value only if it matches the condition
                                        e.target.value = matchedValue[0];
                                      } else {
                                        // If the input doesn't match, keep the original value (or truncate)
                                        e.target.value = value.slice(0, -1); // remove the last invalid character
                                      }
                                    }}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            } else if (key === "sgst") {
                              return (
                                <td>
                                  <input
                                    className="pricing-input"
                                    placeholder="enter"
                                    value={handlePricingValue(item.id, key)}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "sgst",
                                        e.target.value
                                      )
                                    }
                                    onInput={(e) => {
                                      let value = e.target.value;

                                      // Regex to allow up to 100 before the decimal and up to 3 digits after the decimal
                                      const regex =
                                        /^(100(\.\d{0,3})?|(\d{0,2})(\.\d{0,3})?)$/;

                                      // Apply the regex to the input value
                                      const matchedValue = value.match(regex);

                                      if (matchedValue) {
                                        // Update the value only if it matches the condition
                                        e.target.value = matchedValue[0];
                                      } else {
                                        // If the input doesn't match, keep the original value (or truncate)
                                        e.target.value = value.slice(0, -1); // remove the last invalid character
                                      }
                                    }}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                  />
                                </td>
                              );
                            }
                          }

                          if (key === "orderStatus") {
                            const selectedValue =
                              sequenceArray && sequenceArray.length > 0
                                ? sequenceArray.find(
                                    (option) => option?.value === index + 1
                                  )?.value
                                : index + 1; //Current Index
                            if (selectSequenceData !== selectedValue) {
                              return (
                                <>
                                  {(props.page === "order" &&
                                    item.orderStatus !== "3" &&
                                    item.orderStatus !== "4") ||
                                  (props.page === "vendors" &&
                                    item.orderStatus !== "1" &&
                                    item.orderStatus !== "2") ||
                                  (props.page === "purchase-requisition" &&
                                    item.orderStatus !== "4" &&
                                    item.orderStatus !== "3" &&
                                    item.orderStatus !== "1" &&
                                    item.orderStatus !== "2" &&
                                    item.orderStatus !== "0") ? (
                                    <td
                                      onClick={() =>
                                        setSelectSequenceData(index + 1)
                                      }
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? props.page ===
                                            "purchase-requisition"
                                            ? item.orderStatus === "0"
                                              ? "Pending"
                                              : item.orderStatus === "1"
                                              ? "Approved"
                                              : item.orderStatus === "2"
                                              ? "Partially Approved"
                                              : item.orderStatus === "3"
                                              ? "Rejected"
                                              : item.orderStatus === "4"
                                              ? "PO Generated"
                                              : item.orderStatus
                                            : item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Confirmed"
                                            : item.orderStatus === "2"
                                            ? "In-progress"
                                            : item.orderStatus === "3"
                                            ? "Completed"
                                            : item.orderStatus === "4"
                                            ? "Cancelled"
                                            : item.orderStatus
                                          : undefined
                                      }
                                      className={
                                        props.page === "vendors"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Completed"
                                            : item.orderStatus === "2"
                                            ? "Cancelled"
                                            : ""
                                          : props.page ===
                                            "purchase-requisition"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Approved"
                                            : item.orderStatus === "2"
                                            ? "PartiallyApproved"
                                            : item.orderStatus === "3"
                                            ? "Rejected"
                                            : "POGenerated"
                                          : item.orderStatus === "0"
                                          ? "Pending"
                                          : item.orderStatus === "1"
                                          ? "Confirmed"
                                          : item.orderStatus === "2"
                                          ? "In-progress"
                                          : item.orderStatus === "3"
                                          ? "Completed"
                                          : "Cancelled"
                                      }
                                    >
                                      <p
                                        className={`${
                                          props.page === "purchase-requisition"
                                            ? "status-new"
                                            : "status-bg"
                                        }`}
                                        style={{ margin: 0 }}
                                      >
                                        {props.page === "vendors"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Completed"
                                            : "Cancelled"
                                          : props.page ===
                                            "purchase-requisition"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Approved"
                                            : item.orderStatus === "2"
                                            ? "Partially Approved"
                                            : item.orderStatus === "3"
                                            ? "Rejected"
                                            : "PO Generated"
                                          : item.orderStatus === "0"
                                          ? "Pending"
                                          : item.orderStatus === "1"
                                          ? "Confirmed"
                                          : item.orderStatus === "2"
                                          ? "In Progress"
                                          : item.orderStatus === "3"
                                          ? "Completed"
                                          : "Cancelled"}
                                      </p>
                                    </td>
                                  ) : (
                                    <td
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? props.page ===
                                            "purchase-requisition"
                                            ? item.orderStatus === "0"
                                              ? "Pending"
                                              : item.orderStatus === "1"
                                              ? "Approved"
                                              : item.orderStatus === "2"
                                              ? "Partially Approved"
                                              : item.orderStatus === "3"
                                              ? "Rejected"
                                              : item.orderStatus === "4"
                                              ? "PO Generated"
                                              : item.orderStatus
                                            : item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Confirmed"
                                            : item.orderStatus === "2"
                                            ? "In-progress"
                                            : item.orderStatus === "3"
                                            ? "Completed"
                                            : item.orderStatus === "4"
                                            ? "Cancelled"
                                            : item.orderStatus
                                          : undefined
                                      }
                                      className={
                                        props.page === "vendors"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Completed"
                                            : item.orderStatus === "2"
                                            ? "Cancelled"
                                            : ""
                                          : props.page ===
                                            "purchase-requisition"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Approved"
                                            : item.orderStatus === "2"
                                            ? "PartiallyApproved"
                                            : item.orderStatus === "3"
                                            ? "Rejected"
                                            : "POGenerated"
                                          : item.orderStatus === "0"
                                          ? "Pending"
                                          : item.orderStatus === "1"
                                          ? "Confirmed"
                                          : item.orderStatus === "2"
                                          ? "In Progress"
                                          : item.orderStatus === "3"
                                          ? "Completed"
                                          : "Cancelled"
                                      }
                                    >
                                      <p
                                        className={`${
                                          props.page === "purchase-requisition"
                                            ? "status-new"
                                            : "status-bg"
                                        }`}
                                        style={{ margin: 0 }}
                                      >
                                        {props.page === "vendors"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Completed"
                                            : "Cancelled"
                                          : props.page ===
                                            "purchase-requisition"
                                          ? item.orderStatus === "0"
                                            ? "Pending"
                                            : item.orderStatus === "1"
                                            ? "Approved"
                                            : item.orderStatus === "2"
                                            ? "Partially Approved"
                                            : item.orderStatus === "3"
                                            ? "Rejected"
                                            : "PO Generated"
                                          : item.orderStatus === "0"
                                          ? "Pending"
                                          : item.orderStatus === "1"
                                          ? "Confirmed"
                                          : item.orderStatus === "2"
                                          ? "In Progress"
                                          : item.orderStatus === "3"
                                          ? "Completed"
                                          : "Cancelled"}
                                      </p>
                                    </td>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                //Sequence dropdown close on change
                                <td key={subIndex}>
                                  <div className="select-dropdown">
                                    <select
                                      value={item.orderStatus}
                                      onChange={(event) =>
                                        onChange(event, item)
                                      }
                                    >
                                      <option disabled={true}>Select...</option>
                                      {orderStatusArray.map(
                                        (item, statusIndex) => (
                                          <option
                                            key={statusIndex}
                                            value={item.value}
                                          >
                                            {item.status}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                              );
                            }
                          }

                          if (key === "payment") {
                            const selectedValue =
                              paymentSequenceArray &&
                              paymentSequenceArray.length > 0
                                ? paymentSequenceArray.find(
                                    (option) => option?.value === index + 1
                                  )?.value
                                : index + 1; //Current Index
                            if (selectPaymentSequenceData !== selectedValue) {
                              return (
                                //Sequence dropdown open on click
                                <>
                                  {(props.page === "order" &&
                                    item.orderStatus !== "4" &&
                                    (item.orderStatus !== "3" ||
                                      item.payment === "0") && item.type !== '2') ||
                                  (props.page === "vendors" &&
                                    item.orderStatus !== "2" &&
                                    (item.orderStatus !== "1" ||
                                      item.payment === "0")) ? (
                                    <td
                                      onClick={() =>
                                        setPaymentSelectSequenceData(index + 1)
                                      }
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? orderPaymentTableArray.find(
                                              (order) =>
                                                order.value === item.payment
                                            ).status
                                          : undefined
                                      }
                                      className={
                                        item.payment === "0"
                                          ? "Cancelled"
                                          : "Completed"
                                      }
                                    >
                                      <p
                                        className="status-bg"
                                        style={{ margin: 0 }}
                                      >
                                        {
                                          orderPaymentTableArray.find(
                                            (order) =>
                                              order.value === item.payment
                                          ).status
                                        }
                                      </p>
                                    </td>
                                  ) : (
                                    <td
                                      key={subIndex}
                                      data-bs-toggle={
                                        props.showTooltip && key !== "actions"
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip && key !== "actions"
                                          ? orderPaymentTableArray.find(
                                              (order) =>
                                                order.value === item.payment
                                            ).status
                                          : undefined
                                      }
                                      className={
                                        item.payment === "0"
                                          ? "Cancelled"
                                          : "Completed"
                                      }
                                    >
                                      <p
                                        className="status-bg"
                                        style={{ margin: 0 }}
                                      >
                                        {
                                          orderPaymentTableArray.find(
                                            (orderItem) =>
                                              orderItem.value === item.payment
                                          ).status
                                        }
                                      </p>
                                    </td>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                //Sequence dropdown close on change
                                <td key={subIndex}>
                                  <div className="select-dropdown">
                                    <select
                                      value={item.payment}
                                      onChange={(event) =>
                                        changeOrderPaymentStatus(event, item)
                                      }
                                    >
                                      <option disabled={true}>Select...</option>
                                      {orderPaymentArray &&
                                      orderPaymentArray.length > 0
                                        ? orderPaymentArray.map(
                                            (paymentItem, statusIndex) => {
                                              return (
                                                <option
                                                  key={statusIndex}
                                                  value={paymentItem.value}
                                                >
                                                  {paymentItem.status}
                                                </option>
                                              );
                                            }
                                          )
                                        : orderPaymentTableArray.map(
                                            (item, statusIndex) => (
                                              <option
                                                key={statusIndex}
                                                value={item.value}
                                              >
                                                {item.status}
                                              </option>
                                            )
                                          )}
                                    </select>
                                  </div>
                                </td>
                              );
                            }
                          }

                          if (
                            key === "image" ||
                            key === "banner_Image" ||
                            key === "logo_image"
                          ) {
                            return (
                              <td
                                key={subIndex}
                                className={isError ? "error-cell" : undefined}
                                data-bs-toggle={
                                  props.showTooltip && key !== "actions"
                                    ? "tooltip"
                                    : undefined
                                }
                                title={
                                  props.showTooltip && key !== "actions"
                                    ? displayValue
                                    : undefined
                                }
                              >
                                {notificationImageErrors.includes(item[key]) ? (
                                  <img src={ErrorImage} alt="broken_image" />
                                ) : (
                                  <img
                                    src={
                                      API_URL.TOKEN_URL +
                                      "uploads/images/" +
                                      item[key]
                                    }
                                    alt={item[key]}
                                    onError={() =>
                                      handleNotificationImageError(item[key])
                                    }
                                  />
                                )}
                              </td>
                            );
                          }

                          if (
                            key === "orderType" &&
                            props.page === "StockHistory"
                          ) {
                            if (
                              item.orderType === "0" ||
                              item.orderType === "null" ||
                              item.orderType === null
                            ) {
                              return (
                                <td key={subIndex} className="Completed">
                                  {"ADD"}
                                </td>
                              );
                            } else if (item.orderType === "1") {
                              return (
                                <td key={subIndex} className="Cancelled">
                                  {"USED"}
                                </td>
                              );
                            } else if (item.orderType === "2") {
                              return (
                                <td key={subIndex} className="Waste">
                                  {"WASTE"}
                                </td>
                              );
                            }
                          }
                          const childStyle =
                            props?.styleData && props.styleData[key]
                              ? props.styleData[key].type || {}
                              : {};
                          return (
                            <td
                              key={subIndex}
                              data-bs-toggle={
                                props.showTooltip && key !== "actions"
                                  ? "tooltip"
                                  : undefined
                              }
                              title={
                                props.showTooltip && key !== "actions"
                                  ? displayValue
                                  : undefined
                              }
                              className={
                                isError
                                  ? "error-cell"
                                  : item.productStatus !== 0 &&
                                    item.productStatus !== "0"
                                  ? "product-status-color"
                                  : "width-data"
                              }
                              style={childStyle}
                            >
                              {displayValue}
                            </td>
                          );
                        } else {
                          return null;
                        }
                      })}
                      {
                        //Select All Toggle
                        props.showSelectAll && (
                          <td
                          // style={{
                          //   width: "120px",
                          //   minWidth: "120px",
                          //   maxWidth: "120px",
                          // }}
                          >
                            <Toggle
                              disabled={
                                props.disabled === true &&
                                (props.page === "addRoles" ||
                                  props.page === "add-company")
                              }
                              value={
                                selectAllValue &&
                                selectAllValue.includes &&
                                selectAllValue.includes(item.name)
                                  ? 1
                                  : 0
                              }
                              onChange={(e) =>
                                handleToggle(
                                  item.name,
                                  "selectAll",
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                        )
                      }
                      {props.showToggle && (
                        <>
                          {props.showToggle.status && (
                            <td
                              // style={{
                              //   width: "80px",
                              //   minWidth: "80px",
                              //   maxWidth: "80px",
                              // }}
                              key={index}
                            >
                              <Toggle
                                disabled={
                                  props.disabled === true ||
                                  (props.showActions.showUndo &&
                                    (item.is_delete === "1" ||
                                      item.IsDeleted === "1" ||
                                      item.isDelete === "1" ||
                                      item.isDeleted === "1" ||
                                      item.is_deleted === "1"))
                                }
                                value={
                                  (item.status === 1 || item.status === "1"
                                    ? 1
                                    : 0) ||
                                  (item.isActive === 1 || item.isActive === "1"
                                    ? 1
                                    : 0) ||
                                  (item.active === "1" || item.active === 1
                                    ? 1
                                    : 0) ||
                                  (item.is_active === "1" ||
                                  item.is_active === 1
                                    ? 1
                                    : 0) ||
                                  false
                                }
                                onChange={(e) =>
                                  handleToggle(item, "status", e.target.checked)
                                }
                              />
                            </td>
                          )}
                        </>
                      )}
                      {props.showActions && (
                        <td
                          style={
                            props?.styleData?.Actionstoggle
                              ? props.styleData.Actionstoggle.type
                              : {
                                  width: "200px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                }
                          }
                          className="actions"
                        >
                          <div className="action-icon">
                            {props.showActions.showView &&
                              ((props.showActions.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                props.showActions.showUndo === null ||
                                props.showActions.showUndo === undefined) && (
                                <FaEye
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={props.showTooltip ? "view" : undefined}
                                  onClick={() =>
                                    handleNavigate(
                                      props.showActions.navigate,
                                      item,
                                      false
                                    )
                                  }
                                  className="icon-color"
                                />
                              )}
                            {props.showActions.showEdit &&
                              ((props.page === "order" &&
                                item.orderStatus !== "3" &&
                                item.orderStatus !== "4" &&
                                item.type !== "2") ||
                                (props.page === "vendors" &&
                                  item.orderStatus !== "1" &&
                                  item.orderStatus !== "2") ||
                                (props.page === "purchase-requisition" &&
                                  item.orderStatus !== "3" &&
                                  item.orderStatus !== "4" &&
                                  item.orderStatus !== "1" &&
                                  item.orderStatus !== "2") ||
                                (props.page !== "order" &&
                                  props.page !== "vendors" &&
                                  props.page !== "purchase-requisition")) &&
                              ((props.showActions.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                props.showActions.showUndo === null ||
                                props.showActions.showUndo === undefined) && (
                                <MdEdit
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={props.showTooltip ? "edit" : undefined}
                                  onClick={() =>
                                    handleNavigate(
                                      props.showActions.navigate,
                                      item,
                                      true
                                    )
                                  }
                                  className="icon-color"
                                />
                              )}
                            {props.showActions.showDelete &&
                              ((props.showActions.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                props.showActions.showUndo === null ||
                                props.showActions.showUndo === undefined) && (
                                <MdDelete
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "delete" : undefined
                                  }
                                  className="icon-color"
                                  onClick={() => handleDeleteClick(item)}
                                />
                              )}
                            {props.showActions.showReview &&
                              ((props.showActions.showUndo &&
                                (item.is_delete === "0" ||
                                  item.IsDeleted === "0" ||
                                  item.isDelete === "0" ||
                                  item.isDeleted === "0")) ||
                                props.showActions.showUndo === null ||
                                props.showActions.showUndo === undefined) && (
                                <FaStarHalfAlt
                                  onClick={() =>
                                    navigate("/product-review-management")
                                  }
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "Review" : undefined
                                  }
                                  className="icon-color"
                                />
                              )}
                            {props.showActions.showUndo &&
                              (item.is_delete === "1" ||
                                item.isDeleted === "1" ||
                                item.IsDeleted === "1" ||
                                item.isDelete === "1") && (
                                <GrUndo
                                  onClick={() => handleUndoClick(item)}
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={props.showTooltip ? "undo" : undefined}
                                  className="icon-color"
                                />
                              )}
                            {props.showActions.showPlus && (
                              <FaPlus
                                data-bs-toggle={
                                  props.showTooltip ? "tooltip" : undefined
                                }
                                title={props.showTooltip ? "Plus" : undefined}
                                onClick={() =>
                                  handleNavigate(
                                    props.showActions.navigate,
                                    item,
                                    true
                                  )
                                }
                                className="icon-color"
                              />
                            )}
                            {props.showActions.showPrint &&
                              props.page !== "spots" && (
                                <IoMdPrint
                                  className="icon-color"
                                  onClick={() => printOrderDetails(item)}
                                />
                              )}

                            {props.showActions.showInvoice && (
                              <IoMdPrint
                                className="icon-color invoice-print-icon"
                                onClick={() => printInvoice(item)}
                              />
                            )}
                            {props.showActions.showKOTPrint && (
                              <Button
                                data-bs-toggle="tooltip"
                                title="KOT"
                                onClick={() => printOrderDetails(item)}
                                className="KOT-btn"
                                name={<p className="kot-icon">KOT</p>}
                              ></Button>
                            )}
                            {/* changes of spots */}
                            {props.showActions.showPrint &&
                              props.page === "spots" && (
                                <FaQrcode
                                  className="icon-color"
                                  // onClick={() => props.getBrand(item)}
                                  onClick={() => props.QRCodeDetail(item)}
                                />
                              )}
                            {/* {props.showActions.showWp &&
                              props.page === "vendors" && (
                                <>
                                  {item.orderStatus !== "1" &&
                                    item.orderStatus !== "2" && (
                                      <IoLogoWhatsapp
                                        className="icon-color"
                                        onClick={() => {
                                          generatePDFAndUpload(item);
                                        }}
                                      />
                                    )}
                                </>
                              )} */}
                            {props.showActions.showWP &&
                              props.page === "purchase-order" && (
                                <IoLogoWhatsapp
                                  className="icon-color"
                                  onClick={() => {
                                    generatePDFAndUpload(item);
                                  }}
                                />
                              )}
                            {props.showActions.showApprove &&
                              props.page === "purchase-requisition" &&
                              (item.orderStatus === "0" ||
                                item.orderStatus === "2") && (
                                <IoIosCheckmarkCircle
                                  className="icon-color"
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "Approve" : undefined
                                  }
                                  // onClick={() => setPrApproveModal(true)}
                                />
                              )}
                            {props.showActions.showReject &&
                              props.page === "purchase-requisition" &&
                              (item.orderStatus === "0" ||
                                item.orderStatus === "2") && (
                                <MdCancel
                                  className="icon-color"
                                  data-bs-toggle={
                                    props.showTooltip ? "tooltip" : undefined
                                  }
                                  title={
                                    props.showTooltip ? "Reject" : undefined
                                  }
                                />
                              )}

                            {props.showActions.showPO &&
                              props.page === "purchase-requisition" &&
                              (item.orderStatus === "1" ||
                                item.orderStatus === "2") && (
                                <Button
                                  data-bs-toggle="tooltip"
                                  title="Purchase Order"
                                  onClick={() => handlePO(item)}
                                  className="KOT-btn"
                                  name={<p className="kot-icon">PO</p>}
                                ></Button>
                              )}

                            {props.showActions.showGRN &&
                              props.page === "purchase-order" && (
                                <Button
                                  data-bs-toggle="tooltip"
                                  title="Goods Receive Note"
                                  onClick={() =>
                                    handleGRN(
                                      props.showActions.navigateGRN,
                                      item
                                    )
                                  }
                                  className="KOT-btn"
                                  name={<p className="kot-icon">GRN</p>}
                                ></Button>
                              )}

                            {props.showActions.update &&
                              props.page === "product-pricing" && (
                                <IoIosSave
                                  data-bs-toggle="tooltip"
                                  title="Save"
                                  className="icon-color"
                                  onClick={() => {
                                    handleProductPricingUpdate(item);
                                  }}
                                />
                              )}

                            {item.orderStatus === "0" && (
                              <>
                                {props.showActions.showRightIcon && (
                                  <FcCheckmark
                                    data-bs-toggle={
                                      props.showTooltip ? "tooltip" : undefined
                                    }
                                    title={
                                      props.showTooltip ? "Accept" : undefined
                                    }
                                    className="icon-color right-icon-color"
                                    onClick={() =>
                                      handleOrderConfirmationClick(
                                        "Confirm",
                                        item,
                                        tokenData,
                                        dispatch,
                                        orderPage
                                      )
                                    }
                                  />
                                )}
                                &nbsp;
                                {props.showActions.showWrongIcon && (
                                  <RxCross2
                                    data-bs-toggle={
                                      props.showTooltip ? "tooltip" : undefined
                                    }
                                    title={
                                      props.showTooltip ? "Reject" : undefined
                                    }
                                    className="icon-color wrong-icon-color"
                                    onClick={() =>
                                      handleOrderConfirmationClick(
                                        "Cancel",
                                        item,
                                        tokenData,
                                        dispatch,
                                        orderPage
                                      )
                                    }
                                  />
                                )}
                              </>
                            )}
                            {/* changes by sakshi*/}
                            {props.showActions.showRightProductIcon &&
                              item.productStatus !== 1 &&
                              item.productStatus !== "1" && (
                                <>
                                  <FcCheckmark
                                    data-bs-toggle={
                                      props.showTooltip ? "tooltip" : undefined
                                    }
                                    title={
                                      props.showTooltip
                                        ? "Deliver Product"
                                        : undefined
                                    }
                                    className="icon-color right-icon-color"
                                    onClick={() =>
                                      handleLineLevelOrderConfirmationClick(
                                        item,
                                        tokenData,
                                        dispatch,
                                        orderPage
                                      )
                                    }
                                  />
                                  {tableData &&
                                  tableData.length > 1 &&
                                  props.newOrderData.orderStatus !== "3" &&
                                  props.newOrderData.orderStatus !== "4" ? (
                                    <MdDeleteForever
                                      onClick={() =>
                                        deleteLineLevelProduct(item)
                                      }
                                      data-bs-toggle={
                                        props.showTooltip
                                          ? "tooltip"
                                          : undefined
                                      }
                                      title={
                                        props.showTooltip
                                          ? "Remove Product"
                                          : undefined
                                      }
                                      className="wrong-icon-color"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            {props.showActions.showRightProductIcon &&
                              (item.productStatus === 1 ||
                                item.productStatus === "1") && (
                                <span>Delivered</span>
                              )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
            {props.page !== "roles" &&
              props.page !== "role" &&
              props.page !== "users" &&
              props.page !== "category" &&
              props.page !== "subCategory" &&
              props.page !== "leafCategory" &&
              props.page !== "tags" &&
              props.page !== "imageManager" &&
              props.page !== "uploadImage" &&
              props.page !== "brand" &&
              props.page !== "discount" &&
              props.page !== "product" &&
              props.page !== "attributes" &&
              props.page !== "warehouse" &&
              props.page !== "packager" &&
              props.page !== "discountCatalogue" &&
              props.page !== "inventory" &&
              props.page !== "branch" &&
              props.page !== "serviceProvider" &&
              props.page !== "service" &&
              props.page !== "import" &&
              props.page !== "order" &&
              props.page !== "customer" &&
              props.page !== "add-company" &&
              props.page !== "company" &&
              props.page !== "inventory-item" &&
              props.page !== "vendors" &&
              props.page !== "product-inventory" &&
              props.page !== "stocks" &&
              props.page !== "StockHistory" &&
              props.page !== "new-vendor-person" &&
              props.page !== "units" &&
              props.page !== "dashboard" &&
              props.page !== "spots" &&
              props.page !== "purchase-requisition" &&
              props.page !== "purchase-order" &&
              props.page !== "grn" &&
              props.page !== "wallet" &&
              props.page !== "product-pricing" && (
                <div className="custom-pagination">
                  <CustomPagination
                    totalRecords={totalRecords}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                </div>
              )}
          </>
        )}

        {props.page === "order" && zomatoSwiggyModal && (
          <div className="modal-overlay">
            <div className="modal-content-order modal-">
              <div className="modal-header">
                <h4>Sales Details</h4>
                <button
                  className="modal-close"
                  onClick={() => setZomatoSwiggyModal(!zomatoSwiggyModal)}
                >
                  <MdOutlineCancel />
                </button>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className="add-roles-form row">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="name-type">
                              <div className="paragraph-editor ">
                                <h4 className="paragraph-title">
                                  Enter Zomato/Swiggy Price
                                  <span className="required-label"></span>
                                </h4>
                              </div>
                              <Input
                                type="text"
                                name="zomatoSwiggyPrice"
                                // label="Zomato/Swiggy Price"
                                placeholder="Enter Price"
                                className="add-roles"
                                maxLength={10}
                                Regex={/[^0-9\s]/g}
                                value={values.zomatoSwiggyPrice}
                                hidelength="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                          <div className="paragraph-editor">
                            <div className="btn-submit order-submit-btn">
                              <Button
                                type="submit"
                                name="Place Order"
                                className="btn login-btn  place-order-btn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}