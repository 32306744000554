import { Dispatch } from "redux";
import {
  accountProductConfigRequest,
  createAccountRequest,
  getAccountDetailsRequest,
  getVerifyAccountRequest,
  getVerifyResponse,
  stakeHolderRequest,
} from "./PaymentDetailModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { postApiService } from "../../../services/API-services/PostService";
import {
  API_URL,
  removePersistStorage,
  toastClass,
  ValidationMessage,
} from "../../../Utils/Utils";
import { putApiService } from "../../../services/API-services/PutService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  clearPaymentDetailData,
  getPaymentDetailData,
} from "../../../redux/Actions/PaymentDetailAction/PaymentDetailAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  clearAccountDetailData,
  getAccountDetailData,
} from "../../../redux/Actions/PaymentDetailAction/AccountDetailAction";
import {
  clearStakeHolderDetailData,
  getStakeHolderDetailData,
} from "../../../redux/Actions/PaymentDetailAction/StakeHolderDetailAction";
import {
  accountProductConfigData,
  clearAccountProductConfigData,
} from "../../../redux/Actions/PaymentDetailAction/AccountProductConfigAction";
import {
  clearVerifyAccountData,
  getVerifyAccountData,
} from "../../../redux/Actions/PaymentDetailAction/VerifyAccountAction";
import { postServicePayment } from "../../../services/API-services/PostServicePayment";

let errorShow = false;

/*
 * Service to get Account Details
 */
export const getAccountDetailService = (
  accountDetailRequest: getAccountDetailsRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAccountDetailsRequest> => {
  // Update return type here
  return new Promise<getAccountDetailsRequest>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        accountDetailRequest.id !== undefined &&
        accountDetailRequest.id !== null
      ) {
        queryParams += `${accountDetailRequest.id}`;
      }

      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.RAZORPAY +
          API_URL.RAZORPAYACCOUNT +
          "/" +
          (queryParams && queryParams !== "" ? `${queryParams}` : ""),
        token
      );
      // const response = await getApiService(
      //   API_URL.BASE_URL +
      //     API_URL.RAZORPAY +
      //     (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
      //   token
      // );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(getAccountDetailData(response));
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Wallet permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for  creating Account
 */
export const createAccountService = (
  createAccountRequest: createAccountRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    const formData = new FormData();
    createAccountRequest.business_proof_of_identification.forEach(
      (image, index) => {
        formData.append("business_proof_of_identification", image);
      }
    );

    formData.append("email", createAccountRequest.email.toString());
    formData.append("phone", createAccountRequest.phone.toString());
    formData.append("type", createAccountRequest.type.toString());
    formData.append(
      "reference_id",
      createAccountRequest.reference_id.toString()
    );
    formData.append(
      "legal_business_name",
      createAccountRequest.legal_business_name.toString()
    );
    formData.append(
      "business_type",
      createAccountRequest.business_type.toString()
    );
    formData.append(
      "contact_name",
      createAccountRequest.contact_name.toString()
    );
    formData.append(
      "business_proof_of_identification_document_type",
      createAccountRequest.business_proof_of_identification_document_type.toString()
    );
    formData.append(
      "profileData",
      JSON.stringify(createAccountRequest.profileData)
    );
    formData.append(
      "legal_infoData",
      JSON.stringify(createAccountRequest.legal_infoData)
    );
    try {
      dispatch(setLoader());
      const response = await postServicePayment(
        API_URL.BASE_URL + API_URL.RAZORPAY + API_URL.CREATEACCOUNT,
        token,
        formData
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearPaymentDetailData());
        dispatch(clearAccountDetailData());
        dispatch(clearStakeHolderDetailData());
        dispatch(clearAccountProductConfigData());
        dispatch(clearVerifyAccountData());
        dispatch(getPaymentDetailData(response.data));
        toast.success("Account Created Successfully");
        navigate("/payment-details");
      } else {
        toast.error(
          response?.message
            ? response.message
            : response?.error &&
              response.error?.error &&
              response.error?.error?.description
            ? response.error?.error.description
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          //   dispatch(getBranchPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

export const stackHolderService = (
  stakeHolderRequest: stakeHolderRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    const formData = new FormData();
    const addressProofTypes =
      stakeHolderRequest.individual_proof_of_address_type.join(",");
    stakeHolderRequest.individual_proof_of_address.forEach((file) => {
      formData.append("individual_proof_of_address", file);
    });
    formData.append("individual_proof_of_address_type", addressProofTypes);
    stakeHolderRequest.individual_proof_of_identification.forEach(
      (image, index) => {
        formData.append("individual_proof_of_identification", image);
      }
    );
    formData.append(
      "individual_proof_of_identification_type",
      stakeHolderRequest.individual_proof_of_identification_type.toString()
    );
    formData.append("email", stakeHolderRequest.email.toString());
    formData.append("accountId", stakeHolderRequest.accountId.toString());
    formData.append("name", stakeHolderRequest.name.toString());
    formData.append(
      "addressesData",
      JSON.stringify(stakeHolderRequest.addressesData)
    );
    formData.append("kycData", JSON.stringify(stakeHolderRequest.kycData));
    try {
      dispatch(setLoader());
      const response = await postServicePayment(
        API_URL.BASE_URL + API_URL.RAZORPAY + API_URL.CREATESTAKEHOLDERACCOUNT,
        token,
        formData
      );

      if (
        response &&
        response.message === "Stakeholder account successfully created"
      ) {
        dispatch(clearStakeHolderDetailData());
        if (type === "add") {
          dispatch(getStakeHolderDetailData(response));
          toast.success("StakeHolder Account Created Successfully!");
          navigate("/payment-details");
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : response?.error &&
              response.error?.error &&
              response.error?.error.description
            ? response.error?.error?.description
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          //   dispatch(getBranchPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

export const accountProductConfigService = (
  accountProductConfigRequest: accountProductConfigRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      response = await postApiService(
        API_URL.BASE_URL +
          API_URL.RAZORPAY +
          API_URL.UPDATEACCOUNTPRODUCTCONFIG,
        token,
        accountProductConfigRequest
      );
      if (
        response &&
        response.message === "Stakeholder account successfully created"
      ) {
        dispatch(clearAccountProductConfigData());
        dispatch(accountProductConfigData(response));
        toast.success(
          "Your registration was successful! Our team is reviewing your details, and within 48 hours, your account will be verified and activated, enabling you to start collecting online payments."
        );
        navigate("/payment-details");
        return response;
      } else {
        toast.error(
          response?.message
            ? response.message
            : response?.error &&
              response.error?.error &&
              response.error?.error?.description
            ? response.error?.error.description
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          //   dispatch(getBranchPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service to get Account Details
 */
export const verifyAccountService = (
  getVerifyAccountRequest: getVerifyAccountRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getVerifyResponse> => {
  // Update return type here
  return new Promise<getVerifyResponse>(async (resolve, reject) => {
    // Update type parameter here
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        getVerifyAccountRequest.account_id !== undefined &&
        getVerifyAccountRequest.account_id !== null
      ) {
        queryParams += `account_id=${getVerifyAccountRequest.account_id}&`;
      }
      if (
        getVerifyAccountRequest.product_id !== undefined &&
        getVerifyAccountRequest.product_id !== null
      ) {
        queryParams += `product_id=${getVerifyAccountRequest.product_id}&`;
      }

      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.RAZORPAY +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(getVerifyAccountData(response));
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Wallet permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};
