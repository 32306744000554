/**
 * Setting Purchase Requisition list data into reducer
 * @param {*} purchaseRequisition
 * @returns {*}
 */
export const getPurchaseRequisitionData = (purchaseRequisition) => ({
  type: "GET_PURCHASE_REQUISITION_DATA",
  payload: purchaseRequisition,
});

/**
 * clearing Purchase Requisition list data
 * @returns {*}
 */
export const clearPurchaseRequisitionData = () => ({
  type: "CLEAR_PURCHASE_REQUISITION_DATA",
});

/**
 * Setting total Purchase Requisition list data into reducer (No pagnation)
 * @param {*} purchaseRequisition
 * @returns {*}
 */
export const getTotalPurchaseRequisitionData = (purchaseRequisition) => ({
  type: "GET_TOTAL_PURCHASE_REQUISITION",
  payload: purchaseRequisition,
});

/**
 * Clearing total Purchase Requisition list data (No pagination)
 * @returns {*}
 */
export const clearTotalPurchaseRequisitionData = () => ({
  type: "CLEAR_TOTAL_PURCHASE_REQUISITION",
});
